import { Button, Text } from '@approvalmax/ui/src/components';
import { intl, mathService } from '@approvalmax/utils';
import AttachmentsDropdown from 'modules/components/Attachment/AttachmentsDropdown';
import moment from 'moment';

import { isFieldVisible } from '../../NetSuiteRequestCard.utils';
import { messages } from './NetSuiteApplyList.messages';
import { ApplyListColumnDefinition, ApplyListColumnOptions } from './NetSuiteApplyList.types';

export const getColumnDefinitions = ({
    fieldSettings,
    exchangeRate,
    activeAttachmentId,
    onSelectAttachment,
    onSelectBill,
}: ApplyListColumnOptions): ApplyListColumnDefinition[] => {
    const columnDef: ApplyListColumnDefinition[] = [
        {
            id: 'dateDue',
            name: messages.dateDue,
            value: ({ dateDue }) => (dateDue ? moment.utc(dateDue).format('ll') : null),
            cell: (value, { requestId }) =>
                value && requestId ? (
                    <Button link onClick={() => onSelectBill(requestId)}>
                        {value}
                    </Button>
                ) : (
                    <Text font='body' wrap={false}>
                        {value}
                    </Text>
                ),
            isHidden: !isFieldVisible(fieldSettings.dateDue),
        },
        {
            id: 'type',
            name: messages.type,
            value: ({ type }) => type,
            cell: (value, { requestId }) =>
                value && requestId ? (
                    <Button link onClick={() => onSelectBill(requestId)}>
                        {value}
                    </Button>
                ) : (
                    <Text font='body' wrap={false}>
                        {value}
                    </Text>
                ),
            isHidden: !isFieldVisible(fieldSettings.type),
        },
        {
            id: 'reference',
            name: messages.reference,
            value: ({ reference }) => reference,
            cell: (value) => (
                <Text font='body' wrap={false}>
                    {value}
                </Text>
            ),
            isHidden: !isFieldVisible(fieldSettings.reference),
        },
        {
            id: 'originalAmount',
            name: messages.originalAmount,
            value: ({ originalAmount }) => {
                let amount = originalAmount || 0;

                if (typeof exchangeRate === 'number') {
                    amount = mathService.multiply(amount, exchangeRate);
                    amount = mathService.round(amount, 2);
                }

                return intl.formatNumber(amount, 'auto');
            },
            alignRight: true,
            cell: (value) => (
                <Text font='body' wrap={false}>
                    {value}
                </Text>
            ),
            isHidden: !isFieldVisible(fieldSettings.originalAmount),
        },
        {
            id: 'amountDue',
            name: messages.amountDue,
            value: ({ amountDue }) => {
                let amount = amountDue || 0;

                if (typeof exchangeRate === 'number') {
                    amount = mathService.multiply(amount, exchangeRate);
                    amount = mathService.round(amount, 2);
                }

                return intl.formatNumber(amount, 'auto');
            },
            alignRight: true,
            cell: (value) => (
                <Text font='body' wrap={false}>
                    {value}
                </Text>
            ),
            isHidden: !isFieldVisible(fieldSettings.amountDue),
        },
        {
            id: 'currency',
            name: messages.currencyName,
            value: ({ currencyName }) => currencyName,
            cell: (value) => (
                <Text font='body' wrap={false}>
                    {value}
                </Text>
            ),
            isHidden: !isFieldVisible(fieldSettings.currency),
        },
        {
            id: 'discountDate',
            name: messages.discountDate,
            value: ({ discountDate }) => (discountDate ? moment.utc(discountDate).format('ll') : null),
            isHidden: !isFieldVisible(fieldSettings.discountDate),
        },
        {
            id: 'discountAvailable',
            name: messages.discountAvailable,
            value: ({ discountAvailable }) => {
                let discount = discountAvailable || 0;

                if (!discount) {
                    return null;
                }

                if (typeof exchangeRate === 'number') {
                    discount = mathService.multiply(discount, exchangeRate);
                    discount = mathService.round(discount, 2);
                }

                return intl.formatNumber(discount, 'auto');
            },
            alignRight: true,
            cell: (value) => (
                <Text font='body' wrap={false}>
                    {value}
                </Text>
            ),
            isHidden: !isFieldVisible(fieldSettings.discountAvailable),
        },
        {
            id: 'discountAmount',
            name: messages.discountAmount,
            value: ({ discountAmount }) => {
                let amount = discountAmount || 0;

                if (!amount) {
                    return null;
                }

                if (typeof exchangeRate === 'number') {
                    amount = mathService.multiply(amount, exchangeRate);
                    amount = mathService.round(amount, 2);
                }

                return intl.formatNumber(amount, 'auto');
            },
            alignRight: true,
            cell: (value) => (
                <Text font='body' wrap={false}>
                    {value}
                </Text>
            ),
            isHidden: !isFieldVisible(fieldSettings.discountAmount),
        },
        {
            id: 'paymentAmount',
            name: messages.paymentAmount,
            value: ({ paymentAmount }) => {
                let amount = paymentAmount || 0;

                if (typeof exchangeRate === 'number') {
                    amount = mathService.multiply(amount, exchangeRate);
                    amount = mathService.round(amount, 2);
                }

                return intl.formatNumber(amount, 'auto');
            },
            alignRight: true,
            cell: (value) => (
                <Text font='body' wrap={false}>
                    {value}
                </Text>
            ),
            isHidden: !isFieldVisible(fieldSettings.paymentAmount),
        },
        {
            id: 'attachments',
            name: '',
            value: ({ attachments }) => attachments?.length.toString() || '',
            cell: (_value, { attachments, requestId, friendlyName }) =>
                attachments && attachments.length > 0 ? (
                    <AttachmentsDropdown
                        attachments={attachments}
                        activeAttachmentId={activeAttachmentId}
                        onSelectAttachment={(attachmentId: string) =>
                            requestId && friendlyName && onSelectAttachment(attachmentId, requestId, friendlyName)
                        }
                    />
                ) : null,
        },
    ];

    return columnDef.filter((col) => !col.isHidden);
};
