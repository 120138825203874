import { ExtractComponentProp } from '@approvalmax/types';
import { AvatarDropzone } from '@approvalmax/ui/src/components';
import { actions as actionsCommon } from 'modules/common';
import { useDispatch } from 'modules/react-redux';
import { useCurrentUser } from 'modules/utils';
import { useCallback } from 'react';
import { useSetAvatar } from 'shared/data/v1';

export const useSectionGeneral = () => {
    const dispatch = useDispatch();
    const user = useCurrentUser();

    const { mutate: setAvatar, isLoading: isLoadingSetAvatar } = useSetAvatar({
        onSuccess: ({ urlHttps: avatar }) => {
            dispatch(actionsCommon.updateProfile(user.id, { avatar }, { avatar }));
        },
    });

    const handleUploadAvatar = useCallback<ExtractComponentProp<typeof AvatarDropzone, 'onUpload'>>(
        async (file) => {
            const body = new FormData();

            body.append('file', file.source);

            setAvatar({ body });
        },
        [setAvatar]
    );

    // TODO: Remove after replaced with PhoneField component
    const handleTransformPhone = useCallback((value: string) => {
        let result = value.replace(/[^0-9+]/g, '');

        if (result && !result.startsWith('+')) {
            result = '+' + result;
        }

        return result;
    }, []);

    return {
        handleUploadAvatar,
        handleTransformPhone,
        isLoading: isLoadingSetAvatar,
    };
};
