import { HelpCircleIcon } from '@approvalmax/ui';
import { Alert, Button, Flex, Form, Popup, Spacing, toast } from '@approvalmax/ui/src/components';
import { RequestListFilter } from 'pages/requestList/config';
import { FC, memo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useRecoilValue } from 'recoil';
import { routingService } from 'services/routing';
import { OcrFileContentType, useUploadOcrDocument } from 'shared/data/v2';
import { getPath, Path } from 'urlBuilder';

import { AdditionalOptions } from './AdditionalOptions/AdditionalOptions';
import { getOcrDocumentTypeFromIntegrationCode } from './BulkCreationPopupContent.helpers';
import { messages } from './BulkCreationPopupContent.messages';
import { bulkCreationPopupContentState } from './BulkCreationPopupContent.states';
import { AttachmentWithInnerId, BulkCreationPopupContentProps, FormValues } from './BulkCreationPopupContent.types';
import { FileContentType } from './FileContentType/FileContentType';
import { UploadFiles } from './UploadFiles/UploadFiles';

export const BulkCreationPopupContent: FC<BulkCreationPopupContentProps> = memo((props) => {
    const { closePopup } = props;

    const bulkCreationPopupContent = useRecoilValue(bulkCreationPopupContentState);

    const [attachments, setAttachments] = useState<AttachmentWithInnerId[]>([]);
    const [editCompany, setEditCompany] = useState(false);

    const { mutate, isLoading } = useUploadOcrDocument({
        onSuccess: () => {
            routingService.push(getPath(Path.requestMyList, RequestListFilter.MyOcrRequests));
            toast.success(messages.bulkUploadSuccess({ numberBills: attachments.length }));
        },
        onSettled: () => {
            closePopup();
        },
    });

    const form = useForm<FormValues>({
        defaultValues: {
            fileContentType: OcrFileContentType.DocumentPerFile,
            selectedCompanyId: bulkCreationPopupContent.companyId,
            selectedTemplateIntegrationCode: bulkCreationPopupContent.templateIntegrationCode,
            useLinesMerge: false,
        },
    });

    const onSubmit = form.handleSubmit((data) => {
        const documentType = getOcrDocumentTypeFromIntegrationCode(data.selectedTemplateIntegrationCode);

        if (!documentType) return;

        mutate({
            params: {
                path: {
                    companyId: data.selectedCompanyId,
                    documentType: documentType,
                },
            },
            body: {
                fileUploadOptions: { fileContentType: data.fileContentType },
                fileRecordIds: attachments.map((attachment) => attachment.id),
                useLinesMerge: data.useLinesMerge,
            },
        });
    });

    return (
        <Form form={form} onSubmit={onSubmit}>
            <Popup.Header
                title={messages.title}
                progress={isLoading}
                actions={
                    <Button
                        disabled={editCompany || isLoading || attachments.length === 0}
                        color='blue80'
                        type='submit'
                        title={messages.uploadButtonTitle}
                    >
                        {messages.uploadButtonTitle}
                    </Button>
                }
            />

            <Popup.Body>
                {attachments.length > 1 && (
                    <>
                        <Alert startIcon={<HelpCircleIcon />} size='small' color='midnight20' closable>
                            {messages.multipleAttachmentsWarning}
                        </Alert>

                        <Spacing height={16} />
                    </>
                )}

                <Flex container spacing='16' wrap={false}>
                    <FileContentType disabled={isLoading} />

                    <AdditionalOptions disabled={isLoading} editCompany={editCompany} setEditCompany={setEditCompany} />
                </Flex>

                <UploadFiles disabled={isLoading} attachments={attachments} setAttachments={setAttachments} />
            </Popup.Body>
        </Form>
    );
});

BulkCreationPopupContent.displayName = 'BulkCreationPopupContent';
