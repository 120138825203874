import { ValueOf } from '@approvalmax/types';
import { selectors } from 'modules/common';
import { domain } from 'modules/data';
import { UpdateProfile } from 'shared/data/v1';

import { fieldNameNotificationsSection } from './SectionNotifications.constants';

export const getSectionNotificationsDefaultValues = (profile: selectors.types.ExpandedProfile) => {
    return {
        [fieldNameNotificationsSection.newRequests]: profile.sendNewRequestsNotifications ?? true,
        [fieldNameNotificationsSection.comments]: profile.sendCommentsNotifications ?? true,
        [fieldNameNotificationsSection.other]: profile.sendApprovalChangeNotifications ?? true,
        [fieldNameNotificationsSection.workflow]: profile.sendWorkflowNotifications ?? true,
        [fieldNameNotificationsSection.summary]: profile.sendSummaryNotifications ?? false,
        [fieldNameNotificationsSection.summaryDay]: profile.summaryNotificationsSendOnDays ?? [],
        [fieldNameNotificationsSection.summaryTime]: profile.summaryNotificationsSendAtTime ?? '',
    };
};

export const getSectionNotificationsSaveToProfileValues = (
    profile: selectors.types.ExpandedProfile,
    data: UpdateProfile['body']
) => {
    return {
        sendNewRequestsNotifications:
            data[fieldNameNotificationsSection.newRequests] ?? profile.sendNewRequestsNotifications,
        sendCommentsNotifications: data[fieldNameNotificationsSection.comments] ?? profile.sendCommentsNotifications,
        sendApprovalChangeNotifications:
            data[fieldNameNotificationsSection.other] ?? profile.sendApprovalChangeNotifications,
        sendWorkflowNotifications: data[fieldNameNotificationsSection.workflow] ?? profile.sendWorkflowNotifications,
        sendSummaryNotifications: data[fieldNameNotificationsSection.summary] ?? profile.sendSummaryNotifications,
        summaryNotificationsSendOnDays: (
            data[fieldNameNotificationsSection.summaryDay] ?? profile.summaryNotificationsSendOnDays
        ).filter(isDayOfWeek),
        summaryNotificationsSendAtTime:
            data[fieldNameNotificationsSection.summaryTime] ?? profile.summaryNotificationsSendAtTime,
    };
};

export const isDayOfWeek = (content: unknown): content is ValueOf<domain.DayOfWeek> => {
    return typeof content === 'string' && Object.values<string>(domain.DayOfWeek).includes(content);
};
