import { Guid, Reference } from '@approvalmax/types';
import { arrayHelpers, compareHelpers, errorHelpers, miscHelpers } from '@approvalmax/utils';
import cloneDeep from 'lodash/cloneDeep';
import find from 'lodash/find';
import flatten from 'lodash/flatten';
import map from 'lodash/map';
import { ILogger } from 'modules/automation/utils/Logger';
import { automation, constants, selectors } from 'modules/common';
import { backend, dataApi, domain, factories, schemas } from 'modules/data';
import moment from 'moment';
import { normalize } from 'normalizr';
import { api } from 'services/api';

import { checkCopySettingsPossibility } from './utils/helpers';

const getState = automation.getState;

/*

var a = window.ApprovalMax.automation;
var sourceCompanyId = a.common.getCompanyIdByName('Global');
var targetCompanyId = a.common.getCompanyIdByName('Xero2');
var integrationCode = domain.IntegrationCode.XERO_PO;
a.templates.cloneTemplateToAnotherCompany(sourceCompanyId, targetCompanyId, integrationCode);

*/

export interface TemplateBackup {
    template: domain.Template;
    creationDate: string;
}

class CopySettingsError extends Error {
    constructor(message?: string) {
        super(message);
        this.name = 'CopySettingsError';
    }
}

// Xero Workflows that can be copied to each other
const xeroWorkflowsWithCrossCopy: [code: domain.IntegrationCode, fields: domain.FieldSystemPurpose[]][] = [
    [
        domain.IntegrationCode.XeroPo,
        [
            domain.FieldSystemPurpose.Amount,
            domain.FieldSystemPurpose.Requester,
            domain.FieldSystemPurpose.XeroAccount,
            domain.FieldSystemPurpose.XeroBranding,
            domain.FieldSystemPurpose.XeroItem,
            domain.FieldSystemPurpose.XeroSupplier,
            domain.FieldSystemPurpose.XeroTax,
            domain.FieldSystemPurpose.XeroTracking,
        ],
    ],
    [
        domain.IntegrationCode.XeroBill,
        [
            domain.FieldSystemPurpose.Amount,
            domain.FieldSystemPurpose.Requester,
            domain.FieldSystemPurpose.XeroAccount,
            domain.FieldSystemPurpose.XeroItem,
            domain.FieldSystemPurpose.XeroSupplier,
            domain.FieldSystemPurpose.XeroTax,
            domain.FieldSystemPurpose.XeroTracking,
        ],
    ],
    [
        domain.IntegrationCode.XeroCreditNotesReceivable,
        [
            domain.FieldSystemPurpose.Amount,
            domain.FieldSystemPurpose.XeroAccount,
            domain.FieldSystemPurpose.XeroCustomer,
            domain.FieldSystemPurpose.XeroItem,
            domain.FieldSystemPurpose.XeroTax,
            domain.FieldSystemPurpose.XeroTracking,
        ],
    ],
    [
        domain.IntegrationCode.XeroCreditNotesPayable,
        [
            domain.FieldSystemPurpose.Amount,
            domain.FieldSystemPurpose.XeroAccount,
            domain.FieldSystemPurpose.XeroItem,
            domain.FieldSystemPurpose.XeroSupplier,
            domain.FieldSystemPurpose.XeroTax,
            domain.FieldSystemPurpose.XeroTracking,
        ],
    ],
    [
        domain.IntegrationCode.XeroInvoice,
        [
            domain.FieldSystemPurpose.Amount,
            domain.FieldSystemPurpose.XeroAccount,
            domain.FieldSystemPurpose.XeroCustomer,
            domain.FieldSystemPurpose.XeroItem,
            domain.FieldSystemPurpose.XeroTax,
            domain.FieldSystemPurpose.XeroTracking,
        ],
    ],
    [
        domain.IntegrationCode.XeroQuote,
        [
            domain.FieldSystemPurpose.Amount,
            domain.FieldSystemPurpose.Requester,
            domain.FieldSystemPurpose.XeroAccount,
            domain.FieldSystemPurpose.XeroBranding,
            domain.FieldSystemPurpose.XeroCustomer,
            domain.FieldSystemPurpose.XeroItem,
            domain.FieldSystemPurpose.XeroTax,
            domain.FieldSystemPurpose.XeroTracking,
        ],
    ],
];

const checkIfConditionCouldBeCopied = (
    fromTemplate: domain.Template,
    toTemplate: domain.Template | null,
    sourceFieldSystemPurpose: domain.FieldSystemPurpose
) => {
    /**
     * For Xero Workflows with possibility of cross-type copy
     * we should check existence of field from source workflow
     * in target workflow
     */
    if (
        fromTemplate.integrationCode !== toTemplate?.integrationCode &&
        fromTemplate.integrationCode?.startsWith('Xero') &&
        toTemplate?.integrationCode?.startsWith('Xero')
    ) {
        const toTemplateFields = xeroWorkflowsWithCrossCopy.find(
            ([integrationCode]) => integrationCode === toTemplate.integrationCode
        )?.[1];

        if (toTemplateFields) {
            return (
                toTemplateFields.findIndex((fieldSystemPurpose) => fieldSystemPurpose === sourceFieldSystemPurpose) !==
                -1
            );
        }
    }

    return true;
};

const getIsNetSuite = (integrationCode: domain.IntegrationCode | null) =>
    selectors.integration.getIntegrationType(integrationCode) === domain.IntegrationType.NetSuite;

const fieldsWithCustomValues: domain.FieldSystemPurpose[] = [
    domain.FieldSystemPurpose.XeroTracking,
    domain.FieldSystemPurpose.QBooksClass,
    domain.FieldSystemPurpose.QBooksDepartment,
    domain.FieldSystemPurpose.QBooksCustomer,
    domain.FieldSystemPurpose.QBooksPayeeCustomer,
    domain.FieldSystemPurpose.QBooksPayeeEmployee,
    domain.FieldSystemPurpose.QBooksPaymentAccount,
    domain.FieldSystemPurpose.QBooksPaymentMethod,
    domain.FieldSystemPurpose.QBooksPaymentType,
    domain.FieldSystemPurpose.QBooksPayeeVendor,
    domain.FieldSystemPurpose.QBooksVendor,
    domain.FieldSystemPurpose.QBooksPoItem,
    domain.FieldSystemPurpose.QBooksAccount,
    domain.FieldSystemPurpose.NetSuiteCustom,
];

const fieldMatcher = (
    value: Reference,
    sourceField: domain.Field,
    targetField: domain.Field,
    forgiveMissingValues: boolean
) => {
    if (sourceField.id === targetField.id) {
        return value;
    }

    const specialValuesIds = [
        constants.commonConstants.ANY_VALUE_ID,
        constants.commonConstants.EMPTY_VALUE_ID,
        constants.commonConstants.NOT_EMPTY_VALUE_ID,
    ];

    if (specialValuesIds.includes(value.id)) {
        return value;
    }

    let mappedValue = targetField.exactValues.find((v) => v.text === value.text);

    const isFieldWithCustomValues = fieldsWithCustomValues.includes(targetField.systemPurpose);

    if (!mappedValue && !isFieldWithCustomValues) {
        mappedValue = targetField.exactValues.find((v) => v.id === value.id);
    }

    if (!mappedValue) {
        if (forgiveMissingValues) {
            return null;
        }

        throw new Error(`Can't find a field value with text '${value.text}'.`);
    }

    return mappedValue;
};

const matchFieldsIds = (
    sourceFields: domain.Field[],
    targetFields: domain.Field[],
    sourceFieldsIds: string[]
): string[] =>
    sourceFieldsIds.reduce<string[]>((accumulator, fieldId) => {
        const fromField = sourceFields.find((field) => field.id === fieldId);

        if (fromField) {
            const toField = targetFields.find(
                (field) => field.name === fromField?.name && field.systemPurpose === fromField?.systemPurpose
            );

            if (toField) {
                accumulator.push(toField.id);
            }
        }

        return accumulator;
    }, []);

const qboIntegrationCodes = [
    domain.IntegrationCode.QBooksBill,
    domain.IntegrationCode.QBooksPo,
    domain.IntegrationCode.QBooksExpense,
    domain.IntegrationCode.QBooksJournalEntry,
    domain.IntegrationCode.QBooksVendor,
];

const integrationCodesWithDextSubmitter = [
    domain.IntegrationCode.XeroBill,
    domain.IntegrationCode.QBooksBill,
    domain.IntegrationCode.QBooksExpense,
];

const netSuiteIntegrationCodes = [domain.IntegrationCode.NetSuiteBill, domain.IntegrationCode.NetSuitePO];

const templatesAutomation = {
    async getTemplateByCompanyAndIntegration(
        companyId: string,
        integrationCode: domain.IntegrationCode | null
    ): Promise<{
        template: domain.Template;
        users: domain.User[];
    }> {
        const result = await dataApi.templates.select({ companyId });
        const entities = normalize(result, { Templates: [schemas.templateSchema] }).entities as any;

        return {
            template: find(entities.templates, (t: domain.Template) => t.integrationCode === integrationCode)!,
            users: entities.users,
        };
    },

    async getAllFields(companyId: string, integrationCode: domain.IntegrationCode | null) {
        let rowNum = 3000;

        if (integrationCode && netSuiteIntegrationCodes.includes(integrationCode)) {
            rowNum = 10000;
        }

        if (integrationCode && qboIntegrationCodes.includes(integrationCode)) {
            rowNum = 1000;
        }

        if (integrationCode === domain.IntegrationCode.XeroAirwallexBatchPayment) {
            rowNum = 2000;
        }

        let result = await api.companies.selectFields({
            companyId,
            integrationCode,
            withValues: true,
            startFrom: 0,
            rowNum,
        });

        const normalizedFields: Record<string, domain.Field> | undefined = normalize(result, {
            Fields: [schemas.fieldSchema],
        }).entities.fields;

        return normalizedFields ? Object.values(normalizedFields) : [];
    },

    async createField(companyId: string, fieldNames: string[]) {
        let result = await api.companies.createFields({
            fields: fieldNames.map((fieldName) => ({
                name: fieldName,
                fieldType: backend.FieldType.ExactValueRange,
                exactValues: [],
            })),
            companyId,
        });

        const normalizedFields: Record<string, domain.Field> | undefined = normalize(result, {
            Fields: [schemas.fieldSchema],
        }).entities.fields;

        return normalizedFields ? Object.values(normalizedFields) : [];
    },

    async _createMissingFields(
        companyId: string,
        targetFields: domain.Field[],
        template: domain.Template,
        logger: ILogger
    ) {
        let missingFields: string[] = [];

        function extractRules(lines: domain.MatrixLine[]) {
            return flatten(lines.map((l) => l.rules));
        }

        template = cloneDeep(template);

        let rules: domain.MatrixRule[] = ([] as domain.MatrixRule[]).concat(
            extractRules(template.submitterMatrix),
            flatten(template.steps.map((s) => extractRules(s.participantMatrix))),
            flatten(template.steps.map((s) => extractRules(s.editorMatrix)))
        );

        rules.forEach((r) =>
            r.conditions.forEach((c) => {
                const conditionField = find(targetFields, (field) => field.name === c.fieldName)!;

                if (!conditionField && !missingFields.includes(c.fieldName)) {
                    missingFields.push(c.fieldName);
                }
            })
        );

        if (missingFields.length > 0) {
            logger.info(`Creating missing fields: ${missingFields.join(', ')}`);

            const newFields = await this.createField(companyId, missingFields);

            logger.info('/Created missing fields');
            targetFields.push(...newFields);
        }
    },

    async convertRules(params: {
        userId: string;
        rules: domain.MatrixRule[];
        fromTemplate: domain.Template;
        toTemplate: domain.Template | null;
        toCompany: domain.Company;
        sourceFields: domain.Field[];
        targetFields: domain.Field[];
        fieldMatcherFn: typeof fieldMatcher;
        logger: ILogger;
    }) {
        const { userId, rules, fromTemplate, toTemplate, sourceFields, targetFields, fieldMatcherFn, logger } = params;

        rules.forEach((rule) => {
            rule.conditions = rule.conditions
                .map((condition) => {
                    if (!checkIfConditionCouldBeCopied(fromTemplate, toTemplate, condition.fieldSystemPurpose)) {
                        return null;
                    }

                    const conditionField = find(targetFields, (field) => field.name === condition.fieldName);

                    if (!conditionField) {
                        if (fieldsWithCustomValues.includes(condition.fieldSystemPurpose)) {
                            return null;
                        }

                        throw errorHelpers.invalidOperationError(
                            `Failed to find the field ${condition.fieldName} for the specified condition in the target company.`
                        );
                    }

                    condition.fieldId = conditionField.id;
                    condition.fieldName = conditionField.name;

                    switch (condition.conditionType) {
                        case domain.ConditionType.ExactValuesCondition:
                        case domain.ConditionType.NegativeExactValuesCondition:
                            condition.exactValues = condition.exactValues
                                .map((value) => {
                                    const sourceField = sourceFields.find(
                                        (field) => field.name === condition.fieldName
                                    )!;
                                    const targetField = targetFields.find(
                                        (field) => field.name === condition.fieldName
                                    )!;

                                    let targetValue = fieldMatcherFn(value, sourceField, targetField, true);

                                    if (targetValue === null) {
                                        if (!fromTemplate.integrationCode) {
                                            // Create values in standalone field
                                            logger.info(`Field ${targetField.name}; New value: '${value.text}'`);
                                            targetValue = {
                                                id: value.id,
                                                text: value.text,
                                            };
                                            targetField.exactValues.push(targetValue);
                                        } else {
                                            logger.warn(
                                                `${userId}, ${conditionField.name}: failed to find exact value '${value.text}'`
                                            );

                                            return null;
                                        }
                                    }

                                    return targetValue;
                                })
                                .filter((x) => x) as Reference[];
                            break;

                        default:
                            break;
                    }

                    return condition;
                })
                .filter(miscHelpers.notEmptyFilter);
        });
    },

    async cloneTemplate({
        fromCompany,
        fromTemplate,
        toCompany,
        toTemplate,
        logger,
        copySettings,
        deactivateOnCopy,
    }: {
        fromCompany: domain.Company;
        fromTemplate: domain.Template;
        toCompany: domain.Company;
        toTemplate: domain.Template | null;
        logger: ILogger;
        copySettings: boolean;
        deactivateOnCopy: boolean;
    }) {
        const pseudoAuthorId = fromCompany.managers[0];

        // create mock-toTemplate if it's not specified
        if (!toTemplate) {
            if (fromTemplate.integrationCode) {
                throw errorHelpers.invalidOperationError(
                    'You can only omit toTemplate property when copying a standalone template.'
                );
            } else {
                toTemplate = cloneDeep(fromTemplate);
                toTemplate.id = factories.template.createStandaloneTemplate(toCompany.id, pseudoAuthorId).id;
            }
        } else {
            this.backupTemplate(toTemplate);
        }

        // invariant check
        if (fromTemplate.integrationCode !== toTemplate.integrationCode) {
            logger.warn(
                'Integration codes do not match. It might be a mistake ' +
                    '(but in some cases it is actually what you want).'
            );
        }

        // globals
        const integrationCode = fromTemplate.integrationCode;

        let result: domain.Template = cloneDeep(fromTemplate);

        // fetching field data
        logger.info('requesting fields...');

        const sourceFields = await this.getAllFields(fromTemplate.companyId, integrationCode);

        let targetFields = sourceFields;

        if (fromTemplate.companyId !== toCompany.id) {
            targetFields = await this.getAllFields(toCompany.id, integrationCode);
        }

        console.info('source fields:', sourceFields);
        logger.info(`source fields: ${map(sourceFields, (x: any) => x.name)}`);
        console.info('target fields:', targetFields);
        logger.info(`target fields: ${map(targetFields, (x: any) => x.name)}`);

        if (!fromTemplate.integrationCode) {
            await this._createMissingFields(toCompany.id, targetFields, fromTemplate, logger);
        }

        // transforming requesters matrix
        console.info('transforming source template...');
        logger.info('transforming source template...');
        result.id = toTemplate.id;
        result.integrationCode = toTemplate.integrationCode;
        result.templateName = toTemplate.templateName;
        result.autoApprovalRules = toTemplate.autoApprovalRules;
        result.companyId = toCompany.id;

        const toXeroQuote = toTemplate.integrationCode === domain.IntegrationCode.XeroQuote;

        // xero quote template does not have externalSubmitter, nor do some other templates
        // but only xero quote template can be copied from other workflows
        result.externalSubmitter = toXeroQuote ? null : fromTemplate.externalSubmitter || toTemplate.externalSubmitter;

        result.emailExternalSubmitter = toTemplate.emailExternalSubmitter;
        result.version = toTemplate.version;
        result.reviewStep = toTemplate.reviewStep;
        logger.info('transforming submitter matrix...');

        const shouldNotHaveSubmitters = [
            domain.IntegrationCode.XeroCreditNotesPayable,
            domain.IntegrationCode.XeroCreditNotesReceivable,
            domain.IntegrationCode.XeroInvoice,
        ].includes(result.integrationCode!);

        if (shouldNotHaveSubmitters) {
            result.submitterMatrix = [];
        }

        const shouldHaveDextSubmitter =
            result.integrationCode && integrationCodesWithDextSubmitter.includes(result.integrationCode);

        const hasActiveDextConnection = toCompany.receiptBankIntegration?.isConnected;

        if (!shouldHaveDextSubmitter || !hasActiveDextConnection) {
            result.receiptBankExternalSubmitter = null;
        }

        result.submitterMatrix.forEach((mx) => {
            this.convertRules({
                userId: mx.lineId,
                rules: mx.rules,
                fromTemplate,
                toTemplate,
                toCompany,
                sourceFields,
                targetFields,
                fieldMatcherFn: fieldMatcher,
                logger,
            });
        });
        logger.info('/transformed submitter matrix');

        // creating users in requesters mx (to then reuse their ids in steps)
        const isNew = selectors.template.expandTemplate(result).isNew;

        result.steps = [factories.template.createStep(pseudoAuthorId)];

        if (deactivateOnCopy) {
            result.enabled = false;
        } else {
            result.enabled = toTemplate.enabled;
        }

        const isNetSuite = getIsNetSuite(fromTemplate.integrationCode);

        if (isNetSuite) {
            result.documentFields = (result.documentFields ?? []).reduce<domain.DocumentField[]>(
                (documentFields, documentField) => {
                    const targetDocumentField = toTemplate.documentFields?.find(
                        ({ purpose }) => purpose === documentField.purpose
                    );

                    if (!targetDocumentField) {
                        return documentFields;
                    }

                    if (targetDocumentField.availableStates.includes(documentField.state)) {
                        documentFields.push(documentField);

                        return documentFields;
                    }

                    documentFields.push({
                        ...documentField,
                        state: targetDocumentField.defaultState,
                    });

                    return documentFields;
                },
                []
            );
        }

        const templateDataTransfer1 = selectors.template.getTemplateTransfer(getState(), result, {
            skipEmptyAutoApprovalRules: true,
        });

        if (isNew) {
            console.info('creating new template + missing users in the target company...', templateDataTransfer1);
            logger.info('creating new template + missing users in the target company...');
        } else {
            console.info('creating missing users in the target company...', templateDataTransfer1);
            logger.info('creating missing users in the target company...');
        }

        let savedTemplateResponse;

        if (isNew) {
            savedTemplateResponse = await api.templates.create(templateDataTransfer1);
        } else if ('templateId' in templateDataTransfer1) {
            savedTemplateResponse = await api.templates.edit(templateDataTransfer1);
        } else {
            throw errorHelpers.invalidOperationError();
        }

        result.id = savedTemplateResponse.Template.TemplateId;
        result.version = savedTemplateResponse.Template.Version;

        if (deactivateOnCopy) {
            result.enabled = false;
        } else {
            result.enabled = toTemplate.enabled;
        }

        logger.info('/created');

        // transforming step matrices
        targetFields = await this.getAllFields(toCompany.id, integrationCode);
        result.steps = cloneDeep(fromTemplate.steps);

        result.steps.forEach((step, i) => {
            logger.info(`step #${i + 1} (${step.name})...`);

            if (!fromTemplate.integrationCode || isNetSuite) {
                logger.info(`transforming standalone field order...`);
                step.generalFieldOrder = (step.generalFieldOrder || []).map((fieldId) => {
                    const sourceField = sourceFields.find((f) => f.id === fieldId)!;
                    const targetField = find(targetFields, (field) => field.name === sourceField.name)!;

                    return targetField.id;
                });
                logger.info(`/transformed standalone field order...`);
            }

            logger.info(`transforming participant matrix...`);
            step.participantMatrix.forEach((mx) => {
                this.convertRules({
                    userId: mx.lineId,
                    rules: mx.rules,
                    fromTemplate,
                    toTemplate,
                    toCompany,
                    sourceFields,
                    targetFields,
                    fieldMatcherFn: fieldMatcher,
                    logger,
                });
            });
            logger.info(`/transformed participant matrix`);
            logger.info(`transforming editor matrix...`);

            const shouldNotHaveReviewers = ![
                domain.IntegrationCode.XeroBill,
                domain.IntegrationCode.QBooksBill,
                domain.IntegrationCode.QBooksExpense,
            ].includes(result.integrationCode!);

            if (shouldNotHaveReviewers) {
                step.editorMatrix = [];
            }

            step.editorMatrix.forEach((mx) => {
                this.convertRules({
                    userId: mx.lineId,
                    rules: mx.rules,
                    fromTemplate,
                    toTemplate,
                    toCompany,
                    sourceFields,
                    targetFields,
                    fieldMatcherFn: fieldMatcher,
                    logger,
                });
            });

            step.requiredFieldIds = matchFieldsIds(sourceFields, targetFields, step.requiredFieldIds);

            step.readonlyFieldIds = matchFieldsIds(sourceFields, targetFields, step.readonlyFieldIds);

            step.editPermissionsRequiredFieldIds = [];
            step.editingMatrix = [];

            logger.info(`/transformed editor matrix`);
            logger.info(`/step #${i + 1} (${step.name})`);
        });

        result.requiredFieldIds = matchFieldsIds(sourceFields, targetFields, fromTemplate.requiredFieldIds);

        // Final save of the step
        const templateDataTransfer = selectors.template.getTemplateTransfer(getState(), result, {
            skipEmptyAutoApprovalRules: true,
        });

        console.info('data to send:', templateDataTransfer);
        logger.info('saving the target template...');

        if ('templateId' in templateDataTransfer) {
            await api.templates.edit(templateDataTransfer);
        } else {
            throw errorHelpers.invalidOperationError();
        }

        if (copySettings) {
            const canCopySettings = checkCopySettingsPossibility(fromTemplate, result);

            if (!canCopySettings) {
                throw new CopySettingsError(
                    `can't copy settings from ${fromTemplate.integrationCode} to ${result.integrationCode}`
                );
            }

            try {
                await this.copySettings({
                    fromTemplate: fromTemplate.id,
                    fromCompanyId: fromTemplate.companyId,
                    toTemplate: result.id,
                    toCompanyId: result.companyId,
                    logger,
                });
            } catch {
                throw new CopySettingsError();
            }
        }

        logger.info('DONE');
    },

    async copyRules({
        fromTemplate,
        fromStepIndex,
        fromUser,
        fromFields,
        targetUserIds,
        logger,
    }: {
        fromCompany: domain.Company;
        fromTemplate: domain.Template;
        fromStepIndex: number | null;
        fromUser: Guid;
        fromFields: domain.Field[];
        targetUserIds: Guid[];
        logger: ILogger;
        mergeExistingValues: boolean;
    }) {
        this.backupTemplate(fromTemplate);

        let result: domain.Template = cloneDeep(fromTemplate);

        const targetMx = fromStepIndex != null ? result.steps[fromStepIndex].participantMatrix : result.submitterMatrix;
        const fromFieldIds = fromFields.map((f) => f.id);

        let rules = targetMx.find((x) => x.lineId === fromUser)!.rules;

        if (fromFields.length > 0) {
            // Filter rules by fieldIds
            rules = rules
                .map((r) => {
                    const conditions = r.conditions.filter((c) => fromFieldIds.includes(c.fieldId));

                    if (conditions.length > 0) {
                        return {
                            ...r,
                            conditions,
                        };
                    } else {
                        return null;
                    }
                })
                .filter(miscHelpers.notEmptyFilter);
        }

        console.info('transforming user rules...');
        logger.info('transforming user rules...');

        targetMx
            .filter((x) => x.lineId !== fromUser && (targetUserIds.length === 0 || targetUserIds.includes(x.lineId)))
            .forEach((x) => {
                console.info(`transforming rules for ${x.lineId}`);
                logger.info(`transforming rules for ${x.lineId}`);

                if (x.rules.length === rules.length) {
                    const newRules = rules.map((externalRule, i) => {
                        const userRule = x.rules[i];
                        const newConditions = cloneDeep(userRule.conditions);

                        externalRule.conditions.forEach((ec) => {
                            const index = newConditions.findIndex((uc) => uc.fieldId === ec.fieldId);

                            if (index !== -1) {
                                // rule already exists => remove it
                                newConditions.splice(index, 1);
                            }

                            newConditions.push(ec);
                        });

                        return {
                            ...userRule,
                            conditions: newConditions,
                        };
                    });

                    x.rules = newRules;
                } else if (x.rules.length === 0) {
                    // user with no rules
                    x.rules = rules;
                } else {
                    console.info(`!Rule length mismatch! Overriding ALL rules for ${x.lineId}`);
                    logger.warn(`!Rule length mismatch! Overriding ALL rules for ${x.lineId}`);
                    x.rules = rules;
                }
                // if (mergeExistingValues && x.rules.length === 1 && rules.length === 1) {
                //     const oldUserConditions = x.rules[0].conditions;
                //     const newConditions = [];
                //     rules[0].conditions.map(c => {
                //         const oldCondition = oldUserConditions.find(
                //             oc =>
                //                 oc.fieldId === c.fieldId &&
                //                 oc.conditionType === c.conditionType &&
                //                 [
                //                     domain.ConditionType.ExactValuesCondition,
                //                     domain.ConditionType.NegativeExactValuesCondition
                //                 ].includes(c.conditionType as any)
                //         );
                //         if (!oldCondition) {
                //             return c;
                //         }
                //         oldCondition as ;
                //     });
                //     const newRule: domain.MatrixRule = {
                //         conditions: newConditions
                //     };
                //     x.rules = [newRule];
                // } else {
                //     x.rules = rules;
                // }
            });
        console.info('/transformed matrix rules.');
        logger.info('/transformed matrix rules.');

        console.info('Saving template...');
        logger.info('Saving template...');

        const templateDataTransfer1 = selectors.template.getTemplateTransfer(getState(), result);

        if ('templateId' in templateDataTransfer1) {
            await api.templates.edit(templateDataTransfer1);
        } else {
            throw errorHelpers.invalidOperationError();
        }

        console.info('Template saved, all DONE.');
        logger.info('Template saved, all DONE.');
    },

    async copySettings({
        fromTemplate,
        fromCompanyId,
        toTemplate,
        toCompanyId,
        logger,
    }: {
        fromTemplate: string;
        fromCompanyId: string;
        toTemplate: string;
        toCompanyId: string;
        logger: ILogger;
    }) {
        logger.info(`templateSettings copying from ${fromTemplate} to ${toTemplate}`);

        const sourceSettings = await api.templates.getSettings({ templateId: fromTemplate, companyId: fromCompanyId });
        const targetSettings = await api.templates.getSettings({ templateId: toTemplate, companyId: toCompanyId });

        const settings = schemas.template.mapTemplateSettings(sourceSettings.data);
        const mappedTargetSettings = schemas.template.mapTemplateSettings(targetSettings.data);

        settings.templateId = toTemplate;

        if (settings.approvalDisregardDetectionSettings?.effectiveDate) {
            const targetFraudEffectiveDateLimits = selectors.template.getTemplateSettingsFraudEffectiveDateLimits(
                mappedTargetSettings.approvalDisregardDetectionSettings?.effectiveDate ?? null
            );

            /**
             * skip copy of approvalDisregardDetection setting
             * if this setting is already enabled in target workflow settings
             * or effectiveDate value of source workflow settings
             * is earlier than minimum allowed date of target workflow settings
             */
            if (
                mappedTargetSettings.approvalDisregardDetectionSettings?.enabled ||
                moment(settings.approvalDisregardDetectionSettings.effectiveDate).isBefore(
                    targetFraudEffectiveDateLimits.minDate
                )
            ) {
                settings.approvalDisregardDetectionSettings = undefined;
            }
        }

        const transfer = schemas.template.mapTemplateSettingsBack(settings);

        await api.templates.editSettings({ ...transfer, companyId: toCompanyId });

        logger.info('/templateSettings');
    },

    async cloneTemplateBulk({
        fromCompany,
        fromTemplate,
        target,
        logger,
        copySettings,
        deactivateOnCopy,
    }: {
        fromCompany: domain.Company;
        fromTemplate: domain.Template;
        target: Array<{ company: domain.Company; template: domain.Template | null }>;
        logger: ILogger;
        copySettings: boolean;
        deactivateOnCopy: boolean;
    }) {
        const summaries: any[] = [];

        for (const { company: toCompany, template: toTemplate } of target) {
            logger.info('########################################################');
            logger.info(`## Copying the template to company: ${toCompany.name} ##`);
            logger.info('########################################################');

            const activeSummary: {
                company: domain.Company;
                template: domain.Template | null;
                warnings: number;
                errors: number;
                error: Error | null;
                copySettingsError: Error | null;
            } = {
                company: toCompany,
                template: toTemplate,
                warnings: 0,
                errors: 0,
                error: null,
                copySettingsError: null,
            };

            const loggerWrp: ILogger = {
                info: (message: any) => {
                    logger.info(message);
                },
                warn: (message: any) => {
                    logger.warn(message);
                    activeSummary.warnings++;
                },
                error: (message: any) => {
                    logger.error(message);
                    activeSummary.errors++;
                },
            };

            try {
                await this.cloneTemplate({
                    fromCompany,
                    fromTemplate,
                    toCompany,
                    toTemplate,
                    logger: loggerWrp,
                    copySettings,
                    deactivateOnCopy,
                });
            } catch (err) {
                if (err instanceof CopySettingsError) {
                    activeSummary.copySettingsError = err;
                } else {
                    activeSummary.error = err;
                }

                console.error(err);
            }

            summaries.push(activeSummary);
        }

        logger.info('-------- Total Summary --------');

        const copiedTemplates = summaries.filter((s) => s.warnings === 0 && s.errors === 0 && !s.error);

        logger.info(`Companies done without problems ${copiedTemplates.length} of ${summaries.length}.`);

        const copiedWithWarnings = summaries.filter((s) => s.errors === 0 && s.warnings > 0);

        logger.info(`Companies with warnings ${copiedWithWarnings.length} of ${summaries.length}:`);
        copiedWithWarnings.forEach((w) => {
            logger.info(w.company.name);
        });

        const copiedWithErrors = summaries.filter(
            (summary) => summary.errors > 0 || summary.error || summary.copySettingsError
        );

        logger.info(`Companies with errors ${copiedWithErrors.length} of ${summaries.length}:`);
        copiedWithErrors.forEach((er) => {
            logger.info(er.company.name);
        });
        logger.info('----');

        logger.info('BULK_COPY_DONE', {
            copiedTemplates: [...copiedTemplates, ...copiedWithWarnings],
            templatesWithErrors: copiedWithErrors,
        });
    },

    backupTemplate(template: domain.Template) {
        const templateBackups: TemplateBackup[] = JSON.parse(
            localStorage.getItem('automation:template-backups') || '[]'
        );

        templateBackups.push({
            creationDate: moment.utc().toISOString(),
            template,
        });
        localStorage.setItem('automation:template-backups', JSON.stringify(templateBackups));
    },

    listTemplateBackups() {
        const templateBackups: TemplateBackup[] = JSON.parse(
            localStorage.getItem('automation:template-backups') || '[]'
        );

        return arrayHelpers.arraySort(
            templateBackups,
            compareHelpers.comparatorFor<TemplateBackup>(compareHelpers.dateComparator2Desc, 'creationDate')
        );
    },

    async restoreTemplate(template: domain.Template) {
        const templateDataTransfer = selectors.template.getTemplateTransfer(getState(), template);

        console.info('Restoring this template:', templateDataTransfer);

        if ('templateId' in templateDataTransfer) {
            await api.templates.edit(templateDataTransfer);
        } else {
            throw errorHelpers.invalidOperationError();
        }

        console.info('Restored');
    },
};

Object.assign(automation, {
    templates: templatesAutomation,
});

export default templatesAutomation;
