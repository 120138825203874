import { selectors } from 'modules/common';
import * as companyModule from 'modules/company';
import { domain } from 'modules/data';
import { integrationActions, IntegrationSuccessRedirectPage } from 'modules/integration';
import { useSelector } from 'modules/react-redux';
import { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { amplitudeService } from 'services/amplitude';
import { routingService } from 'services/routing';
import { isInviteTeammateExperiment } from 'shared/helpers/experiments';
import { getPath, Path } from 'urlBuilder';

export const useNextStep = (onPopupClose: VoidFunction) => {
    const dispatch = useDispatch();
    const createStandaloneOrganisation = useCreateStandaloneOrganisation();
    const createNetSuiteOrganisation = useCreateNetSuiteOrganisation(onPopupClose);
    const createDearOrganisation = useCreateDearOrganisation(onPopupClose);

    return useCallback(
        (integrationType: domain.IntegrationType) => {
            amplitudeService.sendData('signup: confirm connection type', {
                'connection type': integrationType.toLocaleLowerCase(),
            });

            switch (integrationType) {
                case domain.IntegrationType.None:
                    createStandaloneOrganisation();
                    break;

                case domain.IntegrationType.NetSuite:
                    createNetSuiteOrganisation();
                    break;

                case domain.IntegrationType.Dear:
                    createDearOrganisation();
                    break;

                default:
                    dispatch(
                        integrationActions.connectToIntegration({
                            integrationType,
                            companyId: null,
                            finalRedirectToPage: IntegrationSuccessRedirectPage.Workflow,
                            showGlobalProgress: false,
                        })
                    );
                    break;
            }
        },
        [createDearOrganisation, createNetSuiteOrganisation, createStandaloneOrganisation, dispatch]
    );
};

const useCreateNetSuiteOrganisation = (onPopupClose: VoidFunction) => {
    const dispatch = useDispatch();

    return useCallback(() => {
        dispatch(integrationActions.showNetSuiteIntegrationPopup(null, IntegrationSuccessRedirectPage.Workflow));

        onPopupClose();
    }, [dispatch, onPopupClose]);
};

const useCreateDearOrganisation = (onPopupClose: VoidFunction) => {
    const dispatch = useDispatch();

    return useCallback(() => {
        dispatch(integrationActions.showDearIntegrationPopup(null, IntegrationSuccessRedirectPage.Workflow));

        onPopupClose();
    }, [dispatch, onPopupClose]);
};

export const useCreateStandaloneOrganisation = () => {
    const dispatch = useDispatch();

    return useCallback(
        async (withInviteTeammate?: boolean) => {
            const companyCreatedInfo = await dispatch(companyModule.createOrganisation(null));

            if (!companyCreatedInfo) {
                return;
            }

            amplitudeService.sendData('signup: created org');

            if (withInviteTeammate) {
                routingService.push(
                    getPath(Path.companyUsers, companyCreatedInfo.CompanyId, {
                        addAsAdmin: true,
                        addWithInvite: true,
                        openAddPopup: true,
                    })
                );
            } else {
                routingService.push(getPath(Path.companyWorkflows, companyCreatedInfo.CompanyId));

                await dispatch(
                    companyModule.loadFetchActionsForNewCompany({ companyId: companyCreatedInfo.CompanyId })
                );
            }
        },
        [dispatch]
    );
};

export const useWithInviteTeammate = (canShow?: boolean) => {
    const profile = useSelector(selectors.profile.findProfile);

    return useMemo(
        () => canShow && !!profile?.createdDate && isInviteTeammateExperiment(profile?.createdDate),
        [canShow, profile?.createdDate]
    );
};
