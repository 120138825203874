import { ValueOf } from '@approvalmax/types';
import { BaseEditorProps, TransparentButton } from '@approvalmax/ui';
import { intl } from '@approvalmax/utils';
import { domain } from 'modules/data';
import { font, uFlexCenter } from 'modules/styles';
import { FC, memo, PropsWithChildren } from 'react';
import styled, { css } from 'styled-components';

const i18nPrefix = 'common/ui/weekPickerEditor/WeekPickerEditor';

interface WeekPickerEditorProps extends BaseEditorProps<ValueOf<domain.DayOfWeek>[]> {}

const daysOfWeek = [
    {
        id: domain.DayOfWeek.Monday,
        text: intl.formatMessage({
            id: `${i18nPrefix}.dayOfWeekMonday`,
            defaultMessage: 'Mo',
        }),
    },
    {
        id: domain.DayOfWeek.Tuesday,
        text: intl.formatMessage({
            id: `${i18nPrefix}.dayOfWeekTuesday`,
            defaultMessage: 'Tu',
        }),
    },
    {
        id: domain.DayOfWeek.Wednesday,
        text: intl.formatMessage({
            id: `${i18nPrefix}.dayOfWeekWednesday`,
            defaultMessage: 'We',
        }),
    },
    {
        id: domain.DayOfWeek.Thursday,
        text: intl.formatMessage({
            id: `${i18nPrefix}.dayOfWeekThursday`,
            defaultMessage: 'Th',
        }),
    },
    {
        id: domain.DayOfWeek.Friday,
        text: intl.formatMessage({
            id: `${i18nPrefix}.dayOfWeekFriday`,
            defaultMessage: 'Fr',
        }),
    },
    {
        id: domain.DayOfWeek.Saturday,
        text: intl.formatMessage({
            id: `${i18nPrefix}.dayOfWeekSaturday`,
            defaultMessage: 'Sa',
        }),
    },
    {
        id: domain.DayOfWeek.Sunday,
        text: intl.formatMessage({
            id: `${i18nPrefix}.dayOfWeekSunday`,
            defaultMessage: 'Su',
        }),
    },
];

const StyledDayButtonInternal = styled.button<{ checked: boolean; disabled: boolean }>`
    ${uFlexCenter};
    width: 35px;
    height: 35px;
    border: 1px solid #cacccd;
    border-radius: 50%;
    ${font(12, '#384650')};
    cursor: pointer;

    ${(props) =>
        props.disabled &&
        css`
            opacity: 0.5;
            cursor: default;
        `}

    &:hover:not(:disabled) {
        background: #e9eef0;
        border-color: #e9eef0;
        ${font(12, '#384650')};
    }

    ${(props) =>
        props.checked &&
        css`
            background: #4c8a5c;
            border-color: #4c8a5c;
            ${font(12, '#fff', 'semibold')};
        `}
`;

interface DayButtonProps extends PropsWithChildren {
    className?: string;
    disabled?: boolean;
    checked: boolean;
    onChange: (newValue: boolean) => void;
}

const DayButton: FC<DayButtonProps> = (props) => {
    const { children, checked, disabled, onChange } = props;

    return (
        <TransparentButton disabled={disabled} execute={() => onChange(!checked)}>
            {({ elementProps, disabled }) => (
                <StyledDayButtonInternal
                    {...elementProps}
                    checked={checked}
                    disabled={disabled}
                    data-active={checked ? 'true' : 'false'}
                    data-qa='day-button'
                >
                    {children}
                </StyledDayButtonInternal>
            )}
        </TransparentButton>
    );
};

const Root = styled.div`
    display: flex;
    align-items: center;

    & > :not(:last-child) {
        margin-right: 10px;
    }
`;

export const WeekPickerEditor: FC<WeekPickerEditorProps> = memo((props) => {
    const { className, value = [], onChange, disabled } = props;

    return (
        <Root className={className}>
            {daysOfWeek.map((x) => (
                <DayButton
                    key={x.id}
                    disabled={disabled}
                    checked={value.includes(x.id)}
                    onChange={(isChecked) => {
                        let newValue = value.filter((d) => d !== x.id);

                        if (isChecked) {
                            newValue = [...newValue, x.id];
                        }

                        onChange(newValue);
                    }}
                >
                    {x.text}
                </DayButton>
            ))}
        </Root>
    );
});
