import { Button, Grid, Text } from '@approvalmax/ui/src/components';
import { FC, memo } from 'react';

import { messages } from './InviteTeammateSuggestion.messages';
import { InviteTeammateSuggestionProps } from './InviteTeammateSuggestion.types';

export const InviteTeammateSuggestion: FC<InviteTeammateSuggestionProps> = memo((props) => {
    const { onInviteTeammate } = props;

    return (
        <Grid gap={8}>
            <Text font='label' fontSize='small'>
                {messages.label}
            </Text>

            <Button size='small' noPadding uppercase={false} fontWeight='regular' onClick={onInviteTeammate}>
                {messages.buttonInvite}
            </Button>
        </Grid>
    );
});

InviteTeammateSuggestion.displayName = 'InviteTeammateSuggestion';
