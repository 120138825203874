/**
 * Developer: Stepan Burguchev
 * Date: 5/22/2017
 * Copyright: 2015-2017 ApprovalMax
 *       All Rights Reserved
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF ApprovalMax
 *       The copyright notice above does not evidence any
 *       actual or intended publication of such source code.
 */

import { errorHelpers, intl } from '@approvalmax/utils';
import each from 'lodash/each';
import { selectors } from 'modules/common';
import { domain, State } from 'modules/data';
import { defineMessages } from 'react-intl';

import { PAGE_ID } from '../config';
import { CardCommand } from '../reducers/page/activeCardCommandReducer';
import { ActiveModule } from '../reducers/page/activeModuleReducer';
import { ActivePopupData } from '../reducers/page/activePopupReducer';
import { Page } from '../reducers/pageReducer';
import { expandReportConfig, findReportConfigById, getReportConfigTransfer } from './reportConfigSelectors';
import { ExpandedReportConfig } from './types/ExpandedReportConfig';

const i18nPrefix = 'reports';
const messages = defineMessages({
    dashboardPageTitle: {
        id: `${i18nPrefix}.dashboardPageTitle`,
        defaultMessage: 'Reports | {companyName}',
    },
    reportPageTitle: {
        id: `${i18nPrefix}.reportPageTitle`,
        defaultMessage: '{reportName} | {companyName}',
    },
    unnamedReportName: {
        id: `${i18nPrefix}.unnamedReportName`,
        defaultMessage: 'Unnamed report',
    },
});

export function getPage(state: State): Page {
    return selectors.page.getPage(state, PAGE_ID);
}

export function getPageSafe(state: State): Page | undefined {
    return selectors.page.getPageSafe(state, PAGE_ID);
}

export function checkLoaded(
    state: State,
    options: { companyId?: string; reportConfigId?: string; reportType?: domain.ReportType }
): boolean {
    // the page is considered as loaded if:
    // 1. the active page id is PAGE_ID
    // 2 the page is loaded for options.companyId
    // 3 the page is loaded for options.reportType
    const reportTypeTheSame = options.reportType
        ? state.pages?.reports && getPage(state).reportType === options.reportType
        : true;
    const isActive = selectors.navigation.getActivePageId(state) === PAGE_ID;

    if (!isActive) {
        return false;
    }

    if (!reportTypeTheSame) {
        return false;
    }

    if (options.companyId) {
        const companyId = selectors.navigation.getActiveCompanyId(state);

        return companyId === options.companyId;
    } else if (options.reportConfigId) {
        const reportConfig = findReportConfigById(state, options.reportConfigId);

        if (!reportConfig) {
            return false;
        }

        return selectors.navigation.getActiveCompanyId(state) === reportConfig.companyId;
    } else {
        throw errorHelpers.formatError();
    }
}

export function getDocumentTitle(state: State) {
    const companyName = selectors.navigation.getActiveCompany(state).displayName;

    switch (getActiveModuleId(state)) {
        case ActiveModule.Dashboard:
            return intl.formatMessage(messages.dashboardPageTitle, { companyName });

        case ActiveModule.Card: {
            const reportName = getActiveReportConfig(state)!.name;

            return intl.formatMessage(messages.reportPageTitle, {
                reportName: reportName || intl.formatMessage(messages.unnamedReportName),
                companyName,
            });
        }

        default:
            throw errorHelpers.notSupportedError();
    }
}

export function getActiveModuleId(state: State): ActiveModule {
    return getPage(state).activeModule;
}

export function getActiveReportConfig(state: State): ExpandedReportConfig | null {
    const reportConfig = getPage(state).activeReportConfig;

    if (!reportConfig) {
        return null;
    }

    const company = selectors.navigation.getActiveCompany(state);

    const team = selectors.navigation.getActiveCompany(state).allMembers;

    return expandReportConfig(reportConfig, team, company);
}

export function hasChanges(state: State): boolean {
    return getPage(state).modified;
}

export function getActiveCommand(state: State): CardCommand | null {
    return getPage(state).activeCardCommand;
}

export function getActivePopup<T extends ActivePopupData = ActivePopupData>(state: State): T {
    return getPage(state).activePopup as T;
}

export function getActiveReport(state: State) {
    return getPage(state).activeReport;
}

export function getReportType(state: State) {
    return getPage(state).reportType;
}

export function getCardCommands(state: State) {
    let activeCommand = getActiveCommand(state);
    let reportConfig = getActiveReportConfig(state)!;

    const isNew = reportConfig.isNew;
    const isValid = reportConfig.isValid;
    const modified = hasChanges(state);

    let xeroIntegration = false;
    let integrationEnabled = false;

    const reportConfigTransfer = getReportConfigTransfer(state, reportConfig);
    const isXeroContact = reportConfigTransfer.settings.templateId?.filteringValue.some(
        (filteringValue: any) => filteringValue.integrationCode === domain.IntegrationCode.XeroContact
    );
    const integrationId = selectors.navigation.getActiveCompany(state).integrationId;

    if (integrationId) {
        const integration = selectors.integration.findIntegrationById(state.entities.integrations, integrationId)!;

        if (integration.status === domain.IntegrationStatus.Connected) {
            integrationEnabled = true;
        }

        if (integration.integrationType === domain.IntegrationType.Xero) {
            xeroIntegration = true;
        }
    }

    const globalCommands = [CardCommand.Delete, CardCommand.Save, CardCommand.SaveAsNew];

    let result = {
        [CardCommand.Save]: {
            disabled: false,
        },
        [CardCommand.SaveAsNew]: {
            disabled: !isValid,
            hidden: isNew,
        },
        [CardCommand.DiscardChanges]: {
            disabled: !modified,
        },
        [CardCommand.Delete]: {
            disabled: false,
        },
        [CardCommand.DownloadAuditReports]: {
            disabled: false,
            hidden: reportConfig.reportType === domain.ReportType.LineItem,
        },
        [CardCommand.DownloadAttachmentArchive]: {
            disabled: false,
            hidden: reportConfig.reportType === domain.ReportType.LineItem,
        },
        [CardCommand.CreateAccrualReportXero]: {
            disabled: !integrationEnabled,
            hidden: !xeroIntegration || reportConfig.reportType === domain.ReportType.LineItem || isXeroContact,
        },
        [CardCommand.Print]: {
            disabled: false,
        },
        [CardCommand.ExportAsCsv]: {
            disabled: false,
        },
        [CardCommand.ExecuteReport]: {
            disabled: false,
        },
        [CardCommand.EditFilters]: {
            disabled: false,
        },
    };

    if (activeCommand) {
        each(result, (cmd, cmdId) => {
            if (activeCommand === cmdId || globalCommands.includes(activeCommand!)) {
                cmd.disabled = true;
            }
        });
    }

    return result;
}

export function getDocumentPreview(state: State) {
    return getPage(state).documentPreview;
}
