import { ChevronRightIcon } from '@approvalmax/ui';
import { Flex } from '@approvalmax/ui/src/components';
import { selectors } from 'modules/common';
import { domain } from 'modules/data';
import { useSelector } from 'modules/react-redux';
import { Fragment, memo, useMemo } from 'react';
import { useCompanyBasedOnUrl } from 'shared/hooks/useCompanyBasedOnUrl';

import { getActiveTemplate } from '../../selectors/pageSelectors';
import AddNewStepButton from '../AddNewStepButton/AddNewStepButton';
import { ApprovalStep } from '../ApprovalStep/ApprovalStep';
import { DraggableStepList } from '../DraggableStepList/DraggableStepList';
import EndStepWithDescription from '../EndStepWithDescription/EndStepWithDescription';
import { NetSuiteStartStep } from '../NetSuiteStartStep/NetSuiteStartStep';
import { SingletonSteps } from '../SingletonSteps/SingletonSteps';
import { messages } from './NetSuiteWorkflowDetails.messages';

const NetSuiteWorkflowDetails = memo(() => {
    const company = useCompanyBasedOnUrl();
    const template = useSelector(getActiveTemplate);
    const pluralTemplateName = useMemo(
        () => selectors.template.getTemplateDisplayNameByCode(template?.integrationCode ?? null, true),
        [template?.integrationCode]
    );
    const isInvoiceEditAvailable = company.betaFeatures.includes(domain.CompanyBetaFeature.NetSuiteInvoiceEdit);

    const showCreateInAMAX = useMemo(() => {
        switch (template?.integrationCode) {
            case domain.IntegrationCode.NetSuiteBill:
            case domain.IntegrationCode.NetSuitePO:
            case domain.IntegrationCode.NetSuiteExpenseReport:
            case domain.IntegrationCode.NetSuiteVendor:
            case domain.IntegrationCode.NetSuiteBillPayment:
                return true;

            case domain.IntegrationCode.NetSuiteInvoice:
                return isInvoiceEditAvailable;

            default:
                return false;
        }
    }, [template, isInvoiceEditAvailable]);

    if (!template) {
        return null;
    }

    return (
        <>
            <NetSuiteStartStep showCreateInAMAX={showCreateInAMAX} />

            <SingletonSteps />

            <DraggableStepList steps={template.steps}>
                {template.steps.map((step, index) => (
                    <Fragment key={step.id}>
                        {index === 0 && (
                            <Flex height={28} alignItems='center'>
                                <ChevronRightIcon size={16} color='midnight60' />
                            </Flex>
                        )}

                        <ApprovalStep stepIndex={index} step={step} />
                    </Fragment>
                ))}
            </DraggableStepList>

            <AddNewStepButton />

            <EndStepWithDescription
                color='brandNetSuite100'
                bubbleText={messages.endStepBubbleTextNetSuite}
                description={messages.endStepDescriptionApproveInNetSuite({
                    pluralTemplateName,
                })}
            />
        </>
    );
});

export default NetSuiteWorkflowDetails;
