import { Mods, mods, theme } from '@approvalmax/theme';
import { StyledMods } from '@styled-kit/mods';
import styled from 'styled-components';

export const Root = styled.label<StyledMods<Mods<'disabled' | 'checked' | 'invalid'>>>`
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    border: 1px solid ${theme.color.blue80};
    background-color: ${theme.color.midnight20};
    transition: all ${theme.duration.medium}ms ease-in-out;

    &:hover {
        border-color: ${theme.color.silver80};
        background-color: ${theme.color.silver80};
    }

    ${mods.checked.true`
        &, &:hover {
            color: ${theme.color.white100};
            border-color: ${theme.color.blue80};
            background-color: ${theme.color.blue80};
        }
    `};

    ${mods.disabled.true`
        &, &:hover {
            cursor: default;
            color: ${theme.color.midnight50};
            border-color: ${theme.color.midnight50};
            background-color: ${theme.color.midnight20};
        }

        ${mods.checked.true`
            &, &:hover {
                color: ${theme.color.white100};
                background-color: ${theme.color.blue30};
            }
        `};
    `};

    ${mods.invalid.true`
        ${mods.disabled.false`
            &, &:hover {
                border-color: ${theme.color.red70};
                background-color: ${theme.color.red20};
            }
        `};

        ${mods.checked.true`
            &, &:hover {
                background-color: ${theme.color.red70};
            }
        `};
    `}
`;

export const Input = styled.input`
    display: none;
`;
