import { SaveChangesIcon } from '@approvalmax/ui';
import { toast, useConfirmation } from '@approvalmax/ui/src/components';
import { selectors } from 'modules/common';
import { domain } from 'modules/data';
import { useCallback } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { commonErrorMessages } from 'services/error/messages';
import { routingService } from 'services/routing';
import { getPath, Path } from 'urlBuilder';

import { useExpandedCompany } from '../../hooks';
import { activeCompanyIdState } from '../../states';
import { messages } from './ProfileEditPopup.messages';
import { isModifiedProfileEditPopupState, showProfileEditPopupState } from './ProfileEditPopup.states';

export const useOpenProfileEditPopup = () => {
    const [isPopupOpen, setIsPopupOpen] = useRecoilState(showProfileEditPopupState);

    const handleOpenPopup = useCallback(() => {
        setIsPopupOpen(true);
    }, [setIsPopupOpen]);

    const handleClosePopup = useCallback(() => {
        setIsPopupOpen(false);
    }, [setIsPopupOpen]);

    return {
        isPopupOpen,
        handleOpenPopup,
        handleClosePopup,
    };
};

export const useProfileEditPopup = (profile: selectors.types.ExpandedProfile | null) => {
    const profileEditPopup = useOpenProfileEditPopup();
    const isModified = useRecoilValue(isModifiedProfileEditPopupState);
    const companyId = useRecoilValue(activeCompanyIdState);
    const company = useExpandedCompany(companyId);

    const { confirmation } = useConfirmation({
        size: 'small',
        Icon: SaveChangesIcon,
        cancelButtonColor: 'blue80',
        confirmButtonColor: 'red40',
        confirmMessage: messages.confirmationTitle,
        cancelButtonMessage: messages.confirmationButtonCancel,
        confirmButtonMessage: messages.confirmationButtonConfirm,
    });

    const handleClose = useCallback(
        (open: boolean) => {
            if (open) {
                return;
            }

            const twoFaEnforcementType = company?.enforcementTfaType;

            if (isModified) {
                confirmation()
                    .then(profileEditPopup.handleClosePopup)
                    .catch(() => {});
            } else {
                profileEditPopup.handleClosePopup();
            }

            if (twoFaEnforcementType === domain.TwoFaEnforcementType.Hard && !profile?.is2faEnabled) {
                toast.error(commonErrorMessages.twoFAMustBeEnabled);

                setTimeout(() => {
                    routingService.reloadToUrl(getPath(Path.twoFaHardEnforcement));
                }, 1000);
            }
        },
        [company?.enforcementTfaType, confirmation, isModified, profile?.is2faEnabled, profileEditPopup]
    );

    return {
        handleClose,
        isOpen: profileEditPopup.isPopupOpen,
    };
};
