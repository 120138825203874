import { Button, ExternalLink, QBooksLogo } from '@approvalmax/ui';
import { errorHelpers } from '@approvalmax/utils';
import { domain } from 'modules/data';
import CreateOrganisationPopup from 'modules/first-start/components/CreateOrganisationPopup/CreateOrganisationPopup';
import { useSelector } from 'modules/react-redux';
import { Cin7Logo, OracleNetsuiteLogo, XeroIcon } from 'modules/sprites';
import { memo, useCallback } from 'react';
import bemFactory from 'react-bem-factory';
import { removePreferredAccountingSystemSignUp } from 'shared/helpers';

import { getLoading } from '../../selectors/moduleSelectors';
import {
    useCreateStandaloneOrganisation,
    useNextStep,
    useSelectIntegrationType,
    useWithInviteTeammate,
} from './NewOrganisationPopup.hooks';
import { messages } from './NewOrganisationPopup.messages';
import {
    ConnectionButton,
    ConnectionOptions,
    Content,
    DetailsPanel,
    DetailsText,
    Logo,
    RegularBody,
    RegularPopup,
    TipPanel,
    TipText,
    TipTitle,
    Title,
} from './NewOrganisationPopup.styles';
import { NewOrganisationPopupProps } from './NewOrganisationPopup.types';

const qa = bemFactory.qa('first-start-new-organisation-popup');

const NewOrganisationPopup = memo<NewOrganisationPopupProps>((props) => {
    const { isFirstStart, fromNoCompanies, closePopup } = props;

    const isLoading = useSelector(getLoading);

    const { integrationType, onSelectXero, onSelectQbooks, onSelectNetSuite, onSelectDear, onSelectStandAlone } =
        useSelectIntegrationType();

    const onNextClick = useNextStep(closePopup);
    const createStandaloneOrganisation = useCreateStandaloneOrganisation();
    const withInviteTeammate = useWithInviteTeammate(isFirstStart || fromNoCompanies);

    const content = (
        <Content>
            <Title>{messages.contentTitle}</Title>

            <ConnectionOptions>
                <ConnectionButton active={integrationType === domain.IntegrationType.Xero} execute={onSelectXero}>
                    <Logo as={XeroIcon} width={40} height={40} />

                    {messages.xeroButton}
                </ConnectionButton>

                <ConnectionButton active={integrationType === domain.IntegrationType.QBooks} execute={onSelectQbooks}>
                    <Logo as={QBooksLogo} width={40} height={40} />

                    {messages.qboButton}
                </ConnectionButton>

                <ConnectionButton
                    active={integrationType === domain.IntegrationType.NetSuite}
                    execute={onSelectNetSuite}
                >
                    <Logo as={OracleNetsuiteLogo} width={40} height={40} />

                    {messages.netSuiteButton}
                </ConnectionButton>

                <ConnectionButton active={integrationType === domain.IntegrationType.Dear} execute={onSelectDear}>
                    <Logo as={Cin7Logo} width={40} height={40} />

                    {messages.dearButton}
                </ConnectionButton>

                <ConnectionButton active={integrationType === domain.IntegrationType.None} execute={onSelectStandAlone}>
                    {messages.connectLaterButton}
                </ConnectionButton>
            </ConnectionOptions>

            {(() => {
                switch (integrationType) {
                    case domain.IntegrationType.Xero:
                        return (
                            <DetailsPanel firstStart={isFirstStart}>
                                <TipPanel>
                                    <TipTitle>{messages.xeroProTip}</TipTitle>

                                    <TipText>
                                        {messages.xeroProTipText({
                                            learnmore: (text: string) => (
                                                <ExternalLink href={messages.xeroProTipLink}>{text}</ExternalLink>
                                            ),
                                        })}
                                    </TipText>
                                </TipPanel>

                                <Title>{messages.xeroTitle}</Title>

                                <DetailsText>
                                    <ol>
                                        {messages.xeroDetailsText({
                                            item: (content: [string]) => <li>{content}</li>,
                                        })}
                                    </ol>
                                </DetailsText>
                            </DetailsPanel>
                        );

                    case domain.IntegrationType.QBooks:
                        return (
                            <DetailsPanel firstStart={isFirstStart}>
                                <Title>{messages.qboTitle}</Title>

                                <DetailsText>
                                    <ol>
                                        {messages.qboDetailsText({
                                            item: (content: [string]) => <li>{content}</li>,
                                        })}
                                    </ol>
                                </DetailsText>
                            </DetailsPanel>
                        );

                    case domain.IntegrationType.NetSuite:
                        return (
                            <DetailsPanel firstStart={isFirstStart}>
                                <Title>{messages.netSuiteTitle}</Title>

                                <DetailsText>
                                    <ol>
                                        {messages.netSuiteDetailsText({
                                            item: (content: [string]) => <li>{content}</li>,
                                        })}
                                    </ol>
                                </DetailsText>
                            </DetailsPanel>
                        );

                    case domain.IntegrationType.Dear:
                        return (
                            <DetailsPanel firstStart={isFirstStart}>
                                <Title>{messages.dearTitle}</Title>

                                <DetailsText>
                                    <ol>
                                        {messages.dearDetailsText({
                                            item: (content: [string]) => <li>{content}</li>,
                                        })}
                                    </ol>
                                </DetailsText>
                            </DetailsPanel>
                        );

                    case domain.IntegrationType.None:
                        return (
                            <DetailsPanel firstStart={isFirstStart}>
                                <Title>{messages.connectLaterTitle}</Title>

                                <DetailsText>
                                    <ol>
                                        {messages.connectLaterDetailsText({
                                            item: (content: [string]) => <li>{content}</li>,
                                        })}
                                    </ol>
                                </DetailsText>
                            </DetailsPanel>
                        );

                    default:
                        throw errorHelpers.assertNever(integrationType);
                }
            })()}
        </Content>
    );

    const handleConnect = useCallback(
        (integrationType: domain.IntegrationType) => {
            if (isFirstStart) {
                removePreferredAccountingSystemSignUp();
            }

            onNextClick(integrationType);
        },
        [isFirstStart, onNextClick]
    );

    const handleNext = useCallback(() => {
        onNextClick(integrationType);
    }, [integrationType, onNextClick]);

    const handleInviteTeammate = useCallback(() => {
        if (isFirstStart) {
            removePreferredAccountingSystemSignUp();
        }

        createStandaloneOrganisation(true);
    }, [createStandaloneOrganisation, isFirstStart]);

    if (isFirstStart || fromNoCompanies) {
        return (
            <CreateOrganisationPopup
                closable={fromNoCompanies}
                isLoading={isLoading}
                isLoadStepFromLocalStorage={isFirstStart}
                onClose={closePopup}
                onConnectXero={() => handleConnect(domain.IntegrationType.Xero)}
                onConnectQBooks={() => handleConnect(domain.IntegrationType.QBooks)}
                onConnectNetSuite={() => handleConnect(domain.IntegrationType.NetSuite)}
                onConnectCin7={() => handleConnect(domain.IntegrationType.Dear)}
                onConnectStandalone={() => handleConnect(domain.IntegrationType.None)}
                withInviteTeammate={withInviteTeammate}
                onInviteTeammate={handleInviteTeammate}
            />
        );
    }

    return (
        <RegularPopup
            qa={qa()}
            title={messages.title}
            buttons={
                <Button execute={handleNext} disabled={isLoading}>
                    {messages.nextButton}
                </Button>
            }
        >
            <RegularBody>{content}</RegularBody>
        </RegularPopup>
    );
});

export default NewOrganisationPopup;
