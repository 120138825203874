import {
    AirwallexIcon,
    AmIcon,
    ApCreditNotesIcon,
    ArCreditNotesIcon,
    BoxIcon,
    FileIcon,
    FilesStackUsdIcon,
    FilesUsdIcon,
    InvoiceIcon,
    NoteBookIcon,
    SalesQuoteIcon,
    UsersIcon,
} from '@approvalmax/ui';
import { IconFC } from '@approvalmax/ui/src/components';
import { selectors } from 'modules/common';
import { domain } from 'modules/data';
import { isGrnAvailable } from 'modules/utils';
import { TemplateRequestListFilter } from 'pages/requestList/config';

import { filtersMessages, workflowsMessages } from './WorkflowsMenu.messages';
import { WorkflowsMenuProps } from './WorkflowsMenu.types';

const {
    Accepted,
    Declined,
    AllByType,
    OnReview,
    OnApproval,
    Approved,
    Billed,
    NotBilled,
    AwaitingPayment,
    Failed,
    Processing,
    Paid,
    PartiallyPaid,
    Cancelled,
    Rejected,
    OnHold,
    GrnNotReceived,
    GrnPartiallyReceived,
    GrnFullyReceived,
    Credited,
    PartiallyReceived,
    PendingBill,
    PendingReceipt,
    Closed,
} = TemplateRequestListFilter;

const getWorkflowFeaturesAvailability = (company: WorkflowsMenuProps['company']) => {
    return {
        isGrnAvailable: isGrnAvailable(company),
        isGrnXeroPOAvailable: company.xeroPurchaseOrderSettings?.isGrnEnabled,
        isGrnQBOPOAvailable: company.qboPurchaseOrderSettings?.isGrnEnabled,
        isJournalEntryAvailable: company.licenseFeatures.includes(
            domain.CompanyLicenseFeature.QBOJournalEntryWorkflows
        ),
        isXeroContactAvailable: company.licenseFeatures.includes(domain.CompanyLicenseFeature.XeroContactWorkflows),
        isQBOVendorAvailable: company.licenseFeatures.includes(domain.CompanyLicenseFeature.QBOVendorWorkflows),
        isXeroBatchPaymentAvailable: company.licenseFeatures.includes(
            domain.CompanyLicenseFeature.XeroBillBatchPayments
        ),
        isAirwallexPaymentAvailable: company.flags.isAirwallexPaymentAvailable,
        isAirwallexConnectAvailable: company.airwallexIntegration?.isConnected,
        isAmaxPayBatchPaymentAvailable:
            company.betaFeatures.includes(domain.CompanyBetaFeature.PayOpenBankingUK) ||
            company.licenseFeatures.includes(domain.CompanyLicenseFeature.Pay),
        isXeroManualJournalAvailable:
            company.licenseFeatures.includes(domain.CompanyLicenseFeature.XeroManualJournals) ||
            company.betaFeatures.includes(domain.CompanyBetaFeature.XeroManualJournal),
        isNetSuiteInvoiceEditAvailable: company.betaFeatures.includes(domain.CompanyBetaFeature.NetSuiteInvoiceEdit),
        isNetSuiteRAAvailable: company.betaFeatures.includes(domain.CompanyBetaFeature.NetSuiteRA),
        isNetSuiteJournalEntryAvailable: company.betaFeatures.includes(domain.CompanyBetaFeature.NetSuiteJournalEntry),
        isReviewStepAvailable: selectors.company.getIsReviewStepAvailable(company),
        isOnHoldStatusAvailable: selectors.company.getIsOnHoldStatusAvailable(company),
    };
};

export const getWorkflowsMeta = (
    company: WorkflowsMenuProps['company']
): {
    [key in domain.IntegrationCode | 'Standalone']: {
        name?: string;
        available?: boolean;
        hasAddButton?: boolean;
        startIcon: IconFC;
        subItems: {
            id: TemplateRequestListFilter;
            name: string;
            available?: boolean;
        }[];
    };
} => {
    const {
        isAirwallexConnectAvailable,
        isAirwallexPaymentAvailable,
        isAmaxPayBatchPaymentAvailable,
        isGrnAvailable,
        isGrnQBOPOAvailable,
        isGrnXeroPOAvailable,
        isJournalEntryAvailable,
        isNetSuiteInvoiceEditAvailable,
        isNetSuiteRAAvailable,
        isNetSuiteJournalEntryAvailable,
        isQBOVendorAvailable,
        isXeroBatchPaymentAvailable,
        isXeroContactAvailable,
        isXeroManualJournalAvailable,
        isReviewStepAvailable,
        isOnHoldStatusAvailable,
    } = getWorkflowFeaturesAvailability(company);

    return {
        [domain.IntegrationCode.XeroPo]: {
            name: workflowsMessages.xeroPoName,
            startIcon: BoxIcon,
            subItems: [
                {
                    id: AllByType,
                    name: filtersMessages.allName,
                },
                {
                    id: OnReview,
                    name: filtersMessages.onReview,
                    available: isReviewStepAvailable,
                },
                {
                    id: OnApproval,
                    name: filtersMessages.onApprovalName,
                },
                {
                    id: Approved,
                    name: filtersMessages.approvedName,
                },
                {
                    id: Billed,
                    name: filtersMessages.billedName,
                },
                {
                    id: NotBilled,
                    name: filtersMessages.notBilledName,
                },
                {
                    id: Cancelled,
                    name: filtersMessages.cancelledName,
                },
                {
                    id: Rejected,
                    name: filtersMessages.rejectedName,
                },
                {
                    id: OnHold,
                    name: filtersMessages.onHold,
                    available: isOnHoldStatusAvailable,
                },
                {
                    id: GrnNotReceived,
                    name: filtersMessages.grnNotReceivedName,
                    available: isGrnAvailable && isGrnXeroPOAvailable,
                },
                {
                    id: GrnPartiallyReceived,
                    name: filtersMessages.grnPartiallyReceivedName,
                    available: isGrnAvailable && isGrnXeroPOAvailable,
                },
                {
                    id: GrnFullyReceived,
                    name: filtersMessages.grnFullyReceivedName,
                    available: isGrnAvailable && isGrnXeroPOAvailable,
                },
            ],
        },
        [domain.IntegrationCode.XeroBill]: {
            name: workflowsMessages.xeroBillName,
            startIcon: FileIcon,
            subItems: [
                {
                    id: AllByType,
                    name: filtersMessages.allName,
                },
                {
                    id: OnReview,
                    name: filtersMessages.onReview,
                    available: isReviewStepAvailable,
                },
                {
                    id: OnApproval,
                    name: filtersMessages.onApprovalName,
                },
                {
                    id: Approved,
                    name: filtersMessages.approvedName,
                },
                {
                    id: Paid,
                    name: filtersMessages.paidName,
                },
                {
                    id: Cancelled,
                    name: filtersMessages.cancelledName,
                },
                {
                    id: Rejected,
                    name: filtersMessages.rejectedName,
                },
                {
                    id: OnHold,
                    name: filtersMessages.onHold,
                    available: isOnHoldStatusAvailable,
                },
            ],
        },
        [domain.IntegrationCode.XeroAmaxPayBatchPayment]: {
            name: workflowsMessages.amaxPayBatchPaymentName,
            available: isAmaxPayBatchPaymentAvailable,
            hasAddButton: isAmaxPayBatchPaymentAvailable,
            startIcon: AmIcon,
            subItems: [
                {
                    id: AllByType,
                    name: filtersMessages.allName,
                },
                {
                    id: OnApproval,
                    name: filtersMessages.onApprovalName,
                },
                {
                    id: AwaitingPayment,
                    name: filtersMessages.awaitingPaymentName,
                },
                {
                    id: Processing,
                    name: filtersMessages.processingName,
                },
                {
                    id: Paid,
                    name: filtersMessages.paidName,
                },
                {
                    id: PartiallyPaid,
                    name: filtersMessages.partiallyPaidName,
                },
                {
                    id: Failed,
                    name: filtersMessages.failedName,
                },
                {
                    id: Cancelled,
                    name: filtersMessages.cancelledName,
                },
                {
                    id: Rejected,
                    name: filtersMessages.rejectedName,
                },
                {
                    id: OnHold,
                    name: filtersMessages.onHold,
                    available: isOnHoldStatusAvailable,
                },
            ],
        },
        [domain.IntegrationCode.XeroBillBatchPayment]: {
            name: workflowsMessages.xeroBillBatchPaymentName,
            available: isXeroBatchPaymentAvailable,
            startIcon: FilesStackUsdIcon,
            subItems: [
                {
                    id: AllByType,
                    name: filtersMessages.allName,
                },
                {
                    id: OnApproval,
                    name: filtersMessages.onApprovalName,
                },
                {
                    id: Approved,
                    name: filtersMessages.approvedName,
                },
                {
                    id: Cancelled,
                    name: filtersMessages.cancelledName,
                },
                {
                    id: Rejected,
                    name: filtersMessages.rejectedName,
                },
                {
                    id: OnHold,
                    name: filtersMessages.onHold,
                    available: isOnHoldStatusAvailable,
                },
            ],
        },
        [domain.IntegrationCode.XeroAirwallexBatchPayment]: {
            name: workflowsMessages.xeroAirwallexBatchPaymentName,
            available: isAirwallexPaymentAvailable,
            hasAddButton: isAirwallexConnectAvailable,
            startIcon: AirwallexIcon,
            subItems: [
                {
                    id: AllByType,
                    name: filtersMessages.allName,
                },
                {
                    id: OnApproval,
                    name: filtersMessages.onApprovalName,
                },
                {
                    id: AwaitingPayment,
                    name: filtersMessages.awaitingPaymentName,
                },
                {
                    id: Processing,
                    name: filtersMessages.processingName,
                },
                {
                    id: Paid,
                    name: filtersMessages.paidName,
                },
                {
                    id: PartiallyPaid,
                    name: filtersMessages.partiallyPaidName,
                },
                {
                    id: Failed,
                    name: filtersMessages.failedName,
                },
                {
                    id: Cancelled,
                    name: filtersMessages.cancelledName,
                },
                {
                    id: Rejected,
                    name: filtersMessages.rejectedName,
                },
                {
                    id: OnHold,
                    name: filtersMessages.onHold,
                    available: isOnHoldStatusAvailable,
                },
            ],
        },
        [domain.IntegrationCode.XeroQuote]: {
            name: workflowsMessages.salesQuotesName,
            startIcon: SalesQuoteIcon,
            subItems: [
                {
                    id: AllByType,
                    name: filtersMessages.allName,
                },
                {
                    id: OnReview,
                    name: filtersMessages.onReview,
                    available: isReviewStepAvailable,
                },
                {
                    id: OnApproval,
                    name: filtersMessages.onApprovalName,
                },
                {
                    id: Approved,
                    name: filtersMessages.approvedName,
                },
                {
                    id: Cancelled,
                    name: filtersMessages.cancelledName,
                },
                {
                    id: Rejected,
                    name: filtersMessages.rejectedName,
                },
                {
                    id: OnHold,
                    name: filtersMessages.onHold,
                    available: isOnHoldStatusAvailable,
                },
                {
                    id: Accepted,
                    name: filtersMessages.acceptedName,
                },
                {
                    id: Declined,
                    name: filtersMessages.declinedName,
                },
            ],
        },
        [domain.IntegrationCode.XeroInvoice]: {
            name: workflowsMessages.xeroInvoiceName,
            startIcon: InvoiceIcon,
            subItems: [
                {
                    id: AllByType,
                    name: filtersMessages.allName,
                },
                {
                    id: OnReview,
                    name: filtersMessages.onReview,
                    available: isReviewStepAvailable,
                },
                {
                    id: OnApproval,
                    name: filtersMessages.onApprovalName,
                },
                {
                    id: Approved,
                    name: filtersMessages.approvedName,
                },
                {
                    id: Paid,
                    name: filtersMessages.paidName,
                },
                {
                    id: Cancelled,
                    name: filtersMessages.cancelledName,
                },
                {
                    id: Rejected,
                    name: filtersMessages.rejectedName,
                },
                {
                    id: OnHold,
                    name: filtersMessages.onHold,
                    available: isOnHoldStatusAvailable,
                },
            ],
        },
        [domain.IntegrationCode.XeroCreditNotesPayable]: {
            name: workflowsMessages.xeroCreditNotesPayableName,
            startIcon: ApCreditNotesIcon,
            subItems: [
                {
                    id: AllByType,
                    name: filtersMessages.allName,
                },
                {
                    id: OnApproval,
                    name: filtersMessages.onApprovalName,
                },
                {
                    id: Approved,
                    name: filtersMessages.approvedName,
                },
                {
                    id: Paid,
                    name: filtersMessages.paidName,
                },
                {
                    id: Cancelled,
                    name: filtersMessages.cancelledName,
                },
                {
                    id: Rejected,
                    name: filtersMessages.rejectedName,
                },
                {
                    id: OnHold,
                    name: filtersMessages.onHold,
                    available: isOnHoldStatusAvailable,
                },
            ],
        },
        [domain.IntegrationCode.XeroCreditNotesReceivable]: {
            name: workflowsMessages.xeroCreditNotesReceivableName,
            startIcon: ArCreditNotesIcon,
            subItems: [
                {
                    id: AllByType,
                    name: filtersMessages.allName,
                },
                {
                    id: OnApproval,
                    name: filtersMessages.onApprovalName,
                },
                {
                    id: Approved,
                    name: filtersMessages.approvedName,
                },
                {
                    id: Paid,
                    name: filtersMessages.paidName,
                },
                {
                    id: Cancelled,
                    name: filtersMessages.cancelledName,
                },
                {
                    id: Rejected,
                    name: filtersMessages.rejectedName,
                },
                {
                    id: OnHold,
                    name: filtersMessages.onHold,
                    available: isOnHoldStatusAvailable,
                },
            ],
        },
        [domain.IntegrationCode.XeroContact]: {
            name: workflowsMessages.xeroContactName,
            available: isXeroContactAvailable,
            startIcon: UsersIcon,
            subItems: [
                {
                    id: AllByType,
                    name: filtersMessages.allName,
                },
                {
                    id: OnReview,
                    name: filtersMessages.onReview,
                    available: isReviewStepAvailable,
                },
                {
                    id: OnApproval,
                    name: filtersMessages.onApprovalName,
                },
                {
                    id: Approved,
                    name: filtersMessages.approvedName,
                },
                {
                    id: Cancelled,
                    name: filtersMessages.cancelledName,
                },
                {
                    id: Rejected,
                    name: filtersMessages.rejectedName,
                },
                {
                    id: OnHold,
                    name: filtersMessages.onHold,
                    available: isOnHoldStatusAvailable,
                },
            ],
        },
        [domain.IntegrationCode.QBooksPo]: {
            name: workflowsMessages.qBooksPoName,
            startIcon: BoxIcon,
            subItems: [
                {
                    id: AllByType,
                    name: filtersMessages.allName,
                },
                {
                    id: OnReview,
                    name: filtersMessages.onReview,
                    available: isReviewStepAvailable,
                },
                {
                    id: OnApproval,
                    name: filtersMessages.onApprovalName,
                },
                {
                    id: Approved,
                    name: filtersMessages.approvedName,
                },
                {
                    id: Cancelled,
                    name: filtersMessages.cancelledName,
                },
                {
                    id: Rejected,
                    name: filtersMessages.rejectedName,
                },
                {
                    id: OnHold,
                    name: filtersMessages.onHold,
                    available: isOnHoldStatusAvailable,
                },
                {
                    id: GrnNotReceived,
                    name: filtersMessages.grnNotReceivedName,
                    available: isGrnAvailable && isGrnQBOPOAvailable,
                },
                {
                    id: GrnPartiallyReceived,
                    name: filtersMessages.grnPartiallyReceivedName,
                    available: isGrnAvailable && isGrnQBOPOAvailable,
                },
                {
                    id: GrnFullyReceived,
                    name: filtersMessages.grnFullyReceivedName,
                    available: isGrnAvailable && isGrnQBOPOAvailable,
                },
            ],
        },
        [domain.IntegrationCode.QBooksBill]: {
            name: workflowsMessages.qBooksBillName,
            startIcon: FileIcon,
            subItems: [
                {
                    id: AllByType,
                    name: filtersMessages.allName,
                },
                {
                    id: OnReview,
                    name: filtersMessages.onReview,
                    available: isReviewStepAvailable,
                },
                {
                    id: OnApproval,
                    name: filtersMessages.onApprovalName,
                },
                {
                    id: Approved,
                    name: filtersMessages.approvedName,
                },
                {
                    id: Paid,
                    name: filtersMessages.paidName,
                },
                {
                    id: Cancelled,
                    name: filtersMessages.cancelledName,
                },
                {
                    id: Rejected,
                    name: filtersMessages.rejectedName,
                },
                {
                    id: OnHold,
                    name: filtersMessages.onHold,
                    available: isOnHoldStatusAvailable,
                },
            ],
        },
        [domain.IntegrationCode.QBooksInvoice]: {
            name: workflowsMessages.qBooksInvoiceName,
            startIcon: InvoiceIcon,
            subItems: [
                {
                    id: AllByType,
                    name: filtersMessages.allName,
                },
                {
                    id: OnApproval,
                    name: filtersMessages.onApprovalName,
                },
                {
                    id: Approved,
                    name: filtersMessages.approvedName,
                },
                {
                    id: Paid,
                    name: filtersMessages.paidName,
                },
                {
                    id: Cancelled,
                    name: filtersMessages.cancelledName,
                },
                {
                    id: Rejected,
                    name: filtersMessages.rejectedName,
                },
                {
                    id: OnHold,
                    name: filtersMessages.onHold,
                    available: isOnHoldStatusAvailable,
                },
            ],
        },
        [domain.IntegrationCode.QBooksVendor]: {
            name: workflowsMessages.qBooksVendorName,
            available: isQBOVendorAvailable,
            startIcon: UsersIcon,
            subItems: [
                {
                    id: AllByType,
                    name: filtersMessages.allName,
                },
                {
                    id: OnReview,
                    name: filtersMessages.onReview,
                    available: isReviewStepAvailable,
                },
                {
                    id: OnApproval,
                    name: filtersMessages.onApprovalName,
                },
                {
                    id: Approved,
                    name: filtersMessages.approvedName,
                },
                {
                    id: Cancelled,
                    name: filtersMessages.cancelledName,
                },
                {
                    id: Rejected,
                    name: filtersMessages.rejectedName,
                },
                {
                    id: OnHold,
                    name: filtersMessages.onHold,
                    available: isOnHoldStatusAvailable,
                },
            ],
        },
        [domain.IntegrationCode.QBooksExpense]: {
            name: workflowsMessages.qBooksExpenseName,
            startIcon: FilesUsdIcon,
            subItems: [
                {
                    id: AllByType,
                    name: filtersMessages.allName,
                },
                {
                    id: OnReview,
                    name: filtersMessages.onReview,
                    available: isReviewStepAvailable,
                },
                {
                    id: OnApproval,
                    name: filtersMessages.onApprovalName,
                },
                {
                    id: Approved,
                    name: filtersMessages.approvedName,
                },
                {
                    id: Cancelled,
                    name: filtersMessages.cancelledName,
                },
                {
                    id: Rejected,
                    name: filtersMessages.rejectedName,
                },
                {
                    id: OnHold,
                    name: filtersMessages.onHold,
                    available: isOnHoldStatusAvailable,
                },
            ],
        },
        [domain.IntegrationCode.QBooksJournalEntry]: {
            name: workflowsMessages.qBooksJournalEntryName,
            available: isJournalEntryAvailable,
            startIcon: NoteBookIcon,
            subItems: [
                {
                    id: AllByType,
                    name: filtersMessages.allName,
                },
                {
                    id: OnReview,
                    name: filtersMessages.onReview,
                    available: isReviewStepAvailable,
                },
                {
                    id: OnApproval,
                    name: filtersMessages.onApprovalName,
                },
                {
                    id: Approved,
                    name: filtersMessages.approvedName,
                },
                {
                    id: Cancelled,
                    name: filtersMessages.cancelledName,
                },
                {
                    id: Rejected,
                    name: filtersMessages.rejectedName,
                },
                {
                    id: OnHold,
                    name: filtersMessages.onHold,
                    available: isOnHoldStatusAvailable,
                },
            ],
        },
        [domain.IntegrationCode.NetSuitePO]: {
            name: workflowsMessages.netSuitePOName,
            startIcon: BoxIcon,
            subItems: [
                {
                    id: AllByType,
                    name: filtersMessages.allName,
                },
                {
                    id: OnApproval,
                    name: filtersMessages.onApprovalName,
                },
                {
                    id: Approved,
                    name: filtersMessages.approvedName,
                },
                {
                    id: Billed,
                    name: filtersMessages.billedName,
                },
                {
                    id: Cancelled,
                    name: filtersMessages.cancelledName,
                },
                {
                    id: Rejected,
                    name: filtersMessages.rejectedName,
                },
                {
                    id: OnHold,
                    name: filtersMessages.onHold,
                    available: isOnHoldStatusAvailable,
                },
                {
                    id: PendingReceipt,
                    name: filtersMessages.pendingReceiptTitle,
                },
                {
                    id: PartiallyReceived,
                    name: filtersMessages.partiallyReceivedTitle,
                },
                {
                    id: PendingBill,
                    name: filtersMessages.pendingBillTitle,
                },
                {
                    id: Closed,
                    name: filtersMessages.closedTitle,
                },
            ],
        },
        [domain.IntegrationCode.NetSuiteBill]: {
            name: workflowsMessages.netSuiteBillName,
            startIcon: FileIcon,
            subItems: [
                {
                    id: AllByType,
                    name: filtersMessages.allName,
                },
                {
                    id: OnApproval,
                    name: filtersMessages.onApprovalName,
                },
                {
                    id: Approved,
                    name: filtersMessages.approvedName,
                },
                {
                    id: Paid,
                    name: filtersMessages.paidName,
                },
                {
                    id: Cancelled,
                    name: filtersMessages.cancelledName,
                },
                {
                    id: Rejected,
                    name: filtersMessages.rejectedName,
                },
                {
                    id: OnHold,
                    name: filtersMessages.onHold,
                    available: isOnHoldStatusAvailable,
                },
            ],
        },
        [domain.IntegrationCode.DearPo]: {
            name: workflowsMessages.dearPoName,
            startIcon: BoxIcon,
            subItems: [
                {
                    id: AllByType,
                    name: filtersMessages.allName,
                },
                {
                    id: OnApproval,
                    name: filtersMessages.onApprovalName,
                },
                {
                    id: Approved,
                    name: filtersMessages.approvedName,
                },
                {
                    id: Billed,
                    name: filtersMessages.billedName,
                },
                {
                    id: Cancelled,
                    name: filtersMessages.cancelledName,
                },
                {
                    id: Rejected,
                    name: filtersMessages.rejectedName,
                },
                {
                    id: OnHold,
                    name: filtersMessages.onHold,
                    available: isOnHoldStatusAvailable,
                },
            ],
        },
        [domain.IntegrationCode.NetSuiteSalesOrder]: {
            name: workflowsMessages.netSuiteSalesOrderName,
            startIcon: FileIcon,
            hasAddButton: false,
            subItems: [
                {
                    id: AllByType,
                    name: filtersMessages.allName,
                },
                {
                    id: OnApproval,
                    name: filtersMessages.onApprovalName,
                },
                {
                    id: Approved,
                    name: filtersMessages.approvedName,
                },
                {
                    id: Billed,
                    name: filtersMessages.billedName,
                },
                {
                    id: Cancelled,
                    name: filtersMessages.cancelledName,
                },
                {
                    id: Rejected,
                    name: filtersMessages.rejectedName,
                },
                {
                    id: OnHold,
                    name: filtersMessages.onHold,
                    available: isOnHoldStatusAvailable,
                },
            ],
        },
        [domain.IntegrationCode.NetSuiteInvoice]: {
            name: workflowsMessages.netSuiteInvoiceName,
            startIcon: FileIcon,
            hasAddButton: isNetSuiteInvoiceEditAvailable,
            subItems: [
                {
                    id: AllByType,
                    name: filtersMessages.allName,
                },
                {
                    id: OnApproval,
                    name: filtersMessages.onApprovalName,
                },
                {
                    id: Approved,
                    name: filtersMessages.approvedName,
                },
                {
                    id: Paid,
                    name: filtersMessages.paidName,
                },
                {
                    id: Cancelled,
                    name: filtersMessages.cancelledName,
                },
                {
                    id: Rejected,
                    name: filtersMessages.rejectedName,
                },
                {
                    id: OnHold,
                    name: filtersMessages.onHold,
                    available: isOnHoldStatusAvailable,
                },
            ],
        },
        [domain.IntegrationCode.NetSuiteExpenseReport]: {
            name: workflowsMessages.netSuiteExpenseReportName,
            startIcon: FilesUsdIcon,
            subItems: [
                {
                    id: AllByType,
                    name: filtersMessages.allName,
                },
                {
                    id: OnApproval,
                    name: filtersMessages.onApprovalName,
                },
                {
                    id: Approved,
                    name: filtersMessages.approvedName,
                },
                {
                    id: Paid,
                    name: filtersMessages.paidName,
                },
                {
                    id: Cancelled,
                    name: filtersMessages.cancelledName,
                },
                {
                    id: Rejected,
                    name: filtersMessages.rejectedName,
                },
                {
                    id: OnHold,
                    name: filtersMessages.onHold,
                    available: isOnHoldStatusAvailable,
                },
            ],
        },
        [domain.IntegrationCode.NetSuiteVRA]: {
            name: workflowsMessages.netSuiteVRAName,
            startIcon: FilesUsdIcon,
            hasAddButton: false,
            subItems: [
                {
                    id: AllByType,
                    name: filtersMessages.allName,
                },
                {
                    id: OnApproval,
                    name: filtersMessages.onApprovalName,
                },
                {
                    id: Approved,
                    name: filtersMessages.approvedName,
                },
                {
                    id: Credited,
                    name: filtersMessages.creditedName,
                },
                {
                    id: Cancelled,
                    name: filtersMessages.cancelledName,
                },
                {
                    id: Rejected,
                    name: filtersMessages.rejectedName,
                },
                {
                    id: OnHold,
                    name: filtersMessages.onHold,
                    available: isOnHoldStatusAvailable,
                },
            ],
        },
        [domain.IntegrationCode.NetSuiteBillPayment]: {
            name: workflowsMessages.netSuiteBillPaymentName,
            startIcon: FilesUsdIcon,
            subItems: [
                {
                    id: AllByType,
                    name: filtersMessages.allName,
                },
                {
                    id: OnApproval,
                    name: filtersMessages.onApprovalName,
                },
                {
                    id: Approved,
                    name: filtersMessages.approvedName,
                },
                {
                    id: Cancelled,
                    name: filtersMessages.cancelledName,
                },
                {
                    id: Rejected,
                    name: filtersMessages.rejectedName,
                },
                {
                    id: OnHold,
                    name: filtersMessages.onHold,
                    available: isOnHoldStatusAvailable,
                },
            ],
        },
        [domain.IntegrationCode.NetSuiteRA]: {
            name: workflowsMessages.netSuiteRAName,
            available: isNetSuiteRAAvailable,
            startIcon: FilesUsdIcon,
            hasAddButton: false,
            subItems: [
                {
                    id: AllByType,
                    name: filtersMessages.allName,
                },
                {
                    id: OnApproval,
                    name: filtersMessages.onApprovalName,
                },
                {
                    id: Approved,
                    name: filtersMessages.approvedName,
                },
                {
                    id: Paid,
                    name: filtersMessages.paidName,
                },
                {
                    id: Cancelled,
                    name: filtersMessages.cancelledName,
                },
                {
                    id: Rejected,
                    name: filtersMessages.rejectedName,
                },
                {
                    id: OnHold,
                    name: filtersMessages.onHold,
                    available: isOnHoldStatusAvailable,
                },
            ],
        },
        [domain.IntegrationCode.XeroManualJournal]: {
            name: workflowsMessages.xeroManualJournalName,
            available: isXeroManualJournalAvailable,
            startIcon: FileIcon,
            subItems: [
                {
                    id: AllByType,
                    name: filtersMessages.allName,
                },
                {
                    id: OnReview,
                    name: filtersMessages.onReview,
                    available: isReviewStepAvailable,
                },
                {
                    id: OnApproval,
                    name: filtersMessages.onApprovalName,
                },
                {
                    id: Approved,
                    name: filtersMessages.approvedName,
                },
                {
                    id: Cancelled,
                    name: filtersMessages.cancelledName,
                },
                {
                    id: Rejected,
                    name: filtersMessages.rejectedName,
                },
                {
                    id: OnHold,
                    name: filtersMessages.onHold,
                    available: isOnHoldStatusAvailable,
                },
            ],
        },
        [domain.IntegrationCode.NetSuiteJournalEntry]: {
            name: workflowsMessages.netSuiteJournalEntryName,
            available: isNetSuiteJournalEntryAvailable,
            startIcon: FileIcon,
            hasAddButton: false,
            subItems: [
                {
                    id: AllByType,
                    name: filtersMessages.allName,
                },
                {
                    id: OnReview,
                    name: filtersMessages.onReview,
                    available: isReviewStepAvailable,
                },
                {
                    id: OnApproval,
                    name: filtersMessages.onApprovalName,
                },
                {
                    id: Approved,
                    name: filtersMessages.approvedName,
                },
                {
                    id: Cancelled,
                    name: filtersMessages.cancelledName,
                },
                {
                    id: Rejected,
                    name: filtersMessages.rejectedName,
                },
                {
                    id: OnHold,
                    name: filtersMessages.onHold,
                    available: isOnHoldStatusAvailable,
                },
            ],
        },
        [domain.IntegrationCode.NetSuiteVendor]: {
            name: workflowsMessages.netSuiteVendorName,
            startIcon: FileIcon,
            subItems: [
                {
                    id: AllByType,
                    name: filtersMessages.allName,
                },
                {
                    id: OnApproval,
                    name: filtersMessages.onApprovalName,
                },
                {
                    id: Approved,
                    name: filtersMessages.approvedName,
                },
                {
                    id: Cancelled,
                    name: filtersMessages.cancelledName,
                },
                {
                    id: Rejected,
                    name: filtersMessages.rejectedName,
                },
            ],
        },
        Standalone: {
            startIcon: AmIcon,
            subItems: [
                {
                    id: AllByType,
                    name: filtersMessages.allName,
                },
                {
                    id: OnApproval,
                    name: filtersMessages.onApprovalName,
                },
                {
                    id: Approved,
                    name: filtersMessages.approvedName,
                },
                {
                    id: Cancelled,
                    name: filtersMessages.cancelledName,
                },
                {
                    id: Rejected,
                    name: filtersMessages.rejectedName,
                },
                {
                    id: OnHold,
                    name: filtersMessages.onHold,
                    available: isOnHoldStatusAvailable,
                },
            ],
        },
    };
};
