import { Box, Button, Flex, Form, Label, Select } from '@approvalmax/ui/src/components';
import { selectors } from 'modules/common';
import { useSelector } from 'modules/react-redux';
import { FC, memo, useCallback, useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { isOcrEnabledForTemplate } from 'shared/helpers';

import { FormValues } from '../BulkCreationPopupContent.types';
import { messages } from './CompanyEditor.messages';
import { CompanyEditorProps } from './CompanyEditor.types';

export const CompanyEditor: FC<CompanyEditorProps> = memo((props) => {
    const { prevValues, setEditCompany } = props;

    const companies = useSelector(selectors.company.getOcrAvailableCompanies);

    const form = useFormContext<FormValues>();

    const selectedCompanyId = form.getValues('selectedCompanyId');
    const selectedTemplateIntegrationCode = form.getValues('selectedTemplateIntegrationCode');

    const selectedCompany = useSelector((state) => selectors.company.getCompanyById(state, selectedCompanyId));
    const selectedCompanyTemplates = useSelector((state) =>
        selectors.meta.getCreatableTemplatesByCompanyId(state, selectedCompanyId)
    );

    const filteredCompanyTemplates = useMemo(
        () =>
            selectedCompanyTemplates.filter(
                (companyTemplate) =>
                    companyTemplate.integrationCode &&
                    isOcrEnabledForTemplate(selectedCompany, companyTemplate.integrationCode)
            ),
        [selectedCompany, selectedCompanyTemplates]
    );

    // Adjust the template integration code to align with the current company
    useEffect(() => {
        if (
            filteredCompanyTemplates.length > 0 &&
            filteredCompanyTemplates.every((template) => template.integrationCode !== selectedTemplateIntegrationCode)
        ) {
            form.setValue('selectedTemplateIntegrationCode', filteredCompanyTemplates[0].integrationCode);
        }
    }, [filteredCompanyTemplates, form, selectedTemplateIntegrationCode]);

    const onCancel = useCallback(() => {
        setEditCompany(false);
        form.setValue('selectedCompanyId', prevValues.selectedCompanyId);
        form.setValue('selectedTemplateIntegrationCode', prevValues.selectedTemplateIntegrationCode);
    }, [form, prevValues.selectedCompanyId, prevValues.selectedTemplateIntegrationCode, setEditCompany]);

    const onSave = useCallback(() => {
        setEditCompany(false);
    }, [setEditCompany]);

    return (
        <Form.Part>
            <Box color='white100' width='100%' spacing='12'>
                <Flex direction='column'>
                    <div>
                        <Label required>{messages.companySelectTitle}</Label>

                        <Select
                            name='selectedCompanyId'
                            size='small'
                            clearable={false}
                            itemNameKey='displayName'
                            items={companies}
                        />
                    </div>

                    <Flex spacing='8' wrap={false}>
                        <Flex block>
                            <Label required>{messages.templateSelectTitle}</Label>

                            <Select
                                name='selectedTemplateIntegrationCode'
                                size='small'
                                clearable={false}
                                itemNameKey='displayName'
                                itemIdKey='integrationCode'
                                items={filteredCompanyTemplates}
                            />
                        </Flex>

                        <Flex justifyContent='end' spacing='8' size={12} alignItems='end'>
                            <Button size='small' color='midnight40' onClick={onCancel}>
                                {messages.cancelButtonTitle}
                            </Button>

                            <Button size='small' onClick={onSave} color='blue80' type='submit'>
                                {messages.saveButtonTitle}
                            </Button>
                        </Flex>
                    </Flex>
                </Flex>
            </Box>
        </Form.Part>
    );
});

CompanyEditor.displayName = 'CompanyEditor';
