import { selectors } from 'modules/common';
import { loadDelegatesPopup, loadProfilePopup } from 'modules/profile';
import { useCallback } from 'react';
import bemFactory from 'react-bem-factory';
import { useDispatch, useSelector } from 'react-redux';
import { useOpenProfileEditPopup } from 'shared/components';
import { useUserBetaFeatures } from 'shared/hooks';

import { messages } from './UserSettingsMenu.messages';

const qa = bemFactory.qa('page-nav-bar');

export const useMenuItems = () => {
    const dispatch = useDispatch();

    const { isBelgradeUIProfileEdit } = useUserBetaFeatures();
    const profileEditPopup = useOpenProfileEditPopup();

    const openProfilePopup = useCallback(() => {
        if (isBelgradeUIProfileEdit) {
            profileEditPopup.handleOpenPopup();
        } else {
            dispatch(loadProfilePopup());
        }
    }, [dispatch, isBelgradeUIProfileEdit, profileEditPopup]);

    const openDelegatesPopup = useCallback(() => dispatch(loadDelegatesPopup()), [dispatch]);

    const isDelegatesAvailable = useDelegatesAvailable();

    return [
        {
            id: 'profile',
            name: messages.profileInfoName,
            onClick: openProfilePopup,
            title: messages.profileInfoTitle,
            dataQa: qa('action-profile-button'),
        },
        {
            id: 'delegates',
            name: messages.profileDelegatesName,
            onClick: openDelegatesPopup,
            available: isDelegatesAvailable,
            title: messages.profileDelegatesTitle,
            dataQa: qa('action-delegates-button'),
        },
    ].filter(({ available }) => available ?? true);
};

export const useDelegatesAvailable = () => {
    const companies = useSelector(selectors.company.getCompanies);

    return companies.length > 0;
};
