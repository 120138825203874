import { Accept } from 'react-dropzone';

export const avatarMaxFileSize = 10000000;
export const avatarMaxFileSizeLabel = `${avatarMaxFileSize / 1000 / 1000} MB`;
export const avatarAllowedFileTypes: Accept = {
    'image/png': ['.png'],
    'image/jpeg': ['.jpg', '.jpeg'],
};

export const firstNameMinLength = 1;
export const firstNameMaxLength = 500;
export const lastNameMinLength = 1;
export const lastNameMaxLength = 500;

export const fieldNamesSectionGeneral = <const>{
    firstName: 'firstName',
    lastName: 'lastName',
    phone: 'phone',
    email: 'email',
    timeZoneForEmail: 'timeZoneForEmail',
};
