import { domain } from 'modules/data';
import { Route } from 'modules/page';
import { useCallback, useMemo } from 'react';
import { useUserBetaFeatures } from 'shared/hooks';

export const useRoutesPaths = (routesBasic: Route[], routesRedesign: Route[], includeRoot?: boolean) => {
    const userBetaList = useUserBetaFeatures();

    /**
     * Check if a user has access to a beta feature from API or local storage
     */
    const getAccess = useCallback(
        (beta?: domain.ProfileBetaFeature) => {
            if (!beta) return true;

            const hasInBeta = userBetaList[`is${beta}`];
            const hasInStorage = localStorage.getItem(beta) === 'true';

            return hasInBeta || hasInStorage;
        },
        [userBetaList]
    );

    /**
     * Create an array with paths of all routes that are not redesigned
     */
    const pathsBasic = useMemo(
        () =>
            routesBasic.reduce<string[]>(
                (acc, route) => {
                    const isRedesignRoute = routesRedesign.find(({ path, beta }) => {
                        return getAccess(beta) && String(path) === String(route.path);
                    });

                    if (isRedesignRoute) return acc;

                    const pathArray = Array.isArray(route.path) ? route.path : [route.path];

                    acc.push(...pathArray);

                    return acc;
                },
                includeRoot ? ['/'] : []
            ),
        [getAccess, includeRoot, routesBasic, routesRedesign]
    );

    /**
     * Create an array with paths of all redesigned routes
     */
    const pathsRedesign = useMemo(() => {
        return routesRedesign.reduce<string[]>((acc, { path, beta }) => {
            if (!getAccess(beta)) return acc;

            const pathArray = Array.isArray(path) ? path : [path];

            acc.push(...pathArray);

            return acc;
        }, []);
    }, [getAccess, routesRedesign]);

    return {
        pathsBasic,
        pathsRedesign,
    };
};
