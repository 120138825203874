import { ColumnDefinition } from '@approvalmax/ui/src/components';

import { messages } from './VersionHistoryPopupContent.messages';
import { AccountValueWithId } from './VersionHistoryPopupContent.types';

export const tableDefinition: ColumnDefinition<AccountValueWithId>[] = [
    {
        id: 'name',
        name: messages.name,
        value: (item) => item.value,
    },
];
