import { Button, Flex, Popup, Table } from '@approvalmax/ui/src/components';
import { selectors } from 'modules/common';
import { getSettingsLinksByIntegration } from 'modules/common/selectors/linkSelectors';
import { ExpandedCompany } from 'modules/common/selectors/types';
import { State } from 'modules/data';
import { memo, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { applyFieldSettings, closeFieldSettingsPopup } from '../../actions';
import { getActiveFieldSettings, getActiveTemplate } from '../../selectors/pageSelectors';
import { SettingsSection } from '../WorkflowSettingsPopupContent/components/SettingsSection/SettingsSection';
import { areFieldsStateEqual, filterHeaderFields, filterLineFields } from './FieldSettingsPopup.helpers';
import { useFieldsTable } from './FieldSettingsPopup.hooks';
import { messages } from './FieldSettingsPopup.messages';

export const FieldSettingsPopup = memo(() => {
    const popupData = useSelector(getActiveFieldSettings);
    const template = useSelector(getActiveTemplate);
    const dispatch = useDispatch();

    const company = useSelector<State, ExpandedCompany>((state) =>
        selectors.company.getCompanyById(state, template?.companyId || '')
    );

    const links = getSettingsLinksByIntegration(company.integration?.integrationType, template?.integrationCode);

    const headerFields = useMemo(() => filterHeaderFields(template?.documentFields), [template]);

    const itemsFields = useMemo(() => filterLineFields('Items', template?.documentFields), [template]);

    const expensesFields = useMemo(() => filterLineFields('Expenses', template?.documentFields), [template]);

    const applyLineFields = useMemo(() => filterLineFields('ApplyLine', template?.documentFields), [template]);

    const creditLineFields = useMemo(() => filterLineFields('CreditLine', template?.documentFields), [template]);

    const addressLineFields = useMemo(() => filterLineFields('AddressLine', template?.documentFields), [template]);

    const {
        fieldSettingsState: headerFieldsSettings,
        resetToDefaultState: resetHeaderFieldsToDefault,
        tableDef: headerFieldsTableDef,
    } = useFieldsTable(headerFields);

    const {
        fieldSettingsState: itemsFieldsSettings,
        resetToDefaultState: resetItemsFieldsToDefault,
        tableDef: itemsFieldsTableDef,
    } = useFieldsTable(itemsFields);

    const {
        fieldSettingsState: expensesFieldsSettings,
        resetToDefaultState: resetExpensesFieldsToDefault,
        tableDef: expensesFieldsTableDef,
    } = useFieldsTable(expensesFields);

    const {
        fieldSettingsState: applyLineFieldsSettings,
        resetToDefaultState: resetApplyLineFieldsToDefault,
        tableDef: applyLineFieldsTableDef,
    } = useFieldsTable(applyLineFields);

    const {
        fieldSettingsState: creditLineFieldsSettings,
        resetToDefaultState: resetCreditLineFieldsToDefault,
        tableDef: creditLineFieldsTableDef,
    } = useFieldsTable(creditLineFields);

    const {
        fieldSettingsState: addressLineFieldsSettings,
        resetToDefaultState: resetAddressLineFieldsToDefault,
        tableDef: addressLineFieldsTableDef,
    } = useFieldsTable(addressLineFields);

    const templateDisplayName = selectors.template.getTemplateDisplayNameByCode(template?.integrationCode ?? null);

    const closePopup = useCallback(() => dispatch(closeFieldSettingsPopup()), [dispatch]);

    const onSave = useCallback(() => {
        const allFieldSettings = [
            ...headerFieldsSettings,
            ...itemsFieldsSettings,
            ...expensesFieldsSettings,
            ...applyLineFieldsSettings,
            ...creditLineFieldsSettings,
            ...addressLineFieldsSettings,
        ];

        const hasChanges = !areFieldsStateEqual(template?.documentFields, allFieldSettings);

        if (hasChanges) {
            dispatch(
                applyFieldSettings(
                    (template?.documentFields ?? []).map((field) => {
                        const fieldSettings = allFieldSettings.find((item) => item.fieldPurpose === field.purpose);

                        if (fieldSettings) {
                            return {
                                ...field,
                                state: fieldSettings.fieldState,
                            };
                        }

                        return field;
                    })
                )
            );
        }

        closePopup();
    }, [
        headerFieldsSettings,
        itemsFieldsSettings,
        expensesFieldsSettings,
        applyLineFieldsSettings,
        creditLineFieldsSettings,
        addressLineFieldsSettings,
        dispatch,
        template,
        closePopup,
    ]);

    const onResetToDefault = useCallback(() => {
        resetHeaderFieldsToDefault();
        resetItemsFieldsToDefault();
        resetExpensesFieldsToDefault();
        resetApplyLineFieldsToDefault();
        resetCreditLineFieldsToDefault();
        resetAddressLineFieldsToDefault();
    }, [
        resetHeaderFieldsToDefault,
        resetItemsFieldsToDefault,
        resetExpensesFieldsToDefault,
        resetApplyLineFieldsToDefault,
        resetCreditLineFieldsToDefault,
        resetAddressLineFieldsToDefault,
    ]);

    const onToggle = useCallback((open: boolean) => !open && closePopup(), [closePopup]);

    return (
        <Popup onToggle={onToggle} open={Boolean(popupData)} size='large'>
            <Popup.Header
                title={messages.popupTitle({ workflowDisplayName: templateDisplayName })}
                actions={
                    <Flex spacing='16' wrap={false}>
                        <Button size='medium' onClick={onResetToDefault} title={messages.resetToDefaultButton}>
                            {messages.resetToDefaultButton}
                        </Button>

                        <Button size='medium' color='blue80' onClick={onSave} title={messages.doneButton}>
                            {messages.doneButton}
                        </Button>
                    </Flex>
                }
            />

            <Popup.Body>
                <Flex direction='column' spacing='32'>
                    {headerFields.length > 0 && (
                        <SettingsSection
                            title={messages.headerFieldsTitle}
                            learnMoreLink={links.HeaderFields}
                            spacing='32'
                        >
                            <Table bordered data={headerFieldsSettings} columns={headerFieldsTableDef} />
                        </SettingsSection>
                    )}

                    {expensesFields.length > 0 && (
                        <SettingsSection
                            title={messages.expensesFieldsTitle}
                            learnMoreLink={links.ExpensesFields}
                            spacing='32'
                        >
                            <Table bordered data={expensesFieldsSettings} columns={expensesFieldsTableDef} />
                        </SettingsSection>
                    )}

                    {itemsFields.length > 0 && (
                        <SettingsSection
                            title={messages.itemsFieldsTitle}
                            learnMoreLink={links.ItemsFields}
                            spacing='32'
                        >
                            <Table bordered data={itemsFieldsSettings} columns={itemsFieldsTableDef} />
                        </SettingsSection>
                    )}

                    {applyLineFields.length > 0 && (
                        <SettingsSection
                            title={messages.applyLineFieldsTitle}
                            learnMoreLink={links.ApplyLineFields}
                            spacing='32'
                        >
                            <Table bordered data={applyLineFieldsSettings} columns={applyLineFieldsTableDef} />
                        </SettingsSection>
                    )}

                    {creditLineFields.length > 0 && (
                        <SettingsSection
                            title={messages.creditLineFieldsTitle}
                            learnMoreLink={links.CreditLineFields}
                            spacing='32'
                        >
                            <Table bordered data={creditLineFieldsSettings} columns={creditLineFieldsTableDef} />
                        </SettingsSection>
                    )}

                    {addressLineFields.length > 0 && (
                        <SettingsSection
                            title={messages.addressLineFieldsTitle}
                            learnMoreLink={links.AddressLineFields}
                            spacing='32'
                        >
                            <Table bordered data={addressLineFieldsSettings} columns={addressLineFieldsTableDef} />
                        </SettingsSection>
                    )}
                </Flex>
            </Popup.Body>
        </Popup>
    );
});

FieldSettingsPopup.displayName = 'FieldSettingsPopup';
