import { FC, memo, MouseEventHandler, useCallback } from 'react';
import { useToggle } from 'react-use';

import { AttachmentIcon, CloseIcon, VisibilityOnIcon } from '../../icons';
import { Flex } from '../Flex/Flex';
import { Progress } from '../Progress/Progress';
import { Text } from '../Text/Text';
import { RemoveButton, Root, ShowActivationButton } from './Attachment.styles';
import { AttachmentProps } from './Attachment.types';

/**
 * The Attachment UI component displays either an uploaded or uploading file.
 */
const Attachment: FC<AttachmentProps> = memo((props) => {
    const {
        id,
        name,
        label,
        source,
        color = 'midnight20',
        size = 'medium',
        disabled,
        children,
        onRemove,
        onShow,
        progress,
        active,
        ...restProps
    } = props;

    const attachmentName = name || source?.name || '';
    const [hover, toggleHover] = useToggle(false);

    const onRemoveFile = useCallback(() => {
        onRemove?.({ id, name: attachmentName, source });
    }, [onRemove, source, id, attachmentName]);

    const onShowFile = useCallback<MouseEventHandler<HTMLButtonElement>>(
        (e) => {
            onShow?.({ id, name: attachmentName, source }, e.ctrlKey || e.altKey || e.metaKey);
        },
        [onShow, source, id, attachmentName]
    );

    return (
        <Root
            radius='xsmall'
            spacing='12'
            color={color}
            $size={size}
            $hasShow={Boolean(onShow)}
            $disabled={disabled}
            $active={hover || active}
            {...restProps}
        >
            <Flex alignItems='center' justifyContent='space-between' wrap={false} spacing='12'>
                <Flex alignItems='center' wrap={false} spacing='8'>
                    <Flex shrink={0}>
                        <AttachmentIcon size={12} />
                    </Flex>

                    <Text font='label' ellipsis={1} fontSize='small'>
                        {attachmentName}
                    </Text>
                </Flex>

                <Flex shrink={0}>
                    {label && (
                        <Text
                            font='label'
                            wrap={false}
                            color='midnight60'
                            fontSize='small'
                            spacing={onRemove && !progress ? '0 28 0 0' : undefined}
                        >
                            {label}
                        </Text>
                    )}

                    {progress && <Progress shape='circle' size='uxsmall' />}

                    {onRemove && !progress && (
                        <RemoveButton icon onClick={onRemoveFile}>
                            <CloseIcon size={12} />
                        </RemoveButton>
                    )}
                </Flex>
            </Flex>

            {onShow && (
                <ShowActivationButton
                    onClick={onShowFile}
                    disabled={disabled}
                    onMouseEnter={toggleHover}
                    onMouseLeave={toggleHover}
                    $disabled={disabled}
                    $active={hover || active}
                >
                    <VisibilityOnIcon size={12} />
                </ShowActivationButton>
            )}
        </Root>
    );
});

export default Attachment;
