import { domain } from 'modules/data';
import { atom, selector } from 'recoil';

export const userBetaFeaturesArrayState = atom<domain.ProfileBetaFeature[]>({
    key: 'userBetaFeaturesArrayState',
    default: [],
});

type UserBetaFeaturesStatus = {
    [K in domain.ProfileBetaFeature as `is${Capitalize<K>}`]: boolean;
};

/**
 * @deprecated Direct usage is prohibited. Please use a {@link useUserBetaFeatures} hook instead
 */
export const userBetaFeaturesStatusState = selector<UserBetaFeaturesStatus>({
    key: 'userBetaFeaturesStatusState',
    get: ({ get }) => {
        const currentBetaFeatures = get(userBetaFeaturesArrayState);
        const allBetaFeatures = Object.values(domain.ProfileBetaFeature);

        return allBetaFeatures.reduce((acc, feature) => {
            acc[`is${feature}`] = currentBetaFeatures.includes(feature);

            return acc;
        }, {} as UserBetaFeaturesStatus);
    },
});
