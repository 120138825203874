import { selectors } from 'modules/common';
import { z } from 'zod';

import { schemaSectionAppearance } from '../SectionAppearance/SectionAppearance.schema';
import { getSchemaSectionGeneral } from '../SectionGeneral/SectionGeneral.schema';
import { schemaSectionNotifications } from '../SectionNotifications/SectionNotifications.schema';

export const getSchema = (profile: selectors.types.ExpandedProfile) => {
    return z
        .object({})
        .merge(schemaSectionAppearance)
        .merge(getSchemaSectionGeneral(profile))
        .merge(schemaSectionNotifications);
};
