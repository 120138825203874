import { Extension } from '@tiptap/core';
import Bold from '@tiptap/extension-bold';
import BulletList from '@tiptap/extension-bullet-list';
import CharacterCount from '@tiptap/extension-character-count';
import Document from '@tiptap/extension-document';
import History from '@tiptap/extension-history';
import Italic from '@tiptap/extension-italic';
import ListItem from '@tiptap/extension-list-item';
import { MentionOptions } from '@tiptap/extension-mention';
import OrderedList from '@tiptap/extension-ordered-list';
import Paragraph from '@tiptap/extension-paragraph';
import Placeholder from '@tiptap/extension-placeholder';
import Text from '@tiptap/extension-text';
import Underline from '@tiptap/extension-underline';
import { Editor, EditorEvents, useEditor } from '@tiptap/react';
import cs from 'classnames';
import debounce from 'lodash/debounce';
import { forwardRef, ForwardRefExoticComponent, RefAttributes, useCallback, useEffect } from 'react';
import bemFactory from 'react-bem-factory';
import { useUnmount } from 'react-use';

import { Flex } from '../Flex/Flex';
import Label from '../Label/Label';
import { Controller, MenuBar } from './components';
import { createSuggestion } from './extensions/mention/helpers/suggestion';
import Mention from './extensions/mention/mention';
import NoNewLine from './extensions/noNewLine/noNewLine';
import { EditorField, Root } from './RichEditor.styles';
import { ChildrenComponents, RichEditorProps } from './RichEditor.types';

const bem = bemFactory.block('rich-editor');

export const RichEditor = forwardRef((props, ref) => {
    const {
        value = '',
        onChange = () => undefined,
        placeholder = '',
        invalid,
        changeOnBlur,
        changeImmediately,
        onBlur,
        onFocus,
        dataQa = '',
        maxLength,
        className,
        readOnly = false,
        allowTextFormatting = true,
        allowMention = false,
        mentionItems,
        customMentionConfig,
        restrictNewLine = false,
        onCtrlEnter,
        minHeight = 88,
        height,
        disabled,
        size = 'medium',
        name,
        required,
        label,
    } = props;

    const debouncedOnChange = debounce(onChange, 400);

    const extensionsForTextFormatting = allowTextFormatting
        ? [Bold, Italic, Underline, OrderedList, BulletList, ListItem]
        : [];

    const defaultMentionConfig: Partial<MentionOptions> = {
        HTMLAttributes: {
            class: 'mention', // INFO: do not change this class, it is also used in mobile app
        },
        renderLabel: ({ node }) => node.attrs.name,
        suggestion: {
            ...createSuggestion({}),
            items: mentionItems,
        },
    };

    const extensionsForMentions =
        allowMention && (mentionItems || customMentionConfig)
            ? [Mention.configure({ ...defaultMentionConfig, ...customMentionConfig })]
            : [];

    const editor = useEditor({
        extensions: [
            ...(restrictNewLine ? [NoNewLine] : []),
            ...extensionsForTextFormatting,
            ...extensionsForMentions,
            Document,
            Paragraph,
            Text,

            Placeholder.configure({
                placeholder,
                emptyEditorClass: bem('placeholder'),
            }),
            CharacterCount.configure({
                limit: maxLength,
            }),
            History.configure({
                depth: 100,
            }),

            Extension.create({
                addKeyboardShortcuts() {
                    return {
                        'Cmd-Enter'() {
                            onCtrlEnter?.();

                            return true;
                        },
                        'Ctrl-Enter'() {
                            onCtrlEnter?.();

                            return true;
                        },
                    };
                },
            }),
        ],
        content: value,
        editable: !readOnly && !disabled,
        editorProps: {
            attributes: {
                class: cs(bem('text-field'), className),
                'data-qa': dataQa,
            },
        },
        onUpdate: (props: EditorEvents['update']) => {
            const value = props.editor.getText().trim() ? props.editor.view.dom.innerHTML : '';

            if (!changeOnBlur) {
                if (changeImmediately) {
                    onChange(value);
                } else {
                    debouncedOnChange(value);
                }
            }
        },
        onBlur: (props: EditorEvents['blur']) => {
            const value = props.editor.getText().trim() ? props.editor.view.dom.innerHTML : '';

            if (changeOnBlur) {
                onChange(value);
            }

            if (onBlur) {
                onBlur(props.event);
            }
        },
        onFocus: (props: EditorEvents['focus']) => {
            if (onFocus) {
                onFocus(props.event);
            }
        },
    });

    useEffect(() => {
        if (editor && !editor.isDestroyed && value === '') {
            editor?.commands.setContent(value, false, {
                preserveWhitespace: true,
            });
        }
    }, [editor, value]);

    useUnmount(() => {
        editor?.destroy();
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncedUpdateEditor = useCallback(
        debounce((newValue: string) => {
            if (editor && !editor.isFocused && !editor.isDestroyed) {
                editor?.commands.setContent(newValue, false, {
                    preserveWhitespace: true,
                });
            }
        }, 300),
        [editor]
    );

    useEffect(() => {
        debouncedUpdateEditor(value);
    }, [value, debouncedUpdateEditor]);

    const showMenuBar = allowTextFormatting && !readOnly && !!editor && !disabled;

    return (
        <Root
            $invalid={invalid}
            $disabled={disabled}
            $empty={!value}
            $minHeight={minHeight}
            $height={height}
            $size={size}
        >
            {(label || showMenuBar) && (
                <Flex alignItems='center' justifyContent='space-between'>
                    {label && (
                        <Label
                            font='headline'
                            required={required}
                            size='xxsmall'
                            color='midnight80'
                            fontWeight='medium'
                            spacing='0'
                        >
                            {label}
                        </Label>
                    )}

                    {showMenuBar && <MenuBar editor={editor} />}
                </Flex>
            )}

            <EditorField editor={editor as Editor} name={name} required={required} ref={ref} />
        </Root>
    );
}) as ForwardRefExoticComponent<RichEditorProps & RefAttributes<HTMLDivElement>> & ChildrenComponents;

RichEditor.displayName = 'RichEditor';
RichEditor.Controller = Controller;
