import { FC } from 'react';
import { RecoilRoot } from 'recoil';

export const withRecoilRoot = <Props extends Record<string, any>>(Component: FC<Props>) => {
    const WithRecoilRoot: FC<Props> = (props) => (
        <RecoilRoot override={false}>
            <Component {...props} />
        </RecoilRoot>
    );

    WithRecoilRoot.displayName = 'WithRecoilRoot';

    return WithRecoilRoot;
};
