import { Button, Flex, Popup, Spacing, Text } from '@approvalmax/ui/src/components';
import { FC, memo } from 'react';

import { messages } from './VersionConflictPopupContent.messages';
import { VersionConflictPopupContentProps } from './VersionConflictPopupContent.types';

export const VersionConflictPopupContent: FC<VersionConflictPopupContentProps> = memo((props) => {
    const { onClose, onOverwrite } = props;

    return (
        <>
            <Popup.Header title={messages.title} />

            <Popup.Body spacing='32'>
                <Text font='body' fontSize='medium'>
                    {messages.description}
                </Text>

                <Spacing height={32} />

                <Flex spacing='32' justifyContent='end'>
                    <Button onClick={onOverwrite} color='red40' size='large' uppercase>
                        {messages.buttonOverwrite}
                    </Button>

                    <Button onClick={onClose} color='blue80' size='large' uppercase>
                        {messages.buttonCancel}
                    </Button>
                </Flex>
            </Popup.Body>
        </>
    );
});

VersionConflictPopupContent.displayName = 'VersionConflictPopupContent';
