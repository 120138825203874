import { RequestMutationOptions } from '@approvalmax/data';
import { useMutate } from 'shared/data';

import { profileApiPaths } from '../paths';
import { SetAvatar } from './useSetAvatar.types';

export const useSetAvatar = (mutationOptions?: RequestMutationOptions<SetAvatar>) => {
    return useMutate(profileApiPaths.setAvatar, {
        mutationOptions,
        apiVersion: 'v1',
        mapToCamelCase: true,
    });
};
