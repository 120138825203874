import { selectors } from 'modules/common';
import { isPossiblePhoneNumber } from 'react-phone-number-input';
import { z } from 'zod';

import { checkIsAdministratorUserProfile } from '../../ProfileEditPopup.helpers';
import {
    fieldNamesSectionGeneral,
    firstNameMaxLength,
    firstNameMinLength,
    lastNameMaxLength,
    lastNameMinLength,
} from './SectionGeneral.constants';
import { messages } from './SectionGeneral.messages';

export const getSchemaSectionGeneral = (profile: selectors.types.ExpandedProfile) => {
    return z.object({
        [fieldNamesSectionGeneral.firstName]: z
            .string()
            .trim()
            .min(firstNameMinLength, messages.errorFirstNameRequired)
            .max(firstNameMaxLength, messages.errorFirstNameExceeded),
        [fieldNamesSectionGeneral.lastName]: z
            .string()
            .trim()
            .min(lastNameMinLength, messages.errorLastNameRequired)
            .max(lastNameMaxLength, messages.errorLastNameExceeded),
        [fieldNamesSectionGeneral.phone]: (checkIsAdministratorUserProfile(profile)
            ? z.string().trim().min(1, messages.errorPhoneRequired)
            : z.string().trim().optional()
        ).refine((phone) => isPossiblePhoneNumber(phone || ''), { message: messages.errorPhoneInvalid }),
        [fieldNamesSectionGeneral.email]: z
            .string()
            .trim()
            .min(1, messages.errorEmailRequired)
            .email(messages.errorEmailInvalid),
        [fieldNamesSectionGeneral.timeZoneForEmail]: z.string().trim().min(1, messages.errorTimeZoneRequired),
    });
};
