import { Reference } from '@approvalmax/types';

import { AppThemeOptions } from './SectionAppearance.types';
import { messages } from './SectionAppearence.messages';

export const fieldNameAppTheme = 'appTheme';

export const appThemeOptions: Reference[] = [
    { id: AppThemeOptions.light, text: messages.themeLight },
    { id: AppThemeOptions.dark, text: messages.themeDark },
];
