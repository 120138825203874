import { ArrowReplayIcon, CircleFilledIcon, CircleIcon } from '@approvalmax/ui';
import { Box, Flex, Text } from '@approvalmax/ui/src/components';
import { FC, memo, useCallback, useMemo } from 'react';

import { messages } from './VersionItem.messages';
import { Click, Content, StatusBadge } from './VersionItem.styles';
import { VersionItemProps } from './VersionItem.types';

export const VersionItem: FC<VersionItemProps> = memo((props) => {
    const { title, date, version, comment, active, checked, isFirstInList, isLastInList, onSetVersion } = props;

    const handleClick = useCallback(() => {
        onSetVersion(version);
    }, [onSetVersion, version]);

    const dotIcon = useMemo(() => {
        switch (true) {
            case active:
                return <CircleFilledIcon size={8} color={checked ? 'green100' : 'green70'} />;

            case checked:
                return <CircleFilledIcon size={8} color='midnight60' />;

            default:
                return <CircleIcon size={8} color='midnight60' />;
        }
    }, [active, checked]);

    return (
        <Flex spacing='8' wrap={false}>
            <Flex direction='column' spacing='0' width={8} alignItems='center'>
                <Flex height={10} justifyContent='center' width={1}>
                    {!isFirstInList && <Box color='midnight60' width={1} height='100%' />}
                </Flex>

                {dotIcon}

                <Flex grow={1} width={1}>
                    {!isLastInList && <Box color='midnight60' width={1} height='100%' />}
                </Flex>
            </Flex>

            <Click onClick={handleClick}>
                <Content spacing='8 12' width='fill-available' radius='xsmall' $active={active} $checked={checked}>
                    <Flex spacing='8' wrap={false} justifyContent='space-between'>
                        <Flex direction='column' spacing='4'>
                            <Text
                                font='label'
                                fontSize='small'
                                fontWeight='medium'
                                color={active || checked ? 'midnight100' : 'midnight70'}
                            >
                                {title}
                            </Text>

                            <Text
                                font='label'
                                fontSize='xsmall'
                                color={active || checked ? 'midnight100' : 'midnight70'}
                            >
                                {date}
                            </Text>

                            {comment && (
                                <Flex spacing='2' wrap={false}>
                                    <ArrowReplayIcon color='midnight70' width={10} height={10} />

                                    <Text font='label' fontSize='xsmall' color='midnight70' spacing='4 0'>
                                        {comment}
                                    </Text>
                                </Flex>
                            )}
                        </Flex>

                        <StatusBadge $color={active ? 'green100' : 'midnight80'} $hide={!checked}>
                            <Text font='label' fontSize='small' wrap={false}>
                                {active ? messages.active : messages.archived}
                            </Text>
                        </StatusBadge>
                    </Flex>
                </Content>
            </Click>
        </Flex>
    );
});

VersionItem.displayName = 'VersionItem';
