import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('app.WorkflowIdPage.GroupOfValuesPopup.ActionsMenu', {
    edit: 'Edit',
    archive: 'Archive',
    activate: 'Activate',
    delete: 'Delete',
    viewHistory: 'View history',
    areYouSure: 'Are you sure?',
    deleteSuccess: '"{groupName}" has been deleted.',
    archiveSuccess: '"{groupName}" has been archived.',
    activateSuccess: '"{groupName}" is now active.',
});
