import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('components.ProfileEditPopup.SectionGeneral', {
    title: 'General',
    avatarLabel: 'Avatar',
    firstNameLabel: 'First name',
    firstNamePlaceholder: 'Enter your first name',
    lastNamePlaceholder: 'Enter your last name',
    lastNameLabel: 'Last name',
    phonePlaceholder: 'Enter your phone number',
    phoneLabel: 'Phone',
    timeZonePlaceholder: 'Enter your time zone',
    timeZoneLabel: 'Time zone',
    timeZoneHint: 'This timezone is used for email notifications.',
    errorFirstNameRequired: 'First name cannot be empty.',
    errorFirstNameExceeded: 'First name cannot be longer than 500 characters.',
    errorLastNameRequired: 'Last name cannot be empty.',
    errorLastNameExceeded: 'Last name cannot be longer than 500 characters.',
    errorPhoneRequired: 'Phone number cannot be empty.',
    errorPhoneInvalid: 'Phone number must contain 11 digits.',
    errorTimeZoneRequired: 'Time zone cannot be empty.',
    errorEmailRequired: 'Email cannot be empty.',
    errorEmailInvalid: 'Email format is invalid.',
});
