import { messages } from './customFields.messages';
import { CustomFieldAccessLevel, CustomFieldLevel } from './customFields.types';

export const customFieldAccessLevels = [
    {
        id: CustomFieldAccessLevel.Hidden,
        text: messages.hiddenFieldAccessLevel,
    },
    {
        id: CustomFieldAccessLevel.ReadOnly,
        text: messages.readOnlyFieldAccessLevel,
    },
    {
        id: CustomFieldAccessLevel.Editable,
        text: messages.editableFieldAccessLevel,
    },
    {
        id: CustomFieldAccessLevel.Mandatory,
        text: messages.mandatoryFieldAccessLevel,
    },
];

export const customFieldLevels = [
    {
        id: CustomFieldLevel.Header,
        text: messages.fieldLevelsHeader,
    },
    {
        id: CustomFieldLevel.Lines,
        text: messages.fieldLevelsLines,
    },
    {
        id: CustomFieldLevel.HeaderAndLines,
        text: messages.fieldLevelsHeaderAndLindes,
    },
];
