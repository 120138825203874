import { messages } from './CreationPopupContent.messages';

export const formFieldNames = <const>{
    groupName: 'groupName',
    fieldType: 'fieldType',
};

export const fieldTypes = <const>{
    account: 'account',
};

export const fieldTypeOptions = [{ id: fieldTypes.account, name: messages.account }];

export const nameMaxLength = 100;
