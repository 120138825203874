import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('shared.constants.netSuite.customFields', {
    hiddenFieldAccessLevel: 'Hidden',
    readOnlyFieldAccessLevel: 'Read-only',
    editableFieldAccessLevel: 'Editable',
    mandatoryFieldAccessLevel: 'Mandatory',
    fieldLevelsHeader: 'Header',
    fieldLevelsLines: 'Lines',
    fieldLevelsHeaderAndLindes: 'Header And Lines',
});
