import { isEnvironment } from './isEnvironment';
import { isKeyOfObject } from './isKeyOfObject';
import { isTruthy } from './isTruthy';
import { isValueOfObject } from './isValueOfObject';

export const typeGuardHelpers = {
    isKeyOfObject,
    isEnvironment,
    isTruthy,
    isValueOfObject,
};
