import { domain } from 'modules/data';
import { OcrContractsOcrDocumentTypes } from 'shared/data/v2';

export const getOcrDocumentTypeFromIntegrationCode = (integrationCode: domain.IntegrationCode | null) => {
    switch (integrationCode) {
        case domain.IntegrationCode.XeroBill:
            return OcrContractsOcrDocumentTypes.Xero_bill;

        case domain.IntegrationCode.XeroInvoice:
            return OcrContractsOcrDocumentTypes.Xero_invoice;

        case domain.IntegrationCode.QBooksBill:
            return OcrContractsOcrDocumentTypes.QBooks_bill;

        case domain.IntegrationCode.NetSuiteBill:
            return OcrContractsOcrDocumentTypes.NetSuite_vendor_bill;

        default:
            return null;
    }
};
