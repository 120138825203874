import { useMemo } from 'react';

const experimentOnValue = 'on';
const experimentOffValue = 'off';

export const useIsLocaleExperimentOn = (experimentName: string, probability = 0.5) => {
    return useMemo(() => {
        let isExperimentOn = false;

        const experimentStorageValue = localStorage.getItem(experimentName);

        if (experimentStorageValue) {
            isExperimentOn = experimentStorageValue === experimentOnValue;
        } else {
            isExperimentOn = Math.random() < probability;

            localStorage.setItem(experimentName, isExperimentOn ? experimentOnValue : experimentOffValue);
        }

        return isExperimentOn;
    }, [experimentName, probability]);
};
