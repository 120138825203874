import { mixins, Mods, mods, theme } from '@approvalmax/theme';
import { StyledMods } from '@styled-kit/mods';
import styled from 'styled-components';

import { Box } from '../Box/Box';
import Button from '../Button/Button';
import { AttachmentProps } from './Attachment.types';

export const RemoveButton = styled(Button)`
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 1;
    display: flex;
    align-items: center;

    svg {
        color: ${theme.color.midnight100};
    }

    &:hover {
        svg {
            color: ${theme.color.blue100};
        }
    }
`;

export const ShowActivationButton = styled.button<StyledMods<Mods<'disabled' | 'active'>>>`
    border-radius: ${theme.radius.xsmall};
    ${mixins.position.absoluteFill};
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;

    ${mods.disabled.true`
        z-index: 1;
    `};

    ${mods.disabled.false`
        cursor: pointer;
    `};

    svg {
        background-color: ${theme.color.blue10};
        box-shadow: 0 0 12px 12px ${theme.color.blue10};
        border-radius: 50%;
    }

    ${mods.active.false`
        svg {
            opacity: 0;
        }
    `};
`;

export const Root = styled(Box)<
    StyledMods<Pick<AttachmentProps, 'color' | 'disabled' | 'size'> & Mods<'active'> & { hasShow: boolean }>
>`
    border-radius: ${theme.radius.xsmall};
    position: relative;

    &,
    svg {
        transition: ${theme.duration.medium}ms ease-in-out;
    }

    ${mods.size.xsmall`
        padding: 4px;

        ${RemoveButton} {
            padding-right: 4px;
        }
    `};

    ${mods.size.medium`
        padding: 8px;

        ${RemoveButton} {
            padding-right: 8px;
        }
    `};

    ${mods('hasShow', true)`
        ${mods.disabled.false`
            ${mods.active.true`
                &,
                svg {
                    color: ${theme.color.blue100};
                }
            `};
        `};
    `};

    ${mods.color.midnight20`
        ${mods('hasShow', true)`
            ${mods.disabled.false`
                ${mods.active.true`
                    background-color: ${theme.color.blue10};
                    box-shadow: inset 0 0 0 1px ${theme.color.blue40};
                `};
            `};
        `};
    `};

    ${mods.disabled.true`
        &,
        svg {
            color: ${theme.color.midnight60};
        }
    `};
`;
