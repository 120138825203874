import { selectors } from 'modules/common';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useSetRecoilState } from 'recoil';
import { companiesBetaFeaturesState } from 'shared/states/companiesBetaFeatures';

export const useSetCompaniesBetaFeatures = () => {
    const companies = useSelector(selectors.company.companiesSelector);
    const setCompaniesBetaFeatures = useSetRecoilState(companiesBetaFeaturesState);

    useEffect(() => {
        setCompaniesBetaFeatures(new Map(companies.map((company) => [company.id, company.betaFeatures])));
    }, [companies, setCompaniesBetaFeatures]);
};
