import { domain } from 'modules/data';

import { messages } from './FieldSettingsPopup.messages';
import { FieldState } from './FieldSettingsPopup.types';

export const fieldStateList: {
    id: domain.DocumentFieldState;
    text: string;
}[] = [
    {
        id: domain.DocumentFieldState.Hidden,
        text: messages.hiddenFieldState,
    },
    {
        id: domain.DocumentFieldState.ReadOnly,
        text: messages.readOnlyFieldState,
    },
    {
        id: domain.DocumentFieldState.Editable,
        text: messages.editableFieldState,
    },
    {
        id: domain.DocumentFieldState.Mandatory,
        text: messages.mandatoryFieldState,
    },
];

export const getFieldStateValue = (accessLevel: domain.DocumentFieldState) => {
    return fieldStateList.find((item) => item.id === accessLevel) ?? null;
};

export const getFieldName = (field: FieldState) => {
    switch (field.fieldPurpose) {
        case 'ItemsUnitPrice':
            return messages.unitPriceFieldName;

        default:
            return field.fieldName || '';
    }
};

export const areFieldsStateEqual = (oldFields: domain.DocumentField[] = [], newFields: FieldState[]): boolean =>
    Boolean(
        oldFields.every(({ purpose, state }) => {
            const newFiledSetting = newFields.find(({ fieldPurpose }) => fieldPurpose === purpose);

            if (!newFiledSetting) return false;

            return newFiledSetting.fieldState === state;
        })
    );

export const filterHeaderFields = (documentFields?: domain.DocumentField[]) => {
    if (!documentFields) {
        return [];
    }

    const fields = documentFields.filter(
        ({ purpose }) =>
            !purpose.startsWith('Items') &&
            !purpose.startsWith('Expenses') &&
            !purpose.startsWith('ApplyLine') &&
            !purpose.startsWith('CreditLine') &&
            !purpose.startsWith('AddressLine')
    );

    return [...fields].sort((field1, field2) => field1.defaultOrder - field2.defaultOrder);
};

export const filterLineFields = (purposePrefix: string, documentFields?: domain.DocumentField[]) => {
    if (!documentFields) {
        return [];
    }

    const fields = documentFields.filter(({ purpose }) => purpose.startsWith(purposePrefix));

    return [...fields].sort((field1, field2) => field1.defaultOrder - field2.defaultOrder);
};
