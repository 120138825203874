import { Attachment, Box, Button, Checkbox, Flex, Form, Popup, Radio, Text } from '@approvalmax/ui/src/components';
import { FC, memo } from 'react';
import { useForm } from 'react-hook-form';

import { UploadType } from '../UploadPlaceholder/UploadPlaceholder.types';
import { messages } from './ChooseFilePopupContent.messages';
import { ChooseFilePopupContentProps, FormDataValues } from './ChooseFilesPopupItems.types';

export const ChooseFilePopupContent: FC<ChooseFilePopupContentProps> = memo((props) => {
    const { files, closePopup, setFiles, onDrop } = props;

    const form = useForm<FormDataValues>({
        defaultValues: {
            uploadAsAttachments: false,
            ocrFileName: files[0].source.name || '',
            fileNamesAsAttachments: files.map((file) => file.source.name),
        },
    });

    const uploadAsAttachments = form.watch('uploadAsAttachments');
    const fileNamesAsAttachments = form.watch('fileNamesAsAttachments');

    const onSubmit = form.handleSubmit((data) => {
        if (data.uploadAsAttachments) {
            const filteredFiles = files.filter((file) => data.fileNamesAsAttachments.includes(file.source.name));

            setFiles(filteredFiles);
            onDrop(filteredFiles, UploadType.General);
        } else {
            const ocrFile = files.find((file) => file.source.name === data.ocrFileName);

            if (ocrFile) {
                setFiles([ocrFile]);
                onDrop([ocrFile], UploadType.Ocr);
            }
        }

        closePopup();
    });

    const uploadDisabled = fileNamesAsAttachments.length === 0 && uploadAsAttachments;

    return (
        <Form form={form} onSubmit={onSubmit}>
            <Popup.Header
                title={messages.title}
                actions={
                    <Button color='blue80' type='submit' title={messages.uploadButtonTitle} disabled={uploadDisabled}>
                        {messages.uploadButtonTitle}
                    </Button>
                }
            />

            <Popup.Body>
                <Flex direction='column' spacing='16'>
                    <Box spacing='16' color='midnight20'>
                        <Checkbox name='uploadAsAttachments' alignItems='center'>
                            {messages.uploadAsAttachmentsText}
                        </Checkbox>
                    </Box>

                    <>
                        <Text font='label' fontSize='large' fontWeight='medium'>
                            {uploadAsAttachments ? messages.attachemntsUploadText : messages.ocrUploadText}
                        </Text>

                        <Box spacing='0 0 0 16'>
                            {uploadAsAttachments ? (
                                <Checkbox.Group name='fileNamesAsAttachments' spacing='8' block>
                                    {files.map((file) => (
                                        <Checkbox value={file.source?.name} key={file.id} alignItems='center'>
                                            <Attachment source={file.source} />
                                        </Checkbox>
                                    ))}
                                </Checkbox.Group>
                            ) : (
                                <Radio.Group name='ocrFileName' spacing='8' block>
                                    {files.map((file) => (
                                        <Radio value={file.source?.name} key={file.id} alignItems='center'>
                                            <Attachment source={file.source} />
                                        </Radio>
                                    ))}
                                </Radio.Group>
                            )}
                        </Box>
                    </>
                </Flex>
            </Popup.Body>
        </Form>
    );
});

ChooseFilePopupContent.displayName = 'ChooseFilePopupContent';
