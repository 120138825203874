import { fileHelpers } from '@approvalmax/utils';
import isError from 'lodash/isError';
import { ComponentProps, FC, memo, MouseEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { DropzoneProps as DropzoneExternalProps, useDropzone } from 'react-dropzone';
import { useDrop } from 'react-use';

import { AttachmentIcon, DragFileIcon } from '../../icons';
import Attachment from '../Attachment/Attachment';
import { Box } from '../Box/Box';
import Button from '../Button/Button';
import { Flex } from '../Flex/Flex';
import List from '../List/List';
import { Spacing } from '../Spacing/Spacing';
import { Text } from '../Text/Text';
import { toast } from '../Toast/Toast.helpers';
import { getErrorMessage, handleDuplicateNames, prepareFiles } from './Dropzone.helpers';
import { errorMessages, messages } from './Dropzone.messages';
import { Root, Zone } from './Dropzone.styles';
import { DropzoneProps, FileRecord } from './Dropzone.types';

/*
 * Component that abstracts the logic and UI for a drag and drop file upload zone using react-dropzone
 * https://react-dropzone.js.org/
 */
const Dropzone: FC<DropzoneProps> = memo((props) => {
    const {
        hideZone,
        size = 'medium',
        disabled,
        multiple = false,
        hideErrorToast = false,
        noDuplicateNames = false,
        accept,
        children,
        maxSize,
        minSize = 0,
        maxFilesInList,
        value,
        progress,
        invalid,
        width,
        height,
        hideButton,
        hideAcceptedExtensions,
        bordered,
        dragFileMessage,
        dragFileIcon,
        onUpload,
        filterFiles,
        onDropAccepted,
        onDropRejected,
        onChange,
        onRemove,
        onShow,
        onFileDialogCancel,
        additionalUploadButtonProps,
        readOnly,
        ...restProps
    } = props;

    const { over: isDragOnPageActive } = useDrop();
    const acceptedExtensions = useMemo(
        () =>
            accept
                ? Object.values(accept)
                      .map((ext) => ext.join(', '))
                      .join(', ')
                : '',
        [accept]
    );

    const [files, setFiles] = useState<Required<DropzoneProps>['value']>([]);
    const [newFiles, setNewFiles] = useState<FileRecord[]>([]);
    const [isUploadFromAdditionalButton, setIsUploadFromAdditionalButton] = useState(false);
    const hasFiles = Boolean(files.length);
    const hasNewFiles = Boolean(newFiles.length);
    const hasMultipleFiles = multiple && (hasFiles || hasNewFiles);
    const fileWordMessageValue = { file: multiple ? messages.files : messages.file };
    const isShowActionsUnderFiles =
        ((hideZone && !hasFiles && !hasNewFiles) || hasMultipleFiles) && !hideButton && !readOnly;
    const isShowAdditionalUploadButton =
        additionalUploadButtonProps && Object.keys(additionalUploadButtonProps).length > 0;

    useEffect(() => {
        const preparedValue = (value || []).map((file) => ({
            ...file,
            name: file.name || file.source?.name || '',
        }));

        setFiles(preparedValue);
    }, [value]);

    const onDropAcceptedFiles = useCallback<Required<DropzoneExternalProps>['onDropAccepted']>(
        async (acceptedFiles, event) => {
            let preparedFiles = prepareFiles(filterFiles ? filterFiles(acceptedFiles) : acceptedFiles);

            if (maxFilesInList && preparedFiles.length + files.length > maxFilesInList) {
                toast.error(errorMessages.tooManyFilesInList({ maxFilesInList }));

                return;
            }

            if (noDuplicateNames) {
                preparedFiles = handleDuplicateNames(preparedFiles, files);
            }

            if (onUpload) {
                setNewFiles((newFiles) => [...newFiles, ...preparedFiles]);

                const uploadResults = await Promise.all(
                    preparedFiles.map(async (file) => {
                        try {
                            await onUpload(file, isUploadFromAdditionalButton);

                            return { id: file.id, success: true };
                        } catch (error) {
                            if (isError(error)) {
                                toast.error(error.message);
                            }

                            return { id: file.id, success: false };
                        }
                    })
                );

                const uploadResultsMap = new Map(uploadResults.map((result) => [result.id, result.success]));

                setNewFiles((prevFiles) => prevFiles.filter((file) => !uploadResultsMap.has(file.id)));

                preparedFiles = preparedFiles.filter((file) => uploadResultsMap.get(file.id));
            }

            if (preparedFiles.length) {
                if (value === undefined) {
                    setFiles((prevFiles) => (multiple ? [...prevFiles, ...preparedFiles] : preparedFiles));
                }

                onDropAccepted?.(preparedFiles, event);
            }
        },
        [
            files,
            filterFiles,
            isUploadFromAdditionalButton,
            maxFilesInList,
            multiple,
            noDuplicateNames,
            onDropAccepted,
            onUpload,
            value,
        ]
    );

    const onDropRejectedFiles = useCallback<Required<DropzoneExternalProps>['onDropRejected']>(
        (rejectedFiles, event) => {
            if (!hideErrorToast) {
                rejectedFiles.forEach(({ file, errors }) =>
                    errors.map((error) => {
                        toast.error(
                            <>
                                <Text font='label' fontSize='large' fontWeight='medium' spacing='0 0 8' ellipsis={2}>
                                    {file.name}
                                </Text>

                                {getErrorMessage(error, { maxSize: maxSize || 100000, minSize })}
                            </>
                        );
                    })
                );
            }

            onDropRejected?.(rejectedFiles, event);
        },
        [hideErrorToast, maxSize, minSize, onDropRejected]
    );

    const onRemoveFile = useCallback<Required<ComponentProps<typeof Attachment>>['onRemove']>(
        ({ id, source }) => {
            if (!id) return;

            if (value === undefined) {
                setFiles((prevFiles) => prevFiles.filter((file) => id !== file.id));
            }

            onRemove?.({ id, source });
        },
        [onRemove, value]
    );

    useEffect(() => {
        onChange?.(files);
    }, [files, onChange]);

    const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
        accept,
        maxSize,
        minSize,
        multiple,
        disabled: disabled || readOnly,
        noClick: true,
        noKeyboard: true,
        onDropAccepted: onDropAcceptedFiles,
        onDropRejected: onDropRejectedFiles,
        onFileDialogCancel,
        ...restProps,
    });

    const handleOpen = (isAdditionalButton?: boolean) => (e: MouseEvent<HTMLButtonElement>) => {
        isAdditionalButton && additionalUploadButtonProps?.onClick?.(e);
        setIsUploadFromAdditionalButton(Boolean(isAdditionalButton));
        open();
    };

    return (
        <Root
            {...getRootProps()}
            {...restProps}
            $size={size}
            $hide={hideZone || hasFiles || hasNewFiles}
            $drag={isDragOnPageActive}
            $height={height}
            $width={width}
        >
            <input {...getInputProps()} />

            {children}

            {(hasFiles || hasNewFiles) && (
                <List spacing='4 0' removeLastSpacing>
                    {files.map((file) => (
                        <List.Item key={file.id}>
                            <Attachment onRemove={onRemoveFile} onShow={onShow} progress={progress} {...file} />
                        </List.Item>
                    ))}

                    {newFiles.map((file) => (
                        <List.Item key={file.id}>
                            <Attachment onShow={onShow} progress {...file} />
                        </List.Item>
                    ))}
                </List>
            )}

            {hasMultipleFiles && !hideButton && <Spacing height={12} />}

            {isShowActionsUnderFiles && (
                <Flex spacing='8'>
                    <Button
                        onClick={handleOpen()}
                        color={invalid ? 'red40' : 'blue80'}
                        size={size === 'medium' ? 'small' : 'xsmall'}
                        startIcon={<AttachmentIcon />}
                        disabled={disabled}
                    >
                        {messages.selectFileButton(fileWordMessageValue)}
                    </Button>

                    {isShowAdditionalUploadButton && (
                        <Button
                            {...additionalUploadButtonProps}
                            onClick={handleOpen(true)}
                            size={size === 'medium' ? 'small' : 'xsmall'}
                            disabled={disabled}
                        />
                    )}
                </Flex>
            )}

            {!hideZone && !readOnly && (
                <Zone
                    $bordered={bordered}
                    $hide={hasFiles || hasNewFiles}
                    $active={isDragActive}
                    $drag={isDragOnPageActive && !disabled}
                >
                    {dragFileIcon || (
                        <DragFileIcon
                            size={size === 'medium' ? 32 : 24}
                            color={isDragActive || disabled ? 'midnight70' : 'midnight80'}
                        />
                    )}

                    <Box spacing={size === 'medium' ? '16 0' : '8 0'}>
                        <Text
                            font='label'
                            textAlign='center'
                            fontSize={size}
                            color={isDragActive || disabled ? 'midnight70' : 'midnight100'}
                            fontWeight='medium'
                        >
                            {isDragActive
                                ? messages.hint(fileWordMessageValue)
                                : dragFileMessage || messages.dragFileMessage}
                        </Text>

                        {(acceptedExtensions || maxSize) && (
                            <Text font='body' fontSize='small' textAlign='center' color='midnight70' spacing='8 0 0'>
                                {`${!hideAcceptedExtensions ? acceptedExtensions.toUpperCase() : ''} ${maxSize ? messages.maxSizeText({ maxSize: fileHelpers.formatBytes(maxSize, 0) }) : ''}`}
                            </Text>
                        )}
                    </Box>

                    <Flex spacing='8'>
                        <Button
                            onClick={handleOpen()}
                            size={size === 'medium' ? 'small' : 'xsmall'}
                            color='midnight80'
                            startIcon={<AttachmentIcon />}
                            disabled={isDragOnPageActive || disabled}
                        >
                            {messages.selectFileButton(fileWordMessageValue)}
                        </Button>

                        {isShowAdditionalUploadButton && (
                            <Button
                                {...additionalUploadButtonProps}
                                onClick={handleOpen(true)}
                                size={size === 'medium' ? 'small' : 'xsmall'}
                                disabled={isDragOnPageActive || disabled}
                            />
                        )}
                    </Flex>
                </Zone>
            )}
        </Root>
    );
});

export default Dropzone;
