import { useDismiss, useFloating, useInteractions, useRole } from '@floating-ui/react';
import { useMemo, useState } from 'react';
import { useUpdateEffect } from 'react-use';

import { PopupProps } from './Popup.types';

export const useToggle = (props: Pick<PopupProps, 'open' | 'onToggle' | 'onClose'>) => {
    const { open: controlledOpen, onToggle, onClose } = props;

    const [uncontrolledOpen, setUncontrolledOpen] = useState(false);

    useUpdateEffect(() => {
        if (controlledOpen !== undefined && controlledOpen) {
            onToggle?.(true);
        }
    }, [controlledOpen, onToggle]);

    return useMemo(
        () => ({
            isOpen: controlledOpen ?? uncontrolledOpen,
            setToggle: (status: boolean) => {
                setUncontrolledOpen(Boolean(status));
                onToggle?.(Boolean(status));

                if (!status) onClose?.();
            },
        }),
        [controlledOpen, onClose, onToggle, uncontrolledOpen]
    );
};

export const useFloatingPopup = (props: ReturnType<typeof useToggle> & Pick<PopupProps, 'preventAutoClose'>) => {
    const { isOpen, setToggle, preventAutoClose } = props;

    const data = useFloating({
        open: isOpen,
        onOpenChange: setToggle,
    });

    const context = data.context;

    const dismiss = useDismiss(context, {
        outsidePress: (event) => {
            if (preventAutoClose) return false;

            if (event.target instanceof HTMLElement || event.target instanceof SVGElement) {
                return !event.target?.closest('.Toastify');
            }

            return true;
        },
        escapeKey: !preventAutoClose,
    });
    const role = useRole(context);

    const interactions = useInteractions([dismiss, role]);

    return useMemo(
        () => ({
            ...interactions,
            ...data,
        }),
        [interactions, data]
    );
};
