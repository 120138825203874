import { mods } from '@approvalmax/theme';
import { StyledMods } from '@styled-kit/mods';
import styled from 'styled-components';

import type { StepperContextParams } from '../../Stepper.types';
import type { StepContextParams } from './Step.types';

export const Root = styled.div<StyledMods<Pick<Required<StepperContextParams>, 'direction'>>>`
    display: grid;
    gap: 8px;

    ${mods('direction', 'vertical')`
        grid-template-columns: max-content auto;
    `}

    ${mods('direction', 'horizontal')`
        grid-template-rows: max-content auto;
    `}
`;

export const IconWrapper = styled.div<
    StyledMods<
        Pick<Required<StepperContextParams>, 'direction'> &
            Pick<Required<StepContextParams>, 'last'> & { middle: boolean }
    >
>`
    display: grid;
    gap: 8px;

    ${mods('direction', 'vertical')`
        grid-template-columns: max-content;
        grid-template-rows: max-content 1fr;
    `}

    ${mods('direction', 'horizontal')`
        grid-template-columns: max-content 1fr;
    `}

    ${mods('middle', true)`
        justify-content: center;

        ${mods('direction', 'horizontal')`
            grid-template-columns: 1fr max-content 1fr ;
        `}
    `}

    ${mods('last', true)`
        justify-content: end;

        ${mods('direction', 'horizontal')`
            grid-template-columns: 1fr max-content;
        `}
    `}
`;

export const StepContentWrapper = styled.div`
    display: grid;
    gap: 4px;
    grid-auto-rows: max-content;
`;
