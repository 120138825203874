import { Flex } from '@approvalmax/ui/src/components';
import range from 'lodash/range';
import { memo } from 'react';
import Skeleton from 'react-loading-skeleton';

export const Loading = memo(() => {
    return (
        <Flex direction='column' spacing='12'>
            {range(5).map((_, i) => (
                <Skeleton key={i} height={28} />
            ))}
        </Flex>
    );
});

Loading.displayName = 'Loading';
