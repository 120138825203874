import { mixins, mods, RequiredMods, theme } from '@approvalmax/theme';
import { animated } from '@react-spring/web';
import { StyledMods } from '@styled-kit/mods';
import styled, { css } from 'styled-components';

import { AlertProps } from './Alert.types';

export const AnimatedBox = styled(animated.div as any)`
    overflow: hidden;
`;

export const Content = styled.div`
    flex-grow: 1;
`;

export const Icon = styled.div`
    margin-right: 8px;
`;

const rootSizeMixin = (
    fontSize: RequiredMods['fontSize'],
    paddingV: RequiredMods['spacing'],
    paddingH: RequiredMods['spacing'],
    iconSize: RequiredMods['iconSize']
) => css`
    padding: ${paddingV}px ${paddingH}px;
    ${mixins.font('body', fontSize)}

    ${mods.color.transparent`
        padding: 0;
    `};

    ${Icon} svg {
        ${mixins.iconSize(iconSize)};
    }
`;

export const Root = styled.div<StyledMods<Pick<AlertProps, 'color' | 'size' | 'invalid' | 'alignItems'>>>`
    border-radius: ${theme.radius.small};
    display: flex;
    align-items: ${({ $alignItems }) => $alignItems};
    color: ${theme.color.midnight80};

    ${mods.color.midnight20`
        background-color: ${theme.color.midnight20};
        box-shadow: inset 0 0 0 1px ${theme.color.midnight50};
    `};

    ${mods.color.red80`
        color: ${theme.color.white100};
        background-color: ${theme.color.red80};
        box-shadow: inset 0 0 0 1px ${theme.color.red80};
    `};

    ${mods.size.small(rootSizeMixin('small', 12, 16, 16))};
    ${mods.size.medium(rootSizeMixin('medium', 16, 20, 20))};

    ${mods.invalid.true`
        background-color: ${theme.color.red20};
        box-shadow: inset 0 0 0 1px ${theme.color.red40};

        ${mods.color.transparent`
            color: ${theme.color.red100};
            background-color: ${theme.color.transparent};
            box-shadow: none;
        `};
    `};
`;
