import { enabledByParams, RequestParams, RequestQueryOptions } from '@approvalmax/data';
import { useGet } from 'shared/data';

import { authApiPaths } from '../paths';
import { GetMicrosoftOAuthUrl } from './useGetMicrosoftOAuthUrl.types';

export const useGetMicrosoftOAuthUrl = (
    params?: RequestParams<GetMicrosoftOAuthUrl>,
    queryOptions?: RequestQueryOptions<GetMicrosoftOAuthUrl>
) => {
    return useGet(authApiPaths.microsoftOAuthUrl, {
        params,
        queryOptions: {
            ...queryOptions,
            enabled: enabledByParams<GetMicrosoftOAuthUrl['params']['query']>(
                {
                    redirectUrl: params?.query?.redirectUrl,
                    ssoStartingPoint: params?.query?.ssoStartingPoint,
                    uiState: params?.query?.uiState,
                },
                queryOptions
            ),
        },
        apiVersion: 'v1',
        mapToCamelCase: true,
    });
};
