import { intl, mathService, numberHelpers } from '@approvalmax/utils';
import moment from 'moment';

import { isFieldVisible } from '../../NetSuiteRequestCard.utils';
import { messages } from './NetSuiteJournalEntryLines.messages';
import { LinesColumnDefinition, LinesFieldsSettings } from './NetSuiteJournalEntryLines.types';

export const getColumnDefs = (
    fieldSettings: LinesFieldsSettings,
    exchangeRate?: number | null
): LinesColumnDefinition[] => {
    const columnDef: LinesColumnDefinition[] = [
        {
            id: 'account',
            name: messages.accountColumnName,
            value: ({ account }) => (account ? account.text : null),
            isHidden: !isFieldVisible(fieldSettings.account),
        },
        {
            id: 'debitAmount',
            name: messages.debitAmountColumnName,
            value: ({ debitAmount }) => {
                let amount = debitAmount || 0;

                if (numberHelpers.isNumber(exchangeRate)) {
                    amount = mathService.multiply(amount, exchangeRate);
                    amount = mathService.round(amount, 2);
                }

                return intl.formatNumber(amount || 0, 'auto');
            },
            alignRight: true,
            isHidden: !isFieldVisible(fieldSettings.debitAmount),
        },
        {
            id: 'creditAmount',
            name: messages.creditAmountColumnName,
            value: ({ creditAmount }) => {
                let amount = creditAmount || 0;

                if (numberHelpers.isNumber(exchangeRate)) {
                    amount = mathService.multiply(amount, exchangeRate);
                    amount = mathService.round(amount, 2);
                }

                return intl.formatNumber(amount || 0, 'auto');
            },
            alignRight: true,
            isHidden: !isFieldVisible(fieldSettings.creditAmount),
        },
        {
            id: 'taxCode',
            name: messages.taxCodeColumnName,
            value: ({ taxCode }) => (taxCode ? taxCode.text : null),
            minWidth: 105,
            isHidden: !isFieldVisible(fieldSettings.taxCode),
        },
        {
            id: 'taxRate',
            name: messages.taxRateColumnName,
            value: ({ taxRate, taxCode }) => {
                if (!taxCode) {
                    return null;
                }

                return numberHelpers.isNumber(taxRate) ? `${intl.formatNumber(taxRate || 0, 'auto')}%` : taxRate;
            },
            alignRight: true,
            isHidden: !isFieldVisible(fieldSettings.taxRate),
        },
        {
            id: 'taxAmount',
            name: messages.taxAmountColumnName,
            value: ({ taxAmount }) => {
                let amount = taxAmount || 0;

                if (numberHelpers.isNumber(exchangeRate)) {
                    amount = mathService.multiply(amount, exchangeRate);
                    amount = mathService.round(amount, 2);
                }

                return amount > 0 ? intl.formatNumber(amount, 'auto') : null;
            },
            alignRight: true,
            isHidden: !isFieldVisible(fieldSettings.taxAmount),
        },
        {
            id: 'grossAmount',
            name: messages.grossAmountColumnName,
            value: ({ grossAmount }) => {
                let amount = grossAmount || 0;

                if (numberHelpers.isNumber(exchangeRate)) {
                    amount = mathService.multiply(amount, exchangeRate);
                    amount = mathService.round(amount, 2);
                }

                return amount > 0 ? intl.formatNumber(amount, 'auto') : null;
            },
            alignRight: true,
            isHidden: !isFieldVisible(fieldSettings.grossAmount),
        },
        {
            id: 'memo',
            name: messages.memoColumnName,
            value: ({ memo }) => memo,
            isHidden: !isFieldVisible(fieldSettings.memo),
        },
        {
            id: 'department',
            name: messages.departmentColumnName,
            value: ({ department }) => (department ? department.text : null),
            isHidden: !isFieldVisible(fieldSettings.department),
        },
        {
            id: 'class',
            name: messages.classColumnName,
            value: ({ class: lineClass }) => (lineClass ? lineClass.text : null),
            isHidden: !isFieldVisible(fieldSettings.class),
        },
        {
            id: 'location',
            name: messages.locationColumnName,
            value: ({ location }) => (location ? location.text : null),
            isHidden: !isFieldVisible(fieldSettings.location),
        },
        {
            id: 'customer',
            name: messages.customerColumnName,
            value: ({ customer }) => (customer ? customer.text : null),
            isHidden: !isFieldVisible(fieldSettings.customer),
        },
        {
            id: 'amortizationSchedule',
            name: messages.amortizationScheduleColumnName,
            value: ({ amortizationSchedule }) => (amortizationSchedule ? amortizationSchedule.text : null),
            isHidden: !isFieldVisible(fieldSettings.amortizationSchedule),
        },
        {
            id: 'amortizationStartDate',
            name: messages.amortizationStartDateColumnName,
            value: ({ amortizationStartDate }) =>
                amortizationStartDate ? moment.utc(amortizationStartDate).format('ll') : null,
            isHidden: !isFieldVisible(fieldSettings.amortizationStartDate),
        },
        {
            id: 'amortizationEndDate',
            name: messages.amortizationEndDateColumnName,
            value: ({ amortizationEndDate }) =>
                amortizationEndDate ? moment.utc(amortizationEndDate).format('ll') : null,
            isHidden: !isFieldVisible(fieldSettings.amortizationEndDate),
        },
        {
            id: 'amortizationResidual',
            name: messages.amortizationResidualColumnName,
            value: ({ amortizationResidual }) => {
                if (numberHelpers.isNumber(amortizationResidual) && amortizationResidual !== 0) {
                    return intl.formatNumber(amortizationResidual, 'auto');
                }

                return null;
            },
            alignRight: true,
            isHidden: !isFieldVisible(fieldSettings.amortizationResidual),
        },
    ];

    return columnDef.filter((col) => !col.isHidden);
};
