import { memo } from 'react';
import { useController } from 'react-hook-form';

import { WeekDayPicker } from '../../WeekDayPicker';
import { ControllerProps } from './Controller.types';

/**
 * The `WeekDayPicker.Controller` allows you to use the `WeekDayPicker` with the `react-hook-form` library.
 * They take the same properties as the `WeekDayPicker` component,
 * plus the `control` property to bind it with the library and `rules` for validation.
 *
 * You can use control without the `Controller` subcomponent if it is in a `Form` or `Form.Part` component.
 */
export const Controller = memo<ControllerProps>((props) => {
    const { control, name, onChange, ...restProps } = props;

    const { field, fieldState } = useController({
        control,
        name,
        rules: {
            required: restProps.required,
        },
        defaultValue: props.defaultValue || props.value,
    });

    return <WeekDayPicker {...field} {...fieldState} {...restProps} onChange={field.onChange} />;
});

Controller.displayName = 'Controller';
