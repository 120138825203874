import { TypeOfBusiness } from 'modules/data/domain';
import { getTypeOfBusiness } from 'shared/helpers';

export const getBusinessType = () => {
    const storageValue = getTypeOfBusiness();

    switch (storageValue) {
        case TypeOfBusiness.Business:
            return 'business';

        case TypeOfBusiness.Partner:
            return 'partner';

        default:
            return undefined;
    }
};
