import { Mods, mods, theme } from '@approvalmax/theme';
import { StyledMods } from '@styled-kit/mods';
import styled, { css } from 'styled-components';

import Box from '../Box/Box';
import { AvatarDropzoneProps } from './AvatarDropzone.types';

export const Root = styled.div<StyledMods<Pick<AvatarDropzoneProps, 'width'> & Mods<'drag'>>>`
    position: relative;

    ${mods('width')(
        (value) => css`
            width: ${typeof value === 'number' ? `${value}px` : value};
        `
    )};
`;

export const Zone = styled(Box)<StyledMods<Mods<'active' | 'drag' | 'progress' | 'disabled'>>>`
    width: 100%;
    aspect-ratio: 1;
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    background-color: ${theme.color.blue10};
    border: 1px solid ${theme.color.blue30};
    transition: all ${theme.duration.slow}ms;

    img {
        min-width: 100%;
        min-height: 100%;
    }

    &:hover {
        ${mods.progress.false`
            cursor: pointer;
            border-color: ${theme.color.blue70};
        `}
    }

    ${mods.drag.true`
        border-color: ${theme.color.blue70};
        border-style: dashed;
    `};

    ${mods.active.true`
        border-color: ${theme.color.blue70};
        border-style: solid;
    `};

    ${mods.disabled.true`
        &,
        &:hover {
            cursor: default;
            background-color: ${theme.color.midnight20};
            border-color: ${theme.color.midnight50};
            user-select: none;
        }
    `}
`;
