export const isValidUrl = (url: string): boolean => {
    if (url.trim() !== '') {
        const urlRegex =
            '^https://(?:(?:[A-Z0-9](?:[A-Z0-9-]{0,61}[A-Z0-9])?\\.)+(?:[A-Z]{2,6}\\.?|[A-Z0-9-]{2,}\\.?)|\\d{1,3}\\.\\d{1,3}\\.\\d{1,3}\\.\\d{1,3})(?::\\d+)?(?:/?|[/?]\\S+)$';

        return url.length < 2083 && new RegExp(urlRegex, 'i').test(url);
    }

    return false;
};
