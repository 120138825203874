import { DropdownEditor } from '@approvalmax/ui';
import { intl } from '@approvalmax/utils';
import { ui } from 'modules/common';
import { domain } from 'modules/data';
import { font } from 'modules/styles';
import { FC, memo, useMemo } from 'react';
import bemFactory from 'react-bem-factory';
import { defineMessages } from 'react-intl';
import styled from 'styled-components';

import { useProfileContext } from '../../ProfileContext';
import Section from '../Section/Section';
import Toggle from '../Toggle/Toggle';

const i18nPrefix = 'profile/containers/ProfilePopup/components/NotificationSection/NotificationSection';
const messages = defineMessages({
    emailNotificationsHeader: {
        id: `${i18nPrefix}.emailNotificationsHeader`,
        defaultMessage: 'Email Notifications',
    },
    newRequestsNotification: {
        id: `${i18nPrefix}.newRequestsNotification`,
        defaultMessage: 'New requests, comments',
    },
    newRequestNotification: {
        id: `${i18nPrefix}.newRequestNotification`,
        defaultMessage: 'New requests notifications',
    },
    commentNotification: {
        id: `${i18nPrefix}.commentNotification`,
        defaultMessage: 'Comments notifications',
    },
    workflowNotification: {
        id: `${i18nPrefix}.workflowNotification`,
        defaultMessage: 'Workflow changes',
    },
    otherNotification: {
        id: `${i18nPrefix}.otherNotification`,
        defaultMessage: 'Other notifications',
    },
    newRequestsNotificationNote: {
        id: `${i18nPrefix}.newRequestsNotificationNote`,
        defaultMessage: 'Send instantly',
    },
    summaryNotification: {
        id: `${i18nPrefix}.summaryNotification`,
        defaultMessage: 'Summary of open requests',
    },
    sendSummaryReportEveryText: {
        id: `${i18nPrefix}.sendSummaryReportEveryText`,
        defaultMessage: 'Send report every',
    },
    sendSummaryReportAtText: {
        id: `${i18nPrefix}.sendSummaryReportAtText`,
        defaultMessage: 'at',
    },
});

const Row = styled.div<{ noBorder?: boolean }>`
    display: flex;
    align-items: center;
    height: 50px;
    border-bottom: 1px solid #ebeaea;

    ${(props) => (props.noBorder ? 'border: none;' : '')}
`;

const Name = styled.div`
    ${font(13, '#000', 'semibold')}
`;

const Note = styled.div`
    flex: 1;
    margin-left: 10px;
    ${font(13, '#7f7d7d')}
`;

const SummaryNotificationSection = styled.div`
    display: flex;
    flex-flow: column;
`;

const SummarySelector = styled.div`
    display: flex;
    align-items: center;
    padding-top: 5px;
    padding-bottom: 15px;
    border-bottom: 1px solid #ebeaea;
`;

const SummarySelectorLabel = styled.div`
    margin-right: 10px;
    ${font(13, '#7f7d7d')}
`;

const SummaryTimeLabel = styled.div`
    margin-right: 10px;
`;

const WeekPickerEditor = styled(ui.WeekPickerEditor)`
    margin-right: 10px;
    margin-top: 2px;
`;

const qa = bemFactory.qa('profile-popup-notification-section');

/**
 * @deprecated Redesigned
 */
const NotificationSection: FC = () => {
    const { profile, editProfile } = useProfileContext();

    const changeApprovalNotifications = () => {
        editProfile('sendApprovalChangeNotifications', !profile.sendApprovalChangeNotifications);
    };

    const changeNewRequestsNotifications = () => {
        editProfile('sendNewRequestsNotifications', !profile.sendNewRequestsNotifications);
    };

    const changeCommentsNotifications = () => {
        editProfile('sendCommentsNotifications', !profile.sendCommentsNotifications);
    };

    const changeWorkflowNotifications = () => {
        editProfile('sendWorkflowNotifications', !profile.sendWorkflowNotifications);
    };

    const changeSummaryNotifications = () => {
        editProfile('sendSummaryNotifications', !profile.sendSummaryNotifications);
    };

    const changeSummaryNotificationsOnDays = (newValue: domain.DayOfWeek[]) => {
        editProfile('summaryNotificationsSendOnDays', newValue);
    };

    const changeSummaryNotificationsAtTime = (newValue: ui.TimeReference | null) => {
        if (!newValue) {
            return;
        }

        editProfile('summaryNotificationsSendAtTime', newValue.id);
    };

    const selectedTimeRefference = useMemo(
        () => ui.allTimeReferences.find((x) => x.id === profile.summaryNotificationsSendAtTime) || null,
        [profile.summaryNotificationsSendAtTime]
    );

    return (
        <Section header={intl.formatMessage(messages.emailNotificationsHeader)}>
            <Row>
                <Name>{intl.formatMessage(messages.newRequestNotification)}</Name>

                <Note>{intl.formatMessage(messages.newRequestsNotificationNote)}</Note>

                <Toggle
                    dataQa={qa('new-requests-notification-checkbox-requests')}
                    dataQaId={profile.sendNewRequestsNotifications}
                    onChange={changeNewRequestsNotifications}
                    on={profile.sendNewRequestsNotifications}
                />
            </Row>

            <Row>
                <Name>{intl.formatMessage(messages.commentNotification)}</Name>

                <Note>{intl.formatMessage(messages.newRequestsNotificationNote)}</Note>

                <Toggle
                    dataQa={qa('comments-notification-checkbox-requests')}
                    dataQaId={profile.sendCommentsNotifications}
                    onChange={changeCommentsNotifications}
                    on={profile.sendCommentsNotifications}
                />
            </Row>

            <Row>
                <Name>{intl.formatMessage(messages.otherNotification)}</Name>

                <Note>{intl.formatMessage(messages.newRequestsNotificationNote)}</Note>

                <Toggle
                    dataQa={qa('other-notification-checkbox-requests')}
                    dataQaId={profile.sendApprovalChangeNotifications}
                    onChange={changeApprovalNotifications}
                    on={profile.sendApprovalChangeNotifications}
                />
            </Row>

            <Row>
                <Name>{intl.formatMessage(messages.workflowNotification)}</Name>

                <Note>{intl.formatMessage(messages.newRequestsNotificationNote)}</Note>

                <Toggle
                    dataQa={qa('workflow-notification-checkbox-requests')}
                    dataQaId={profile.sendWorkflowNotifications}
                    onChange={changeWorkflowNotifications}
                    on={profile.sendWorkflowNotifications}
                />
            </Row>

            <SummaryNotificationSection>
                <Row noBorder>
                    <Name>{intl.formatMessage(messages.summaryNotification)}</Name>

                    <Note />

                    <Toggle
                        dataQa={qa('notification-checkbox-summary')}
                        dataQaId={profile.sendSummaryNotifications}
                        onChange={changeSummaryNotifications}
                        on={profile.sendSummaryNotifications}
                    />
                </Row>

                <SummarySelector>
                    <SummarySelectorLabel>
                        {intl.formatMessage(messages.sendSummaryReportEveryText)}
                    </SummarySelectorLabel>

                    <WeekPickerEditor
                        disabled={!profile.sendSummaryNotifications}
                        value={profile.summaryNotificationsSendOnDays}
                        onChange={changeSummaryNotificationsOnDays}
                    />

                    <SummaryTimeLabel>{intl.formatMessage(messages.sendSummaryReportAtText)}</SummaryTimeLabel>

                    <ui.TimePickerEditor
                        disabled={!profile.sendSummaryNotifications}
                        value={selectedTimeRefference}
                        onChange={changeSummaryNotificationsAtTime}
                        buttonComponent={DropdownEditor.NoFilterButton}
                    />
                </SummarySelector>
            </SummaryNotificationSection>
        </Section>
    );
};

export default memo(NotificationSection);
