import { domHelpers, hooks } from '@approvalmax/utils';
import { memo, useCallback } from 'react';
import Skeleton from 'react-loading-skeleton';

import { CopyIcon, DownloadIcon } from '../../icons';
import Button from '../Button/Button';
import { Flex } from '../Flex/Flex';
import { Grid } from '../Grid/Grid';
import { toast } from '../Toast/Toast.helpers';
import { useBackupCodesPrepare } from './BackupCodes.hooks';
import { messages } from './BackupCodes.messages';
import { BackupCodesProps } from './BackupCodes.types';

export const BackupCodes = memo<BackupCodesProps>((props) => {
    const { isLoading, backupCodes = [] } = props;

    const copyToClipboard = hooks.useCopyToClipboard();

    const codes = useBackupCodesPrepare(backupCodes);

    const handleCopy = useCallback(() => {
        const content = codes.map((backupCode) => backupCode.code).join('\n');

        copyToClipboard(content, 'backupCodes', () => {
            toast.info(messages.copiedToast);
        });
    }, [codes, copyToClipboard]);

    const handleDownload = useCallback(() => {
        const content = codes.map((backupCode) => backupCode.code).join('\n');

        domHelpers.downloadBlob([content], {
            fileName: messages.fileName,
            type: 'text/plain',
            extension: 'txt',
        });
    }, [codes]);

    return (
        <Flex direction='column' spacing='24'>
            <Grid gridTemplateColumns='1fr 1fr 1fr 1fr' gap={16}>
                {isLoading &&
                    Array(10)
                        .fill(null)
                        .map((_code, index) => <Skeleton key={index} borderRadius={5} height='100%' />)}

                {!isLoading &&
                    codes.map((backupCode) => (
                        <Button
                            color='blue80'
                            size='medium'
                            outline
                            key={backupCode.code}
                            disabled={backupCode.isUsed}
                            style={{ pointerEvents: 'none' }}
                        >
                            {backupCode.code.replace(/(\d{4})(\d{4})/, '$1 $2')}
                        </Button>
                    ))}
            </Grid>

            <Flex size={24} wrap={false}>
                <Button
                    block
                    color='blue10'
                    size='large'
                    disabled={isLoading}
                    onClick={handleDownload}
                    noPadding={false}
                    startIcon={<DownloadIcon size='20' />}
                >
                    {messages.downloadButton}
                </Button>

                <Flex grow={0} shrink={0}>
                    <Button
                        color='blue10'
                        size='large'
                        disabled={isLoading}
                        onClick={handleCopy}
                        noPadding={false}
                        startIcon={<CopyIcon size='20' />}
                    >
                        {messages.copyButton}
                    </Button>
                </Flex>
            </Flex>
        </Flex>
    );
});

BackupCodes.displayName = 'BackupCodes';
