import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('app.WorkflowIdPage.GroupOfValuesPopup.VersionHistoryPopupContent', {
    popupTitle: '"{groupName}" version history',
    version: 'Version',
    by: 'by',
    accountsLength: '{length} account(s)',
    search: 'Search...',
    name: 'Name',
    noValuesFound: 'No values found matching this name.',
    noValues: 'No values',
});
