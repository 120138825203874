import { SourceFileRecord } from '@approvalmax/ui/src/components';

import { UploadType } from '../UploadPlaceholder/UploadPlaceholder.types';

export interface OcrUploadPlaceholderProps {
    onDrop: (files: SourceFileRecord[], uploadType: UploadType) => void;
    filterFiles: (files: File[]) => File[];
    isLoadingNewAttachments: boolean;
    isLoadingNewOcrAttachments: boolean;
    files: SourceFileRecord[];
    setFiles: (files: SourceFileRecord[]) => void;
}

export enum UploadOptions {
    UploadWithOcr = 'UploadWithOcr',
    UploadWithoutOcr = 'UploadWithoutOcr',
}
