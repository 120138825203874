import { ExtractComponentProp, Reference } from '@approvalmax/types';
import { Form, Select } from '@approvalmax/ui/src/components';
import { selectors } from 'modules/common';
import { domain } from 'modules/data';
import { useSelector } from 'modules/react-redux';
import { FC, memo, useCallback, useMemo, useState } from 'react';
import { useDebounce, useMount } from 'react-use';
import { useFieldData } from 'shared/hooks/useFieldData';

import { messages } from './SelectAccount.messages';
import { SelectAccountProps } from './SelectAccount.types';

export const SelectAccount: FC<SelectAccountProps> = memo((props) => {
    const { companyId, integrationCode, groupOfAccounts, isLoading, onChange, name } = props;

    const [queryValue, setQueryValue] = useState('');
    const [query, setQuery] = useState('');
    const field = useSelector((state) =>
        selectors.field.getFieldsBySystemPurpose(state, companyId, domain.FieldSystemPurpose.XeroAccount)
    )[0];
    const [selectedItems, setSelectedItems] = useState<Reference[]>(
        groupOfAccounts.latestVersion?.values.map((value) => ({
            id: value.fieldValueId || '',
            text: value.value || '',
        })) || []
    );

    useDebounce(() => setQuery(queryValue), 600, [queryValue]);

    const params = useMemo(() => ({ field, integrationCode, query: query.trim() }), [field, integrationCode, query]);

    const { trigger, items, isFetching } = useFieldData(params);

    useMount(trigger);

    const handleChange = useCallback<ExtractComponentProp<typeof Select<Reference, true>, 'onChange'>>(
        (values, items) => {
            const newSelectedItems = items.filter((item) => values.includes(item.id));

            setSelectedItems(newSelectedItems);
            onChange(newSelectedItems);
        },
        [onChange]
    );

    const allItems = useMemo(() => [...selectedItems, ...items], [items, selectedItems]);

    return (
        <Form.Part>
            <Select
                items={allItems}
                placeholder={messages.selectValue}
                onInputChange={setQueryValue}
                name={name}
                textActivatorColor='blue90'
                itemNameKey='text'
                size='large'
                autocomplete
                multiple
                progress={isFetching}
                disabled={isLoading}
                preventSearch
                onChange={handleChange}
            />
        </Form.Part>
    );
});

SelectAccount.displayName = 'SelectAccount';
