import { Box, Button, Checkbox, Divider, Flex, Form, Text } from '@approvalmax/ui/src/components';
import { selectors } from 'modules/common';
import { useSelector } from 'modules/react-redux';
import { FC, memo, useCallback, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { FormValues } from '../BulkCreationPopupContent.types';
import { CompanyEditor } from '../CompanyEditor/CompanyEditor';
import { messages } from './AdditionalOptions.messages';
import { AdditionalOptionsProps } from './AdditionalOptions.types';

export const AdditionalOptions: FC<AdditionalOptionsProps> = memo((props) => {
    const { editCompany, setEditCompany, disabled } = props;

    const form = useFormContext<FormValues>();

    const selectedCompanyId = form.watch('selectedCompanyId');
    const selectedTemplateIntegrationCode = form.watch('selectedTemplateIntegrationCode');

    const [prevValues, setPrevValues] = useState<
        Pick<FormValues, 'selectedCompanyId' | 'selectedTemplateIntegrationCode'>
    >({ selectedCompanyId, selectedTemplateIntegrationCode });

    const activeCompany = useSelector((state) => selectors.company.getCompanyById(state, selectedCompanyId));
    const activeCompanyTemplates = useSelector((state) =>
        selectors.template.getTemplatesByCompanyId(state, selectedCompanyId)
    );

    const integrationTitle = activeCompanyTemplates.find(
        (template) => template.integrationCode === selectedTemplateIntegrationCode
    )?.displayName;

    const onEdit = useCallback(() => {
        setEditCompany(true);
        setPrevValues({ selectedCompanyId, selectedTemplateIntegrationCode });
    }, [selectedCompanyId, selectedTemplateIntegrationCode, setEditCompany]);

    return (
        <Form.Part>
            <Flex size={10} height='fit-content'>
                <Text font='headline' fontSize='xsmall' fontWeight='medium'>
                    {messages.additionalOptionsTitle}
                </Text>

                <Box color='midnight20' spacing='24' radius='xsmall'>
                    <Flex direction='column'>
                        {editCompany ? (
                            <CompanyEditor setEditCompany={setEditCompany} prevValues={prevValues} />
                        ) : (
                            <Flex spacing='8' width='100%' justifyContent='space-between'>
                                <Flex direction='column' spacing='8'>
                                    <Text font='body' fontSize='medium' fontWeight='medium'>
                                        {integrationTitle}
                                    </Text>

                                    <Text font='body'>{`"${activeCompany?.displayName}"`}</Text>
                                </Flex>

                                <Button disabled={disabled} onClick={onEdit} color='blue80' outline>
                                    {messages.editButtonTitle}
                                </Button>
                            </Flex>
                        )}

                        <Divider />

                        <Flex spacing='8'>
                            <Checkbox name='useLinesMerge'>{messages.useLinesMergeCheckboxTitle}</Checkbox>

                            <Text font='label' fontSize='small'>
                                {messages.useLinesMergeCheckboxDescription}
                            </Text>
                        </Flex>
                    </Flex>
                </Box>
            </Flex>
        </Form.Part>
    );
});

AdditionalOptions.displayName = 'AdditionalOptions';
