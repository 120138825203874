import { combineCommandsAnd } from '@approvalmax/types';
import { ActionMenu, Button, EditFilledIcon } from '@approvalmax/ui';
import { fullscreenSplitViewState, openSplitViewState } from '@approvalmax/ui/src/components';
import { actions, selectors } from 'modules/common';
import { domain, State } from 'modules/data';
import { EyeAddIcon, EyeShowOffIcon, OverflowMiniIcon, TbReloadGreenIcon, TbVersionControlIcon } from 'modules/sprites';
import {
    completeReview,
    makeDecision,
    reloadRequest,
    returnToReview,
    showEditWatchersPopup,
    showForceDecisionPopup,
    showRejectRequestPopup,
    submitRequest,
} from 'pages/requestList/actions';
import ForceDecisionButton from 'pages/requestList/components/ForceDecisionButton';
import { starterGuideActionsClass } from 'pages/requestList/constants';
import { useRequestWatchers, useSelectedFilter } from 'pages/requestList/hooks';
import { memo, useCallback, useMemo } from 'react';
import bemFactory from 'react-bem-factory';
import { useDispatch, useSelector } from 'react-redux';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { amplitudeService } from 'services/amplitude';
import { routingService } from 'services/routing';
import { SubscribeAfterTrialButton } from 'shared/components';
import { SubscribeAfterTrialButtonType } from 'shared/components/SubscribeAfterTrialButton/SubscribeAfterTrialButton.types';
import { useRetryRequest } from 'shared/data/queries/requests/useRetryRequest/useRetryRequest';
import { getCanShowSubscribeAfterTrialButton } from 'shared/helpers/experiments';
import { activeAttachmentIdState, ContentSplitViewMode, contentSplitViewState } from 'shared/states';
import { getPath, Path } from 'urlBuilder';

import { CreatePaymentFromBill } from '../CreatePaymentFromBill/CreatePaymentFromBill';
import { OnHoldStatusActions } from '../OnHoldStatusActions/OnHoldStatusActions';
import PayWithAirwallex from '../PayWithAirwallex/PayWithAirwallex';
import { PayWithAmax } from '../PayWithAmax/PayWithAmax';
import POStatus from '../POStatus/POStatus';
import ResolutionBadge from '../ResolutionBadge/ResolutionBadge';
import { integrationCodesWithRequestVersions } from './PrimaryActions.constants';
import { messages } from './PrimaryActions.messages';
import {
    ActionButton,
    ActionButtonText,
    BodyRightPrimaryRow,
    ChangePOStatusButtonContainer,
    OpenAuditReportButton,
    OpenAuditReportButtonIcon,
    OpenAuditReportButtonText,
    PrimaryButtons,
    VersionHistoryButton,
} from './PrimaryActions.styles';
import { PrimaryActionsProps } from './PrimaryActions.types';

const HIDE_FORCE_DECISION_TIP_USER_PREFERENCE =
    'requestList/components/card/Toolbar/HIDE_FORCE_DECISION_TIP_USER_PREFERENCE';

const qa = bemFactory.qa('reql-toolbar-body');

export const PrimaryActions = memo<PrimaryActionsProps>((props) => {
    const { isLocked, hasMatchingValidationError, hasMatchingInsufficientBudgetError, request, readonly } = props;

    const dispatch = useDispatch();
    const [isOpenSplitView, setOpenSplitView] = useRecoilState(openSplitViewState);
    const setContentSplitView = useSetRecoilState(contentSplitViewState);
    const activeAttachmentId = useRecoilValue(activeAttachmentIdState);
    const setFullscreenState = useSetRecoilState(fullscreenSplitViewState);
    const { mutate: retryRequest } = useRetryRequest();
    const hideForceDecisionTip = useSelector((state: State) =>
        selectors.userPreferences.getUserPreference(state, HIDE_FORCE_DECISION_TIP_USER_PREFERENCE, false)
    );

    const hasRequestDetailsVersioningBeta =
        request.integrationType === domain.IntegrationType.Xero &&
        request.company.betaFeatures.includes(domain.CompanyBetaFeature.RequestDetailsVersioning);
    const hasQBORequestDetailsVersioningBeta =
        request.integrationType === domain.IntegrationType.QBooks &&
        request.company.betaFeatures.includes(domain.CompanyBetaFeature.QBORequestDetailsVersioning);

    const isRequestDetailsVersioningBeta = hasRequestDetailsVersioningBeta || hasQBORequestDetailsVersioningBeta;

    const isRequestDetailsVersioningLicenseFeature = request.company.licenseFeatures.includes(
        domain.CompanyLicenseFeature.RequestDetailsVersioning
    );

    const { isActualVersion } = request.flags;

    const allUsers = useSelector((state: State) => selectors.user.getUsers(state));
    const selectedFilter = useSelectedFilter();
    const hideActionsForAdvancedFeatures = selectors.request.getHideActionsForAdvancedFeatures(
        request.company,
        request.integrationCode
    );

    const isCompanyReadonly = request.company.isReadonly;

    const isNetSuiteIntegration = request.integrationType === domain.IntegrationType.NetSuite;
    const isCancelledStatus = request.statusV2 === domain.RequestStatusV2.Cancelled;
    const isEnforcedExternally = request.resolutionOrigin === domain.RequestResolutionOrigin.EnforcedExternally;
    const isApprovalMaxOrigin = request.origin === domain.RequestOrigin.ApprovalMax;
    const isNetSuiteOrigin = request.origin === domain.RequestOrigin.NetSuite;
    const hasExternalCancellationHistory = useMemo(
        () =>
            request.history.some(
                (historyItem) => historyItem.type === domain.RequestHistoryEventType.CancelledExternally
            ),
        [request.history]
    );

    const isRetryAvailable =
        isNetSuiteIntegration &&
        isCancelledStatus &&
        isEnforcedExternally &&
        (isApprovalMaxOrigin || (isNetSuiteOrigin && hasExternalCancellationHistory));

    const isWatchersAvailable = request.statusV2 !== domain.RequestStatusV2.Draft;

    const isQBOPOStatusEnabled =
        request.integrationCode === domain.IntegrationCode.QBooksPo &&
        request.statusV2 === domain.RequestStatusV2.Approved;

    const onHideTip = () => {
        dispatch(actions.updateUserPreference(HIDE_FORCE_DECISION_TIP_USER_PREFERENCE, true));
    };

    const openAuditReport = () => {
        setOpenSplitView(true);
        setContentSplitView({ mode: ContentSplitViewMode.AuditReport });
    };

    const openVersionHistory = useCallback(() => {
        setOpenSplitView(true);
        setFullscreenState(false);
        setContentSplitView({ mode: ContentSplitViewMode.VersionHistory });
    }, [setContentSplitView, setOpenSplitView, setFullscreenState]);

    const submit = () => {
        dispatch(submitRequest(request.id, request.companyId));
    };

    const retry = () => {
        retryRequest(
            {
                body: {
                    requestId: request.id,
                    requestVersion: request.version,
                    companyId: request.companyId,
                },
            },
            {
                onSuccess: () => {
                    dispatch(reloadRequest(request.id, request.companyId));
                },
            }
        );
    };

    const requestDecisionAmplitudeEvent = (decision: string) => {
        amplitudeService.sendData('requests: decide on request', {
            decision,
        });
    };

    const approve = () => {
        dispatch(
            makeDecision({
                target: request,
                decision: domain.RequestStepParticipantDecision.Approve,
                selectedFilter,
            })
        );

        requestDecisionAmplitudeEvent('approve');
    };

    const completeReviewStep = () => {
        dispatch(completeReview(request, selectedFilter));

        requestDecisionAmplitudeEvent('complete review');
    };

    const returnToReviewStep = () => {
        dispatch(returnToReview(request, selectedFilter));

        requestDecisionAmplitudeEvent('send to review');
    };

    const reject = () => {
        dispatch(showRejectRequestPopup(request.id));

        requestDecisionAmplitudeEvent('reject');
    };

    const onForceDecision = (
        decision: domain.RequestStepParticipantDecision.Approve | domain.RequestStepParticipantDecision.Reject
    ) => {
        dispatch(showForceDecisionPopup(request.id, decision));

        requestDecisionAmplitudeEvent(`force ${decision.toLocaleLowerCase()}`);
    };

    const showEditWatchersPopupHandler = useCallback(() => {
        dispatch(showEditWatchersPopup(request.id));
    }, [dispatch, request.id]);

    const edit = useCallback(() => {
        routingService.push(
            getPath(Path.editRequest, request.id, request.companyId, {
                activeAttachmentId,
            })
        );

        amplitudeService.sendData('requests: make request action', { 'action type': 'edit' });
    }, [activeAttachmentId, request.companyId, request.id]);

    const isPOStatusButtonEnabled =
        (request.flags.isAuthor || request.company.flags.isManager) &&
        request.company.integration?.status === domain.IntegrationStatus.Connected;

    const requestWatchers = useRequestWatchers(request, allUsers);
    const watchersCount = requestWatchers.length;

    const isVersionHistoryAvailable =
        (isRequestDetailsVersioningBeta || isRequestDetailsVersioningLicenseFeature) &&
        integrationCodesWithRequestVersions.includes(request.integrationCode);

    const profile = useSelector(selectors.profile.findProfile);
    const canShowSubscribeAfterTrialButton = getCanShowSubscribeAfterTrialButton(request.company, profile);

    return (
        <BodyRightPrimaryRow data-qa={qa('primary-buttons')}>
            {isActualVersion && !request.commands.returnToReview.hidden && (
                <ActionMenu
                    panelFlow='center'
                    button={
                        <ActionButton
                            command={request.commands.returnToReview}
                            disabled={isLocked || isCompanyReadonly}
                        >
                            <OverflowMiniIcon width={22} height={6} />
                        </ActionButton>
                    }
                >
                    <ActionMenu.Item execute={returnToReviewStep} title={messages.sendToReview}>
                        {messages.sendToReview}
                    </ActionMenu.Item>
                </ActionMenu>
            )}

            {isActualVersion && isVersionHistoryAvailable && !isOpenSplitView && (
                <VersionHistoryButton execute={openVersionHistory} title={messages.versionHistoryButtonTitle}>
                    <TbVersionControlIcon />
                </VersionHistoryButton>
            )}

            {isActualVersion && isWatchersAvailable && (
                <ActionButton
                    execute={showEditWatchersPopupHandler}
                    title={readonly ? messages.watchersButtonTitleReadOnly : messages.watchersButtonTitle}
                >
                    {readonly || watchersCount ? (
                        <>
                            <EyeShowOffIcon />

                            {Boolean(watchersCount) && <ActionButtonText>{watchersCount}</ActionButtonText>}
                        </>
                    ) : (
                        <>
                            <EyeAddIcon />

                            <ActionButtonText>{messages.add}</ActionButtonText>
                        </>
                    )}
                </ActionButton>
            )}

            {isRetryAvailable && (
                <ActionButton execute={retry} title={messages.retryButtonTitle}>
                    <TbReloadGreenIcon />

                    <ActionButtonText>{messages.retryButtonText}</ActionButtonText>
                </ActionButton>
            )}

            {request.auditReportUrl && !readonly && (
                <OpenAuditReportButton qa={qa('open-audit-report-button')} execute={openAuditReport}>
                    <OpenAuditReportButtonIcon width={24} height={30} />

                    <OpenAuditReportButtonText>{messages.openAuditReportButtonText}</OpenAuditReportButtonText>
                </OpenAuditReportButton>
            )}

            {isQBOPOStatusEnabled && (
                <ChangePOStatusButtonContainer>
                    <POStatus request={request} disabled={!isPOStatusButtonEnabled || Boolean(readonly)} />
                </ChangePOStatusButtonContainer>
            )}

            {!readonly && !hideActionsForAdvancedFeatures && <OnHoldStatusActions request={request} />}

            <ResolutionBadge request={request} />

            {!readonly && <PayWithAirwallex request={request} />}

            {!readonly && (
                <PrimaryButtons className={starterGuideActionsClass}>
                    {!combineCommandsAnd(request.commands.forceApprove, request.commands.forceReject).hidden && (
                        <ForceDecisionButton
                            disabled={isLocked || isCompanyReadonly}
                            onForceDecision={onForceDecision}
                            hideTip={hideForceDecisionTip}
                            onHideTip={onHideTip}
                            small={!request.commands.approve.hidden}
                            forceApproveCommand={request.commands.forceApprove}
                            forceRejectCommand={request.commands.forceReject}
                        />
                    )}

                    <Button
                        qa={qa('edit-button')}
                        execute={edit}
                        disabled={isLocked || isCompanyReadonly}
                        command={request.commands.editPrimary}
                        title={messages.editButtonTitle}
                        theme='secondary'
                    >
                        <EditFilledIcon />

                        {messages.editButtonText}
                    </Button>

                    <Button
                        qa={qa('review-button')}
                        execute={completeReviewStep}
                        disabled={isLocked || isCompanyReadonly}
                        command={request.commands.completeReview}
                        title={messages.submitForApprovalButtonTitle}
                    >
                        {messages.submitForApprovalButtonText}
                    </Button>

                    <Button
                        qa={qa('force-review-button')}
                        execute={completeReviewStep}
                        disabled={isLocked || isCompanyReadonly}
                        command={request.commands.forceReview}
                        title={messages.forceSubmitForApprovalButtonTitle}
                    >
                        {messages.forceSubmitForApprovalButtonText}
                    </Button>

                    {!request.commands.reject.hidden &&
                        (isCompanyReadonly && canShowSubscribeAfterTrialButton && profile ? (
                            <SubscribeAfterTrialButton
                                type={SubscribeAfterTrialButtonType.Reject}
                                profileId={profile.id}
                                companyId={request.company.id}
                            />
                        ) : (
                            <Button
                                qa={qa('reject-button')}
                                execute={reject}
                                disabled={isLocked || isCompanyReadonly}
                                command={request.commands.reject}
                                title={messages.rejectButtonTitle}
                                colorTheme='grey'
                            >
                                {messages.rejectButtonText}
                            </Button>
                        ))}

                    {!request.commands.approve.hidden &&
                        (isCompanyReadonly && canShowSubscribeAfterTrialButton && profile ? (
                            <SubscribeAfterTrialButton
                                type={SubscribeAfterTrialButtonType.Approve}
                                profileId={profile.id}
                                companyId={request.company.id}
                            />
                        ) : (
                            <Button
                                qa={qa('approve-button')}
                                execute={approve}
                                disabled={
                                    isLocked ||
                                    hasMatchingValidationError ||
                                    hasMatchingInsufficientBudgetError ||
                                    isCompanyReadonly
                                }
                                command={request.commands.approve}
                                title={messages.approveButtonTitle}
                            >
                                {messages.approveButtonText}
                            </Button>
                        ))}

                    <Button
                        qa={qa('submit-button')}
                        execute={submit}
                        disabled={isLocked || isCompanyReadonly}
                        command={request.commands.submit}
                        title={messages.submitButtonTitle}
                    >
                        {messages.submitButtonText}
                    </Button>

                    {request.integrationCode === domain.IntegrationCode.XeroAmaxPayBatchPayment && (
                        <PayWithAmax request={request} />
                    )}

                    {request.integrationCode === domain.IntegrationCode.XeroBill && (
                        <CreatePaymentFromBill request={request} />
                    )}
                </PrimaryButtons>
            )}
        </BodyRightPrimaryRow>
    );
});

PrimaryActions.displayName = 'PrimaryActions';
