import { RequestMutationOptions } from '@approvalmax/data';
import { useMutate } from 'shared/data';

import { groupsOfXeroAccountsApiPaths } from '../paths';
import { CreateGroupsOfXeroAccounts } from './useCreateGroupsOfXeroAccounts.types';

export const useCreateGroupsOfXeroAccounts = (mutationOptions?: RequestMutationOptions<CreateGroupsOfXeroAccounts>) => {
    return useMutate(groupsOfXeroAccountsApiPaths.groupsOfXeroAccounts, {
        mutationOptions,
        method: 'post',
        apiVersion: 'v2',
    });
};
