import { atom } from 'recoil';

export const showProfileEditPopupState = atom({
    key: 'showProfileEditPopupState',
    default: false,
});

export const isModifiedProfileEditPopupState = atom({
    key: 'isModifiedProfileEditState',
    default: false,
});
