import { fileHelpers } from '@approvalmax/utils';
import { memo } from 'react';
import { useDropzone } from 'react-dropzone';
import { useDrop } from 'react-use';

import { CameraIcon } from '../../icons';
import { Avatar } from '../Avatar/Avatar';
import { Flex } from '../Flex/Flex';
import { Progress } from '../Progress/Progress';
import { Text } from '../Text/Text';
import { useAvatarDropzone } from './AvatarDropzone.hooks';
import { messages } from './AvatarDropzone.messages';
import { Root, Zone } from './AvatarDropzone.styles';
import { AvatarDropzoneProps } from './AvatarDropzone.types';

/*
 * Component that abstracts the logic and UI for a drag and drop file upload avatar zone using react-dropzone
 * https://react-dropzone.js.org/
 */
export const AvatarDropzone = memo<AvatarDropzoneProps>((props) => {
    const {
        width = 100,
        disabled,
        accept,
        maxSize,
        minSize = 0,
        avatarUrl,
        progress,
        dragFileIcon,
        dragFileHint,
        dropFileHint,
        onUpload,
        onDropAccepted,
        onDropRejected,
        ...restProps
    } = props;

    const { over: isDragOnPageActive } = useDrop();
    const { onDropAcceptedFiles, onDropRejectedFiles, acceptedExtensions } = useAvatarDropzone(props);

    const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
        accept,
        maxSize,
        minSize,
        disabled,
        maxFiles: 1,
        noClick: true,
        multiple: false,
        noKeyboard: true,
        onDropAccepted: onDropAcceptedFiles,
        onDropRejected: onDropRejectedFiles,
        ...restProps,
    });

    return (
        <Root {...getRootProps()} {...restProps} $drag={isDragOnPageActive} $width={width}>
            <input {...getInputProps()} />

            <Zone
                $active={isDragActive}
                $progress={progress}
                $disabled={disabled}
                $drag={isDragOnPageActive && !disabled}
            >
                {progress && <Progress rounded size='xsmall' shape='circle' />}

                {!progress && !avatarUrl && (
                    <Flex
                        direction='column'
                        spacing='4'
                        alignItems='center'
                        justifyContent='center'
                        width='100%'
                        height='100%'
                        onClick={!disabled ? open : undefined}
                    >
                        {dragFileIcon || isDragActive || (
                            <CameraIcon size={24} color={isDragActive || disabled ? 'midnight70' : 'blue100'} />
                        )}

                        <Text
                            font='label'
                            textAlign='center'
                            fontSize='xsmall'
                            fontWeight='regular'
                            color={disabled ? 'midnight70' : 'blue100'}
                        >
                            {isDragActive
                                ? dropFileHint || messages.dropFileHint
                                : dragFileHint || messages.dragFileHint}
                        </Text>
                    </Flex>
                )}

                {!progress && avatarUrl && (
                    <Flex
                        direction='column'
                        spacing='4'
                        alignItems='center'
                        justifyContent='center'
                        width='100%'
                        height='100%'
                        onClick={!disabled ? open : undefined}
                    >
                        <Avatar size={100} src={avatarUrl} alt={messages.avatarAlt} />
                    </Flex>
                )}
            </Zone>

            <Flex direction='column' spacing='0'>
                {(acceptedExtensions || maxSize) && (
                    <Text font='body' fontSize='xsmall' textAlign='center' color='midnight70' spacing='8 0 0'>
                        {acceptedExtensions && acceptedExtensions.toUpperCase()}

                        {acceptedExtensions && maxSize && <br />}

                        {maxSize && messages.maxSizeText({ maxSize: fileHelpers.formatBytes(maxSize, 0) })}
                    </Text>
                )}
            </Flex>
        </Root>
    );
});

AvatarDropzone.displayName = 'AvatarDropzone';
