import { AddIcon, EmptyBoxImage, SearchIcon } from '@approvalmax/ui';
import { Button, Flex, Popup, Table, Text, TextField } from '@approvalmax/ui/src/components';
import { selectors } from 'modules/common';
import { useSelector } from 'modules/react-redux';
import { memo, useCallback, useMemo, useState } from 'react';
import { useToggle } from 'react-use';

import { getActiveTemplate } from '../../../../selectors/pageSelectors';
import { CreationPopupContent } from '../CreationPopupContent/CreationPopupContent';
import { EditingPopupContent } from '../EditingPopupContent/EditingPopupContent';
import { VersionHistoryPopupContent } from '../VersionHistoryPopupContent/VersionHistoryPopupContent';
import { useFilteredGroupsOfXeroAccounts, useLoadFields, useTableDefinition } from './PopupContent.hooks';
import { Loading } from './PopupContent.loading';
import { messages } from './PopupContent.messages';

export const PopupContent = memo(() => {
    const template = useSelector(getActiveTemplate);
    const [creationPopupOpen, toggleCreationPopupOpen] = useToggle(false);
    const [editingGroupId, setEditingGroupId] = useState<string | null>(null);
    const [viewingHistoryGroupId, setViewingHistoryGroupId] = useState<string | null>(null);
    const company = useSelector((state) => selectors.company.findCompanyById(state, template?.companyId || ''));
    const isReadonly = company?.isReadonly || company?.flags.isAuditor;
    const [searchValue, setSearchValue] = useState('');

    const { isLoading: isLoadingLoadFields } = useLoadFields();
    const {
        isLoading: isLoadingGetGroupsOfXeroAccounts,
        isBackendEmpty,
        allGroups,
        filteredGroups,
    } = useFilteredGroupsOfXeroAccounts(searchValue, template?.companyId);

    const editingGroup = useMemo(
        () => allGroups.find((item) => item.id === editingGroupId) || null,
        [editingGroupId, allGroups]
    );

    const viewingHistoryGroup = useMemo(
        () => allGroups.find((item) => item.id === viewingHistoryGroupId) || null,
        [viewingHistoryGroupId, allGroups]
    );

    const clearEditingGroupId = useCallback(() => {
        setEditingGroupId(null);
    }, []);

    const clearViewingHistoryGroupId = useCallback(() => {
        setViewingHistoryGroupId(null);
    }, []);

    const tableDefinition = useTableDefinition({
        companyId: template?.companyId || '',
        onEdit: setEditingGroupId,
        onViewHistory: setViewingHistoryGroupId,
        isReadonly,
    });

    if (isLoadingLoadFields || isLoadingGetGroupsOfXeroAccounts || !template) return <Loading />;

    return (
        <>
            <Popup.Header title={messages.popupTitle} />

            <Popup.Body>
                <Flex direction='column' height={280} wrap={false}>
                    <Flex wrap={false}>
                        <Button
                            size='small'
                            color='blue10'
                            fontWeight='regular'
                            startIcon={<AddIcon />}
                            onClick={toggleCreationPopupOpen}
                            disabled={isReadonly}
                        >
                            {messages.addNewGroup}
                        </Button>

                        <TextField
                            width={254}
                            size='small'
                            placeholder={messages.searchGroupOfValue}
                            startIcon={<SearchIcon />}
                            value={searchValue}
                            onChange={setSearchValue}
                            disabled={isBackendEmpty}
                        />
                    </Flex>

                    {filteredGroups.length > 0 ? (
                        <Table headerColor='white100' data={filteredGroups} columns={tableDefinition} virtualized />
                    ) : (
                        <Flex direction='column' alignItems='center' spacing='24'>
                            <EmptyBoxImage />

                            <Text font='headline' fontSize='small' color='midnight80' fontWeight='medium'>
                                {isBackendEmpty ? messages.noGroupsAdded : messages.noGroupsFound}
                            </Text>

                            {isBackendEmpty && (
                                <Button color='blue80' size='large' onClick={toggleCreationPopupOpen}>
                                    {messages.addNewGroup}
                                </Button>
                            )}
                        </Flex>
                    )}
                </Flex>
            </Popup.Body>

            <Popup onClose={toggleCreationPopupOpen} open={creationPopupOpen} size='xsmall' preventAutoClose>
                <CreationPopupContent
                    onClose={toggleCreationPopupOpen}
                    onSuccess={setEditingGroupId}
                    companyId={template.companyId}
                />
            </Popup>

            <Popup onClose={clearEditingGroupId} open={!!editingGroup} size='large' preventAutoClose>
                {editingGroup && (
                    <EditingPopupContent
                        onClose={clearEditingGroupId}
                        companyId={template.companyId}
                        integrationCode={template.integrationCode}
                        groupOfAccounts={editingGroup}
                    />
                )}
            </Popup>

            <Popup onClose={clearViewingHistoryGroupId} open={!!viewingHistoryGroup} size='large'>
                {viewingHistoryGroup && (
                    <VersionHistoryPopupContent groupOfAccounts={viewingHistoryGroup} companyId={template.companyId} />
                )}
            </Popup>
        </>
    );
});

PopupContent.displayName = 'PopupContent';
