import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('app.WorkflowIdPage.PopupContent', {
    popupTitle: 'Group of values',
    addNewGroup: 'Add new group',
    searchGroupOfValue: 'Search Group of values',
    noGroupsAdded: 'No groups added',
    noGroupsFound: 'No groups found matching this name.',
    groupName: 'Group name',
    status: 'Status',
    fieldType: 'Field type',
    values: 'Values',
    lastModified: 'Last modified',
    modifiedBy: 'Modified by',
    workflows: 'Workflows',
    archived: 'Archived',
    active: 'Active',
    account: 'Account',
});
