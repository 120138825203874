import { Box, Button, Form, Popup, Select, Spacing, TextField, toast } from '@approvalmax/ui/src/components';
import { zodResolver } from '@hookform/resolvers/zod';
import { FC, memo } from 'react';
import { useForm } from 'react-hook-form';
import { useCreateGroupsOfXeroAccounts } from 'shared/data/v2';
import { z } from 'zod';

import { fieldTypeOptions, fieldTypes, formFieldNames, nameMaxLength } from './CreationPopupContent.constants';
import { messages } from './CreationPopupContent.messages';
import { schema } from './CreationPopupContent.schema';
import { CreationPopupContentProps } from './CreationPopupContent.types';

export const CreationPopupContent: FC<CreationPopupContentProps> = memo((props) => {
    const { companyId, onClose, onSuccess } = props;

    const { mutate, isLoading } = useCreateGroupsOfXeroAccounts();

    const form = useForm<z.infer<typeof schema>>({
        defaultValues: {
            fieldType: fieldTypes.account,
            groupName: '',
        },
        resolver: zodResolver(schema),
        disabled: isLoading,
    });

    const formSubmit = form.handleSubmit((data) => {
        mutate(
            {
                body: { name: data.groupName },
                params: { path: { companyId } },
            },
            {
                onSuccess: (data) => {
                    toast.success(messages.successMessage);
                    onClose();
                    onSuccess(data.id);
                },
                onError: (error) => {
                    if (error.status === 409) {
                        form.setError('groupName', { message: messages.conflictErrorMessage });
                    }
                },
            }
        );
    });

    return (
        <Form form={form} onSubmit={formSubmit}>
            <Popup.Header
                title={messages.popupTitle}
                actions={
                    <Box spacing='10 0 0'>
                        <Button size='small' color='transparent' type='submit' progress={isLoading}>
                            {messages.create}
                        </Button>
                    </Box>
                }
            />

            <Popup.Body>
                <Select
                    items={fieldTypeOptions}
                    label={messages.fieldType}
                    size='medium'
                    required
                    clearable={false}
                    name={formFieldNames.fieldType}
                    hint={messages.fieldTypeHint}
                />

                <Spacing height={24} />

                <TextField
                    label={messages.groupName}
                    placeholder={messages.enterName}
                    name={formFieldNames.groupName}
                    size='medium'
                    required
                    initFocus
                    maxLength={nameMaxLength}
                />
            </Popup.Body>
        </Form>
    );
});

CreationPopupContent.displayName = 'CreationPopupContent';
