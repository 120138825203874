import { Text, toast } from '@approvalmax/ui/src/components';
import { errorHelpers, intl } from '@approvalmax/utils';
import { formatBytes } from '@approvalmax/utils/src/helpers/file/formatBytes';
import React, { Component, PropsWithChildren } from 'react';
import Dropzone, { DropzoneOptions, FileRejection } from 'react-dropzone';
import { defineMessages } from 'react-intl';
import { createSelector } from 'reselect';

import { AttachmentKind } from '../data/AttachmentKind';

const i18nPrefix = 'requestForm/components/FileDropProvider';
const messages = defineMessages({
    fileTooBigError: {
        id: `${i18nPrefix}.fileTooBigError`,
        defaultMessage: 'File size more than {maxSize}',
    },
    fileExtensionNotSupportedError: {
        id: `${i18nPrefix}.fileExtensionNotSupportedError`,
        defaultMessage: 'Invalid file type',
    },
});

const FileDropProviderContext = React.createContext<{
    isActive: boolean;
    attachmentKind: AttachmentKind;
    setAttachmentKind: (attachmentKind: AttachmentKind) => void;
    openFileDialog: () => void;
}>({
    isActive: false,
    attachmentKind: AttachmentKind.General,
    setAttachmentKind: () => {
        throw errorHelpers.invalidOperationError();
    },
    openFileDialog: () => {
        throw errorHelpers.invalidOperationError();
    },
});

interface FileDropProviderProps extends PropsWithChildren, DropzoneOptions {
    onUpload: (files: File[], attachmentType: AttachmentKind) => void;
    className?: string;
    qa?: string;
    maxSize?: number;
    allowedFileExtensions?: string[];
}

interface State {
    isActive: boolean;
    attachmentKind: AttachmentKind;
}

/**
 * @deprecated Use AttachmentsSection
 */
class FileDropProvider extends Component<FileDropProviderProps, State> {
    public static Consumer = FileDropProviderContext.Consumer;

    public state: State = {
        isActive: false,
        attachmentKind: AttachmentKind.General,
    };

    private _getValue = createSelector(
        (options: {
            isActive: boolean;
            attachmentKind: AttachmentKind;
            openFileDialog: () => void;
            setAttachmentKind: (attachmentKind: AttachmentKind) => void;
        }) => options.isActive,
        (options: {
            isActive: boolean;
            attachmentKind: AttachmentKind;
            openFileDialog: () => void;
            setAttachmentKind: (attachmentKind: AttachmentKind) => void;
        }) => options.attachmentKind,
        (options: {
            isActive: boolean;
            attachmentKind: AttachmentKind;
            openFileDialog: () => void;
            setAttachmentKind: (attachmentKind: AttachmentKind) => void;
        }) => options.openFileDialog,
        (options: {
            isActive: boolean;
            attachmentKind: AttachmentKind;
            openFileDialog: () => void;
            setAttachmentKind: (attachmentKind: AttachmentKind) => void;
        }) => options.setAttachmentKind,
        (isActive, attachmentKind, openFileDialog, setAttachmentKind) => ({
            isActive,
            attachmentKind,
            openFileDialog,
            setAttachmentKind,
        })
    );

    public render() {
        const { className, qa, children, maxSize, ...dropzoneOptions } = this.props;

        return (
            <Dropzone maxSize={maxSize} onDrop={this._onDrop} noClick noKeyboard {...dropzoneOptions}>
                {({ getRootProps, getInputProps, open, isDragActive }) => (
                    <section {...getRootProps()} className={className} data-qa={qa}>
                        <input {...getInputProps()} />

                        <FileDropProviderContext.Provider
                            value={this._getValue({
                                isActive: isDragActive,
                                openFileDialog: open,
                                setAttachmentKind: this._setAttachmentKind,
                                attachmentKind: this.state.attachmentKind,
                            })}
                        >
                            {children}
                        </FileDropProviderContext.Provider>
                    </section>
                )}
            </Dropzone>
        );
    }

    private _setAttachmentKind = (attachmentKind: AttachmentKind) => {
        this.setState({ attachmentKind });
    };
    private _onDrop = (accepted: File[], rejected: FileRejection[]) => {
        rejected.forEach(({ file }) => {
            if (this.props.maxSize && file.size > this.props.maxSize) {
                toast.error(
                    <>
                        <Text font='label' fontSize='large' fontWeight='medium' spacing='0 0 8' ellipsis={2}>
                            {file.name}
                        </Text>

                        {intl.formatMessage(messages.fileTooBigError, {
                            maxSize: formatBytes(this.props.maxSize),
                        })}
                    </>
                );
            }
        });

        if (this.props.allowedFileExtensions) {
            let notified = false;

            accepted = accepted.filter((file) => {
                const fileExt = file.name.substr(file.name.lastIndexOf('.')).toLowerCase();

                if (!this.props.allowedFileExtensions!.map((x) => x.toLowerCase()).includes(fileExt)) {
                    if (!notified) {
                        toast.error(
                            <>
                                <Text font='label' fontSize='large' fontWeight='medium' spacing='0 0 8' ellipsis={2}>
                                    {file.name}
                                </Text>

                                {intl.formatMessage(messages.fileExtensionNotSupportedError)}
                            </>
                        );
                    }

                    return false;
                }

                return true;
            });
        }

        if (accepted.length === 0) {
            return;
        }

        this.props.onUpload(accepted, this.state.attachmentKind);
    };
}

export default FileDropProvider;
