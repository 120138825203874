import { memo } from 'react';

import { Text } from '../../../Text/Text';
import { FileErrorToastProps } from './FileErrorToast.types';

export const FileErrorToast = memo<FileErrorToastProps>((props) => {
    const { title, message } = props;

    return (
        <>
            <Text font='label' fontSize='large' fontWeight='medium' spacing='0 0 8' ellipsis={2}>
                {title}
            </Text>

            {message}
        </>
    );
});

FileErrorToast.displayName = 'FileErrorToast';
