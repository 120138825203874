import { mixins, mods } from '@approvalmax/theme';
import { StyledMods } from '@styled-kit/mods';
import styled from 'styled-components';

import type { TextBaseProps } from './Text.types';

export const Root = styled.div<
    StyledMods<
        Pick<
            TextBaseProps,
            | 'color'
            | 'fontSize'
            | 'fontWeight'
            | 'font'
            | 'hoverColor'
            | 'textAlign'
            | 'ellipsis'
            | 'uppercase'
            | 'wrap'
            | 'wordBreak'
            | 'lineThrough'
        > & {
            spacing?: string;
        }
    >
>`
    margin: ${({ $spacing }) => $spacing};

    ${mods(['font', 'fontSize'] as const)(({ font, fontSize }) => mixins.font(font, fontSize, 'regular'))};

    ${mods.color(mixins.color)};

    ${mods.fontWeight(mixins.fontWeight)};

    ${mods.textAlign(mixins.textAlign)};

    ${mods.ellipsis(mixins.ellipsis)};

    word-break: ${({ $wordBreak }) => $wordBreak};

    ${mods.uppercase.true`
        text-transform: uppercase;
    `};

    ${mods.lineThrough.true`
        text-decoration: line-through;
    `};

    ${mods.wrap.false`
        white-space: nowrap;
    `};

    &:hover {
        ${({ $hoverColor }) => $hoverColor && mixins.color($hoverColor)};
    }
`;
