import { RequestMutationOptions } from '@approvalmax/data';
import { routerHelpers } from '@approvalmax/utils';
import { useQueryClient } from '@tanstack/react-query';
import { useMutate } from 'shared/data';

import { groupsOfXeroAccountsApiPaths } from '../paths';
import { UpdateGroupOfXeroAccountsVersions } from './useUpdateGroupOfXeroAccountsVersions.types';

export const useUpdateGroupOfXeroAccountsVersions = (
    mutationOptions?: RequestMutationOptions<UpdateGroupOfXeroAccountsVersions>
) => {
    const queryClient = useQueryClient();

    return useMutate(groupsOfXeroAccountsApiPaths.groupOfXeroAccountsVersions, {
        mutationOptions: {
            ...mutationOptions,
            onSuccess: async (data, variables, context) => {
                const getGroupsOfXeroAccountsPath = routerHelpers.pathToUrl(
                    groupsOfXeroAccountsApiPaths.groupsOfXeroAccounts,
                    { companyId: variables.params?.path?.companyId }
                );

                await queryClient.invalidateQueries([getGroupsOfXeroAccountsPath]);

                mutationOptions?.onSuccess?.(data, variables, context);
            },
        },
        method: 'post',
        apiVersion: 'v2',
    });
};
