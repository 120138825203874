import { useCallback } from 'react';

import { BaseItem, ColumnDefinition, SortOrder, TableProps } from '../../Table.types';

export const useTableSort = <Item extends BaseItem>(
    columnDefinition: ColumnDefinition<Item>,
    onSort: TableProps<Item>['onSort']
) => {
    const { id, sortable, sortOrder } = columnDefinition;

    const handleSort = useCallback(() => {
        if (!sortable || !onSort) {
            return;
        }

        const newSortOrder = sortOrder === SortOrder.asc ? SortOrder.desc : SortOrder.asc;

        onSort(id, newSortOrder);
    }, [id, sortable, sortOrder, onSort]);

    return sortable ? handleSort : undefined;
};
