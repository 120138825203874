import { Box, Spacing } from '@approvalmax/ui/src/components';
import { OracleNetsuiteLogo } from 'modules/sprites';
import { FC, memo } from 'react';

import { ConnectToOrganisationButton, PopupFooter, PopupTitle } from '..';
import { InviteTeammateSuggestion } from '../InviteTeammateSuggestion/InviteTeammateSuggestion';
import { messages } from './NetSuiteContent.messages';
import { NetSuiteContentProps } from './NetSuiteContent.types';

const NetSuiteContent: FC<NetSuiteContentProps> = memo((props) => {
    const {
        isLoading,
        onConnectToOrganisation,
        onSkip,
        onBack,

        withInviteTeammate,
        onInviteTeammate,
    } = props;

    return (
        <>
            <PopupTitle />

            <Box spacing='20 0 0' width={300}>
                <ConnectToOrganisationButton
                    onClick={onConnectToOrganisation}
                    logo={OracleNetsuiteLogo}
                    systemName={messages.netSuiteName}
                    width={300}
                    isLoading={isLoading}
                />
            </Box>

            {withInviteTeammate ? (
                <>
                    <Spacing height={24} />

                    <InviteTeammateSuggestion onInviteTeammate={onInviteTeammate} />

                    <Spacing height={24} />
                </>
            ) : (
                <Spacing height={32} />
            )}

            <PopupFooter isLoading={isLoading} withBackButton onBack={onBack} onSkip={onSkip} />
        </>
    );
});

export default NetSuiteContent;
