import { hooks } from '@approvalmax/utils';
import { ForwardedRef, useImperativeHandle, useRef } from 'react';
import { useRecoilState } from 'recoil';

import { weekDayPickerRefState } from '../Content/Content.states';

export const useOptionRef = (ref: ForwardedRef<HTMLInputElement>) => {
    const innerRef = useRef<HTMLInputElement | null>(null);
    const [optionsGroupRef] = useRecoilState(weekDayPickerRefState);

    useImperativeHandle(ref, () => innerRef.current as HTMLInputElement);
    useImperativeHandle(optionsGroupRef, () => innerRef.current as HTMLInputElement);

    return hooks.useCaptureFocus(innerRef);
};
