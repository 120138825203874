import { Divider, Flex, Popup, Spacing } from '@approvalmax/ui/src/components';
import { memo } from 'react';
import Skeleton from 'react-loading-skeleton';

import { messages } from './PopupContent.messages';

export const Loading = memo(() => {
    return (
        <>
            <Popup.Header title={messages.popupTitle} />

            <Popup.Body>
                <Flex direction='column' minHeight={272}>
                    <Flex wrap={false}>
                        <Skeleton width={126} height={28} />

                        <Skeleton width={254} height={28} />
                    </Flex>

                    <div>
                        <Spacing height={12} />

                        <Skeleton width='100%' height={16} />

                        <Divider orientation='horizontal' spacing='12 0' />

                        <Skeleton width='100%' height={16} />

                        <Divider orientation='horizontal' spacing='12 0' />

                        <Skeleton width='100%' height={16} />
                    </div>
                </Flex>
            </Popup.Body>
        </>
    );
});

Loading.displayName = 'Loading';
