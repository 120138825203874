import { ForwardedRef } from 'react';
import { atom, selectorFamily } from 'recoil';

import { WeekDayPickerValue } from './Content.types';

export const weekDayPickerRefState = atom<ForwardedRef<HTMLInputElement> | null>({
    key: 'weekDayPickerRefState',
    default: null,
});

export const weekDayPickerValuesState = atom<WeekDayPickerValue[]>({
    key: 'weekDayPickerValuesState',
    default: [],
});

export const weekDayPickerSelectedValuesState = selectorFamily<boolean, WeekDayPickerValue>({
    key: 'weekDayPickerSelectedValuesState',
    get:
        (option) =>
        ({ get }) => {
            const selectedValues = get(weekDayPickerValuesState);

            return selectedValues.includes(option);
        },
    set:
        (option) =>
        ({ get, set }) => {
            const selectedValuesPrev = get(weekDayPickerValuesState);

            const selectedValuesNext = selectedValuesPrev.includes(option)
                ? selectedValuesPrev.filter((optionExisted) => optionExisted !== option)
                : [...selectedValuesPrev, option];

            set(weekDayPickerValuesState, selectedValuesNext);
        },
});
