import { Allotment } from 'allotment';
import React, { isValidElement, memo, NamedExoticComponent, useMemo, useRef } from 'react';
import { useRecoilValue } from 'recoil';

import { DownloadButton, Pagination } from './addons';
import { Item, ToggleFullscreen } from './components';
import { ItemProps } from './components/Item/Item.types';
import { useGetChildrenWithCalculatedProps, useHandleKeyDown, useHideResizer } from './SplitView.hooks';
import { fullscreenSplitViewState, openSplitViewState } from './SplitView.states';
import { Container, Root } from './SplitView.styles';
import { ChildrenComponents, SplitViewProps } from './SplitView.types';

const SplitContainer: typeof Allotment = Container;

/**
 * SplitView divides a display into resizable sections, allowing users to adjust the size of each section
 * by dragging a divider.
 */
const SplitView = memo((props) => {
    const { children, pinned = true, ...restProps } = props;
    const rootRef = useRef<HTMLDivElement>(null);

    const fullscreenState = useRecoilValue(fullscreenSplitViewState);
    const openState = useRecoilValue(openSplitViewState);

    const childArray = useMemo(() => React.Children.toArray(children), [children]);

    const hasRenderByDefault = useMemo(
        () =>
            childArray.some((child) => {
                if (!React.isValidElement(child)) return false;

                return 'props' in child && child.props.renderByDefault !== undefined;
            }),
        [childArray]
    );

    const childArrayWithCalculatedProps = useGetChildrenWithCalculatedProps(childArray);

    useHideResizer({ openState, fullscreenState, rootRef });

    useHandleKeyDown();

    const hideSplitView = !openState && !hasRenderByDefault;

    if (hideSplitView) {
        return null;
    }

    return (
        <Root
            $open={openState}
            $pinned={openState && pinned}
            $fullscreen={fullscreenState}
            ref={rootRef}
            {...restProps}
        >
            <SplitContainer separator={openState && !fullscreenState} snap>
                {childArrayWithCalculatedProps.map((child, index) => {
                    let visible = openState;
                    let preferredSize;
                    let minSize;
                    let snap;

                    /**
                     * We can't move Allotment.Pane to SplitView.Item because
                     * allotment checks if child is Allotment.Pane by display name,
                     * so we need to wrap child in Allotment.Pane here and check child props
                     * to pass correctly visible prop to Pane
                     *
                     * @see https://github.com/johnwalley/allotment/blob/main/src/allotment.tsx#L28
                     */
                    if (isValidElement(child) && (child as any).type?.displayName === 'SplitView.Item') {
                        const props = child.props as ItemProps;

                        const isRenderByDefault = Boolean(props.renderByDefault);
                        const isShowInFullScreen = Boolean(props.showInFullScreen);

                        preferredSize = props.preferredSize;
                        minSize = props.minSize;
                        snap = props.snap;

                        if (isRenderByDefault) {
                            visible = true;
                        }

                        if (openState && fullscreenState) {
                            visible = isShowInFullScreen;
                        }
                    }

                    return (
                        <Allotment.Pane
                            snap={snap}
                            minSize={minSize}
                            key={index}
                            visible={visible}
                            preferredSize={preferredSize}
                        >
                            {child}
                        </Allotment.Pane>
                    );
                })}
            </SplitContainer>
        </Root>
    );
}) as NamedExoticComponent<SplitViewProps> & ChildrenComponents;

SplitView.displayName = 'SplitView';
SplitView.Item = Item;
SplitView.ToggleFullscreen = ToggleFullscreen;
SplitView.Addons = { Pagination, DownloadButton };

export default SplitView;
