import { getUseCasesSignUp } from 'shared/helpers';

import { mapTextByUseCases } from './PushingInviteTeammates.constants';
import { messages } from './PushingInviteTeammates.messages';

export const getTextByUseCase = () => {
    const casesFromStorage = getUseCasesSignUp();

    if (!casesFromStorage) {
        return messages.useCaseDefault;
    }

    const text = mapTextByUseCases[casesFromStorage];

    if (!text) {
        return messages.useCaseDefault;
    }

    return text;
};
