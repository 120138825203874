import { ChevronRightIcon } from '@approvalmax/ui';
import { Flex } from '@approvalmax/ui/src/components';
import { selectors } from 'modules/common';
import { domain } from 'modules/data';
import { useSelector } from 'modules/react-redux';
import { Fragment, memo, useMemo } from 'react';
import { isPayOrCapturePromotionsAllowed } from 'shared/helpers';
import { useAddonPromotions } from 'shared/hooks/useAddonPromotions';
import { AddonPromotionType } from 'shared/states';

import { useInitWorkflowDetailsPromotions } from '../../hooks/useInitWorkflowDetailsPromotions';
import { getActiveTemplate } from '../../selectors/pageSelectors';
import AddNewStepButton from '../AddNewStepButton/AddNewStepButton';
import { ApprovalStep } from '../ApprovalStep/ApprovalStep';
import { DraggableStepList } from '../DraggableStepList/DraggableStepList';
import EndStepWithDescription from '../EndStepWithDescription/EndStepWithDescription';
import { PayAndCapturePromotionStep } from '../PayAndCapturePromotionStep/PayAndCapturePromotionStep';
import QBooksStartStep from '../QBooksStartStep/QBooksStartStep';
import { SingletonSteps } from '../SingletonSteps/SingletonSteps';
import { messages } from './QBooksWorkflowDetails.messages';

const QBooksWorkflowDetails = memo(() => {
    const template = useSelector(getActiveTemplate);
    const company = useSelector((state) => selectors.company.findCompanyById(state, template?.companyId || ''));

    const pluralTemplateName = useMemo(
        () => selectors.template.getTemplateDisplayNameByCode(template?.integrationCode ?? null, true),
        [template?.integrationCode]
    );

    const { canShowPromotion } = useAddonPromotions();

    useInitWorkflowDetailsPromotions(company);

    if (!template) {
        return null;
    }

    return (
        <>
            {template.integrationCode === domain.IntegrationCode.QBooksBill &&
                company &&
                isPayOrCapturePromotionsAllowed(company, AddonPromotionType.Capture) &&
                canShowPromotion(AddonPromotionType.Capture) && (
                    <PayAndCapturePromotionStep type={AddonPromotionType.Capture} />
                )}

            <QBooksStartStep />

            <SingletonSteps />

            <DraggableStepList steps={template.steps}>
                {template.steps.map((step, index) => (
                    <Fragment key={step.id}>
                        {index === 0 && (
                            <Flex height={28} alignItems='center'>
                                <ChevronRightIcon size={16} color='midnight60' />
                            </Flex>
                        )}

                        <ApprovalStep stepIndex={index} step={step} />
                    </Fragment>
                ))}
            </DraggableStepList>

            <AddNewStepButton />

            <EndStepWithDescription
                color='brandQBO100'
                bubbleText={messages.endStepBubbleTextPo}
                description={messages.endStepDescriptionPo({
                    pluralTemplateName,
                })}
            />

            {template.integrationCode === domain.IntegrationCode.QBooksBill &&
                company &&
                isPayOrCapturePromotionsAllowed(company, AddonPromotionType.Pay) &&
                canShowPromotion(AddonPromotionType.Pay) && (
                    <PayAndCapturePromotionStep type={AddonPromotionType.Pay} />
                )}
        </>
    );
});

export default QBooksWorkflowDetails;
