import { domHelpers } from '@approvalmax/utils';
import { FloatingFocusManager, FloatingPortal } from '@floating-ui/react';
import { memo, NamedExoticComponent, useMemo, useRef } from 'react';

import { PopupErrorBoundary } from '../PopupErrorBoundary/PopupErrorBoundary';
import { Body, Header } from './components';
import { PopupContext } from './Popup.context';
import { useFloatingPopup, useToggle } from './Popup.hooks';
import { Content, Overlay } from './Popup.styles';
import { ChildrenComponents, PopupProps } from './Popup.types';

/**
 * Popup component allows displaying a dialog over the rest of the page
 */
const Popup = memo((props) => {
    const {
        className,
        children,
        size = 'medium',
        open,
        closable = true,
        onToggle,
        onClose,
        preventAutoClose = false,
        returnFocus = true,
        fullScreen = false,
        zIndex = 1000,
        overflowY = 'auto',
        qa,
        ...restProps
    } = props;

    const stickyContentRef = useRef<HTMLDivElement>(null);
    const root = stickyContentRef.current;

    const { isOpen, setToggle } = useToggle({ open, onToggle, onClose });
    const { context, getFloatingProps, refs } = useFloatingPopup({ isOpen, setToggle, preventAutoClose });
    const contextValue = useMemo(() => ({ size, setToggle, closable, root }), [size, setToggle, closable, root]);

    return (
        <PopupContext.Provider value={contextValue}>
            {isOpen && (
                <FloatingPortal>
                    <Overlay lockScroll $zIndex={zIndex} ref={stickyContentRef}>
                        <FloatingFocusManager context={context} initialFocus={refs.floating} returnFocus={returnFocus}>
                            <Content
                                className={className}
                                ref={refs.setFloating}
                                $size={size}
                                $fullScreen={fullScreen}
                                $overflowY={overflowY}
                                data-qa={domHelpers.generateDataQa(qa, 'popup')}
                                {...getFloatingProps(restProps)}
                            >
                                <PopupErrorBoundary onPopupClose={() => setToggle(false)}>
                                    {children}
                                </PopupErrorBoundary>
                            </Content>
                        </FloatingFocusManager>
                    </Overlay>
                </FloatingPortal>
            )}
        </PopupContext.Provider>
    );
}) as NamedExoticComponent<PopupProps> & ChildrenComponents;

Popup.Body = Body;
Popup.Header = Header;

export default Popup;
