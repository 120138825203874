import { useMount } from 'react-use';
import { debuggingService } from 'services/debugging';
import { mobileAppService } from 'services/mobileApp';
import { sentryService } from 'services/sentry';

import { useApplicationUserContext } from './useApplicationUserContext';
import { useInitHistoryBlock } from './useInitHistoryBlock';
import { useInitialLoading } from './useInitialLoading';
import { useInitRoutingService } from './useInitRoutingService';
import { useServicesAfterFetchingContext } from './useServicesAfterFetchingContext';
import { useSetCompaniesBetaFeatures } from './useSetCompaniesBetaFeatures';
import { useSetUserBetaFeatures } from './useSetUserBetaFeatures';
import { useTwoFaHardEnforcementPage } from './useTwoFaHardEnforcementPage';

export const useLoadApplication = () => {
    window.ApprovalMax.userTimeZone = window.timeZoneUtils.TimeZone.determine_timezone().name();

    const { isInitialLoading } = useApplicationUserContext();

    useInitialLoading();
    useServicesAfterFetchingContext();
    useSetCompaniesBetaFeatures();
    useSetUserBetaFeatures();
    useInitHistoryBlock();
    useInitRoutingService();
    useTwoFaHardEnforcementPage();

    useMount(() => {
        sentryService.init();
        debuggingService.init();
        mobileAppService.init();
    });

    return isInitialLoading;
};
