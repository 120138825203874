import { fileHelpers } from '@approvalmax/utils';
import uniqueId from 'lodash/uniqueId';
import { ErrorCode, FileError } from 'react-dropzone';

import { toast } from '../Toast/Toast.helpers';
import { errorMessages } from './Dropzone.messages';
import { FileRecord } from './Dropzone.types';

export const getErrorMessage = (error: FileError, options: { maxSize: number; minSize: number }) => {
    switch (error.code) {
        case ErrorCode.FileTooLarge:
            return errorMessages.fileTooLarge({
                maxSize: fileHelpers.formatBytes(options.maxSize),
            });

        case ErrorCode.FileTooSmall:
            return errorMessages.fileTooSmall({
                minSize: fileHelpers.formatBytes(options.minSize),
            });

        case ErrorCode.FileInvalidType:
            return errorMessages.fileInvalidType;

        case ErrorCode.TooManyFiles:
            return errorMessages.tooManyFiles;

        default:
            return error.message || errorMessages.unknownError;
    }
};

export const prepareFiles = (files: File[] = []) => {
    return files.map((file) => ({
        source: file,
        id: uniqueId(),
    }));
};

export const isFileRecord = (obj: any): obj is FileRecord => {
    return typeof obj === 'object' && obj !== null && typeof obj.id === 'string' && obj.source instanceof File;
};

export const isFileRecordArray = (obj: any): obj is FileRecord[] => {
    return Array.isArray(obj) && obj.every(isFileRecord);
};

export const handleDuplicateNames = (newFiles: ReturnType<typeof prepareFiles>, files: FileRecord[]) => {
    return newFiles.filter((file) => {
        const isDuplicateName = files.some((item) => item.name === file.source.name);

        if (isDuplicateName) {
            toast.error(errorMessages.duplicateFile({ fileName: file.source.name }));

            return false;
        }

        return true;
    });
};
