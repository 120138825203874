export const noValue = '–';

export const companyIdQueryParam = 'company-id';

export const signUpOauthProviderStorageKey = 'signUpOauthProvider';

export const shouldOpenNewOrgPopupNoCompaniesQueryParam = 'openNewOrgPopup';
export const shouldOpenNewOrgPopupStorageKey = 'shouldOpenNewOrgPopup';

export const maxReportNameLength = 512;

export const experimentHowDidLearn = 'experimentHowDidLearn';
