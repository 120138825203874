import { Box, Button, Flex, Form, TextField } from '@approvalmax/ui/src/components';
import { zodResolver } from '@hookform/resolvers/zod';
import { FC, FormEvent, memo, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { useUpdateGroupOfXeroAccounts } from 'shared/data/v2';
import { z } from 'zod';

import { formFieldNames, nameMaxLength } from './EditGroupName.constants';
import { messages } from './EditGroupName.messages';
import { schema } from './EditGroupName.schema';
import { EditGroupNameProps } from './EditGroupName.types';

export const EditGroupName: FC<EditGroupNameProps> = memo((props) => {
    const { name, companyId, groupOfXeroAccountsId, onSuccess } = props;

    const { mutate, isLoading } = useUpdateGroupOfXeroAccounts();

    const form = useForm<z.infer<typeof schema>>({
        defaultValues: { name },
        resolver: zodResolver(schema),
        disabled: isLoading,
    });

    const formSubmit = useCallback(
        (event: FormEvent) => {
            event.stopPropagation();

            form.handleSubmit((data) => {
                mutate(
                    {
                        params: { path: { companyId, groupOfXeroAccountsId } },
                        body: { name: data.name },
                    },
                    {
                        onSuccess: () => {
                            onSuccess();
                        },
                        onError: (error) => {
                            if (error.status === 409) {
                                form.setError('name', { message: messages.conflictErrorMessage });
                            }
                        },
                    }
                );
            })(event);
        },
        [companyId, form, groupOfXeroAccountsId, mutate, onSuccess]
    );

    return (
        <Form form={form} onSubmit={formSubmit}>
            <Box spacing='16'>
                <Flex justifyContent='space-between' width={342} wrap={false}>
                    <Flex grow={1}>
                        <TextField
                            label={messages.label}
                            placeholder={messages.placeholder}
                            name={formFieldNames.name}
                            maxLength={nameMaxLength}
                            size='small'
                            required
                        />
                    </Flex>

                    <Box spacing='20 0 0'>
                        <Button progress={isLoading} type='submit' color='blue80' size='small'>
                            {messages.save}
                        </Button>
                    </Box>
                </Flex>
            </Box>
        </Form>
    );
});

EditGroupName.displayName = 'EditGroupName';
