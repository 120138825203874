import { QualificationQuestionsAnswers } from 'modules/data/domain';

import { setPreferredAccountingSystemSignUp } from './preferredAccountingSystemSignUp';
import { setTypeOfBusiness } from './typeOfBusiness';
import { setUseCasesSignUp } from './useCasesSignUp';

export const saveQualificationQuestionsAnswers = (
    qualificationQuestionAnswers?: QualificationQuestionsAnswers | null
) => {
    setPreferredAccountingSystemSignUp(qualificationQuestionAnswers);
    setUseCasesSignUp(qualificationQuestionAnswers);
    setTypeOfBusiness(qualificationQuestionAnswers);
};
