import { RequestMutationOptions } from '@approvalmax/data';
import { routerHelpers } from '@approvalmax/utils';
import { useQueryClient } from '@tanstack/react-query';
import { useMutate } from 'shared/data';

import { groupsOfXeroAccountsApiPaths } from '../paths';
import { UpdateGroupOfXeroAccountsIsArchived } from './useUpdateGroupOfXeroAccountsIsArchived.types';

export const useUpdateGroupOfXeroAccountsIsArchived = (
    mutationOptions?: RequestMutationOptions<UpdateGroupOfXeroAccountsIsArchived>
) => {
    const queryClient = useQueryClient();

    return useMutate(groupsOfXeroAccountsApiPaths.groupOfXeroAccountsIsArchived, {
        mutationOptions: {
            ...mutationOptions,
            onSuccess: async (data, variables, context) => {
                const getGroupsOfXeroAccountsPath = routerHelpers.pathToUrl(
                    groupsOfXeroAccountsApiPaths.groupsOfXeroAccounts,
                    {
                        companyId: variables.params?.path?.companyId,
                    }
                );

                await queryClient.invalidateQueries([getGroupsOfXeroAccountsPath]);

                mutationOptions?.onSuccess?.(data, variables, context);
            },
        },
        method: 'patch',
        apiVersion: 'v2',
    });
};
