import { errorHelpers } from '@approvalmax/utils';
import { QualificationQuestionsAnswers, TypeOfBusiness } from 'modules/data/domain';

const storageKey = 'typeOfBusiness';

export const getTypeOfBusiness = () => {
    const value = localStorage.getItem(storageKey) as TypeOfBusiness | null;

    if (!value) {
        return null;
    }

    if (Object.values(TypeOfBusiness).includes(value)) {
        return value;
    }

    return null;
};

export const setTypeOfBusiness = (qualificationQuestionsAnswers?: QualificationQuestionsAnswers | null) => {
    try {
        if (qualificationQuestionsAnswers && qualificationQuestionsAnswers.typeOfBusiness.length > 0) {
            const typeOfBusiness = qualificationQuestionsAnswers.typeOfBusiness[0];

            localStorage.setItem(storageKey, typeOfBusiness);
        }
    } catch (error) {
        errorHelpers.captureException(error);
    }
};
