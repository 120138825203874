import { selectors } from 'modules/common';
import { useSelector } from 'modules/react-redux';
import { useCallback, useMemo, useState } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { isEnoughDaysAfter } from 'shared/helpers';
import { getCanShowPushingConnectGLPopup, getCanShowSubscribeAfterTrialButton } from 'shared/helpers/experiments';
import { useUserBetaFeatures } from 'shared/hooks';
import { activeCompanyIdState, pushingConnectGLPopupHideState } from 'shared/states';

import { pushingConnectGLPopupState } from '../PushingConnectGLPopup/PushingConnectGLPopup.states';
import { subscribeAfterTrialPopupOpenState } from '../SubscribeAfterTrialPopup/SubscribeAfterTrialPopup.states';
import {
    maxShowCountForPushingConnectGLPopup,
    sessionKeyAfterTrialBigPopupShowCount,
    sessionKeyAfterTrialBigPopupShowTime,
    sessionKeyPushingConnectGLShowCount,
    sessionKeyPushingConnectGLShowTime,
    storageKeyPushingConnectGLShowCount,
} from './Drawer.constants';
import { messages } from './Drawer.messages';
import { UseCountMenuClickCallbackParams } from './Drawer.types';

export const useCompanies = () => {
    const activeCompanyId = useRecoilValue(activeCompanyIdState);
    const companiesOrigin = useSelector(selectors.company.getCompanies);

    /**
     * Add name to the unnamed companies
     */
    const companies = useMemo(
        () =>
            companiesOrigin.map((company) => {
                if (!company.name) {
                    return {
                        ...company,
                        name: messages.unnamedCompanyName,
                    };
                }

                return company;
            }),
        [companiesOrigin]
    );
    const company = useMemo<selectors.types.ExpandedCompany | undefined>(
        () => companies.find(({ id }) => id === activeCompanyId) || companies[0],
        [activeCompanyId, companies]
    );

    return {
        companies,
        company,
    };
};

const useCountMenuClickCallback = (params: UseCountMenuClickCallbackParams) => {
    const { sessionKeyShowCount, sessionKeyShowTime, limitChangeOfUrl, limitShowInSession, onOpen } = params;

    const [menuClickCount, setMenuClickCount] = useState(0);

    const handleMenuClick = useCallback(() => {
        const countFromStorage = sessionStorage.getItem(sessionKeyShowCount);

        let showInSessionCount = countFromStorage ? parseInt(countFromStorage, 10) || 0 : 0;

        if (showInSessionCount >= limitShowInSession) {
            if (!isEnoughDaysAfter(2, sessionStorage.getItem(sessionKeyShowTime))) {
                return;
            } else {
                showInSessionCount = 0;
                sessionStorage.removeItem(sessionKeyShowCount);
            }
        }

        if (menuClickCount >= limitChangeOfUrl - 1) {
            setMenuClickCount(0);

            onOpen();
            sessionStorage.setItem(sessionKeyShowCount, String(showInSessionCount + 1));
            sessionStorage.setItem(sessionKeyShowTime, String(Date.now()));

            return;
        }

        setMenuClickCount(menuClickCount + 1);
    }, [limitChangeOfUrl, limitShowInSession, menuClickCount, onOpen, sessionKeyShowCount, sessionKeyShowTime]);

    return handleMenuClick;
};

const useSubscribeAfterTrialPopup = (
    company?: selectors.types.ExpandedCompany,
    profile?: selectors.types.ExpandedProfile | null
) => {
    const setSubscribeAfterTrialPopupOpen = useSetRecoilState(subscribeAfterTrialPopupOpenState);

    const canShowSubscribeAfterTrialButton = useMemo(
        () => getCanShowSubscribeAfterTrialButton(company, profile),
        [company, profile]
    );

    const handleOpen = useCallback(() => {
        setSubscribeAfterTrialPopupOpen(true);
    }, [setSubscribeAfterTrialPopupOpen]);

    const handleMenuClick = useCountMenuClickCallback({
        sessionKeyShowCount: sessionKeyAfterTrialBigPopupShowCount,
        sessionKeyShowTime: sessionKeyAfterTrialBigPopupShowTime,
        limitChangeOfUrl: 3,
        limitShowInSession: 3,
        onOpen: handleOpen,
    });

    return canShowSubscribeAfterTrialButton ? handleMenuClick : undefined;
};

const usePushingConnectGLPopup = (
    company?: selectors.types.ExpandedCompany,
    profile?: selectors.types.ExpandedProfile | null
) => {
    const [showCount, setShowCount] = useState(() => {
        const storageCount = localStorage.getItem(storageKeyPushingConnectGLShowCount);

        return storageCount ? parseInt(storageCount, 10) || 0 : 0;
    });

    const [isPushingConnectGLPopupHide, setPushingConnectGLPopupHide] = useRecoilState(pushingConnectGLPopupHideState);
    const setPushingConnectGLPopupState = useSetRecoilState(pushingConnectGLPopupState);

    const canShowPushingConnectGLPopup = useMemo(
        () => showCount < maxShowCountForPushingConnectGLPopup && getCanShowPushingConnectGLPopup(company, profile),
        [showCount, company, profile]
    );

    const handleOpen = useCallback(() => {
        if (company && profile) {
            setPushingConnectGLPopupState({ companyId: company?.id, profileId: profile?.id });

            const nextCount = showCount + 1;

            if (nextCount >= maxShowCountForPushingConnectGLPopup) {
                localStorage.removeItem(storageKeyPushingConnectGLShowCount);
                setPushingConnectGLPopupHide(true);
            } else {
                setShowCount(nextCount);
                localStorage.setItem(storageKeyPushingConnectGLShowCount, String(nextCount));
            }
        }
    }, [company, profile, setPushingConnectGLPopupHide, setPushingConnectGLPopupState, showCount]);

    const handleMenuClick = useCountMenuClickCallback({
        sessionKeyShowCount: sessionKeyPushingConnectGLShowCount,
        sessionKeyShowTime: sessionKeyPushingConnectGLShowTime,
        limitChangeOfUrl: 2,
        limitShowInSession: 2,
        onOpen: handleOpen,
    });

    return !isPushingConnectGLPopupHide && canShowPushingConnectGLPopup ? handleMenuClick : undefined;
};

export const useHandleMenuClick = (
    company?: selectors.types.ExpandedCompany,
    profile?: selectors.types.ExpandedProfile | null
) => {
    const { isDisablePromoPopups } = useUserBetaFeatures();

    const handleSubscribeAfterTrialPopup = useSubscribeAfterTrialPopup(company, profile);
    const handlePushingConnectGLPopup = usePushingConnectGLPopup(company, profile);

    if (isDisablePromoPopups) {
        return undefined;
    }

    return handleSubscribeAfterTrialPopup ?? handlePushingConnectGLPopup;
};
