import { AvatarDropzone, Flex, Form, Select, Text, TextField } from '@approvalmax/ui/src/components';
import { statics } from 'modules/common';
import { memo } from 'react';
import bemFactory from 'react-bem-factory';

import { checkIsAdministratorUserProfile } from '../../ProfileEditPopup.helpers';
import {
    avatarAllowedFileTypes,
    avatarMaxFileSize,
    fieldNamesSectionGeneral,
    firstNameMaxLength,
    lastNameMaxLength,
} from './SectionGeneral.constants';
import { useSectionGeneral } from './SectionGeneral.hooks';
import { messages } from './SectionGeneral.messages';
import { SectionGeneralProps } from './SectionGeneral.types';

const qa = bemFactory.qa('profile-popup-profile-information-section');

export const SectionGeneral = memo<SectionGeneralProps>((props) => {
    const { profile, progress } = props;

    const { handleUploadAvatar, handleTransformPhone, isLoading } = useSectionGeneral();

    const isAdministrator = checkIsAdministratorUserProfile(profile);

    return (
        <Form.Part>
            <Flex direction='column' spacing='24'>
                <Text font='headline' fontSize='small' fontWeight='medium'>
                    {messages.title}
                </Text>

                <Flex spacing='40 40' wrap={false}>
                    <Flex shrink={0}>
                        <AvatarDropzone
                            width='105px'
                            progress={isLoading}
                            onUpload={handleUploadAvatar}
                            avatarUrl={profile?.avatar}
                            maxSize={avatarMaxFileSize}
                            accept={avatarAllowedFileTypes}
                            disabled={progress}
                        />
                    </Flex>

                    <Flex container grow={1} spacing='16'>
                        <Flex size={12}>
                            <TextField
                                qa={qa('first-name-editor')}
                                name={fieldNamesSectionGeneral.firstName}
                                label={messages.firstNameLabel}
                                placeholder={messages.firstNamePlaceholder}
                                hint={profile.email}
                                maxLength={firstNameMaxLength}
                                disabled={progress}
                                clearable={false}
                                required
                            />
                        </Flex>

                        <Flex size={12}>
                            <TextField
                                qa={qa('last-name-editor')}
                                name={fieldNamesSectionGeneral.lastName}
                                label={messages.lastNameLabel}
                                placeholder={messages.lastNamePlaceholder}
                                maxLength={lastNameMaxLength}
                                disabled={progress}
                                clearable={false}
                                required
                            />
                        </Flex>

                        <Flex size={12}>
                            <TextField
                                qa={qa('phone-editor')}
                                transform={handleTransformPhone}
                                name={fieldNamesSectionGeneral.phone}
                                label={messages.phoneLabel}
                                placeholder={messages.phonePlaceholder}
                                disabled={progress}
                                clearable={false}
                                required={isAdministrator}
                            />
                        </Flex>

                        <Flex size={12} block>
                            <Select
                                qa={qa('timezone-editor')}
                                name={fieldNamesSectionGeneral.timeZoneForEmail}
                                label={messages.timeZoneLabel}
                                placeholder={messages.timeZonePlaceholder}
                                hint={messages.timeZoneHint}
                                size='medium'
                                itemIdKey='id'
                                itemNameKey='text'
                                items={statics.timeZone.timeZoneReferences}
                                disabled={progress}
                                clearable={false}
                                required
                            />
                        </Flex>
                    </Flex>
                </Flex>
            </Flex>
        </Form.Part>
    );
});

SectionGeneral.displayName = 'SectionGeneral';
