import { domain } from 'modules/data';
import { atom, selector } from 'recoil';

type CompanyBetaFeatureStatus = {
    [K in domain.CompanyBetaFeature as `is${Capitalize<K>}`]: boolean;
};

const allCompanyBetaFeatures = Object.values(domain.CompanyBetaFeature);

export const companiesBetaFeaturesState = atom<Map<string, domain.CompanyBetaFeature[]>>({
    key: 'companiesBetaFeaturesState',
    default: new Map(),
});

/**
 * @deprecated Direct usage is prohibited. Please use a {@link useCompanyBetaFeatures} hook instead
 */
export const companyBetaFeaturesState = selector({
    key: 'companyBetaFeaturesState',
    get: ({ get }) => {
        const companiesBetaFeatures = get(companiesBetaFeaturesState);

        return (companyId: string | undefined) => {
            const companyBetaFeatures = companyId ? companiesBetaFeatures.get(companyId) : [];

            return allCompanyBetaFeatures.reduce((acc, feature) => {
                acc[`is${feature}`] = companyBetaFeatures?.includes(feature) ?? false;

                return acc;
            }, {} as CompanyBetaFeatureStatus);
        };
    },
});
