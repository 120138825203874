import { intl } from '@approvalmax/utils';
import { selectors } from 'modules/common';
import { domain, State } from 'modules/data';
import { createAction, createAsyncAction, createErrorAction, ExtractActions } from 'modules/react-redux';
import { defineMessages } from 'react-intl';
import { api } from 'services/api';

const i18nPrefix = 'profile.actions';
const messages = defineMessages({
    changePasswordMessage: {
        id: `${i18nPrefix}.changePasswordMessage`,
        defaultMessage: 'Password updated',
    },
});

export const SHOW_PROFILE_POPUP = 'PROFILE/SHOW_PROFILE_POPUP';
/**
 * @deprecated Use recoil state hook instead {@link useProfileEditPopupState}
 */
export const showProfilePopup = () => createAction(SHOW_PROFILE_POPUP, {});

export const CHANGE_MODIFIED_PROFILE_POPUP = 'PROFILE/CHANGE_MODIFIED_PROFILE_POPUP';
/**
 * @deprecated Not used in the redesigned version, used react-hook-form isDirty property
 */
export const changeProfilePopupModified = (modified: boolean) =>
    createAction(CHANGE_MODIFIED_PROFILE_POPUP, {
        modified,
    });

export const SET_AVATAR = 'PROFILE/SET_AVATAR';
export const SET_AVATAR_RESPONSE = 'PROFILE/SET_AVATAR_RESPONSE';
export const SET_AVATAR_FAILURE = 'PROFILE/SET_AVATAR_FAILURE';

/**
 * @deprecated use hook instead {@link useSetAvatar}
 */
export const setAvatar = (file: File) =>
    createAsyncAction({
        request: (state: State) =>
            createAction(SET_AVATAR, {
                me: selectors.profile.getProfileUser(state),
                file,
            }),

        response: async (request) => {
            let formData = new FormData();

            formData.append('file', file);

            const response: {
                UrlHttps: string;
            } = await api.postFormData('profile/setAvatar', formData);

            return createAction(SET_AVATAR_RESPONSE, {
                request,
                avatarUrl: response.UrlHttps,
            });
        },

        failure: (error) => createErrorAction(SET_AVATAR_FAILURE, error, {}),
    });

export const ADD_BETA_FEATURE = 'PROFILE/ADD_BETA_FEATURE';

export const addProfileBetaFeature = (newBetaFeature: domain.ProfileBetaFeature) => {
    return createAction(ADD_BETA_FEATURE, {
        newBetaFeature,
    });
};

export const REMOVE_BETA_FEATURE = 'PROFILE/REMOVE_BETA_FEATURE';

export const removeProfileBetaFeature = (featureToRemove: domain.ProfileBetaFeature) => {
    return createAction(REMOVE_BETA_FEATURE, {
        featureToRemove,
    });
};

export const APPLY_SAVED_PROFILE_CHANGES = 'PROFILE/APPLY_SAVED_PROFILE_CHANGES';
export const applySavedProfileChanges = (options: { newProfile: domain.Profile }) =>
    createAction(APPLY_SAVED_PROFILE_CHANGES, { ...options });

export const CHANGE_PASSWORD = 'PROFILE/CHANGE_PASSWORD';
export const CHANGE_PASSWORD_RESPONSE = 'PROFILE/CHANGE_PASSWORD_RESPONSE';
export const CHANGE_PASSWORD_FAILURE = 'PROFILE/CHANGE_PASSWORD_FAILURE';
export const changePassword = (isPasswordSet: boolean, newPassword: string, oldPassword = '') =>
    createAsyncAction({
        request: () => createAction(CHANGE_PASSWORD, {}),

        response: async (request) => {
            if (isPasswordSet) {
                await api.profile.updatePassword({
                    oldPwd: oldPassword,
                    newPwd: newPassword,
                });
            } else {
                await api.profile.setPassword({
                    pwd: newPassword,
                    timeZoneForEmail: window.ApprovalMax.userTimeZone,
                });
            }

            return createAction(CHANGE_PASSWORD_RESPONSE, {
                request,
            });
        },

        failure: (error) => createErrorAction(CHANGE_PASSWORD_FAILURE, error, {}),

        successToast: intl.formatMessage(messages.changePasswordMessage),
    });

export const CHANGE_TWO_FA_IS_ENABLED = 'PROFILE/CHANGE_TWO_FA_IS_ENABLED';
export const changeTwoFaIsEnabled = (twoFaIsEnabled: boolean, me: string) =>
    createAction(CHANGE_TWO_FA_IS_ENABLED, { twoFaIsEnabled, me });

export const CHANGE_TWO_FA_BACKUP_CODES_IS_ENABLED = 'PROFILE/CHANGE_TWO_FA_BACKUP_CODES_IS_ENABLED';
export const changeTwoFaBackupCodesIsEnabled = (isEnabled: boolean) =>
    createAction(CHANGE_TWO_FA_BACKUP_CODES_IS_ENABLED, { isEnabled });

export const CHANGE_TWO_FA_ALTERNATIVE_EMAIL_IS_ENABLED = 'PROFILE/CHANGE_TWO_FA_ALTERNATIVE_EMAIL_IS_ENABLED';
export const changeTwoFaAlternativeEmailIsEnabled = (isEnabled: boolean) =>
    createAction(CHANGE_TWO_FA_ALTERNATIVE_EMAIL_IS_ENABLED, { isEnabled });

export type Action = ExtractActions<
    | typeof addProfileBetaFeature
    | typeof applySavedProfileChanges
    | typeof changePassword
    | typeof changeProfilePopupModified
    | typeof changeTwoFaAlternativeEmailIsEnabled
    | typeof changeTwoFaBackupCodesIsEnabled
    | typeof changeTwoFaIsEnabled
    | typeof removeProfileBetaFeature
    | typeof setAvatar
    | typeof showProfilePopup
>;
