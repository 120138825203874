import { Box, Button, ColumnDefinition, Dropdown, List, Table, Text } from '@approvalmax/ui/src/components';
import { dateTimeHelpers, typeGuardHelpers } from '@approvalmax/utils';
import { selectors } from 'modules/common';
import { domain } from 'modules/data';
import { integrationActions } from 'modules/integration';
import { useDispatch, useSelector } from 'modules/react-redux';
import { useEffect, useMemo, useState } from 'react';
import { useDebounce } from 'react-use';
import { noValue } from 'shared/constants';
import { useGetGroupsOfXeroAccounts } from 'shared/data/v2';

import { getActiveTemplate } from '../../../../selectors/pageSelectors';
import { GroupOfXeroAccounts } from '../../GroupOfValuesPopup.types';
import { ActionsMenu } from '../ActionsMenu/ActionsMenu';
import { messages } from './PopupContent.messages';

export const useTableDefinition = (params: {
    companyId: string;
    isReadonly?: boolean;
    onEdit: (groupId: string) => void;
    onViewHistory: (groupId: string) => void;
}) => {
    const { companyId, onEdit, onViewHistory, isReadonly } = params;

    const tableDefinition: ColumnDefinition<GroupOfXeroAccounts>[] = [
        {
            id: 'groupName',
            name: messages.groupName,
            value: (item) => item.name,
            cellComponent: (props) => (
                <Table.Cell {...props}>
                    <Text font='label' fontSize='small' color={props.item.isArchived ? 'midnight60' : 'midnight100'}>
                        {props.item.name}
                    </Text>
                </Table.Cell>
            ),
            width: 200,
        },
        {
            id: 'status',
            name: messages.status,
            cellComponent: (props) => (
                <Table.Cell {...props}>
                    <Text font='label' fontSize='small' color={props.item.isArchived ? 'midnight60' : 'midnight100'}>
                        {props.item.isArchived ? messages.archived : messages.active}
                    </Text>
                </Table.Cell>
            ),
            width: 80,
        },
        {
            id: 'fieldType',
            name: messages.fieldType,
            cellComponent: (props) => (
                <Table.Cell {...props}>
                    <Text font='label' fontSize='small' color={props.item.isArchived ? 'midnight60' : 'midnight100'}>
                        {messages.account}
                    </Text>
                </Table.Cell>
            ),
        },
        {
            id: 'values',
            name: messages.values,
            headerTextAlign: 'center',
            textAlign: 'center',
            cellComponent: (props) => {
                const valuesLength = props.item.latestVersion?.values.length;

                return (
                    <Table.Cell {...props}>
                        {valuesLength ? (
                            <Dropdown
                                width='max-content'
                                activator={
                                    <Button size='small' link>
                                        {valuesLength}
                                    </Button>
                                }
                            >
                                <Box spacing='8'>
                                    <List listStyle='inside'>
                                        {(props.item.latestVersion?.values || []).map((field) => (
                                            <List.Item key={field.fieldValueId}>{field.value}</List.Item>
                                        ))}
                                    </List>
                                </Box>
                            </Dropdown>
                        ) : (
                            <Text
                                font='label'
                                fontSize='small'
                                color={props.item.isArchived ? 'midnight60' : 'midnight100'}
                            >
                                0
                            </Text>
                        )}
                    </Table.Cell>
                );
            },
        },
        {
            id: 'lastModified',
            name: messages.lastModified,
            textAlign: 'center',
            headerTextAlign: 'center',
            cellComponent: (props) => (
                <Table.Cell {...props}>
                    <Text font='label' fontSize='small' color={props.item.isArchived ? 'midnight60' : 'midnight100'}>
                        {props.item.latestVersion?.createdAt
                            ? dateTimeHelpers.formatDateTime(props.item.latestVersion.createdAt, 'DateAndTime')
                            : noValue}
                    </Text>
                </Table.Cell>
            ),
        },
        {
            id: 'modifiedBy',
            name: messages.modifiedBy,
            textAlign: 'center',
            headerTextAlign: 'center',
            cellComponent: (props) => {
                const author = props.item.latestVersion?.author;

                const displayName = author
                    ? selectors.user.getDisplayName(author.firstName, author.lastName, author.userEmail || '')
                    : noValue;

                return (
                    <Table.Cell {...props}>
                        <Text
                            font='label'
                            fontSize='small'
                            color={props.item.isArchived ? 'midnight60' : 'midnight100'}
                        >
                            {displayName}
                        </Text>
                    </Table.Cell>
                );
            },
        },
        {
            id: 'workflows',
            name: messages.workflows,
            cellComponent: (props) => {
                const workflowsLength = props.item.workflows?.length;

                return (
                    <Table.Cell {...props}>
                        {workflowsLength ? (
                            <Dropdown
                                width='max-content'
                                activator={
                                    <Button size='small' link>
                                        {workflowsLength}
                                    </Button>
                                }
                            >
                                <Box spacing='8'>
                                    <List listStyle='inside'>
                                        {props.item.workflows.map((workflow) => (
                                            <List.Item key={workflow.id}>
                                                {selectors.template.getTemplateWorkflowNameByCode(
                                                    typeGuardHelpers.isValueOfObject(
                                                        workflow.integrationCode,
                                                        domain.IntegrationCode
                                                    )
                                                        ? workflow.integrationCode
                                                        : null
                                                )}
                                            </List.Item>
                                        ))}
                                    </List>
                                </Box>
                            </Dropdown>
                        ) : (
                            <Text
                                font='label'
                                fontSize='small'
                                color={props.item.isArchived ? 'midnight60' : 'midnight100'}
                            >
                                0
                            </Text>
                        )}
                    </Table.Cell>
                );
            },
            textAlign: 'center',
            headerTextAlign: 'center',
        },
        {
            id: 'actions',
            name: '',
            cellComponent: (props) => (
                <Table.Cell {...props}>
                    <ActionsMenu
                        companyId={companyId}
                        onEdit={onEdit}
                        onViewHistory={onViewHistory}
                        group={props.item}
                        isReadonly={isReadonly}
                    />
                </Table.Cell>
            ),
            spacing: '0' as const,
            textAlign: 'center',
            width: 40,
        },
    ];

    return tableDefinition;
};

export const useLoadFields = () => {
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(true);
    const template = useSelector(getActiveTemplate);

    useEffect(() => {
        const load = async (companyId: string, integrationCode: domain.IntegrationCode | null) => {
            await dispatch(integrationActions.loadTemplateFieldsByCode({ companyId, integrationCode }));
            setIsLoading(false);
        };

        if (template?.companyId) {
            void load(template.companyId, template.integrationCode);
        }
    }, [dispatch, template?.companyId, template?.integrationCode]);

    return { isLoading };
};

export const useFilteredGroupsOfXeroAccounts = (searchValue: string, companyId?: string) => {
    const { data, isLoading } = useGetGroupsOfXeroAccounts({ path: { companyId } });

    const [filteredGroups, setFilteredGroups] = useState<GroupOfXeroAccounts[]>(data?.items || []);

    useEffect(() => {
        setFilteredGroups(data?.items || []);
    }, [data?.items]);

    const allGroups = useMemo(() => data?.items || [], [data?.items]);

    useDebounce(
        () => {
            const newFilteredGroups = allGroups.filter((group) =>
                group.name.toLocaleLowerCase().includes(searchValue.trim().toLocaleLowerCase())
            );

            setFilteredGroups(newFilteredGroups);
        },
        600,
        [searchValue, allGroups]
    );

    return { isLoading, filteredGroups, isBackendEmpty: allGroups.length === 0, allGroups };
};
