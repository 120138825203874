import { clsx } from 'clsx';
import { FC, memo, useMemo } from 'react';

import { Image, Root } from './Avatar.styles';
import { AvatarProps } from './Avatar.types';

/**
 * Avatars can be used to represent people or objects. It supports images, Icons, or letters.
 */
export const Avatar: FC<AvatarProps> = memo((props) => {
    const {
        alt,
        title,
        src,
        size = 32,
        color = 'green100',
        shape = 'circle',
        children,
        className = '',
        ...restProps
    } = props;

    const initials = useMemo(
        () =>
            !children &&
            !src &&
            alt &&
            alt
                .split(' ')
                .slice(0, 2)
                .reduce<string[]>((acc, item) => {
                    acc.push(item.substring(0, 1));

                    return acc;
                }, [])
                .join(''),
        [alt, children, src]
    );

    return (
        <>
            {src ? (
                <Image
                    className={clsx(className, 'fs-exclude')}
                    title={title}
                    src={src}
                    alt={alt}
                    $size={size}
                    $shape={shape}
                    {...restProps}
                />
            ) : (
                <Root
                    className={clsx(className, 'fs-exclude')}
                    title={title}
                    $size={size}
                    $color={color}
                    $shape={shape}
                    {...restProps}
                >
                    {children || initials}
                </Root>
            )}
        </>
    );
});

Avatar.displayName = 'Avatar';
