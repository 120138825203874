import { forwardRef, memo, useCallback, useEffect } from 'react';
import { useMount } from 'react-use';
import { useRecoilState, useSetRecoilState } from 'recoil';

import { Flex } from '../../../Flex/Flex';
import { WeekDayPickerProps } from '../../WeekDayPicker.types';
import { Option } from '../Option/Option';
import { labels, options } from './Content.constants';
import { weekDayPickerRefState, weekDayPickerValuesState } from './Content.states';
import { WeekDayPickerValue } from './Content.types';

export const Content = memo(
    forwardRef<HTMLInputElement, WeekDayPickerProps>((props, ref) => {
        const { className, qa, onChange, name, invalid, required, disabled, value = [] } = props;

        const setRef = useSetRecoilState(weekDayPickerRefState);
        const [selectedOptions, setSelectedOptions] = useRecoilState(weekDayPickerValuesState);

        useMount(() => {
            setRef(ref);
            setSelectedOptions(value);
        });

        useEffect(() => {
            onChange?.(selectedOptions);
        }, [onChange, selectedOptions]);

        const checkIsDisabled = useCallback(
            (option: WeekDayPickerValue) => {
                const isDisabledAll = disabled === true;
                const isDisabledCurrent = Array.isArray(disabled) && disabled.includes(option);

                return isDisabledAll || isDisabledCurrent;
            },
            [disabled]
        );

        return (
            <Flex className={className} alignItems='center' justifyContent='center' spacing='8' wrap={false}>
                {options.map((option) => (
                    <Option
                        key={option}
                        name={name}
                        value={option}
                        text={labels[option]}
                        disabled={checkIsDisabled(option)}
                        invalid={invalid}
                        required={required}
                        qa={qa}
                    />
                ))}
            </Flex>
        );
    })
);

Content.displayName = 'Content';
