export const authApiPaths = <const>{
    extendSession: 'Auth/extendSession',
    cannyIoToken: 'Auth/getCannyIoToken',
    xeroOAuthUrl: 'Auth/getXeroOAuthUrl',
    qBooksOAuthUrl: 'Auth/getQBooksOAuthUrl',
    googleOAuthUrl: 'Auth/getGoogleOAuthUrl',
    microsoftOAuthUrl: 'Auth/getMicrosoftOAuthUrl',
    logout: 'Auth/logout',
    register: 'Auth/register',
    resetPassword: 'Auth/resetPwd',
    registerViaGoogle: 'Auth/registerViaGoogleOAuth',
    registerViaMicrosoft: 'Auth/registerViaMicrosoftOAuth',
    registerViaQBooks: 'Auth/registerViaQBooksOAuth',
    registerViaXero: 'Auth/registerViaXeroOAuth',
    loginViaGoogle: 'Auth/loginViaGoogleOAuth',
    loginViaMicrosoft: 'Auth/loginViaMicrosoftOAuth',
    loginViaQBooks: 'Auth/loginViaQBooksOAuth',
    loginViaXero: 'Auth/loginViaXeroOAuth',
};
