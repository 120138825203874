import { z } from 'zod';

import { fieldNameNotificationsSection } from './SectionNotifications.constants';

export const schemaSectionNotifications = z.object({
    [fieldNameNotificationsSection.newRequests]: z.boolean(),
    [fieldNameNotificationsSection.comments]: z.boolean(),
    [fieldNameNotificationsSection.other]: z.boolean(),
    [fieldNameNotificationsSection.workflow]: z.boolean(),
    [fieldNameNotificationsSection.summary]: z.boolean(),
    [fieldNameNotificationsSection.summaryDay]: z.array(z.string()),
    [fieldNameNotificationsSection.summaryTime]: z.string().trim(),
});
