import { PascalCaseToCamelCase } from '@approvalmax/types';
import { compareHelpers, errorHelpers, objectHelpers, typeGuardHelpers } from '@approvalmax/utils';
import { statics } from 'modules/common';
import { domain, du } from 'modules/data';
import { isSalesInvoiceLineItemAnswer } from 'pages/requestForm/utils/typeGuards';
import { ApiTypes } from 'shared/data/v2';
import { emailToSupplierHelpers } from 'shared/helpers';

import * as backend from '../../backend';
import { getBeautifiedEntity } from '../../utils';
import { IntegrationCode } from '../Integration';
import {
    LineAmountType,
    QBooksDiscountType,
    QBooksExpenseDetails,
    QBooksRequestSpecifics,
    QBooksSalesInvoiceDetails,
    QBooksVendorDetails,
    RequestAttachment,
} from '../Request';
import {
    QBooksAccountLineItem,
    QBooksBaseLineItem,
    QBooksBillDetails,
    QBooksCustomField,
    QBooksJournalEntryDetails,
    QBooksLineItem,
    QBooksPurchaseOrderDetails,
    QBooksSharedDetails,
    QBooksTaxComponent,
} from '../Request.QBooks';
import { mapAttachment } from './requestSchema';
import { mapReference, mapReferenceWithValue } from './utils';

const mapCustomField = (value: backend.IntegrationsQBooksCustomField): QBooksCustomField => {
    return {
        field: mapReference(value)!,
        value: value.Value!,
    };
};

const mapCustomFieldV2 = (
    customField: PascalCaseToCamelCase<backend.IntegrationsQBooksCustomField>
): QBooksCustomField | null => {
    const field = mapReference(customField);

    if (!field) {
        return null;
    }

    return {
        field,
        value: customField.value,
    };
};

const mapTaxComponent = (value: backend.IntegrationsQBooksTaxComponent): QBooksTaxComponent => {
    return {
        taxAmount: value.TaxAmount,
        taxPercent: value.TaxPercent,
        taxableAmount: value.TaxableAmount,
        taxRateId: value.TaxRateId!,
        hidden: value.Hidden,
    };
};

const mapTaxComponentV2 = (
    value: PascalCaseToCamelCase<backend.IntegrationsQBooksTaxComponent>
): QBooksTaxComponent => {
    return {
        taxAmount: value.taxAmount,
        taxPercent: value.taxPercent,
        taxableAmount: value.taxableAmount,
        taxRateId: value.taxRateId,
        hidden: value.hidden,
    };
};

const mapBaseLineItem = <LineItemAnswer extends PascalCaseToCamelCase<backend.IntegrationsQBooksLineItemAnswer>>(
    value: LineItemAnswer
) => {
    return {
        ...value,
        id: value.lineItemId || '',
        description: value.description || '',
        taxCode: value.taxCode ? mapReference(value.taxCode) ?? undefined : undefined,
        customer: value.customer ? mapReference(value.customer) ?? undefined : undefined,
        class: value.class ? mapReference(value.class) ?? undefined : undefined,
    };
};

const mapBaseLineItemV2 = (
    value:
        | PascalCaseToCamelCase<backend.IntegrationsQBooksLineItemAnswer>
        | ApiTypes['IntegrationsQBooksItemBasedLineAnswerCamelCased']
): QBooksBaseLineItem => {
    return {
        id: value.lineItemId ?? '',
        description: value.description ?? '',
        amount: value.amount,
        taxCode: value.taxCode
            ? {
                  id: value.taxCode.id || '',
                  text: value.taxCode.name || '',
              }
            : undefined,
        customer: value.customer ? mapReference(value.customer) ?? undefined : undefined,
        class: value.class ? mapReference(value.class) ?? undefined : undefined,
        isBillable: value.isBillable,
        isTaxable: value.isTaxable,
        isMatched: value.isMatched ?? false,
        matchedLineItemId: 'matchedLineItemId' in value ? value.matchedLineItemId : undefined,
        remainingBalance: value.remainingBalance,
    };
};

export const mapLineItem = <
    LineItemAnswer extends
        | PascalCaseToCamelCase<backend.IntegrationsQBooksItemBasedLineAnswer>
        | PascalCaseToCamelCase<backend.IntegrationsQBooksSalesInvoiceItemBasedLineAnswer>,
>(
    value: LineItemAnswer
): LineItemAnswer extends PascalCaseToCamelCase<backend.IntegrationsQBooksItemBasedLineAnswer>
    ? Omit<QBooksLineItem, 'discount' | 'discountAmount' | 'serviceDate'>
    : Omit<QBooksLineItem, 'isInventory'> => {
    const common = {
        ...mapBaseLineItem(value),
        defaultRate: value.catalogUnitPrice ?? null,
        item: value.itemId
            ? {
                  id: value.itemId,
                  text: value.item || '',
              }
            : undefined,
        qty: value.qty,
        unitPrice: value.unitPrice,
        unitPriceGross: value.unitPriceGross || value.unitPrice,
    };

    return (isSalesInvoiceLineItemAnswer(value)
        ? {
              ...common,
              discount: value.discount ?? null,
              discountAmount: value.discountAmount ?? null,
              serviceDate: value.serviceDate ?? null,
          }
        : {
              ...common,
              isInventory: value.isInventory ?? false,
              account: value.accountId
                  ? {
                        id: value.accountId,
                        text: value.account || '',
                    }
                  : undefined,
          }) as unknown as LineItemAnswer extends PascalCaseToCamelCase<backend.IntegrationsQBooksItemBasedLineAnswer>
        ? Omit<QBooksLineItem, 'discount' | 'discountAmount' | 'serviceDate'>
        : Omit<QBooksLineItem, 'isInventory'>;
};

export const isSalesInvoiceLineItemAnswerV2 = (
    lineItem:
        | PascalCaseToCamelCase<
              backend.IntegrationsQBooksItemBasedLineAnswer | backend.IntegrationsQBooksSalesInvoiceItemBasedLineAnswer
          >
        | ApiTypes['IntegrationsQBooksItemBasedLineAnswerCamelCased']
): lineItem is PascalCaseToCamelCase<backend.IntegrationsQBooksSalesInvoiceItemBasedLineAnswer> => {
    return 'discount' in lineItem || 'discountAmount' in lineItem || 'serviceDate' in lineItem;
};

export const mapLineItemV2 = (
    value:
        | PascalCaseToCamelCase<
              backend.IntegrationsQBooksItemBasedLineAnswer | backend.IntegrationsQBooksSalesInvoiceItemBasedLineAnswer
          >
        | ApiTypes['IntegrationsQBooksItemBasedLineAnswerCamelCased']
): QBooksLineItem => {
    const common = {
        ...mapBaseLineItemV2(value),
        defaultRate: value.catalogUnitPrice ?? null,
        item: value.itemId
            ? {
                  id: value.itemId,
                  text: value.item ?? '',
              }
            : undefined,
        qty: value.qty,
        unitPrice: value.unitPrice,
        unitPriceGross: value.unitPriceGross || value.unitPrice,
    };

    return isSalesInvoiceLineItemAnswerV2(value)
        ? {
              ...common,
              discount: value.discount ?? null,
              discountAmount: value.discountAmount ?? null,
              serviceDate: value.serviceDate ?? null,
          }
        : {
              ...common,
              isInventory: value.isInventory ?? false,
          };
};

export const mapAccountLineItem = (value: PascalCaseToCamelCase<backend.IntegrationsQBooksAccountBasedLineAnswer>) => {
    return {
        ...mapBaseLineItem(value),
        account:
            value.accountId && value.account
                ? {
                      id: value.accountId,
                      text: value.account,
                  }
                : undefined,
    };
};

export const mapAccountLineItemV2 = (
    value:
        | PascalCaseToCamelCase<backend.IntegrationsQBooksAccountBasedLineAnswer>
        | ApiTypes['IntegrationsQBooksAccountBasedLineAnswerCamelCased']
): QBooksAccountLineItem => {
    return {
        ...mapBaseLineItemV2(value),
        account: value.accountId
            ? {
                  id: value.accountId,
                  text: value.account || '',
              }
            : undefined,
    };
};

const mapSharedDetails = (value: any): QBooksSharedDetails => {
    return {
        number: '', // To be overridden later
        date: value.Date!,
        vendor: mapReference(value.Vendor)!,
        department: mapReference(value.Department),
        departmentTerminology: value.DepartmentTerminology === undefined ? null : value.DepartmentTerminology,
        lineItems: (value.LineItems || (value as any).ItemBasedLines || []).map((lineItem: any) =>
            mapLineItem(objectHelpers.pascalCaseToCamelCase(lineItem))
        ),
        accountLineItems: ((value as any).AccountBasedLines || []).map((lineItem: any) =>
            mapAccountLineItem(objectHelpers.pascalCaseToCamelCase(lineItem))
        ),
        memo: value.Memo!,
        mailingAddress: value.MailingAddress!,
        subTotal: value.SubTotal || 0,
        lineAmountType: value.LineAmountType as LineAmountType,
        totalTax: value.TotalTax || 0,
        taxComponents: (value.TaxComponents || []).map(mapTaxComponent),
        isStatusPushPending: value.IsStatusPushPending || value.isStatusPushPending || false,
        lockDate: value.LockDate || value.CompanyLockDate || null,
        lockDatePolicy: value.TemplateLockDatePolicy,
        url: value.Url || value.url || null,
        editUrl: value.EditUrl || value.editUrl || null,
    };
};

const mapSharedDetailsV2 = (
    value:
        | PascalCaseToCamelCase<
              | backend.IntegrationsQBooksPurchaseOrderAnswer
              | backend.IntegrationsQBooksBillInvoiceAnswer
              | backend.IntegrationsQBooksExpenseAnswer
          >
        | ApiTypes['IntegrationsQBooksPurchaseOrderAnswerCamelCased']
        | ApiTypes['IntegrationsQBooksBillInvoiceAnswerCamelCased']
        | ApiTypes['IntegrationsQBooksExpenseAnswerCamelCased']
): QBooksSharedDetails => {
    const lineItems = 'itemBasedLines' in value ? value.itemBasedLines : 'lineItems' in value ? value.lineItems : [];

    return {
        number: '',
        date: value.date || '',
        vendor: mapReference(value.vendor),
        department: mapReference(value.department),
        departmentTerminology: value.departmentTerminology ?? null,
        lineItems: (lineItems || []).map(mapLineItemV2),
        accountLineItems: (value.accountBasedLines || []).map(mapAccountLineItemV2),
        memo: value.memo ?? '',
        mailingAddress: value.mailingAddress ?? '',
        subTotal: value.subTotal || 0,
        lineAmountType: value.lineAmountType as LineAmountType,
        totalTax: value.totalTax || 0,
        taxComponents: (value.taxComponents || []).map(mapTaxComponentV2),
        isStatusPushPending: value.isStatusPushPending || false,
        lockDate: value.lockDate || value.companyLockDate || null,
        lockDatePolicy: value.templateLockDatePolicy,
        url: value.url || null,
        editUrl: value.editUrl || null,
    };
};

const mapPurchaseOrder = (
    value: backend.IntegrationsQBooksPurchaseOrderAnswer,
    emailToContact?: backend.EmailToContact
): QBooksPurchaseOrderDetails => {
    if (!value) {
        throw errorHelpers.formatError('[requestSchema.QBooks] Purchase Order details is undefined.');
    }

    const shared = mapSharedDetails(value);

    return {
        ...shared,
        integrationCode: domain.IntegrationCode.QBooksPo,
        integrationType: domain.IntegrationType.QBooks,
        number: value.PurchaseOrderNumber,
        vendorMessage: value.VendorMessage ?? '',
        customFields: (value.CustomFields || []).map(mapCustomField),
        shipping: {
            method: value.ShippingMethod!,
            address: value.ShippingAddress!,
        },
        sendToSupplier: emailToContact?.EmailHasToBeSent || false,
        emailToSupplier: {
            emailHasToBeSent: emailToContact?.EmailHasToBeSent || false,
            from: emailToContact?.EmailFrom || '',
            replyTo: emailToContact?.EmailReplyTo || '',
            to: (emailToContact?.EmailTo || '').split(',').filter(Boolean),
            cc: (emailToContact?.EmailCC || '').split(',').filter(Boolean),
            subject: emailToSupplierHelpers.escapeExpressions(emailToContact?.EmailSubject || ''),
            body: emailToContact?.EmailBody || '',
            attachments: (emailToContact?.Attachments || [])
                .map((attachment) => mapAttachment(attachment))
                .sort(compareHelpers.comparatorFor<RequestAttachment>(compareHelpers.stringComparator2Asc, 'name')),
        },
        status: value.Status || null,
        grnStatus: value.GrnStatus || null,
    };
};

export const mapQboPurchaseOrderV2 = (
    value:
        | PascalCaseToCamelCase<backend.IntegrationsQBooksPurchaseOrderAnswer>
        | ApiTypes['IntegrationsQBooksPurchaseOrderAnswerCamelCased'],
    emailToContact?:
        | PascalCaseToCamelCase<backend.EmailToContact>
        | ApiTypes['V2RequestsRequestAnswerCamelCased']['emailToContact']
): QBooksPurchaseOrderDetails => {
    if (!value) {
        throw errorHelpers.formatError('[requestSchema.QBooks] Purchase Order details is undefined.');
    }

    const shared = mapSharedDetailsV2(value);

    return {
        ...shared,
        integrationCode: domain.IntegrationCode.QBooksPo,
        integrationType: domain.IntegrationType.QBooks,
        number: value.purchaseOrderNumber,
        vendorMessage: value.vendorMessage ?? '',
        customFields: (value.customFields || []).map(mapCustomFieldV2).filter(typeGuardHelpers.isTruthy),
        shipping: {
            method: value.shippingMethod ?? '',
            address: value.shippingAddress ?? '',
        },
        sendToSupplier: emailToContact?.emailHasToBeSent ?? false,
        emailToSupplier: {
            emailHasToBeSent: emailToContact?.emailHasToBeSent ?? false,
            from: emailToContact?.emailFrom ?? '',
            replyTo: emailToContact?.emailReplyTo ?? '',
            to: (emailToContact?.emailTo ?? '').split(',').filter(Boolean),
            cc: (emailToContact?.emailCC ?? '').split(',').filter(Boolean),
            subject: emailToSupplierHelpers.escapeExpressions(emailToContact?.emailSubject || ''),
            body: emailToContact?.emailBody ?? '',
            attachments: (emailToContact?.attachments || [])
                .map((attachment) => mapAttachment(attachment))
                .sort(compareHelpers.comparatorFor<RequestAttachment>(compareHelpers.stringComparator2Asc, 'name')),
        },
        status: value.status || null,
        grnStatus: value.grnStatus || null,
    };
};

const mapSalesInvoice = (
    value: backend.IntegrationsQBooksInvoiceAnswer,
    emailToContact?: backend.EmailToContact
): QBooksSalesInvoiceDetails => {
    if (!value) {
        throw errorHelpers.formatError('[requestSchema.QBooks] Sales Invoice details is undefined.');
    }

    return {
        applyTaxAfterDiscount: value.ApplyTaxAfterDiscount || false,
        balance: value.Balance,
        billingAddress: value.BillingAddress ?? '',
        customFields: (value.CustomFields || []).map(mapCustomField),
        customer: mapReference(value.Customer),
        customerMemo: value.CustomerMemo ?? '',
        date: value.Date,
        department: mapReference(value.Department),
        deposit: value.Deposit,
        discount: 'Discount' in value ? value.Discount : value.DiscountAmount,
        discountType: 'Discount' in value ? QBooksDiscountType.Percent : QBooksDiscountType.Amount,
        dueDate: value.DueDate ?? '',
        editUrl: value.EditUrl || null,
        integrationCode: domain.IntegrationCode.QBooksInvoice,
        integrationType: domain.IntegrationType.QBooks,
        isPaid: value.IsPaid || false,
        isStatusPushPending: value.IsStatusPushPending || false,
        lineAmountType: value.LineAmountType,
        lineItems: (value.LineItems || []).map((lineItem) =>
            mapLineItem(objectHelpers.pascalCaseToCamelCase(lineItem))
        ),
        lockDate: value.LockDate || value.CompanyLockDate || null,
        lockDatePolicy: value.TemplateLockDatePolicy,
        memo: value.Memo ?? '',
        number: value.SalesInvoiceNumber,
        shipping: {
            method: value.ShippingMethod ?? '',
            address: value.ShippingAddress ?? '',
            date: value.ShippingDate ?? '',
            price: value.Shipping,
            fromAddress: value.ShippingFromAddress ?? '',
            trackingNumber: value.TrackingNumber ?? '',
        },
        shippingTaxAmount: value.ShippingTaxAmount,
        shippingTaxCode: mapReference(value.ShippingTaxCode),
        status: value.Status,
        subTotal: value.SubTotal || 0,
        taxCode: mapReference(value.TaxCode),
        taxComponents: (value.TaxComponents || []).map(mapTaxComponent),
        term: mapReference(value.Term),
        totalTax: value.TotalTax || 0,
        url: value.Url || null,
        useAutoTaxes: value.UseAutoTaxes ?? false,

        emailToContact: {
            emailHasToBeSent: emailToContact?.EmailHasToBeSent || false,
            from: emailToContact?.EmailFrom || '',
            replyTo: emailToContact?.EmailReplyTo || '',
            to: (emailToContact?.EmailTo || '').split(',').filter(Boolean),
            cc: (emailToContact?.EmailCC || '').split(',').filter(Boolean),
            subject: emailToSupplierHelpers.escapeExpressions(emailToContact?.EmailSubject || ''),
            body: emailToContact?.EmailBody || '',
            attachments: (emailToContact?.Attachments || [])
                .map((attachment) => mapAttachment(attachment))
                .sort(compareHelpers.comparatorFor<RequestAttachment>(compareHelpers.stringComparator2Asc, 'name')),
        },
    };
};

const mapBill = (value: backend.IntegrationsQBooksBillInvoiceAnswer): QBooksBillDetails => {
    if (!value) {
        throw errorHelpers.formatError('[requestSchema.QBooks] Bill details is undefined.');
    }

    const shared = mapSharedDetails(value);

    return {
        ...shared,
        integrationCode: domain.IntegrationCode.QBooksBill,
        integrationType: domain.IntegrationType.QBooks,
        number: value.BillInvoiceNumber,
        dueDate: value.DueDate!,
        term: mapReference(value.Term),
        isPaid: value.IsPaid,
    };
};

export const mapQboBillV2 = (
    value:
        | PascalCaseToCamelCase<backend.IntegrationsQBooksBillInvoiceAnswer>
        | ApiTypes['IntegrationsQBooksBillInvoiceAnswerCamelCased']
): QBooksBillDetails => {
    if (!value) {
        throw errorHelpers.formatError('[requestSchema.QBooks] Bill details is undefined.');
    }

    const shared = mapSharedDetailsV2(value);

    return {
        ...shared,
        integrationCode: domain.IntegrationCode.QBooksBill,
        integrationType: domain.IntegrationType.QBooks,
        number: value.billInvoiceNumber,
        dueDate: value.dueDate || '',
        term: mapReference(value.term),
        isPaid: value.isPaid,
    };
};

const mapExpense = (value: any): QBooksExpenseDetails => {
    if (!value) {
        throw errorHelpers.formatError('[requestSchema.QBooks] Expense details is undefined.');
    }

    const shared = mapSharedDetails(value);

    return {
        ...shared,
        integrationCode: domain.IntegrationCode.QBooksExpense,
        integrationType: domain.IntegrationType.QBooks,
        payee: mapReference(value.Payee) || null,
        payeeType: mapReferenceWithValue<backend.IntegrationsQBooksPayeeTypes>(value.PayeeType) || null,
        paymentAccount: mapReference(value.PaymentAccount) || null,
        paymentType: mapReference(value.PaymentType) || null,
        paymentMethod: mapReference(value.PaymentMethod) || null,
        expenseNumber: value.ExpenseNumber,
        newCreatedVendor: null,
    };
};

export const mapQboExpenseV2 = (
    value:
        | PascalCaseToCamelCase<backend.IntegrationsQBooksExpenseAnswer>
        | ApiTypes['IntegrationsQBooksExpenseAnswerCamelCased']
): QBooksExpenseDetails => {
    if (!value) {
        throw errorHelpers.formatError('[requestSchema.QBooks] Expense details is undefined.');
    }

    const shared = mapSharedDetailsV2(value);

    return {
        ...shared,
        integrationCode: domain.IntegrationCode.QBooksExpense,
        integrationType: domain.IntegrationType.QBooks,
        payee: mapReference(value.payee),
        payeeType: mapReferenceWithValue<backend.IntegrationsQBooksPayeeTypes | string>(value.payeeType),
        paymentAccount: mapReference(value.paymentAccount),
        paymentType: mapReference<backend.IntegrationQBooksPaymentTypeId | string>(value.paymentType),
        paymentMethod: mapReference(value.paymentMethod),
        expenseNumber: value.expenseNumber || '',
        newCreatedVendor: null,
    };
};

const mapVendor = (value: any) => {
    if (!value) {
        throw errorHelpers.formatError('[requestSchema.QBooks] Bill details is undefined.');
    }

    return {
        ...mapSharedDetails(value),
        ...getBeautifiedEntity(value),
        address: getBeautifiedEntity(value.address),
        paymentDetails: value.paymentDetails ? getBeautifiedEntity(value.paymentDetails) : null,
        currencyCode: statics.currency.findCurrencyByCode(value.currencyCode || ''),
    } as unknown as QBooksVendorDetails;
};

const mapJournalEntry = (value: backend.IntegrationsQBooksJournalEntryAnswer): QBooksJournalEntryDetails => {
    if (!value) {
        throw errorHelpers.formatError('[requestSchema.QBooks] Journal Entry details is undefined.');
    }

    return {
        integrationCode: domain.IntegrationCode.QBooksJournalEntry,
        integrationType: domain.IntegrationType.QBooks,
        date: value.Date || '',
        number: value.Number || '',
        memo: value.Memo || '',
        lineAmountType: (value.LineAmountType as LineAmountType) || domain.LineAmountType.TaxExclusive,
        debitSubTotal: value.DebitSubTotal || 0,
        creditSubTotal: value.CreditSubTotal || 0,
        debitTotalTax: value.DebitTotalTax || 0,
        creditTotalTax: value.CreditTotalTax || 0,
        lines: (value.Lines || []).map((line) => ({
            id: line.LineId,
            account: line.Account ? du.parseServerReference(line.Account) : null,
            amount: line.Amount,
            amountType: line.AmountSide,
            description: line.Description,
            payeeType: line.PayeeType
                ? {
                      id: line.PayeeType,
                      text: line.PayeeType,
                  }
                : null,
            payee: line.Payee ? du.parseServerReference(line.Payee) : null,
            taxCode: line.TaxCode ? du.parseServerReference(line.TaxCode) : null,
            taxRateId: line.TaxRate?.TaxRateId || null,
            taxApplicableOnType: line.TaxApplicableOnType || domain.TaxApplicableOnType.None,
            class: line.Class ? du.parseServerReference(line.Class) : null,
            department: line.Department ? du.parseServerReference(line.Department) : null,
        })),
        taxComponents: value.TaxComponents.map((taxComponent) => ({
            taxableAmount: taxComponent.TaxableAmount,
            taxApplicableOnType: taxComponent.TaxApplicableOnType,
            taxCodeId: taxComponent.TaxCodeId,
            taxCodeName: taxComponent.TaxCodeName,
            taxDebitAmount: taxComponent.TaxDebitAmount,
            taxCreditAmount: taxComponent.TaxCreditAmount,
            taxRateId: taxComponent.TaxRateId,
            taxPercent: taxComponent.TaxPercent,
            taxAmount: taxComponent.TaxAmount,
            hidden: taxComponent.Hidden,
        })),
        url: value.Url || null,
        editUrl: value.EditUrl || null,
        isStatusPushPending: value.IsStatusPushPending || false,
        lockDatePolicy: value.TemplateLockDatePolicy,
        lockDate: value.LockDate || value.CompanyLockDate || null,
    };
};

export const getQbooksBase = (request: backend.RequestAnswer): QBooksRequestSpecifics => {
    switch (request.IntegrationCode) {
        case IntegrationCode.QBooksPo:
            return {
                details: mapPurchaseOrder(request.QBooksPurchaseOrderDetails, request.EmailToContact),
                integrationCode: request.IntegrationCode,
                integrationType: domain.IntegrationType.QBooks,
            };

        case IntegrationCode.QBooksInvoice:
            return {
                details: mapSalesInvoice(request.QBooksSalesInvoiceDetails, request.EmailToContact),
                integrationCode: request.IntegrationCode,
                integrationType: domain.IntegrationType.QBooks,
            };

        case IntegrationCode.QBooksBill:
            return {
                details: mapBill(request.QBooksBillInvoiceDetails),
                integrationCode: request.IntegrationCode,
                integrationType: domain.IntegrationType.QBooks,
            };

        case IntegrationCode.QBooksExpense:
            return {
                details: mapExpense(request.QBooksExpenseDetails),
                integrationCode: request.IntegrationCode,
                integrationType: domain.IntegrationType.QBooks,
            };

        case IntegrationCode.QBooksVendor:
            return {
                details: mapVendor(request.QBooksVendorDetails),
                integrationCode: request.IntegrationCode,
                integrationType: domain.IntegrationType.QBooks,
            };

        case IntegrationCode.QBooksJournalEntry:
            return {
                details: mapJournalEntry(request.QBooksJournalEntryDetails),
                integrationCode: request.IntegrationCode,
                integrationType: domain.IntegrationType.QBooks,
            };

        default:
            throw errorHelpers.notSupportedError();
    }
};
