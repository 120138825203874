import { domain } from 'modules/data';
import { findDocumentFieldState } from 'shared/helpers';

export const useNetSuiteVendorFieldSettings = (documentFields: domain.DocumentField[]) => ({
    email: findDocumentFieldState(documentFields, 'Email'),
    currency: findDocumentFieldState(documentFields, 'Currency'),
    webAddress: findDocumentFieldState(documentFields, 'WebAddress'),
    salutation: findDocumentFieldState(documentFields, 'Salutation'),
    jobTitle: findDocumentFieldState(documentFields, 'JobTitle'),
    category: findDocumentFieldState(documentFields, 'Category'),
    comments: findDocumentFieldState(documentFields, 'Comments'),
    phone: findDocumentFieldState(documentFields, 'Phone'),
    altPhone: findDocumentFieldState(documentFields, 'AltPhone'),
    fax: findDocumentFieldState(documentFields, 'Fax'),
    legalName: findDocumentFieldState(documentFields, 'LegalName'),
    accountNumber: findDocumentFieldState(documentFields, 'AccountNumber'),
    defaultExpenseAccount: findDocumentFieldState(documentFields, 'DefaultExpenseAccount'),
    defaultPayablesAccount: findDocumentFieldState(documentFields, 'DefaultPayablesAccount'),
    terms: findDocumentFieldState(documentFields, 'Terms'),
    creditLimit: findDocumentFieldState(documentFields, 'CreditLimit'),
    incoterm: findDocumentFieldState(documentFields, 'Incoterm'),
    taxNumber: findDocumentFieldState(documentFields, 'TaxIdentificationNumber'),
    is1099Eligible: findDocumentFieldState(documentFields, 'Is1099Eligible'),
    isJobResource: findDocumentFieldState(documentFields, 'IsJobResource'),
    subsidiaries: findDocumentFieldState(documentFields, 'Subsidiaries'),
    currencies: findDocumentFieldState(documentFields, 'Currencies'),
    addressLinesCountry: findDocumentFieldState(documentFields, 'AddressLinesCountry'),
    addressLinesAttention: findDocumentFieldState(documentFields, 'AddressLinesAttention'),
    addressLinesAddressee: findDocumentFieldState(documentFields, 'AddressLinesAddressee'),
    addressLinesPhone: findDocumentFieldState(documentFields, 'AddressLinesPhone'),
    addressLinesPrimaryAddress: findDocumentFieldState(documentFields, 'AddressLinesPrimaryAddress'),
    addressLinesSecondaryAddress: findDocumentFieldState(documentFields, 'AddressLinesSecondaryAddress'),
    addressLinesState: findDocumentFieldState(documentFields, 'AddressLinesState'),
    addressLinesCity: findDocumentFieldState(documentFields, 'AddressLinesCity'),
    addressLinesZip: findDocumentFieldState(documentFields, 'AddressLinesZip'),
});
