import { AlertIcon, ChevronLeftIcon } from '@approvalmax/ui';
import { Alert, Button, Grid, List, Text } from '@approvalmax/ui/src/components';
import { FC, memo } from 'react';

import { messages } from './InviteTeammateContent.messages';
import { InviteTeammateContentProps } from './InviteTeammateContent.types';

export const InviteTeammateContent: FC<InviteTeammateContentProps> = memo((props) => {
    const { isLoading, onBack, onInviteTeammate } = props;

    return (
        <Grid gap={32}>
            <Grid gap={16}>
                <Text font='headline' fontSize='xxsmall'>
                    {messages.title}
                </Text>

                <Text font='body'>{messages.description}</Text>

                <Alert color='midnight20' size='medium' alignItems='center' startIcon={<AlertIcon />}>
                    {messages.alert}
                </Alert>
            </Grid>

            <Grid gap={8}>
                <Text font='label' fontSize='medium'>
                    {messages.adminAble}
                </Text>

                <Text font='body' fontSize='small'>
                    <Grid gap={8} gridTemplateColumns='repeat(2, 1fr)'>
                        <List listStyle='inside'>
                            <List.Item>{messages.adminAble1}</List.Item>

                            <List.Item>{messages.adminAble2}</List.Item>

                            <List.Item>{messages.adminAble3}</List.Item>
                        </List>

                        <List listStyle='inside'>
                            <List.Item>{messages.adminAble4}</List.Item>

                            <List.Item>{messages.adminAble5}</List.Item>

                            <List.Item>{messages.adminAble6}</List.Item>
                        </List>
                    </Grid>
                </Text>
            </Grid>

            <Grid gridTemplateColumns='max-content 1fr' gap={16}>
                <Button
                    size='large'
                    color='midnight40'
                    startIcon={<ChevronLeftIcon />}
                    onClick={onBack}
                    disabled={isLoading}
                >
                    {messages.buttonBack}
                </Button>

                <Button size='large' color='blue80' onClick={onInviteTeammate} progress={isLoading}>
                    {messages.buttonInvite}
                </Button>
            </Grid>
        </Grid>
    );
});

InviteTeammateContent.displayName = 'InviteTeammateContent';
