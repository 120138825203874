import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { hasChangesState } from 'shared/states';

export const useInitHistoryBlock = () => {
    const { block } = useHistory();

    const hasChanges = useRecoilValue(hasChangesState);

    useEffect(() => {
        const unblock = block(() => (hasChanges ? '' : undefined));

        return () => {
            unblock();
        };
    }, [block, hasChanges]);
};
