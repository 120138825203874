import { Reference } from '@approvalmax/types';
import { EditIcon } from '@approvalmax/ui';
import { Box, Button, Dropdown, Flex, Form, Popup, Spacing, Text, toast } from '@approvalmax/ui/src/components';
import { zodResolver } from '@hookform/resolvers/zod';
import { FC, memo, useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useToggle } from 'react-use';
import { UpdateGroupOfXeroAccountsVersions, useUpdateGroupOfXeroAccountsVersions } from 'shared/data/v2';
import { z } from 'zod';

import { AddCommentPopupContent } from '../AddCommentPopupContent/AddCommentPopupContent';
import { EditGroupName } from '../EditGroupName/EditGroupName';
import { SelectAccount } from '../SelectAccount/SelectAccount';
import { VersionConflictPopupContent } from '../VersionConflictPopupContent/VersionConflictPopupContent';
import { formFieldNames } from './EditingPopupContent.constants';
import { messages } from './EditingPopupContent.messages';
import { schema } from './EditingPopupContent.schema';
import { EditingPopupContentProps } from './EditingPopupContent.types';

export const EditingPopupContent: FC<EditingPopupContentProps> = memo((props) => {
    const { onClose, companyId, integrationCode, groupOfAccounts } = props;

    const [commentPopupOpen, toggleCommentPopupOpen] = useToggle(false);
    const [forceUpdateData, setForceUpdateData] = useState<UpdateGroupOfXeroAccountsVersions['body'] | null>(null);
    const [selectedItems, setSelectedItems] = useState<Reference[]>([]);
    const { mutate, isLoading } = useUpdateGroupOfXeroAccountsVersions();
    const [editNameOpen, setEditNameOpen] = useState(false);

    const form = useForm<z.infer<typeof schema>>({
        values: {
            values: groupOfAccounts.latestVersion?.values.map((value) => value.fieldValueId || '') || [],
        },
        resolver: zodResolver(schema),
        disabled: isLoading,
    });

    const values = form.watch('values');

    const formSubmit = form.handleSubmit(() => {
        toggleCommentPopupOpen(true);
    });

    const updateGroupOfAccounts = useCallback(
        (body: UpdateGroupOfXeroAccountsVersions['body']) => {
            const { id, name } = groupOfAccounts;

            mutate(
                { body, params: { path: { companyId: companyId, groupOfXeroAccountsId: id } } },
                {
                    onSuccess: () => {
                        toast.success(messages.successMessage({ groupName: name }));
                        setForceUpdateData(null);
                        onClose();
                    },
                    onError: (error, variables) => {
                        if (error.status === 409) {
                            setForceUpdateData(variables.body || null);
                        }
                    },
                }
            );
        },
        [groupOfAccounts, mutate, companyId, onClose]
    );

    const onSubmitComment = useCallback(
        (params: { comment: string }) => {
            const { comment } = params;
            const { latestVersion } = groupOfAccounts;

            toggleCommentPopupOpen(false);

            const preparedValues = selectedItems
                .filter((selectedItem) => values.includes(selectedItem.id))
                .map((selectedItem) => ({
                    fieldValueId: selectedItem.id,
                    value: selectedItem.text,
                }));

            updateGroupOfAccounts({
                comment,
                forceCreate: false,
                values: preparedValues,
                prevVersionNumber: latestVersion?.versionNumber,
            });
        },
        [values, groupOfAccounts, selectedItems, updateGroupOfAccounts, toggleCommentPopupOpen]
    );

    const handleClearValues = useCallback(() => {
        form.setValue('values', []);
    }, [form]);

    const closeOverwritePopup = useCallback(() => {
        setForceUpdateData(null);
    }, []);

    const onOverwrite = useCallback(() => {
        forceUpdateData && updateGroupOfAccounts({ ...forceUpdateData, forceCreate: true });
        setForceUpdateData(null);
    }, [forceUpdateData, updateGroupOfAccounts]);

    const onEditGroupNameSuccess = useCallback(() => {
        setEditNameOpen(false);
    }, []);

    return (
        <>
            <Form form={form} onSubmit={formSubmit}>
                <Popup.Header
                    title={messages.popupTitle({ groupName: groupOfAccounts.name })}
                    actions={
                        <Flex spacing='16' wrap={false}>
                            <Button
                                size='medium'
                                color='blue10'
                                disabled={values.length === 0 || isLoading}
                                onClick={handleClearValues}
                            >
                                {messages.clear}
                            </Button>

                            <Button size='medium' color='blue80' type='submit' progress={isLoading}>
                                {messages.save}
                            </Button>
                        </Flex>
                    }
                />

                <Popup.Body>
                    <Box height={300} overflowY='auto'>
                        <Flex justifyContent='space-between'>
                            <Flex spacing='0' alignItems='center' wrap={false} maxWidth={400}>
                                <Text
                                    color='midnight100'
                                    font='label'
                                    fontSize='medium'
                                    fontWeight='medium'
                                    spacing='0 12 0 0'
                                    ellipsis={1}
                                    title={groupOfAccounts.name}
                                >
                                    {groupOfAccounts.name}
                                </Text>

                                <Dropdown
                                    placement='bottom'
                                    width='max-content'
                                    onOpen={setEditNameOpen}
                                    open={editNameOpen}
                                    activator={
                                        <Button startIcon={<EditIcon />} color='blue10' size='xsmall'>
                                            {messages.edit}
                                        </Button>
                                    }
                                >
                                    <EditGroupName
                                        name={groupOfAccounts.name}
                                        companyId={companyId}
                                        groupOfXeroAccountsId={groupOfAccounts.id}
                                        onSuccess={onEditGroupNameSuccess}
                                    />
                                </Dropdown>
                            </Flex>

                            <Flex spacing='8'>
                                <Text font='label' fontSize='medium' color='midnight70'>
                                    {messages.fieldType}
                                </Text>

                                <Text font='label' fontSize='medium' color='midnight100'>
                                    {messages.account}
                                </Text>
                            </Flex>
                        </Flex>

                        <Spacing height={24} />

                        <SelectAccount
                            companyId={companyId}
                            integrationCode={integrationCode}
                            groupOfAccounts={groupOfAccounts}
                            isLoading={isLoading}
                            onChange={setSelectedItems}
                            name={formFieldNames.values}
                        />
                    </Box>
                </Popup.Body>
            </Form>

            <Popup open={commentPopupOpen} preventAutoClose onToggle={toggleCommentPopupOpen}>
                <AddCommentPopupContent onSubmit={onSubmitComment} />
            </Popup>

            <Popup closable={false} open={!!forceUpdateData} size='small' preventAutoClose>
                <VersionConflictPopupContent
                    open={!!forceUpdateData}
                    onClose={closeOverwritePopup}
                    onOverwrite={onOverwrite}
                />
            </Popup>
        </>
    );
});

EditingPopupContent.displayName = 'EditingPopupContent';
