import './fileList.scss';

import { miscHelpers } from '@approvalmax/utils';
import { FC, memo } from 'react';
import bemFactory from 'react-bem-factory';
import { useRecoilValue } from 'recoil';
import { ocrIsLoadingState } from 'shared/states';

import { useShowAttachment } from '../../../hooks/useShowAttachment';
import { RequestEditMode } from '../../../selectors/requestSelectors';
import { ocrAttachmentIdState } from '../../SplitViewWrapper/components';
import AddFileButton from '../AddFileButton';
import FileItem from '../FileItem/FileItem';
import { messages } from './FileList.messages';
import { FileListProps } from './FileList.types';

const bem = bemFactory.block('reqf-file-list');

const FileList: FC<FileListProps> = (props) => {
    const {
        className,
        attachments,
        newAttachments,
        readonly,
        attachmentKind,
        removeAttachment,
        editMode,
        maxFilesCount,
        customHandleClickOnAttachment,
        buttonTitle,
    } = props;

    const ocrAttachmentId = useRecoilValue(ocrAttachmentIdState);
    const ocrIsLoading = useRecoilValue(ocrIsLoadingState);

    const { showAttachment } = useShowAttachment({ customHandle: customHandleClickOnAttachment });

    const allAttachmentsCount = attachments.length + newAttachments.length;

    const isCountLimitExceeded = typeof maxFilesCount === 'number' ? allAttachmentsCount >= maxFilesCount : false;

    return (
        <div className={bem.add(className)()}>
            {attachments.map((attachment) => (
                <FileItem
                    fileName={attachment.name}
                    key={attachment.id}
                    readonly={
                        readonly ||
                        (ocrAttachmentId === attachment.id && ocrIsLoading) ||
                        Boolean(attachment.externalId) ||
                        (!attachment.isNew &&
                            (editMode === RequestEditMode.Editor || editMode === RequestEditMode.Approver))
                    }
                    onRemove={() => {
                        removeAttachment(attachment.id);
                    }}
                    loading={ocrAttachmentId === attachment.id && ocrIsLoading}
                    loadingText={messages.recognitionText}
                    onOpen={(newTab) => showAttachment(attachment, newTab)}
                    fileId={attachment.id}
                />
            ))}

            {newAttachments.map((attachment) => (
                <FileItem
                    fileName={attachment.name}
                    key={attachment.id}
                    loading={true}
                    disabled={false}
                    readonly={false}
                    onRemove={miscHelpers.noop}
                    onOpen={miscHelpers.noop}
                    fileId={attachment.id}
                />
            ))}

            {!readonly && !isCountLimitExceeded && (
                <AddFileButton attachmentKind={attachmentKind} title={buttonTitle} />
            )}
        </div>
    );
};

export default memo(FileList);
