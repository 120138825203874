import { intl, mathService, numberHelpers } from '@approvalmax/utils';

import { isFieldVisible } from '../../NetSuiteRequestCard.utils';
import { messages } from './NetSuiteInvoiceItemList.messages';
import { InvoiceItemListColumnDefinition, InvoiceItemListFieldSettings } from './NetSuiteInvoiceItemList.types';

export const getColumnDefs = (
    fieldSettings: InvoiceItemListFieldSettings,
    exchangeRate?: number | null
): InvoiceItemListColumnDefinition[] => {
    const columnDef: InvoiceItemListColumnDefinition[] = [
        {
            id: 'item',
            name: messages.item,
            value: ({ item }) => item?.text || null,
            isHidden: !isFieldVisible(fieldSettings.itemsItem),
        },
        {
            id: 'description',
            name: messages.description,
            value: ({ description }) => description,
            minWidth: 200,
            isHidden: !isFieldVisible(fieldSettings.itemsDescription),
        },
        {
            id: 'quantity',
            name: messages.quantity,
            value: ({ quantity }) => (quantity ? intl.formatNumber(quantity, 'auto') : null),
            alignRight: true,
            isHidden: !isFieldVisible(fieldSettings.itemsQuantity),
        },
        {
            id: 'units',
            name: messages.units,
            value: ({ units }) => units?.text || null,
            isHidden: !isFieldVisible(fieldSettings.itemsUnit),
        },
        {
            id: 'price-level',
            name: messages.priceLevel,
            value: ({ priceLevel }) => priceLevel?.text || null,
            isHidden: !isFieldVisible(fieldSettings.itemsPriceLevel),
        },
        {
            id: 'rate',
            name: messages.rate,
            value: ({ unitPrice }) => {
                if (!unitPrice) {
                    return null;
                }

                let value = unitPrice || 0;

                if (typeof exchangeRate === 'number') {
                    value = mathService.multiply(value, exchangeRate);
                    value = mathService.round(value, 2);
                }

                return intl.formatNumber(value, 'auto');
            },
            alignRight: true,
            isHidden: !isFieldVisible(fieldSettings.itemsUnitPrice),
        },
        {
            id: 'amount',
            name: messages.amount,
            value: ({ amount }) => {
                if (!amount) {
                    return null;
                }

                let value = amount || 0;

                if (typeof exchangeRate === 'number') {
                    value = mathService.multiply(value, exchangeRate);
                    value = mathService.round(value, 2);
                }

                return intl.formatNumber(value, 'auto');
            },
            alignRight: true,
            isHidden: !isFieldVisible(fieldSettings.itemsAmount),
        },
        {
            id: 'tax-code',
            name: messages.taxCode,
            value: ({ taxCode }) => taxCode?.text || null,
            isHidden: !isFieldVisible(fieldSettings.itemsTaxCode),
        },
        {
            id: 'tax-rate',
            name: messages.taxRate,
            value: ({ taxRate }) => {
                if (!numberHelpers.isNumber(taxRate)) {
                    return null;
                }

                return `${intl.formatNumber(mathService.round(taxRate, 2) || 0, 'auto')}%`;
            },
            alignRight: true,
            isHidden: !isFieldVisible(fieldSettings.itemsTaxRate),
        },
        {
            id: 'tax-amount',
            name: messages.taxAmount,
            value: ({ taxAmount }) => {
                if (!taxAmount) {
                    return null;
                }

                let value = taxAmount || 0;

                if (typeof exchangeRate === 'number') {
                    value = mathService.multiply(value, exchangeRate);
                    value = mathService.round(value, 2);
                }

                return intl.formatNumber(value, 'auto');
            },
            alignRight: true,
            isHidden: !isFieldVisible(fieldSettings.itemsTaxAmount),
        },
        {
            id: 'gross-amount',
            name: messages.grossAmount,
            value: ({ grossAmount }) => {
                if (!grossAmount) {
                    return null;
                }

                let value = grossAmount || 0;

                if (typeof exchangeRate === 'number') {
                    value = mathService.multiply(value, exchangeRate);
                    value = mathService.round(value, 2);
                }

                return intl.formatNumber(value, 'auto');
            },
            alignRight: true,
        },
        {
            id: 'class',
            name: messages.class,
            value: ({ class: lineClass }) => lineClass?.text || null,
            isHidden: !isFieldVisible(fieldSettings.itemsClass),
        },
        {
            id: 'department',
            name: messages.department,
            value: ({ department }) => department?.text || null,
            isHidden: !isFieldVisible(fieldSettings.itemsDepartment),
        },
        {
            id: 'location',
            name: messages.location,
            value: ({ location }) => location?.text || null,
            isHidden: !isFieldVisible(fieldSettings.itemsLocation),
        },
    ];

    return columnDef.filter((col) => !col.isHidden);
};
