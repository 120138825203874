import { mixins, Mods, mods, theme } from '@approvalmax/theme';
import { Button } from '@approvalmax/ui/src/components';
import { StyledMods } from '@styled-kit/mods';
import styled from 'styled-components';

export const SortableHandleContent = styled.div<StyledMods<Mods<'hide'>>>`
    width: 100%;
    height: 12px;
    padding-bottom: 4px;
    display: flex;
    justify-content: center;
    opacity: 0;
    transition: opacity ${theme.duration.medium}ms linear;
    cursor: move !important;
    pointer-events: auto !important;

    ${mods.hide.true`
        visibility: hidden;
    `}

    * {
        cursor: move !important;
    }

    &:hover {
        opacity: 1;
    }
`;

export const Root = styled.div.attrs<{
    $transform?: string;
    $transition?: string;
}>((props) => ({
    style: {
        transform: props.$transform,
        transition: props.$transition,
    },
}))<StyledMods<Mods<'active'> & { transform?: string; transition?: string }>>`
    width: 260px;
    background: ${theme.color.white100};
    padding: 4px 0 4px 4px;
    margin-top: -16px;
    z-index: 1;
    display: flex;
    flex-wrap: nowrap;

    &:hover {
        ${SortableHandleContent} {
            opacity: 1;
        }
    }

    ${mods.active.true`
        z-index: 2;
        border: 1px solid ${theme.color.midnight50};
        border-radius: ${theme.radius.xsmall};
        box-shadow: ${theme.shadow.xsmall};
        pointer-events: none;

        ${SortableHandleContent} {
            opacity: 1;
        }
    `}
`;

export const RemoveButton = styled(Button)`
    opacity: 0;
    transition: opacity ${theme.duration.medium}ms linear;
`;

export const Bubble = styled.div<StyledMods<Mods<'color' | 'clickable' | 'closable'>>>`
    width: 100%;
    height: 28px;
    padding: 8px;
    border-radius: ${theme.radius.xsmall};
    ${mods.color(mixins.colorTextWithBg)};
    cursor: pointer;

    ${mods.clickable.false`
        cursor: default;
        pointer-events: none;
    `};

    ${mods.closable.true`
        ${RemoveButton} {
            opacity: 1;
        }
    `};

    &:hover {
        ${RemoveButton} {
            opacity: 1;
        }
    }
`;
