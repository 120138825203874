export enum PartnerSegment {
    LargePartner = 'LargePartner',
    SmallPartner = 'SmallPartner',
}

export enum BusinessSegment {
    ManagedBusiness = 'ManagedBusiness',
    UnmanagedBusiness = 'UnmanagedBusiness',
}

export type QualificationQuestionsAnswersPartner = {
    typeOfBusiness: TypeOfBusiness.Partner[];
    partnerStatus?: PartnerStatus[];
    numberOfClients?: NumberOfClients[];
    useCase?: UseCase[];
    segment?: PartnerSegment[];
};

export type QualificationQuestionsAnswersBusiness = {
    typeOfBusiness: TypeOfBusiness.Business[];
    productInterest?: ProductInterest[];
    numberOfEntities?: NumberOfEntities[];
    useCase?: UseCase[];
    segment?: BusinessSegment[];
};

export type QualificationQuestionsAnswers =
    | QualificationQuestionsAnswersPartner
    | QualificationQuestionsAnswersBusiness;

export enum TypeOfBusiness {
    Partner = 'Partner',
    Business = 'Business',
}

export enum PartnerStatus {
    XeroPlatinum = 'XeroPlatinum',
    XeroGold = 'XeroGold',
    XeroOther = 'XeroOther',
    QBOPlatinum = 'QBOPlatinum',
    QBOElite = 'QBOElite',
    QBOOther = 'QBOOther',
    OracleNetSuite = 'OracleNetSuite',
    Hybrid = 'Hybrid',
    Other = 'Other',
}

export enum ProductInterest {
    Xero = 'Xero',
    QuickBooksOnline = 'QuickBooksOnline',
    OracleNetSuite = 'OracleNetSuite',
    Cin7Core = 'Cin7Core',
    Hybrid = 'Hybrid',
    Other = 'Other',
}

export enum NumberOfEntities {
    Less3 = 'Less3',
    Beetween3_10 = 'Beetween3_10',
    More10 = 'More10',
}

export enum NumberOfClients {
    Beetween1_10 = 'Beetween1_10',
    Beetween10_50 = 'Beetween10_50',
    More50 = 'More50',
}

export enum UseCase {
    StreamlineAuditProcesses = 'StreamlineAuditProcesses',
    EstablishInternalFinancialControls = 'EstablishInternalFinancialControls',
    AutomateApprovalWorkflows = 'AutomateApprovalWorkflows',
    SpeedupTheApprovalProcess = 'SpeedupTheApprovalProcess',
    Other = 'Other',
}
