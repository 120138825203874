import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { useMergeRefs } from '@floating-ui/react';

import { HorizontalDragIcon } from '../../../../icons';
import { Button } from '../../../index';
import { typedMemo } from '../../Table.helpers';
import { BaseItem } from '../../Table.types';
import { HeaderCellProps } from '../index';
import { useStickyShadow } from './HeaderCell.hooks';
import { Root, SortableHandleContent } from './HeaderCell.styles';

export const HeaderCell = typedMemo(<Item extends BaseItem>(props: HeaderCellProps<Item>) => {
    const { id, reorderable, color = 'silver80', columnDefinition, stickyHeader, children } = props;

    const { attributes, listeners, setNodeRef, transform, transition, active } = useSortable({
        id,
        disabled: !reorderable,
        data: { type: 'column' },
    });

    const { setCellRef, withStickyShadow } = useStickyShadow(columnDefinition.sticky);

    const ref = useMergeRefs([setNodeRef, setCellRef]);

    return (
        <Root
            ref={ref}
            $color={color}
            $transform={CSS.Translate.toString(transform)}
            $transition={transition}
            data-column-id={id}
            $isDragging={active?.id === id}
            $stickyHeader={stickyHeader}
            $sticky={columnDefinition.sticky}
            $withStickyShadow={withStickyShadow}
        >
            {reorderable && (
                <SortableHandleContent $isDragging={active?.id === id} {...attributes} {...listeners}>
                    <Button noPadding icon>
                        <HorizontalDragIcon size={16} />
                    </Button>
                </SortableHandleContent>
            )}

            {children}
        </Root>
    );
});
