import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('pages.RequestList.NetSuiteRequestCard.NetSuiteJournalEntryLines', {
    sectionHeaderText: 'Lines',
    accountColumnName: 'Account',
    debitAmountColumnName: 'Debit amount',
    creditAmountColumnName: 'Credit amount',
    taxCodeColumnName: 'Tax code',
    taxRateColumnName: 'Tax rate',
    taxAmountColumnName: 'Tax amount',
    grossAmountColumnName: 'Gross amount',
    memoColumnName: 'Memo',
    departmentColumnName: 'Department',
    classColumnName: 'Class',
    locationColumnName: 'Location',
    customerColumnName: 'Customer',
    amortizationScheduleColumnName: 'Amortization schedule',
    amortizationStartDateColumnName: 'Amortization start date',
    amortizationEndDateColumnName: 'Amortization end date',
    amortizationResidualColumnName: 'Amortization residual',
});
