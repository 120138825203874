import uniq from 'lodash/uniq';
import moment from 'moment';
import React from 'react';
import bemFactory from 'react-bem-factory';
import { amplitudeService } from 'services/amplitude';
import { getUseCasesSignUp } from 'shared/helpers';
import { isNewUserForTrialExperiment } from 'shared/helpers/experiments';
import { AccountPath, getAccountUrl } from 'urlBuilder';

import { UpgradeAdvancedPlanNotification } from '../UpgradeAdvancedPlanNotification/UpgradeAdvancedPlanNotification';
import UpgradeExpiredPlanNotification from '../UpgradeExpiredPlanNotification/UpgradeExpiredPlanNotification';
import { mapTextByUseCases } from './LicensingNotification.constants';
import { messages } from './LicensingNotification.messages';
import { RenderPartProps } from './LicensingNotification.types';

const bem = bemFactory.block('app-licensing-notification');

const getSubscriptionOwnerMailUrl = (subscriptions: RenderPartProps['subscriptions']) => {
    return `mailto:${uniq(subscriptions.map((l) => l.accountOwner.userEmail)).join(',')}`;
};

const getCompanyOwnerMailUrl = (companies: RenderPartProps['companies']) => {
    return `mailto:${uniq(companies.map((c) => c.allMembers.find((m) => m.id === c.author)!.userEmail)).join(',')}`;
};

const renderExpirationActionButtons = (hasActivePaidSubscription: RenderPartProps['hasActivePaidSubscription']) => {
    return hasActivePaidSubscription ? (
        <a
            className={bem('actions-myaccount-button')}
            href={getAccountUrl(AccountPath.root, {
                origin: 'wa_expiration_action',
            })}
        >
            {messages.trialMyAccountManageText}
        </a>
    ) : (
        <a
            className={bem('actions-myaccount-button')}
            href={getAccountUrl(AccountPath.buyNow, { origin: 'wa_expiration_action' })}
            onClick={() => {
                amplitudeService.sendData('navigation: click on buy now');
            }}
        >
            {messages.trialMyAccountBuyNowText}
        </a>
    );
};

export const renderGraceSubscriptions = (
    subscriptions: RenderPartProps['subscriptions'],
    isOwner: RenderPartProps['isOwner']
) => {
    if (!subscriptions.length) {
        return null;
    }

    const minDays = subscriptions.reduce((v, s) => Math.min(s.graceEndsInDays!, v), Number.MAX_VALUE);

    return (
        <div className={bem('panel-wrp')}>
            <div className={bem('grace-panel')}>
                <div className={bem('grace-message')}>{messages.graceMessage({ days: minDays })}</div>

                <div className={bem('grace-actions')}>
                    {isOwner ? (
                        <React.Fragment>
                            <a className={bem('contact-button')} href='mailto:sales@approvalmax.com'>
                                {messages.contactSalesButtonText}
                            </a>

                            <a
                                className={bem('grace-myaccount-button')}
                                href={getAccountUrl(AccountPath.subscriptions, {
                                    origin: 'wa_grace_subscriptions',
                                })}
                            >
                                {messages.graceMyAccountText}
                            </a>
                        </React.Fragment>
                    ) : (
                        <a className={bem('contact-button')} href={getSubscriptionOwnerMailUrl(subscriptions)}>
                            {messages.contactOwnerButtonText}
                        </a>
                    )}
                </div>
            </div>
        </div>
    );
};

export const renderExpiredSubscriptions = (
    subscriptions: RenderPartProps['subscriptions'],
    isOwner: RenderPartProps['isOwner'],
    hasActivePaidSubscription: RenderPartProps['hasActivePaidSubscription']
) => {
    const companies = subscriptions.flatMap((s) => s.companies.filter((c) => c.flags.isManager));

    if (!companies.length) {
        return null;
    }

    const expirationDate = subscriptions.reduce((d, l) => {
        const expires = moment.utc(l.dueDate!);

        return expires.isBefore(d) ? expires : d;
    }, moment.utc());

    let text;

    if (companies.length === 1) {
        text = messages.expiredCommercialOneMessage({
            date: expirationDate.format('LL'),
            companyName: companies[0].displayName,
        });
    } else {
        text = messages.expiredCommercialManyMessage({
            date: expirationDate.format('LL'),
            count: companies.length,
        });
    }

    return (
        <div className={bem('panel-wrp')}>
            <div className={bem('expired-panel')}>
                <div className={bem('expired-message')}>{text}</div>

                <div className={bem('expired-actions')}>
                    {isOwner ? (
                        <>
                            <a className={bem('contact-button')} href='mailto:sales@approvalmax.com'>
                                {messages.contactSalesButtonText}
                            </a>

                            {renderExpirationActionButtons(hasActivePaidSubscription)}
                        </>
                    ) : (
                        <a className={bem('contact-button')} href={getSubscriptionOwnerMailUrl(subscriptions)}>
                            {messages.contactOwnerButtonText}
                        </a>
                    )}
                </div>
            </div>
        </div>
    );
};

export const renderExpiredTrialOfAdvancedOrganisations = (companies: RenderPartProps['companies']) => (
    <UpgradeExpiredPlanNotification companies={companies} />
);

export const renderTrialOfAdvancedOrganisations = (companies: RenderPartProps['companies']) => (
    <UpgradeAdvancedPlanNotification companies={companies} />
);

const getExpiredTextByUseCase = () => {
    const casesFromStorage = getUseCasesSignUp();

    if (!casesFromStorage) {
        return messages.expiredTrialUseCaseDefault;
    }

    const text = mapTextByUseCases[casesFromStorage];

    if (!text) {
        return messages.expiredTrialUseCaseDefault;
    }

    return text;
};

export const renderExpiredOrganisations = (
    companies: RenderPartProps['companies'],
    isOwner: RenderPartProps['isOwner'],
    hasActivePaidSubscription: RenderPartProps['hasActivePaidSubscription'],
    profileCreatedDate?: string
) => {
    if (!companies.length) {
        return null;
    }

    const expirationDate = companies.reduce((d, c) => {
        const expires = moment.utc(c.licenseExpires!);

        return expires.isBefore(d) ? expires : d;
    }, moment.utc());

    let text;

    if (companies.length === 1) {
        text = messages.expiredTrialOneMessage({
            date: expirationDate.format('LL'),
            companyName: companies[0].displayName,
        });
    } else {
        text = messages.expiredTrialManyMessage({
            date: expirationDate.format('LL'),
            count: companies.length,
        });
    }

    if (profileCreatedDate && isNewUserForTrialExperiment(profileCreatedDate)) {
        text += ` ${getExpiredTextByUseCase()}`;
    }

    return (
        <div className={bem('panel-wrp')}>
            <div className={bem('expired-panel')}>
                <div className={bem('expired-message')}>{text}</div>

                <div className={bem('expired-actions')}>
                    {isOwner ? (
                        <React.Fragment>
                            <a className={bem('contact-button')} href='mailto:sales@approvalmax.com'>
                                {messages.contactSalesButtonText}
                            </a>

                            {renderExpirationActionButtons(hasActivePaidSubscription)}
                        </React.Fragment>
                    ) : (
                        <a className={bem('contact-button')} href={getCompanyOwnerMailUrl(companies)}>
                            {messages.contactOwnerButtonText}
                        </a>
                    )}
                </div>
            </div>
        </div>
    );
};

export const renderExpiringTrialOrganisations = (
    companies: RenderPartProps['companies'],
    isOwner: RenderPartProps['isOwner'],
    hasActivePaidSubscription: RenderPartProps['hasActivePaidSubscription']
) => {
    if (!companies.length) {
        return null;
    }

    const expiresIn = companies.reduce((d, c) => {
        const expiresInDays = c.licenseExpiresInDays!;

        return expiresInDays < d ? expiresInDays : d;
    }, 999);

    let text;

    if (companies.length === 1) {
        text = messages.trialExpiresSingleCompanyMessage({
            companyName: companies[0].displayName,
            days: expiresIn,
        });
    } else {
        text = messages.trialExpiresMultipleCompaniesMessage({ count: companies.length });
    }

    return (
        <div className={bem('panel-wrp')}>
            <div className={bem('trial-panel')}>
                <div className={bem('trial-message')}>{text}</div>

                <div className={bem('trial-actions')}>
                    {isOwner ? (
                        <React.Fragment>
                            <a
                                className={bem('contact-button')}
                                onClick={() => {
                                    amplitudeService.sendData('navigation: click on contact sales');
                                }}
                                href='mailto:sales@approvalmax.com'
                            >
                                {messages.trialContactSalesText}
                            </a>

                            {renderExpirationActionButtons(hasActivePaidSubscription)}
                        </React.Fragment>
                    ) : (
                        <a className={bem('contact-button')} href={getCompanyOwnerMailUrl(companies)}>
                            {messages.contactOwnerButtonText}
                        </a>
                    )}
                </div>
            </div>
        </div>
    );
};
