import { Flex, Text } from '@approvalmax/ui/src/components';
import { CloseIcon } from '@approvalmax/ui/src/icons';
import { selectors } from 'modules/common';
import { useSelector } from 'modules/react-redux';
import { FC, memo, useCallback, useState } from 'react';
import { amplitudeService } from 'services/amplitude';
import { routingService } from 'services/routing';
import { getPreferredAccountingSystemSignUp } from 'shared/helpers';

import {
    Cin7Content,
    InitialContent,
    InviteTeammateContent,
    NetSuiteContent,
    QBooksContent,
    VideoContent,
    XeroContent,
} from './components';
import { getBusinessType } from './CreateOrganisationPopup.helpers';
import { messages } from './CreateOrganisationPopup.messages';
import { CloseButton, Root } from './CreateOrganisationPopup.styles';
import { CreateOrganisationPopupProps, CurrentStep } from './CreateOrganisationPopup.types';

const CreateOrganisationPopup: FC<CreateOrganisationPopupProps> = memo((props) => {
    const {
        closable,
        isLoading,
        isLoadStepFromLocalStorage,
        withInviteTeammate,
        onClose,
        onConnectXero,
        onConnectQBooks,
        onConnectNetSuite,
        onConnectCin7,
        onConnectStandalone,
        onInviteTeammate,
    } = props;

    const profile = useSelector(selectors.profile.findProfile);

    const [currentStep, setCurrentStep] = useState<CurrentStep>(() => {
        const preferedAccountingSystemSignUp = getPreferredAccountingSystemSignUp();

        if (isLoadStepFromLocalStorage && preferedAccountingSystemSignUp) {
            return preferedAccountingSystemSignUp;
        }

        return CurrentStep.Initial;
    });

    const handleBack = useCallback(() => {
        setCurrentStep(CurrentStep.Initial);
        amplitudeService.sendData('signup: click on choose a different system to connect');
    }, []);

    const handleCreateDemo = useCallback((nextStep: CurrentStep.XeroVideo | CurrentStep.QBooksVideo, GL: string) => {
        setCurrentStep(nextStep);
        amplitudeService.sendData('signup: click on connect demo account', {
            GL,
        });
    }, []);

    const handleCreateDemoVideo = useCallback((url: string, GL: string) => {
        routingService.navigateToExternalUrlInNewTab(url);
        amplitudeService.sendData('signup: click on create test account', {
            GL,
        });
    }, []);

    const handleConnectDemoVideo = useCallback((callback: () => void, GL: string) => {
        callback();
        amplitudeService.sendData('signup: click on connect test account', {
            GL,
        });
    }, []);

    const [prevStepInvite, setPrevStepInvite] = useState<CurrentStep | null>(null);

    const handleShowInviteTeammate = useCallback(
        (prevStep: CurrentStep) => {
            setPrevStepInvite(prevStep);
            setCurrentStep(CurrentStep.InviteTeammate);

            amplitudeService.sendData('signup: land on invite teammate pop-up', {
                user_id: profile?.id,
                business_type: getBusinessType(),
                gl: prevStep,
            });
        },
        [profile?.id]
    );

    const handleBackInviteTeammate = useCallback(() => {
        setCurrentStep(prevStepInvite ?? CurrentStep.Initial);
    }, [prevStepInvite]);

    const handleInviteTeammate = useCallback(() => {
        amplitudeService.sendData('signup: clicked on invite teammate', {
            user_id: profile?.id,
            business_type: getBusinessType(),
            gl: prevStepInvite,
        });

        onInviteTeammate();
    }, [onInviteTeammate, prevStepInvite, profile?.id]);

    const isCloseVisible =
        closable &&
        currentStep !== CurrentStep.QBooksVideo &&
        currentStep !== CurrentStep.XeroVideo &&
        currentStep !== CurrentStep.InviteTeammate;

    return (
        <Root radius='small' color='white100' spacing='40 52' width={600}>
            {isCloseVisible && (
                <CloseButton onClick={onClose} icon color='white100' size='small'>
                    <CloseIcon size={28} />
                </CloseButton>
            )}

            <Text font='body' fontSize='medium'>
                <Flex direction='column' alignItems='center' spacing='0'>
                    {
                        {
                            [CurrentStep.Initial]: (
                                <InitialContent
                                    onConnectXero={() => setCurrentStep(CurrentStep.Xero)}
                                    onConnectQBooks={() => setCurrentStep(CurrentStep.QBooks)}
                                    onConnectNetSuite={() => setCurrentStep(CurrentStep.NetSuite)}
                                    onConnectCin7={() => setCurrentStep(CurrentStep.Cin7)}
                                    onSkip={onConnectStandalone}
                                    isLoading={isLoading}
                                />
                            ),

                            [CurrentStep.Xero]: (
                                <XeroContent
                                    onCreateDemo={() => handleCreateDemo(CurrentStep.XeroVideo, 'xero')}
                                    onConnectToOrganisation={onConnectXero}
                                    onSkip={onConnectStandalone}
                                    onBack={handleBack}
                                    isLoading={isLoading}
                                    withInviteTeammate={withInviteTeammate}
                                    onInviteTeammate={() => handleShowInviteTeammate(CurrentStep.Xero)}
                                />
                            ),

                            [CurrentStep.QBooks]: (
                                <QBooksContent
                                    onCreateDemo={() => handleCreateDemo(CurrentStep.QBooksVideo, 'qbooks')}
                                    onConnectToOrganisation={onConnectQBooks}
                                    onSkip={onConnectStandalone}
                                    onBack={handleBack}
                                    isLoading={isLoading}
                                    withInviteTeammate={withInviteTeammate}
                                    onInviteTeammate={() => handleShowInviteTeammate(CurrentStep.QBooks)}
                                />
                            ),

                            [CurrentStep.NetSuite]: (
                                <NetSuiteContent
                                    onConnectToOrganisation={onConnectNetSuite}
                                    onSkip={onConnectStandalone}
                                    onBack={handleBack}
                                    isLoading={isLoading}
                                    withInviteTeammate={withInviteTeammate}
                                    onInviteTeammate={() => handleShowInviteTeammate(CurrentStep.NetSuite)}
                                />
                            ),

                            [CurrentStep.Cin7]: (
                                <Cin7Content
                                    onConnectToOrganisation={onConnectCin7}
                                    onSkip={onConnectStandalone}
                                    onBack={handleBack}
                                    isLoading={isLoading}
                                    withInviteTeammate={withInviteTeammate}
                                    onInviteTeammate={() => handleShowInviteTeammate(CurrentStep.Cin7)}
                                />
                            ),

                            [CurrentStep.XeroVideo]: (
                                <VideoContent
                                    videoSrc='https://www.youtube.com/embed/RyjupSqhnHo?autoplay=1&mute=1&cc_load_policy=1'
                                    title={messages.xeroVideoTitle}
                                    description={messages.xeroVideoDescription}
                                    createDemo={messages.xeroVideoCreateDemo}
                                    connectDemo={messages.xeroVideoConnectDemo}
                                    onCreateDemo={() =>
                                        handleCreateDemoVideo('https://login.xero.com/identity/user/login', 'xero')
                                    }
                                    onConnectDemo={() => handleConnectDemoVideo(onConnectXero, 'xero')}
                                    onBack={() => setCurrentStep(CurrentStep.Xero)}
                                    isLoading={isLoading}
                                />
                            ),

                            [CurrentStep.QBooksVideo]: (
                                <VideoContent
                                    videoSrc='https://www.youtube.com/embed/2uj4DErncIE?autoplay=1&mute=1&cc_load_policy=1'
                                    title={messages.qbooksVideoTitle}
                                    description={messages.qbooksVideoDescription}
                                    createDemo={messages.qbooksVideoCreateDemo}
                                    connectDemo={messages.qbooksVideoConnectDemo}
                                    onCreateDemo={() =>
                                        handleCreateDemoVideo('https://quickbooks.intuit.com/login/', 'qbooks')
                                    }
                                    onConnectDemo={() => handleConnectDemoVideo(onConnectQBooks, 'qbooks')}
                                    onBack={() => setCurrentStep(CurrentStep.QBooks)}
                                    isLoading={isLoading}
                                />
                            ),

                            [CurrentStep.InviteTeammate]: (
                                <InviteTeammateContent
                                    onBack={handleBackInviteTeammate}
                                    onInviteTeammate={handleInviteTeammate}
                                    isLoading={isLoading}
                                />
                            ),
                        }[currentStep]
                    }
                </Flex>
            </Text>
        </Root>
    );
});

export default CreateOrganisationPopup;
