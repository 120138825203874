import { toast } from '@approvalmax/ui/src/components';
import { zodResolver } from '@hookform/resolvers/zod';
import { actions } from 'modules/profile';
import { useDispatch } from 'modules/react-redux';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useRecoilState } from 'recoil';
import { useUpdateProfile } from 'shared/data/v1';
import { z } from 'zod';

import { useOpenProfileEditPopup } from '../../ProfileEditPopup.hooks';
import { isModifiedProfileEditPopupState } from '../../ProfileEditPopup.states';
import { fieldNameAppTheme } from '../SectionAppearance/SectionAppearance.constants';
import { AppThemeOptions } from '../SectionAppearance/SectionAppearance.types';
import {
    getSectionGeneralDefaultValues,
    getSectionGeneralSaveToProfileValues,
} from '../SectionGeneral/SectionGeneral.helpers';
import {
    getSectionNotificationsDefaultValues,
    getSectionNotificationsSaveToProfileValues,
} from '../SectionNotifications/SectionNotifications.helpers';
import { messages } from './Content.messages';
import { getSchema } from './Content.schema';
import { ContentProps } from './Content.types';

export const useProfileEditForm = (props: ContentProps) => {
    const { profile } = props;

    const dispatch = useDispatch();
    const profileEditPopup = useOpenProfileEditPopup();
    const [isModified, setIsModified] = useRecoilState(isModifiedProfileEditPopupState);

    const form = useForm<z.infer<ReturnType<typeof getSchema>>>({
        defaultValues: {
            [fieldNameAppTheme]: AppThemeOptions.light,
            ...getSectionGeneralDefaultValues(profile),
            ...getSectionNotificationsDefaultValues(profile),
        },
        resolver: zodResolver(getSchema(profile)),
    });

    const { mutate: updateProfile, isLoading: isLoadingUpdateProfile } = useUpdateProfile({
        onSuccess: (_response, data) => {
            if (data.body) {
                const newProfile: typeof profile = {
                    ...profile,
                    ...getSectionGeneralSaveToProfileValues(profile, data.body),
                    ...getSectionNotificationsSaveToProfileValues(profile, data.body),
                };

                profileEditPopup.handleClosePopup();
                dispatch(actions.applySavedProfileChanges({ newProfile }));
            }

            toast.success(messages.messageProfileSaved);
        },
    });

    useEffect(() => {
        setIsModified(form.formState.isDirty);
    }, [form.formState.isDirty, setIsModified]);

    const handleSubmit = form.handleSubmit(({ appTheme, email, ...body }) => {
        updateProfile({ body });
    });

    return {
        form,
        isModified,
        handleSubmit,
        isSubmitting: isLoadingUpdateProfile,
    };
};
