import { Divider, Flex, Form, Select, Text, WeekDayPicker } from '@approvalmax/ui/src/components';
import { ui } from 'modules/common';
import { memo } from 'react';
import bemFactory from 'react-bem-factory';
import { useFormContext } from 'react-hook-form';

import { NotificationRow } from './components/NotificationRow/NotificationRow';
import { fieldNameNotificationsSection } from './SectionNotifications.constants';
import { messages } from './SectionNotifications.messages';
import { SectionNotificationsProps } from './SectionNotifications.types';

const qa = bemFactory.qa('profile-popup-notification-section');

export const SectionNotifications = memo<SectionNotificationsProps>(() => {
    const form = useFormContext();
    const fieldSummary = form.watch(fieldNameNotificationsSection.summary);

    return (
        <Form.Part>
            <Flex direction='column' spacing='24'>
                <Text font='headline' fontSize='small' fontWeight='medium'>
                    {messages.title}
                </Text>

                <div>
                    <NotificationRow
                        name={fieldNameNotificationsSection.newRequests}
                        title={messages.optionTitleNewRequest}
                        label={messages.labelSentInstantly}
                        qa={qa('new-requests-notification-checkbox-requests')}
                    />

                    <Divider color='midnight50' />

                    <NotificationRow
                        name={fieldNameNotificationsSection.comments}
                        title={messages.optionTitleComments}
                        label={messages.labelSentInstantly}
                        qa={qa('comments-notification-checkbox-requests')}
                    />

                    <Divider color='midnight50' />

                    <NotificationRow
                        name={fieldNameNotificationsSection.other}
                        title={messages.optionTitleOther}
                        label={messages.labelSentInstantly}
                        qa={qa('other-notification-checkbox-requests')}
                    />

                    <Divider color='midnight50' />

                    <NotificationRow
                        name={fieldNameNotificationsSection.workflow}
                        title={messages.optionTitleWorkflow}
                        label={messages.labelSentInstantly}
                        qa={qa('workflow-notification-checkbox-requests')}
                    />

                    <Divider color='midnight50' />

                    <NotificationRow
                        name={fieldNameNotificationsSection.summary}
                        title={messages.optionTitleSummary}
                        qa={qa('notification-checkbox-summary')}
                    >
                        <Flex alignItems='center' spacing='12'>
                            <Text font='label' fontSize='medium' fontWeight='regular' color='midnight60'>
                                {messages.sendSummaryReportEvery}
                            </Text>

                            <WeekDayPicker
                                name={fieldNameNotificationsSection.summaryDay}
                                disabled={!fieldSummary}
                                qa='day-button'
                            />

                            <Text font='label' fontSize='medium' fontWeight='regular' color='midnight60'>
                                {messages.sendSummaryReportAt}
                            </Text>

                            <Flex width={65}>
                                <Select
                                    name={fieldNameNotificationsSection.summaryTime}
                                    size='small'
                                    itemIdKey='id'
                                    itemNameKey='text'
                                    items={ui.allTimeReferences}
                                    clearable={false}
                                    disabled={!fieldSummary}
                                />
                            </Flex>
                        </Flex>
                    </NotificationRow>
                </div>
            </Flex>
        </Form.Part>
    );
});

SectionNotifications.displayName = 'SectionNotifications';
