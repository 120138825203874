import { Button, Flex, Form, Popup } from '@approvalmax/ui/src/components';
import { memo } from 'react';

import { SectionGeneral } from '../SectionGeneral/SectionGeneral';
import { SectionNotifications } from '../SectionNotifications/SectionNotifications';
import { useProfileEditForm } from './Content.hooks';
import { messages } from './Content.messages';
import { ContentProps } from './Content.types';

export const Content = memo<ContentProps>((props) => {
    const { profile } = props;

    const { form, handleSubmit, isModified, isSubmitting } = useProfileEditForm(props);

    return (
        <Form form={form} onSubmit={handleSubmit}>
            <Popup.Header
                title={messages.title}
                actions={
                    <Button
                        type='submit'
                        color='blue80'
                        size='medium'
                        title={messages.buttonSaveTitle}
                        disabled={!isModified}
                        progress={isSubmitting}
                    >
                        {messages.buttonSaveText}
                    </Button>
                }
            />

            <Popup.Body>
                <Flex direction='column' spacing='40'>
                    <SectionGeneral progress={isSubmitting} profile={profile} />

                    <SectionNotifications progress={isSubmitting} profile={profile} />
                </Flex>
            </Popup.Body>
        </Form>
    );
});

Content.displayName = 'Content';
