import { Popup } from '@approvalmax/ui/src/components';
import { memo, useCallback } from 'react';
import { useRecoilState } from 'recoil';

import { PopupContent } from './components';
import { groupOfValuesPopupOpenState } from './GroupOfValuesPopup.states';

export const GroupOfValuesPopup = memo(() => {
    const [open, setOpen] = useRecoilState(groupOfValuesPopupOpenState);

    const onToggle = useCallback((open: boolean) => !open && setOpen(false), [setOpen]);

    return (
        <Popup onToggle={onToggle} open={open} size='large' preventAutoClose>
            <PopupContent />
        </Popup>
    );
});

GroupOfValuesPopup.displayName = 'GroupOfValuesPopup';
