export enum CustomFieldType {
    Body = 'body',
    Line = 'line',
    Segment = 'segment',
}

export enum CustomFieldAccessLevel {
    Hidden = 'Hidden',
    ReadOnly = 'ReadOnly',
    Editable = 'Editable',
    Mandatory = 'Mandatory',
}

export enum CustomFieldLevel {
    Header = 'Header',
    Lines = 'Lines',
    HeaderAndLines = 'Header And Lines',
}
