import { Flex } from '@approvalmax/ui/src/components';
import {
    DndContext,
    DragEndEvent,
    MouseSensor,
    pointerWithin,
    TouchSensor,
    useSensor,
    useSensors,
} from '@dnd-kit/core';
import { restrictToHorizontalAxis } from '@dnd-kit/modifiers';
import { horizontalListSortingStrategy, SortableContext } from '@dnd-kit/sortable';
import { FC, useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import { reorderSteps } from '../../actions';
import { DraggableStepListProps } from './DraggableStepList.types';

export const DraggableStepList: FC<DraggableStepListProps> = (props) => {
    const { steps, children } = props;

    const dispatch = useDispatch();

    // steps var is immutable, so we need to make a copy
    // for the SortableContext
    const stepsCopy = useMemo(() => [...steps], [steps]);

    const sensors = useSensors(
        useSensor(MouseSensor),
        useSensor(TouchSensor, { activationConstraint: { distance: 10 } })
    );

    const onSortEnd = useCallback(
        (event: DragEndEvent) => {
            const { active, over } = event;

            const oldIndex = steps.findIndex((step) => step.id === active.id);
            const newIndex = steps.findIndex((step) => step.id === over?.id);

            if (oldIndex !== newIndex) {
                dispatch(reorderSteps(oldIndex, newIndex));
            }
        },
        [dispatch, steps]
    );

    return (
        <DndContext
            sensors={sensors}
            modifiers={[restrictToHorizontalAxis]}
            collisionDetection={pointerWithin}
            onDragEnd={onSortEnd}
        >
            <Flex wrap={false} spacing='0'>
                <SortableContext items={stepsCopy} strategy={horizontalListSortingStrategy}>
                    {children}
                </SortableContext>
            </Flex>
        </DndContext>
    );
};

DraggableStepList.displayName = 'DraggableStepList';
