import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('components.WeekDayPicker.Content', {
    dayShortnameSunday: 'Su',
    dayShortnameMonday: 'Mo',
    dayShortnameTuesday: 'Tu',
    dayShortnameWednesday: 'We',
    dayShortnameThursday: 'Th',
    dayShortnameFriday: 'Fr',
    dayShortnameSaturday: 'Sa',
});
