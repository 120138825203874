import { ChangeEvent, forwardRef, memo, useCallback } from 'react';
import { useRecoilState } from 'recoil';

import { Text } from '../../../Text/Text';
import { weekDayPickerSelectedValuesState } from '../Content/Content.states';
import { useOptionRef } from './Option.hooks';
import { Input, Root } from './Option.styles';
import { OptionProps } from './Option.types';

export const Option = memo(
    forwardRef<HTMLInputElement, OptionProps>((props, ref) => {
        const { qa, name, text, value, disabled, invalid, required } = props;

        const { inputRef, parentProps } = useOptionRef(ref);

        const [checked, setChecked] = useRecoilState(weekDayPickerSelectedValuesState(value));

        const handleChange = useCallback(
            (event: ChangeEvent<HTMLInputElement>) => {
                setChecked(event.target.checked);
            },
            [setChecked]
        );

        return (
            <Root {...parentProps} $checked={checked} $disabled={disabled} $invalid={!!invalid}>
                <Input
                    type='checkbox'
                    name={name}
                    onChange={handleChange}
                    checked={checked}
                    disabled={disabled}
                    required={!!required}
                    aria-invalid={!!invalid}
                    aria-checked={checked}
                    ref={inputRef}
                    data-qa={qa}
                />

                <Text font='label' fontSize='small' fontWeight='regular'>
                    {text}
                </Text>
            </Root>
        );
    })
);

Option.displayName = 'Option';
