import { forwardRef, memo } from 'react';
import { RecoilRoot } from 'recoil';

import { Controller } from './components';
import { Content } from './components';
import { ChildrenComponents, WeekDayPickerProps } from './WeekDayPicker.types';

export const WeekDayPicker = memo(
    forwardRef<HTMLInputElement, WeekDayPickerProps>((props, ref) => (
        <RecoilRoot>
            <Content {...props} ref={ref} />
        </RecoilRoot>
    ))
) as typeof Content & ChildrenComponents;

WeekDayPicker.Controller = Controller;

WeekDayPicker.displayName = 'WeekDayPicker';
