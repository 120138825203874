import { UseCase } from 'modules/data/domain';

import { messages } from './PopupContent.messages';

export const mapTextByUseCases = {
    [UseCase.StreamlineAuditProcesses]: messages.useCaseStreamlineAuditProcesses,
    [UseCase.EstablishInternalFinancialControls]: messages.useCaseEstablishInternalFinancialControls,
    [UseCase.AutomateApprovalWorkflows]: messages.useCaseAutomateApprovalWorkflows,
    [UseCase.SpeedupTheApprovalProcess]: messages.useCaseSpeedupTheApprovalProcess,

    [[UseCase.StreamlineAuditProcesses, UseCase.EstablishInternalFinancialControls].join(',')]:
        messages.useCaseStreamlineAuditProcessesAndEstablishInternalFinancialControls,
    [[UseCase.StreamlineAuditProcesses, UseCase.AutomateApprovalWorkflows].join(',')]:
        messages.useCaseStreamlineAuditProcessesAndAutomateApprovalWorkflows,
    [[UseCase.StreamlineAuditProcesses, UseCase.SpeedupTheApprovalProcess].join(',')]:
        messages.useCaseStreamlineAuditProcessesAndSpeedupTheApprovalProcess,
    [[UseCase.EstablishInternalFinancialControls, UseCase.AutomateApprovalWorkflows].join(',')]:
        messages.useCaseEstablishInternalFinancialControlsAndAutomateApprovalWorkflows,
    [[UseCase.EstablishInternalFinancialControls, UseCase.SpeedupTheApprovalProcess].join(',')]:
        messages.useCaseEstablishInternalFinancialControlsAndSpeedupTheApprovalProcess,
    [[UseCase.AutomateApprovalWorkflows, UseCase.SpeedupTheApprovalProcess].join(',')]:
        messages.useCaseAutomateApprovalWorkflowsAndSpeedupTheApprovalProcess,

    [[
        UseCase.StreamlineAuditProcesses,
        UseCase.EstablishInternalFinancialControls,
        UseCase.AutomateApprovalWorkflows,
    ].join(',')]:
        messages.useCaseStreamlineAuditProcessesAndEstablishInternalFinancialControlsAndAutomateApprovalWorkflows,
    [[
        UseCase.StreamlineAuditProcesses,
        UseCase.EstablishInternalFinancialControls,
        UseCase.SpeedupTheApprovalProcess,
    ].join(',')]:
        messages.useCaseStreamlineAuditProcessesAndEstablishInternalFinancialControlsAndSpeedupTheApprovalProcess,

    [[UseCase.StreamlineAuditProcesses, UseCase.AutomateApprovalWorkflows, UseCase.SpeedupTheApprovalProcess].join(
        ','
    )]: messages.useCaseStreamlineAuditProcessesAndAutomateApprovalWorkflowsAndSpeedupTheApprovalProcess,

    [[
        UseCase.EstablishInternalFinancialControls,
        UseCase.AutomateApprovalWorkflows,
        UseCase.SpeedupTheApprovalProcess,
    ].join(',')]:
        messages.useCaseEstablishInternalFinancialControlsAndAutomateApprovalWorkflowsAndSpeedupTheApprovalProcess,

    [[
        UseCase.StreamlineAuditProcesses,
        UseCase.EstablishInternalFinancialControls,
        UseCase.AutomateApprovalWorkflows,
        UseCase.SpeedupTheApprovalProcess,
    ].join(',')]: messages.useCaseDefault,
};

export const refineFormProd = '58c89400-8c6e-11ef-b96e-7182bf573a81';
export const refineFormNonProd = 'd7c118c0-905a-11ef-809d-7bf872371c59';
