import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('components.ProfileEditPopup.SectionNotifications', {
    title: 'Email Notifications',
    optionTitleNewRequest: 'New requests notifications',
    optionTitleComments: 'Comments notifications',
    optionTitleWorkflow: 'Workflow changes',
    optionTitleOther: 'Other notifications',
    optionTitleSummary: 'Summary of pending approvals',
    labelSentInstantly: 'Sent instantly',
    sendSummaryReportEvery: 'Send a report every',
    sendSummaryReportAt: 'at',
});
