import { errorHelpers } from '@approvalmax/utils';
import { QualificationQuestionsAnswers, UseCase } from 'modules/data/domain';

const storageKey = 'useCasesSignUp';

export const getUseCasesSignUp = () => {
    return localStorage.getItem(storageKey);
};

export const setUseCasesSignUp = (qualificationQuestionsAnswers?: QualificationQuestionsAnswers | null) => {
    try {
        if (
            !qualificationQuestionsAnswers ||
            !qualificationQuestionsAnswers.useCase ||
            qualificationQuestionsAnswers.useCase.length === 0
        ) {
            return;
        }

        const useCasesSet = new Set(qualificationQuestionsAnswers.useCase);
        const orederedUseCases = [];

        if (useCasesSet.has(UseCase.StreamlineAuditProcesses)) {
            orederedUseCases.push(UseCase.StreamlineAuditProcesses);
        }

        if (useCasesSet.has(UseCase.EstablishInternalFinancialControls)) {
            orederedUseCases.push(UseCase.EstablishInternalFinancialControls);
        }

        if (useCasesSet.has(UseCase.AutomateApprovalWorkflows)) {
            orederedUseCases.push(UseCase.AutomateApprovalWorkflows);
        }

        if (useCasesSet.has(UseCase.SpeedupTheApprovalProcess)) {
            orederedUseCases.push(UseCase.SpeedupTheApprovalProcess);
        }

        if (useCasesSet.has(UseCase.Other)) {
            orederedUseCases.push(UseCase.Other);
        }

        localStorage.setItem(storageKey, orederedUseCases.join(','));
    } catch (error) {
        errorHelpers.captureException(error);
    }
};
