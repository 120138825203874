import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('app.WorkflowIdPage.GroupOfValuesPopup.AddCommentPopupContent', {
    popupTitle: 'Add a comment about the changes made',
    description1: 'You are initiating the update of the current group of values.',
    description2: 'To let the users know why the changes have been made, please, leave a comment here.',
    optionalText: 'This is optional.',
    buttonText: 'Confirm',
    addCommentPlaceholder: 'Add a comment',
});
