import { PascalCaseToCamelCase, Reference } from '@approvalmax/types';
import {
    CheckboxEditor,
    DateTimeEditor,
    FocusImplementer,
    NumberEditor,
    TextAreaEditor,
    TextEditor,
    TimeEditor,
    validators,
} from '@approvalmax/ui';
import { numberHelpers } from '@approvalmax/utils';
import uniq from 'lodash/uniq';
import { constants } from 'modules/common';
import { backend, domain } from 'modules/data';
import { mapReference } from 'modules/data/domain/schemas';
import moment from 'moment';
import { isPossiblePhoneNumber } from 'react-phone-number-input';
import { NetSuiteApplyListLineExpanded } from 'shared/states';

import { NetSuiteLineItemCustomReferenceCell } from '../components/NetSuiteLineItemCustomReferenceCell/NetSuiteLineItemCustomReferenceCell';
import { CustomFieldsState } from '../data/types/CustomFields';
import { NetSuiteExpenseLineExpanded } from '../data/types/NetSuiteExpenseLineExpanded';
import { NetSuiteItemLineExpanded } from '../data/types/NetSuiteItemLineExpanded';
import { useFieldSettingsExpenses, useFieldSettingsItems } from '../hooks';
import { messages } from './NetSuiteRequestForm.messages';
import {
    NetSuiteFieldValue,
    renderLineCustomFieldEditorParams,
    ValidateCustomFieldsParams,
    ValidateExpenseAndItemLinesParams,
} from './NetSuiteRequestForm.types';

export const checkIfCustomFieldHasValue = (field: domain.NetSuiteCustomField) => {
    if (field.fieldType === 'CheckBox') {
        return true;
    }

    const hasValue =
        field.valueBoolean !== null ||
        field.valueDate ||
        field.valueDecimal !== null ||
        field.valueLong !== null ||
        field.valueRef ||
        field.valueRefs ||
        field.valueString;

    return Boolean(hasValue);
};

export const getCustomFieldsTransfer = (
    customFields: domain.NetSuiteCustomField[] | undefined
): backend.transfers.IntegrationNetSuiteCustomField[] | null => {
    if (!customFields) {
        return null;
    }

    return customFields
        .filter((field) => checkIfCustomFieldHasValue(field))
        .map((field) => {
            if (field.valueRef) {
                return {
                    ...field,
                    valueRefId: field.valueRef.id,
                    valueRefIds: null,
                };
            }

            if (field.valueRefs) {
                return {
                    ...field,
                    valueRefId: null,
                    valueRefIds: field.valueRefs.map((valueRef) => valueRef.id),
                };
            }

            return {
                ...field,
                valueRefId: null,
                valueRefIds: null,
            };
        });
};

export const getItemLineTransfer = (
    itemLine: domain.NetSuiteItemLine,
    integrationCode: domain.IntegrationCode
): backend.transfers.IntegrationNetSuiteItemLine => {
    let transfer: backend.transfers.IntegrationNetSuiteItemLine = {
        amount: typeof itemLine.amount === 'number' ? itemLine.amount : null,
        taxCodeId: itemLine.taxCode?.id ?? null,
        taxRate: itemLine.taxRate || null,
        taxAmount: itemLine.taxAmount || null,
        departmentId: itemLine.department?.id || null,
        classId: itemLine.class?.id || null,
        locationId: itemLine.location?.id || null,
        isBillable: itemLine.isBillable || false,
        isClosed: itemLine.isClosed || false,
        itemId: itemLine.item?.id || null,
        vendorName: itemLine.vendorName || null,
        quantity: itemLine.quantity || null,
        unitAbbreviation: itemLine.units?.text || null,
        unitId: itemLine.units?.id || null,
        unitPrice: itemLine.unitPrice || null,
        description: itemLine.description || null,
        customerId: itemLine.customer?.id ?? null,
        expectedReceiptDate: itemLine.expectedReceiptDate || null,
        lineNumber: itemLine.lineNumber,
        customFields: getCustomFieldsTransfer(itemLine.customFields),
    };

    if (integrationCode === domain.IntegrationCode.NetSuiteBill) {
        transfer = {
            ...transfer,
            amortizationScheduleName: itemLine.amortizationSchedule?.text || null,
            amortizationStartDate: itemLine.amortizationStartDate || null,
            amortizationEndDate: itemLine.amortizationEndDate || null,
            amortizationResidual:
                typeof itemLine.amortizationResidual === 'number' ? itemLine.amortizationResidual : null,
            orderDocId: itemLine.orderDocId || null,
            orderLineNumber: itemLine.orderLineNumber || null,
        };
    }

    return transfer;
};

export const getExpenseLineTransfer = (
    expenseLine: domain.NetSuiteExpenseLine,
    integrationCode: domain.IntegrationCode
): backend.transfers.IntegrationNetSuiteExpenseLine => {
    let transfer: backend.transfers.IntegrationNetSuiteExpenseLine = {
        amount: typeof expenseLine.amount === 'number' ? expenseLine.amount : null,
        taxCodeId: expenseLine.taxCode?.id ?? null,
        taxRate: expenseLine.taxRate || null,
        taxAmount: expenseLine.taxAmount || null,
        departmentId: expenseLine.department?.id || null,
        classId: expenseLine.class?.id || null,
        locationId: expenseLine.location?.id || null,
        isBillable: expenseLine.customer ? Boolean(expenseLine.isBillable) : null,
        categoryId: expenseLine.category?.id || null,
        accountId: expenseLine.account?.id || null,
        memo: expenseLine.memo || null,
        customerId: expenseLine.customer?.id || null,
        lineNumber: expenseLine.lineNumber,
        customFields: getCustomFieldsTransfer(expenseLine.customFields),
    };

    if (integrationCode === domain.IntegrationCode.NetSuiteBill) {
        transfer = {
            ...transfer,
            amortizationScheduleName: expenseLine.amortizationSchedule?.text || null,
            amortizationStartDate: expenseLine.amortizationStartDate || null,
            amortizationEndDate: expenseLine.amortizationEndDate || null,
            amortizationResidual:
                typeof expenseLine.amortizationResidual === 'number' ? expenseLine.amortizationResidual : null,
            orderDocId: expenseLine.orderDocId || null,
            orderLineNumber: expenseLine.orderLineNumber || null,
        };
    }

    return transfer;
};

export const checkExpenseLineIsEmpty = (expenseLine: domain.NetSuiteExpenseLine): boolean => {
    if (
        typeof expenseLine.amount === 'number' ||
        expenseLine.class ||
        expenseLine.customer ||
        expenseLine.department ||
        typeof expenseLine.grossAmount === 'number' ||
        expenseLine.isBillable ||
        expenseLine.location ||
        expenseLine.taxCode ||
        expenseLine.category ||
        expenseLine.memo
    ) {
        return false;
    }

    return true;
};

export const checkExpenseLinesAreInvalid = (
    expenseLines: domain.NetSuiteExpenseLine[],
    expensesFieldsSettings: ReturnType<typeof useFieldSettingsExpenses>,
    integrationCode: domain.IntegrationCode
): boolean => {
    const hasInvalidExpenseLine = expenseLines.find((expenseLine) => {
        if (checkExpenseLineIsEmpty(expenseLine)) {
            return false;
        }

        const isInvalidAccount = expenseLine.category ? false : !expenseLine.account;

        if (isInvalidAccount || typeof expenseLine.amount !== 'number') {
            return true;
        }

        if (expensesFieldsSettings.taxCode === domain.DocumentFieldState.Mandatory && !expenseLine.taxCode) {
            return true;
        }

        if (expensesFieldsSettings.category === domain.DocumentFieldState.Mandatory && !expenseLine.category) {
            return true;
        }

        if (expensesFieldsSettings.class === domain.DocumentFieldState.Mandatory && !expenseLine.class) {
            return true;
        }

        if (expensesFieldsSettings.customer === domain.DocumentFieldState.Mandatory && !expenseLine.customer) {
            return true;
        }

        if (expensesFieldsSettings.department === domain.DocumentFieldState.Mandatory && !expenseLine.department) {
            return true;
        }

        if (expensesFieldsSettings.location === domain.DocumentFieldState.Mandatory && !expenseLine.location) {
            return true;
        }

        if (expensesFieldsSettings.memo === domain.DocumentFieldState.Mandatory && !expenseLine.memo) {
            return true;
        }

        if (integrationCode === domain.IntegrationCode.NetSuiteBill) {
            if (
                expensesFieldsSettings.amortizationSchedule === domain.DocumentFieldState.Mandatory &&
                !expenseLine.amortizationSchedule
            ) {
                return true;
            }

            if (
                expensesFieldsSettings.amortizationStartDate === domain.DocumentFieldState.Mandatory &&
                !expenseLine.amortizationStartDate
            ) {
                return true;
            }

            if (
                expensesFieldsSettings.amortizationEndDate === domain.DocumentFieldState.Mandatory &&
                !expenseLine.amortizationEndDate
            ) {
                return true;
            }

            if (
                expensesFieldsSettings.amortizationResidual === domain.DocumentFieldState.Mandatory &&
                typeof expenseLine.amortizationResidual !== 'number'
            ) {
                return true;
            }
        }

        return false;
    });

    return Boolean(hasInvalidExpenseLine);
};

export const checkItemLineIsEmpty = (itemLine: domain.NetSuiteItemLine): boolean => {
    if (
        typeof itemLine.amount === 'number' ||
        itemLine.class ||
        itemLine.customer ||
        itemLine.department ||
        itemLine.isBillable ||
        itemLine.location ||
        itemLine.taxCode ||
        itemLine.description ||
        itemLine.item ||
        typeof itemLine.quantity === 'number' ||
        typeof itemLine.unitPrice === 'number' ||
        itemLine.units
    ) {
        return false;
    }

    return true;
};

export const checkItemLinesAreInvalid = (
    itemLines: domain.NetSuiteItemLine[],
    itemsFieldsSettings: ReturnType<typeof useFieldSettingsItems>,
    integrationCode: domain.IntegrationCode
): boolean => {
    const hasInvalidItemLine = itemLines.find((itemLine) => {
        if (checkItemLineIsEmpty(itemLine)) {
            return false;
        }

        if (!itemLine.item || typeof itemLine.amount !== 'number') {
            return true;
        }

        if (itemsFieldsSettings.taxCode === domain.DocumentFieldState.Mandatory && !itemLine.taxCode) {
            return true;
        }

        if (itemsFieldsSettings.class === domain.DocumentFieldState.Mandatory && !itemLine.class) {
            return true;
        }

        if (itemsFieldsSettings.customer === domain.DocumentFieldState.Mandatory && !itemLine.customer) {
            return true;
        }

        if (itemsFieldsSettings.department === domain.DocumentFieldState.Mandatory && !itemLine.department) {
            return true;
        }

        if (itemsFieldsSettings.description === domain.DocumentFieldState.Mandatory && !itemLine.description) {
            return true;
        }

        if (
            itemsFieldsSettings.expectedReceiptDate === domain.DocumentFieldState.Mandatory &&
            !itemLine.expectedReceiptDate
        ) {
            return true;
        }

        if (itemsFieldsSettings.location === domain.DocumentFieldState.Mandatory && !itemLine.location) {
            return true;
        }

        if (itemsFieldsSettings.units === domain.DocumentFieldState.Mandatory && !itemLine.units) {
            return true;
        }

        if (itemsFieldsSettings.vendorName === domain.DocumentFieldState.Mandatory && !itemLine.vendorName) {
            return true;
        }

        if (integrationCode === domain.IntegrationCode.NetSuiteBill) {
            if (
                itemsFieldsSettings.amortizationSchedule === domain.DocumentFieldState.Mandatory &&
                !itemLine.amortizationSchedule
            ) {
                return true;
            }

            if (
                itemsFieldsSettings.amortizationStartDate === domain.DocumentFieldState.Mandatory &&
                !itemLine.amortizationStartDate
            ) {
                return true;
            }

            if (
                itemsFieldsSettings.amortizationEndDate === domain.DocumentFieldState.Mandatory &&
                !itemLine.amortizationEndDate
            ) {
                return true;
            }

            if (
                itemsFieldsSettings.amortizationResidual === domain.DocumentFieldState.Mandatory &&
                typeof itemLine.amortizationResidual !== 'number'
            ) {
                return true;
            }
        }

        return false;
    });

    return Boolean(hasInvalidItemLine);
};

export const checkExpensesAndItemLinesAreEmpty = (
    expenseLines: NetSuiteExpenseLineExpanded[],
    itemLines: NetSuiteItemLineExpanded[]
) => {
    return (
        expenseLines.every((expense) => checkExpenseLineIsEmpty(expense)) &&
        itemLines.every((item) => checkItemLineIsEmpty(item))
    );
};

export const checkExpensesOrItemLineAmortizationDateIsInvalid = (
    line: NetSuiteExpenseLineExpanded | NetSuiteItemLineExpanded
) => {
    if (typeof line.amortizationStartDate === 'string' && typeof line.amortizationEndDate === 'string') {
        return moment(line.amortizationStartDate).isAfter(moment(line.amortizationEndDate));
    }

    return false;
};

export const checkApplyListLineIsEmpty = (applyLine: NetSuiteApplyListLineExpanded) => {
    return !numberHelpers.isNumber(applyLine.amountDue);
};

export const checkApplyListLinesAreEmpty = (applyLines: NetSuiteApplyListLineExpanded[]) => {
    return applyLines.every((applyLine) => checkApplyListLineIsEmpty(applyLine));
};

export const validateExpenseAndItemLines = (params: ValidateExpenseAndItemLinesParams) => {
    const { expenseLines, itemLines, customFieldsList, workflowId, errors } = params;

    const expenseLineWithInvalidTaxAmount = expenseLines.find((line) => {
        if (typeof line.taxAmount === 'number') {
            if (
                line.taxAmount > constants.netSuiteConstants.taxAmountMaxValue ||
                line.taxAmount < -constants.netSuiteConstants.taxAmountMaxValue
            ) {
                return true;
            } else {
                const taxAmountDigitsCount = line.taxAmount.toString().replace(/[^\d]/g, '').length;

                if (taxAmountDigitsCount > constants.netSuiteConstants.taxAmountMaxDigitsCount) {
                    return true;
                }
            }
        }

        return false;
    });

    if (expenseLineWithInvalidTaxAmount) {
        errors.push(
            messages.expenseLineTaxAmountExceedError({
                maxValue: constants.netSuiteConstants.taxAmountMaxValue,
                maxDigitsCount: constants.netSuiteConstants.taxAmountMaxDigitsCount,
            })
        );
    }

    const expenseLineWithInvalidAmortizationDate = expenseLines.find((line) =>
        checkExpensesOrItemLineAmortizationDateIsInvalid(line)
    );

    if (expenseLineWithInvalidAmortizationDate) {
        errors.push(messages.expenseOrItemLineAmortizationDateError);
    }

    const itemLineWithInvalidAmortizationDate = itemLines.find((line) =>
        checkExpensesOrItemLineAmortizationDateIsInvalid(line)
    );

    if (itemLineWithInvalidAmortizationDate) {
        errors.push(messages.expenseOrItemLineAmortizationDateError);
    }

    const itemLineWithInvalidAmount = itemLines.find((line) => {
        if (typeof line.amount === 'number') {
            return (
                line.amount > constants.netSuiteConstants.maxLineItemAmount ||
                line.amount < -constants.netSuiteConstants.maxLineItemAmount
            );
        }

        return false;
    });

    if (itemLineWithInvalidAmount) {
        errors.push(messages.itemLineAmountExceedError({ maxValue: constants.netSuiteConstants.maxLineItemAmount }));
    }

    const itemLineWithInvalidTaxAmount = itemLines.find((line) => {
        if (typeof line.taxAmount === 'number') {
            if (
                line.taxAmount > constants.netSuiteConstants.taxAmountMaxValue ||
                line.taxAmount < -constants.netSuiteConstants.taxAmountMaxValue
            ) {
                return true;
            } else {
                const taxAmountDigitsCount = line.taxAmount.toString().replace(/[^\d]/g, '').length;

                if (taxAmountDigitsCount > constants.netSuiteConstants.taxAmountMaxDigitsCount) {
                    return true;
                }
            }
        }

        return false;
    });

    if (itemLineWithInvalidTaxAmount) {
        errors.push(
            messages.itemLineTaxAmountExceedError({
                maxValue: constants.netSuiteConstants.taxAmountMaxValue,
                maxDigitsCount: constants.netSuiteConstants.taxAmountMaxDigitsCount,
            })
        );
    }

    itemLines.forEach((itemLine) => {
        if (checkItemLineIsEmpty(itemLine)) {
            return;
        }

        validateCustomFields({
            customFieldsValues: itemLine.customFields || [],
            customFieldsLevels: ['Lines', 'Header And Lines'],
            customFieldsList,
            workflowId,
            errors,
        });
    });

    expenseLines.forEach((expenseLine) => {
        if (checkExpenseLineIsEmpty(expenseLine)) {
            return;
        }

        validateCustomFields({
            customFieldsValues: expenseLine.customFields || [],
            customFieldsLevels: ['Lines', 'Header And Lines'],
            customFieldsList,
            workflowId,
            errors,
        });
    });
};

export const validateCustomFields = (params: ValidateCustomFieldsParams) => {
    const { customFieldsValues, customFieldsList, customFieldsLevels, workflowId, errors } = params;

    customFieldsValues.forEach((field) => {
        if (field.fieldType === 'EMailAddress' && field.valueString) {
            const isInvalidEmail = !validators.isEmail(field.valueString);

            if (isInvalidEmail) {
                errors.push(messages.emailError);
            }
        }

        if (field.fieldType === 'PhoneNumber' && field.valueString) {
            const isInvalidPhone = !isPossiblePhoneNumber(field.valueString || '', 'US');

            if (isInvalidPhone) {
                errors.push(messages.phoneError);
            }
        }

        if (field.fieldType === 'Hyperlink' && field.valueString) {
            const isInvalidLink = !isHttpValid(field.valueString);

            if (isInvalidLink) {
                errors.push(messages.hyperLinkError);
            }
        }
    });

    customFieldsList
        .filter((field) => customFieldsLevels.includes(field.netSuiteField?.level ?? ''))
        .forEach((field) => {
            const fieldWorkflowSettings = field.workflows?.find((workflow) => workflow.workflowId === workflowId);

            if (fieldWorkflowSettings?.isMandatory) {
                const fieldData = customFieldsValues.find((fieldValue) => fieldValue.companyFieldId === field.id);

                const hasFieldValue = fieldData
                    ? checkIfCustomFieldHasValue(fieldData)
                    : field.netSuiteField?.type === 'CheckBox';

                if (!hasFieldValue) {
                    errors.push(messages.mandatoryFieldsError);
                }
            }
        });
};

export const getFileNameWithRequestId = (fileName: string, requestId: string) => {
    const lastDotIndex = fileName.lastIndexOf('.');

    return lastDotIndex === -1
        ? `${fileName}-${requestId}`
        : `${fileName.slice(0, lastDotIndex)}-${requestId}.${fileName.slice(lastDotIndex + 1)}`;
};

export const fixDuplicateLineNumbers = (
    savedExpenseLines: domain.NetSuiteExpenseLine[],
    savedItemLines: domain.NetSuiteItemLine[]
) => {
    const allLineNumbers = [
        ...savedItemLines.map((item) => item.lineNumber),
        ...savedExpenseLines.map((expense) => expense.lineNumber),
    ];

    const uniqLineNumbers = uniq(allLineNumbers);

    const hasDuplicateLineNumbers = allLineNumbers.length !== uniqLineNumbers.length;

    if (hasDuplicateLineNumbers) {
        let lineNumber = Math.max(...uniqLineNumbers) + 1;

        const expenseLines = savedExpenseLines.map((line) => {
            const modifiedLine = {
                ...line,
                lineNumber,
            };

            lineNumber += 1;

            return modifiedLine;
        });

        const itemLines = savedItemLines.map((line) => {
            const modifiedLine = {
                ...line,
                lineNumber,
            };

            lineNumber += 1;

            return modifiedLine;
        });

        return { expenseLines, itemLines };
    }

    return { expenseLines: savedExpenseLines, itemLines: savedItemLines };
};

export const getCustomFieldMaxLength = (field: domain.Field): number | undefined => {
    switch (field.netSuiteField?.type) {
        case 'PhoneNumber':
            return 32;

        case 'FreeFormText':
            return 300;

        case 'LongText':
            return 500000;

        case 'TextArea':
            return 4000;

        case 'EMailAddress':
            return 254;
    }

    return;
};

export const isHttpValid = (urlString: string) => {
    try {
        const newUrl = new URL(urlString);

        return newUrl.protocol === 'http:' || newUrl.protocol === 'https:';
    } catch {
        return false;
    }
};

export const getCustomFieldIsInvalid = (
    field: domain.Field,
    fieldData?: domain.NetSuiteCustomField,
    workflowId?: string
) => {
    if (field.netSuiteField?.type === 'CheckBox') {
        return false;
    }

    const isMandatoryField = field.workflows?.some(
        (workflow) => workflow.workflowId === workflowId && workflow.isMandatory
    );

    if (!fieldData) {
        return Boolean(isMandatoryField);
    }

    if (
        field.netSuiteField?.type === 'EMailAddress' &&
        fieldData.valueString &&
        !validators.isEmail(fieldData.valueString)
    ) {
        return true;
    }

    if (
        field.netSuiteField?.type === 'PhoneNumber' &&
        fieldData.valueString &&
        !isPossiblePhoneNumber(fieldData.valueString, 'US')
    ) {
        return true;
    }

    if (field.netSuiteField?.type === 'Hyperlink' && fieldData.valueString && !isHttpValid(fieldData.valueString)) {
        return true;
    }

    if (isMandatoryField) {
        const hasValue = checkIfCustomFieldHasValue(fieldData);

        return !hasValue;
    }

    return false;
};

export const renderLineCustomFieldEditor = (params: renderLineCustomFieldEditorParams) => {
    const { lineId, field, fieldState, sublistType, isReadonly = false, onChange } = params;

    switch (field.netSuiteField?.type) {
        case 'CheckBox':
            return (
                <CheckboxEditor
                    onChange={(value) => onChange(lineId, field, 'valueBoolean', value)}
                    value={fieldState?.valueBoolean || false}
                    disabled={isReadonly}
                />
            );

        case 'Currency':
            return (
                <FocusImplementer>
                    {({ registerFocusable }) => (
                        <NumberEditor
                            ref={registerFocusable}
                            allowFloat
                            value={fieldState?.valueDecimal || null}
                            onChange={(value) => onChange(lineId, field, 'valueDecimal', value)}
                            placeholder={messages.inputDefaultPlaceholder}
                            displayFormat='currency'
                            max={constants.netSuiteConstants.maxCustomNumericFieldAmount}
                            min={-constants.netSuiteConstants.maxCustomNumericFieldAmount}
                            precision={2}
                            theme='transparent'
                            disabled={isReadonly}
                        />
                    )}
                </FocusImplementer>
            );

        case 'DecimalNumber':
            return (
                <FocusImplementer>
                    {({ registerFocusable }) => (
                        <NumberEditor
                            ref={registerFocusable}
                            allowFloat
                            value={fieldState?.valueDecimal || null}
                            onChange={(value) => onChange(lineId, field, 'valueDecimal', value)}
                            placeholder={messages.inputDefaultPlaceholder}
                            displayFormat='as-is'
                            max={constants.netSuiteConstants.maxCustomNumericFieldAmount}
                            min={-constants.netSuiteConstants.maxCustomNumericFieldAmount}
                            precision={8}
                            theme='transparent'
                            disabled={isReadonly}
                        />
                    )}
                </FocusImplementer>
            );

        case 'IntegerNumber':
            return (
                <FocusImplementer>
                    {({ registerFocusable }) => (
                        <NumberEditor
                            ref={registerFocusable}
                            allowFloat={false}
                            value={fieldState?.valueLong || null}
                            onChange={(value) => onChange(lineId, field, 'valueLong', value)}
                            placeholder={messages.inputDefaultPlaceholder}
                            displayFormat='as-is'
                            max={constants.netSuiteConstants.maxCustomNumericFieldAmount}
                            min={-constants.netSuiteConstants.maxCustomNumericFieldAmount}
                            theme='transparent'
                            disabled={isReadonly}
                        />
                    )}
                </FocusImplementer>
            );

        case 'Percent':
            return (
                <FocusImplementer>
                    {({ registerFocusable }) => (
                        <NumberEditor
                            ref={registerFocusable}
                            allowFloat
                            value={fieldState?.valueDecimal || null}
                            onChange={(value) => onChange(lineId, field, 'valueDecimal', value)}
                            placeholder={messages.inputDefaultPlaceholder}
                            displayFormat='as-is'
                            max={100}
                            min={0}
                            precision={4}
                            theme='transparent'
                            disabled={isReadonly}
                        />
                    )}
                </FocusImplementer>
            );

        case 'Date':
            return (
                <FocusImplementer>
                    {({ registerFocusable }) => (
                        <DateTimeEditor
                            ref={registerFocusable}
                            value={fieldState?.valueDate || null}
                            placeholder={messages.selectDefaulPlaceholder}
                            onChange={(value) => onChange(lineId, field, 'valueDate', value)}
                            theme='transparent'
                            disabled={isReadonly}
                        />
                    )}
                </FocusImplementer>
            );

        case 'TimeOfDay':
            return (
                <FocusImplementer>
                    {({ registerFocusable }) => (
                        <TimeEditor
                            ref={registerFocusable}
                            variant='time'
                            value={fieldState?.valueDate || null}
                            theme='transparent'
                            onChange={(value) => onChange(lineId, field, 'valueDate', value)}
                            disabled={isReadonly}
                        />
                    )}
                </FocusImplementer>
            );

        case 'Datetime':
            return (
                <FocusImplementer>
                    {({ registerFocusable }) => (
                        <TimeEditor
                            ref={registerFocusable}
                            variant='datetime'
                            value={fieldState?.valueDate || null}
                            theme='transparent'
                            onChange={(value) => onChange(lineId, field, 'valueDate', value)}
                            disabled={isReadonly}
                        />
                    )}
                </FocusImplementer>
            );

        case 'Hyperlink':
        case 'PhoneNumber':
        case 'EMailAddress':
            return (
                <FocusImplementer>
                    {({ registerFocusable }) => (
                        <TextEditor
                            ref={registerFocusable}
                            value={fieldState?.valueString || ''}
                            onChange={(value) => onChange(lineId, field, 'valueString', value)}
                            placeholder={messages.inputDefaultPlaceholder}
                            changeOnBlur
                            theme='transparent'
                            maxLength={getCustomFieldMaxLength(field)}
                            disabled={isReadonly}
                        />
                    )}
                </FocusImplementer>
            );

        case 'TextArea':
        case 'LongText':
        case 'FreeFormText':
            return (
                <FocusImplementer>
                    {({ registerFocusable }) => (
                        <TextAreaEditor
                            ref={registerFocusable}
                            value={fieldState?.valueString || ''}
                            onChange={(value) => onChange(lineId, field, 'valueString', value)}
                            changeOnBlur
                            minHeight={2}
                            placeholder={messages.inputDefaultPlaceholder}
                            theme='transparent'
                            maxLength={getCustomFieldMaxLength(field)}
                            disabled={isReadonly}
                        />
                    )}
                </FocusImplementer>
            );

        case 'ListRecord': {
            return (
                <NetSuiteLineItemCustomReferenceCell
                    value={fieldState?.valueRef || null}
                    singleLinePerItem={false}
                    placeholder={messages.selectDefaulPlaceholder}
                    onChange={(value: Reference | null) => onChange(lineId, field, 'valueRef', value)}
                    disabled={isReadonly}
                    fieldId={field.id}
                    sublistType={sublistType}
                />
            );
        }

        case 'MultipleSelect': {
            return (
                <NetSuiteLineItemCustomReferenceCell
                    value={fieldState?.valueRefs || null}
                    singleLinePerItem={false}
                    placeholder={messages.selectDefaulPlaceholder}
                    multiple
                    fieldId={field.id}
                    sublistType={sublistType}
                    onChange={(value: Reference[] | null) => onChange(lineId, field, 'valueRefs', value)}
                    disabled={isReadonly}
                />
            );
        }

        default:
            return null;
    }
};

export const sortCustomFields = (customFields: domain.Field[]) => {
    return [...customFields].sort((field1, field2) => {
        if (field1.netSuiteField && field2.netSuiteField) {
            // Custom segments fields first
            if (
                field1.netSuiteField.fieldScriptId.startsWith('cseg') &&
                !field2.netSuiteField.fieldScriptId.startsWith('cseg')
            ) {
                return -1;
            }

            if (
                !field1.netSuiteField.fieldScriptId.startsWith('cseg') &&
                field2.netSuiteField.fieldScriptId.startsWith('cseg')
            ) {
                return 1;
            }

            return field1.netSuiteField.order - field2.netSuiteField.order;
        }

        return 0;
    });
};

export const isFutureDate = (date?: string | null) => {
    if (date) {
        const momentNow = moment().startOf('day');

        const momentDate = moment(date).startOf('day');

        return momentDate.isAfter(momentNow);
    }

    return false;
};

export const mapAddress = (address: domain.BillingAddress | null): domain.BillingAddress | null => {
    if (!address) {
        return null;
    }

    return {
        id: address.id,
        fullAddress: address.fullAddress?.replace(/<br>|\n/g, ' ') || null,
    };
};

export const mapVendor = (vendor: PascalCaseToCamelCase<backend.IntegrationsNetSuiteVendor>['vendor'] | null) => {
    if (!vendor) {
        return null;
    }

    return {
        ...vendor,
        text: vendor.name,
        currency: mapReference(vendor.currency),
        defaultBillingAddress: mapAddress(vendor.defaultBillingAddress),
        defaultExpenseAccount: mapReference(vendor.defaultExpenseAccount),
        defaultPayablesAccount: mapReference(vendor.defaultPayablesAccount),
        incoterm: mapReference(vendor.incoterm),
        terms: mapReference(vendor.terms),
    };
};

export const mapEmployee = (
    employee: PascalCaseToCamelCase<backend.IntegrationsNetSuiteEmployee>['employee'] | null,
    currencies: Reference[]
): domain.NetSuiteVendor | null => {
    if (!employee) {
        return null;
    }

    const defaultBillingAddress = employee.addresses?.find(({ isDefaultBillings }) => isDefaultBillings);

    return {
        id: employee.id,
        text: employee.name,
        currency: currencies?.find(({ id }) => id === employee.defaultCurrencyId) ?? null,
        currencyIds: employee.currencyIds,
        defaultBillingAddress: defaultBillingAddress
            ? mapAddress({ id: defaultBillingAddress.id, fullAddress: defaultBillingAddress.name })
            : null,
        address:
            employee.addresses
                ?.map(({ id, name: fullAddress }) => mapAddress({ id, fullAddress }))
                .filter((address) => address !== null) ?? null,
        email: null,
        taxNumber: null,
        defaultExpenseAccount: null,
        defaultPayablesAccount: null,
        incoterm: null,
        terms: null,
    };
};

export const isFieldMandatory = (documentFieldState?: domain.DocumentFieldState) =>
    documentFieldState === domain.DocumentFieldState.Mandatory;

export const isFieldVisible = (documentFieldState?: domain.DocumentFieldState) =>
    documentFieldState !== domain.DocumentFieldState.Hidden;

export const isFieldReadOnly = (documentFieldState?: domain.DocumentFieldState) =>
    documentFieldState === domain.DocumentFieldState.ReadOnly;

export const isFieldEditable = (documentFieldState?: domain.DocumentFieldState) =>
    documentFieldState === domain.DocumentFieldState.Editable ||
    documentFieldState === domain.DocumentFieldState.Mandatory;

const isValueEmpty = (value: NetSuiteFieldValue) => (typeof value === 'string' ? !value.trim() : !value);

export const isMandatoryFieldEmpty = (value: NetSuiteFieldValue, documentFieldState?: domain.DocumentFieldState) =>
    isFieldMandatory(documentFieldState) && isValueEmpty(value);

export const isOptionalFieldInvalid = (
    showErrors: boolean,
    value: NetSuiteFieldValue,
    documentFieldState?: domain.DocumentFieldState
) => showErrors && isMandatoryFieldEmpty(value, documentFieldState);

export const convertFieldsToState = (fields: domain.NetSuiteCustomField[]): CustomFieldsState => {
    return fields.reduce<CustomFieldsState>((total, current) => {
        total[current.companyFieldId] = current;

        return total;
    }, {});
};
