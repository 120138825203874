import { Box, Spacing } from '@approvalmax/ui/src/components';
import { Cin7BlueLogo } from 'modules/sprites';
import { FC, memo } from 'react';

import { ConnectToOrganisationButton, PopupFooter, PopupTitle } from '..';
import { InviteTeammateSuggestion } from '../InviteTeammateSuggestion/InviteTeammateSuggestion';
import { messages } from './Cin7Content.messages';
import { Cin7ContentProps } from './Cin7Content.types';

const Cin7Content: FC<Cin7ContentProps> = memo((props) => {
    const { isLoading, onConnectToOrganisation, onSkip, onBack, withInviteTeammate, onInviteTeammate } = props;

    return (
        <>
            <PopupTitle />

            <Box spacing='20 0 0' width={300}>
                <ConnectToOrganisationButton
                    onClick={onConnectToOrganisation}
                    logo={Cin7BlueLogo}
                    titleSystemName={messages.titleCin7Name}
                    systemName={messages.cin7Name}
                    width={300}
                    isLoading={isLoading}
                />
            </Box>

            {withInviteTeammate ? (
                <>
                    <Spacing height={24} />

                    <InviteTeammateSuggestion onInviteTeammate={onInviteTeammate} />

                    <Spacing height={24} />
                </>
            ) : (
                <Spacing height={32} />
            )}

            <PopupFooter isLoading={isLoading} withBackButton onBack={onBack} onSkip={onSkip} />
        </>
    );
});

export default Cin7Content;
