import { Guid, Reference } from '@approvalmax/types';

import { FieldSystemPurpose } from './Field';

export enum ConditionType {
    NumericRangeCondition = 'NumericRangeCondition',
    ExactValuesCondition = 'ExactValuesCondition',
    NegativeExactValuesCondition = 'NegativeExactValuesCondition',
    ServerCondition = 'ServerCondition',
    BoolCondition = 'BoolCondition',
}

export enum NumericRangeConditionType {
    Any = 'Any',
    Above = 'Above',
    Below = 'Below',
    Within = 'Within',
}

export enum ServerConditionType {
    SuppliersOnly = 'SuppliersOnly',
    CustomersOnly = 'CustomersOnly',
    AllContacts = 'AllContacts',
}

export enum AmountType {
    Gross = 'Gross',
    Net = 'Net',
}

export interface BaseCondition {
    fieldId: Guid;
    fieldName: string;
    fieldSystemPurpose: FieldSystemPurpose;
    allowEditing?: boolean;
    allowCreation?: boolean;
}

export interface AlwaysTrueCondition extends BaseCondition {
    conditionType: null;
}

export interface NumericRangeCondition extends BaseCondition {
    conditionType: ConditionType.NumericRangeCondition;
    numericRangeConditionType: NumericRangeConditionType;
    numericRangeLess: number | null;
    numericRangeGreaterEquals: number | null;
    amountType: AmountType;
}

export interface ExactValuesGroup {
    id: string;
    versionNumber: number;
    versionId: string;
}

export interface ExactValuesCondition extends BaseCondition {
    conditionType: ConditionType.ExactValuesCondition | ConditionType.NegativeExactValuesCondition;
    exactValues: Reference[];
    exactValuesGroups?: ExactValuesGroup[];
}

export interface ServerCondition extends BaseCondition {
    conditionType: ConditionType.ServerCondition;
    serverConditionType: ServerConditionType;
}

export interface BoolCondition extends BaseCondition {
    conditionType: ConditionType.BoolCondition;
    exactConstraintBool: boolean | null;
}

export type MatrixCondition =
    | AlwaysTrueCondition
    | NumericRangeCondition
    | ExactValuesCondition
    | ServerCondition
    | BoolCondition;

export interface MatrixRule {
    conditions: MatrixCondition[];
}

export interface MatrixLine {
    lineId: Guid;
    rules: MatrixRule[];
    isBackup?: boolean;
}

export interface AutoApproveMatrixLine {
    lineId: Guid;
    conditions: MatrixCondition[];
}
