import React, { FC, memo, Ref } from 'react';
import bemFactory from 'react-bem-factory';
import Input, { Value } from 'react-phone-number-input/input';

import { BaseEditorProps } from '../EditorBase';
import TextEditor from '../text/TextEditor';

interface PhoneEditorProps extends BaseEditorProps<string> {
    className?: string;
    placeholder?: string;
    maxLength?: number;
    onEnter?: () => void;
    changeOnBlur?: boolean;
    onEscape?: () => void;
    onUp?: () => void;
    onDown?: () => void;
}

const TextComponent: any = React.forwardRef((props: PhoneEditorProps, ref: Ref<HTMLInputElement>) => {
    return <TextEditor {...props} spellCheck={false} ref={ref} type='tel' />;
});

const bem = bemFactory.block('form-phone-editor');

const PhoneEditor: FC<PhoneEditorProps> = (props) => {
    const { className, value, onChange } = props;

    const handleChange = (value: Value) => {
        onChange(value as string);
    };

    return (
        <div className={bem.add(className)()}>
            <Input {...props} value={value as Value} onChange={handleChange} inputComponent={TextComponent} />
        </div>
    );
};

export default memo(PhoneEditor);
