import { constants } from '@approvalmax/utils';
import isError from 'lodash/isError';
import { useCallback, useMemo } from 'react';
import { DropzoneProps as DropzoneExternalProps } from 'react-dropzone';

import { toast } from '../Toast/Toast.helpers';
import { getErrorMessage, prepareFiles } from './AvatarDropzone.helpers';
import { errorMessages } from './AvatarDropzone.messages';
import { AvatarDropzoneProps, ExtensionsType } from './AvatarDropzone.types';
import { FileErrorToast } from './components/FileErrorToast/FileErrorToast';

export const useAvatarDropzone = (props: AvatarDropzoneProps) => {
    const { accept, onDropRejected, onDropAccepted, onUpload, minSize = 0, maxSize = 100000 } = props;

    const acceptedExtensions = useMemo(
        () =>
            accept
                ? Object.values(accept)
                      .map((ext) => ext.map((el) => el.replaceAll('.', '')).join(', '))
                      .join(', ')
                : '',
        [accept]
    );

    const onDropAcceptedFiles = useCallback<Required<DropzoneExternalProps>['onDropAccepted']>(
        async (acceptedFiles, event) => {
            const file = prepareFiles(acceptedFiles)[0];

            try {
                await onUpload(file);

                onDropAccepted?.([file], event);
            } catch (error) {
                if (isError(error)) {
                    toast.error(error.message);
                }
            }
        },
        [onDropAccepted, onUpload]
    );

    const onDropRejectedFiles = useCallback<Required<DropzoneExternalProps>['onDropRejected']>(
        (rejectedFiles, event) => {
            if (rejectedFiles.length > 1) {
                toast.error(errorMessages.tooManyFiles);
            } else {
                const { file, errors } = rejectedFiles[0];

                errors.map((error) => {
                    toast.error(
                        <FileErrorToast title={file.name} message={getErrorMessage(error, { maxSize, minSize })} />
                    );
                });
            }

            onDropRejected?.(rejectedFiles, event);
        },
        [maxSize, minSize, onDropRejected]
    );

    return {
        onDropAcceptedFiles,
        onDropRejectedFiles,
        acceptedExtensions,
    };
};

export const useExtensions = <Extension extends keyof ExtensionsType>(extensionTypes: Extension[]) => {
    return useMemo(
        () =>
            extensionTypes.reduce(
                (acc, item) => {
                    acc = {
                        ...acc,
                        ...constants.extensions[item],
                    };

                    return acc;
                },
                {} as ExtensionsType[Extension]
            ),
        [extensionTypes]
    );
};
