import isBoolean from 'lodash/isBoolean';
import { constants, selectors } from 'modules/common';
import { domain } from 'modules/data';

import { messages } from './StatusBadge.messages';
import { ResolutionMode, StatusBadgeMode, StatusBadgeProps } from './StatusBadge.types';

export const useResolutionBadge = (request: selectors.types.ExpandedRequest) => {
    const hasAfterApprovalChange = request.fraudulentActivity.includes(
        domain.RequestFraudulentActivity.PostApprovalChange
    );
    const hasExternalResolving = request.fraudulentActivity.includes(
        domain.RequestFraudulentActivity.ExternalResolving
    );

    const isApproved = request.statusV2 === domain.RequestStatusV2.Approved;
    const isCancelled = request.statusV2 === domain.RequestStatusV2.Cancelled;
    const isRejected = request.statusV2 === domain.RequestStatusV2.Rejected;
    const isOnReview = request.statusV2 === domain.RequestStatusV2.OnReview;

    const isQBooksBillPaid = request.integrationCode === domain.IntegrationCode.QBooksBill && request.details.isPaid;
    const isQBooksInvoicePaid =
        request.integrationCode === domain.IntegrationCode.QBooksInvoice && request.details.isPaid;

    const isXeroPaid =
        request.integrationType === domain.IntegrationType.Xero &&
        request.integrationCode !== domain.IntegrationCode.XeroContact &&
        request.integrationCode !== domain.IntegrationCode.XeroManualJournal &&
        request.details.status === domain.XeroObjectStatus.Paid;

    const isNetSuitePaid =
        (request.integrationCode === domain.IntegrationCode.NetSuiteBill ||
            request.integrationCode === domain.IntegrationCode.NetSuitePO) &&
        request.details.status === domain.NetSuiteObjectStatus.Paid;

    const isNetSuiteInvoicePaid =
        request.integrationCode === domain.IntegrationCode.NetSuiteInvoice &&
        request.details.status === domain.NetSuiteInvoiceStatus.Paid;

    const isPaid =
        isApproved &&
        (isXeroPaid || isNetSuitePaid || isNetSuiteInvoicePaid || isQBooksBillPaid || isQBooksInvoicePaid);

    const isPendingPush =
        (request.details.integrationType === domain.IntegrationType.Xero && request.details.isStatusPushPending) ||
        (request.details.integrationType === domain.IntegrationType.QBooks &&
            request.details.integrationCode !== domain.IntegrationCode.QBooksVendor &&
            request.details.isStatusPushPending);

    let badgeMode: StatusBadgeProps['mode'];
    let badgeLabel: StatusBadgeProps['label'] = '';
    let badgeHasIcon: StatusBadgeProps['hasIcon'] = false;
    let badgeQaMode: ResolutionMode = ResolutionMode.None;
    let badgeTooltip: string[] = [];

    if (isOnReview) {
        badgeMode = StatusBadgeMode.Pending;
        badgeLabel = messages.onReviewBadgeText;
    }

    if (isApproved) {
        badgeMode = hasExternalResolving ? StatusBadgeMode.Warning : StatusBadgeMode.Success;
        badgeLabel = messages.approvedBadgeText;
        badgeHasIcon = hasExternalResolving || hasAfterApprovalChange;
        badgeQaMode = ResolutionMode.Paid;
    }

    if (isPaid) {
        badgeMode = hasExternalResolving ? StatusBadgeMode.Warning : StatusBadgeMode.Info;
        badgeLabel = messages.paidBadgeText;
        badgeHasIcon = hasExternalResolving || hasAfterApprovalChange;
        badgeQaMode = ResolutionMode.Paid;
    }

    if (isCancelled) {
        badgeMode = StatusBadgeMode.Cancelled;
        badgeLabel = messages.cancelledBadgeText;
        badgeQaMode = ResolutionMode.Cancelled;
    }

    if (isRejected) {
        badgeMode = StatusBadgeMode.Rejected;
        badgeLabel = messages.rejectedBadgeText;
        badgeQaMode = ResolutionMode.Rejected;
    }

    if (isPendingPush) {
        badgeMode = StatusBadgeMode.Pending;
        badgeTooltip.push(messages.pendingPushTitle({ integrationName: request.company.integration?.displayName }));
    }

    if (request.majorFraudulentActivityTexts.length) {
        badgeTooltip = [...badgeTooltip, ...request.majorFraudulentActivityTexts];
    }

    if (!isCancelled && request.integrationCode === domain.IntegrationCode.XeroQuote) {
        switch (request.details.quoteStatus) {
            case domain.XeroQuoteStatus.Accepted:
                badgeMode = StatusBadgeMode.Success;
                badgeLabel = messages.acceptedBadgeText;
                break;

            case domain.XeroQuoteStatus.Declined:
                badgeMode = StatusBadgeMode.Rejected;
                badgeLabel = messages.declinedBadgeText;
                break;

            case domain.XeroQuoteStatus.Sent:
                if (isBoolean(request.details.isAccepted)) {
                    badgeMode = StatusBadgeMode.Pending;
                    badgeLabel = request.details.isAccepted ? messages.acceptedBadgeText : messages.declinedBadgeText;
                }
        }
    }

    if (request.integrationCode === domain.IntegrationCode.XeroAirwallexBatchPayment) {
        const details = request.details;

        switch (details.paymentStatus) {
            case domain.AirwallexPaymentStatus.AwaitingPayment:
                badgeMode = StatusBadgeMode.Success;
                badgeLabel = messages.awaitingPaymentBadgeText;
                break;

            case domain.AirwallexPaymentStatus.Processing:
                badgeMode = StatusBadgeMode.Success;
                badgeLabel = messages.processingBadgeText;
                break;

            case domain.AirwallexPaymentStatus.Paid:
                badgeMode = StatusBadgeMode.Info;
                badgeLabel = messages.paidBadgeText;
                break;

            case domain.AirwallexPaymentStatus.PartiallyPaid:
                badgeMode = StatusBadgeMode.Info;
                badgeLabel = messages.partiallyPaidBadgeText;
                break;

            case domain.AirwallexPaymentStatus.Failed:
                badgeMode = StatusBadgeMode.Error;
                badgeLabel = messages.failedBadgeText;
                break;
        }
    }

    if (request.integrationCode === domain.IntegrationCode.XeroAmaxPayBatchPayment) {
        const details = request.details;

        switch (details.status) {
            case domain.AmaxPayXeroBatchPaymentStatus.AwaitingPayment:
                badgeMode = StatusBadgeMode.Success;
                badgeLabel = messages.awaitingPaymentBadgeText;
                break;

            case domain.AmaxPayXeroBatchPaymentStatus.Processing:
                badgeMode = StatusBadgeMode.Success;
                badgeLabel = messages.processingBadgeText;
                break;

            case domain.AmaxPayXeroBatchPaymentStatus.Paid:
                badgeMode = StatusBadgeMode.Info;
                badgeLabel = messages.paidBadgeText;
                break;

            case domain.AmaxPayXeroBatchPaymentStatus.PartiallyPaid:
                badgeMode = StatusBadgeMode.Info;
                badgeLabel = messages.partiallyPaidBadgeText;
                break;

            case domain.AmaxPayXeroBatchPaymentStatus.Failed:
                badgeMode = StatusBadgeMode.Error;
                badgeLabel = messages.failedBadgeText;
                break;
        }
    }

    if (request.integrationCode === domain.IntegrationCode.NetSuitePO) {
        const details = request.details;

        if (
            details.status &&
            isApproved &&
            !constants.netSuiteConstants.skippedExternalStatuses.includes(details.status.toLowerCase())
        ) {
            badgeMode = hasExternalResolving ? StatusBadgeMode.Warning : StatusBadgeMode.Info;
            badgeLabel = details.status;
        }
    }

    if (request.integrationCode === domain.IntegrationCode.NetSuiteExpenseReport) {
        switch (request.details.status) {
            case domain.NetSuiteExpenseReportStatus.InProgress:
                badgeMode = StatusBadgeMode.Rejected;
                badgeLabel = messages.inProgressBadgeText;
                badgeHasIcon = false;
                break;

            case domain.NetSuiteExpenseReportStatus.PaidInFull:
                badgeMode = StatusBadgeMode.Info;
                badgeLabel = messages.paidInFullBadgeText;
                badgeHasIcon = false;
                break;
        }
    }

    if (
        request.integrationType === domain.IntegrationType.NetSuite &&
        (request.resolutionOrigin === domain.RequestResolutionOrigin.EnforcedExternally ||
            request.resolutionOrigin === domain.RequestResolutionOrigin.ResolvedExternally)
    ) {
        badgeHasIcon = true;
    }

    if (!badgeLabel || !badgeMode) {
        return null;
    }

    return {
        mode: badgeMode,
        label: badgeLabel,
        hasIcon: badgeHasIcon,
        tooltip: badgeTooltip.join('\n'),
        qaMode: badgeQaMode,
    };
};
