import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('app.WorkflowIdPage.GroupOfValuesPopup.CreationPopupContent', {
    popupTitle: 'New Group of values',
    create: 'Create',
    requiredField: 'Field is required',
    fieldType: 'Field type',
    groupName: 'Group name',
    enterName: 'Enter name',
    account: 'Account',
    fieldTypeHint: 'Only Account is currently available',
    successMessage: 'Group of accounts has been successfully created.',
    conflictErrorMessage: 'Another group already exists with this name.',
});
