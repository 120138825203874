export const requestsApiPaths = <const>{
    requests: 'companies/:companyId/requests',
    dextTransaction: 'companies/:companyId/requests/:requestId/dext/transaction',
    batchPaymentAmaxPayXeroBill:
        'companies/:companyId/requests/:batchPaymentRequestId/amaxPay/xero/bills/:billRequestId',
    amaxPayXeroBatchPayments: 'companies/:companyId/requests/:requestId/amaxPay/xero/batchPayments',
    amaxPayXeroBillTransaction:
        'companies/:companyId/requests/:requestId/amaxPay/xero/bills/:billRequestId/transaction',
    batchPaymentAmaxPayXeroBillAttachment:
        'companies/:companyId/requests/:batchPaymentRequestId/amaxPay/xero/bills/:billRequestId/attachments/:attachmentId',
    batchPaymentAirwallexXeroBillAttachment:
        'companies/:companyId/requests/:batchPaymentRequestId/airwallex/xero/bills/:billRequestId/attachments/:attachmentId',
    batchPaymentXeroBillAttachment:
        'companies/:companyId/requests/:batchPaymentRequestId/xero/bills/:billRequestId/attachments/:attachmentId',
    batchPaymentXeroBill: 'companies/:companyId/requests/:batchPaymentRequestId/xero/bills/:billRequestId',
    currencyExchangeRate: 'companies/:companyId/requests/:requestId/currencyExchangeRate',
    standaloneRequestCurrencies: 'companies/:companyId/standalone/requests/:requestId/currencies',
    netSuiteRequestsDuplicates: 'companies/:companyId/netsuite/requests/duplicates',
    qBooksRequestsDuplicates: 'companies/:companyId/qbooks/requests/duplicates',
    xeroRequestsDuplicates: 'companies/:companyId/xero/requests/duplicates',
    versions: 'companies/:companyId/requests/:requestId/versions',
    requestVersion: 'companies/:companyId/requests/:requestId/versions/:requestVersion',
    putOnHold: 'companies/:companyId/requests/:requestId/putOnHold',
    takeOffHold: 'companies/:companyId/requests/:requestId/takeOffHold',
    netSuiteBillPaymentBills: 'companies/:companyId/netsuite/bill-payments/:billPaymentRequestId/lines',
    netSuiteBillPaymentBill: 'companies/:companyId/netsuite/bill-payments/:billPaymentRequestId/lines/:lineRequestId',
    netSuiteBillPaymentBillAttachment:
        'companies/:companyId/netsuite/bill-payments/:billPaymentRequestId/lines/:lineRequestId/attachments/:attachmentId',
};
